import { ContactsDetailsActions } from './contacts-details.actions';
import {
  ContactsDetailsState,
  initialContactsDetailsState,
} from './contacts-details.models';

export function contactsDetailsReducer(
  state: ContactsDetailsState = initialContactsDetailsState,
  action: ContactsDetailsActions
): ContactsDetailsState {
  switch (action.type) {
    case 'FETCH_CONTACTS':
      return { ...state, fetchContactsOnGoing: true };
    case 'FETCH_CONTACTS_SUCCESS':
      return {
        ...state,
        contacts: action.payload.entries.map((entry) => ({
          type: action.contactType,
          businessUnit: entry.businessUnit,
          country: entry.country,
          entity: entry.profile,
          actionLevel: entry.action,
          updatedAt: entry.updatedAt,
          updatedBy: entry.updatedBy,
        })),
        page: action.payload.pageInfo.page,
        totalPages: action.payload.pageInfo.totalPages,
        fetchContactsOnGoing: false,
      };
    case 'FETCH_CONTACTS_FAIL':
      return {
        ...state,
        fetchContactsOnGoing: false,
        contacts: [],
        page: undefined,
        totalPages: undefined,
      };
    default: {
      return state;
    }
  }
}
