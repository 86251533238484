import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { Store } from '@ngrx/store';
import { hasAccessToExportPage } from '@shared/utils/access/access.utils';
import {
  redirectToHome,
  showDangerNotification,
} from '@store/header/header.actions';
import { AppState } from '@store/root/root.reducer';
import { getCurrentUserAccesses } from '@store/root/root.selectors';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class ExportGuard implements CanActivate, CanActivateChild {
  constructor(private store: Store<AppState>) {}

  public canActivateChild(): Observable<boolean> {
    return this.store.select(getCurrentUserAccesses).pipe(
      filter((accesses) => !!accesses),
      map((accesses) => {
        if (hasAccessToExportPage(accesses)) {
          return true;
        }
        this.store.dispatch(redirectToHome());
        this.store.dispatch(showDangerNotification('You are not authorized!'));
        return false;
      })
    );
  }

  public canActivate(): Observable<boolean> {
    return this.store.select(getCurrentUserAccesses).pipe(
      filter((accesses) => !!accesses),
      map((accesses) => {
        if (hasAccessToExportPage(accesses)) {
          return true;
        }
        this.store.dispatch(redirectToHome());
        this.store.dispatch(showDangerNotification('You are not authorized!'));
        return false;
      })
    );
  }
}
