import { BusinessUnitActions } from './business-unit.actions';
import {
  BusinessUnitState,
  initialBusinessUnitState,
} from './business-unit.models';

export function businessUnitReducer(
  state: BusinessUnitState = initialBusinessUnitState,
  action: BusinessUnitActions
): BusinessUnitState {
  switch (action.type) {
    case 'FETCH_BUSINESS_UNITS':
      return { ...state, fetchBusinessUnitsOnGoing: true };
    case 'FETCH_BUSINESS_UNITS_SUCCESS':
      const { businessUnits } = action;

      if (action.underPerimeter) {
        return {
          ...state,
          fetchBusinessUnitsOnGoing: false,
          perimeterBusinessUnits: businessUnits,
        };
      }
      return {
        ...state,
        fetchBusinessUnitsOnGoing: false,
        allBusinessUnits: businessUnits,
      };
    case 'FETCH_BUSINESS_UNITS_FAIL':
      if (action.underPerimeter) {
        return {
          ...state,
          fetchBusinessUnitsOnGoing: false,
          perimeterBusinessUnits:
            initialBusinessUnitState.perimeterBusinessUnits,
        };
      }
      return {
        ...state,
        fetchBusinessUnitsOnGoing: false,
        allBusinessUnits: initialBusinessUnitState.allBusinessUnits,
      };

    default: {
      return state;
    }
  }
}
