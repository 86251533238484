import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CountryEffects } from '@store/country/country.effects';
import { CtaSpecificEffects } from '@store/cta-specific/cta-specific.effects';
import { CtaEffects } from '@store/cta/cta.effects';
import { GuidelineEffects } from '@store/guideline/guideline.effects';
import { HeaderEffects } from '@store/header/header.effects';
import {
  initialAppState,
  metaReducers,
  rootReducer,
} from '@store/root/root.reducer';
import { UserEffects } from '@store/user/user.effects';
import { AcknowledgementEffects } from './acknowledgement/acknowledgement.effects';
import { BusinessUnitEffects } from './business-unit/business-unit.effects';
import { ContactsDetailsEffects } from './contacts-details/contacts-details.effects';
import { DelegationEffects } from './delegation/delegation.effects';
import { GuidelineEditEffects } from './guideline/edit/guideline-edit.effects';
import { GuidelineViewEffects } from './guideline/view/guideline-view.effects';
import { ValidatorEffects } from './validator/validator.effects';

export const routerStateConfig = {
  stateKey: 'router',
};

@NgModule({
  imports: [
    StoreModule.forRoot(rootReducer, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
      metaReducers,
      initialState: initialAppState,
    }),
    StoreModule.forFeature(routerStateConfig.stateKey, routerReducer),
    StoreRouterConnectingModule.forRoot(routerStateConfig),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states,
      logOnly: !window.configurations.production,
    }),
    EffectsModule.forRoot([
      AcknowledgementEffects,
      BusinessUnitEffects,
      ContactsDetailsEffects,
      CountryEffects,
      CtaEffects,
      CtaSpecificEffects,
      DelegationEffects,
      GuidelineEffects,
      GuidelineEditEffects,
      GuidelineViewEffects,
      HeaderEffects,
      UserEffects,
      ValidatorEffects,
    ]),
  ],
})
export class AppStoreModule {
  public static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: AppStoreModule,
    };
  }
}
