import { RouterNavigationAction } from '@ngrx/router-store';
import {
  AddHeaderItem,
  HeaderActions,
  RemoveHeaderItem,
} from './header.actions';
import { HeaderState, initialHeaderState } from './header.models';

export function headerReducer(
  state: HeaderState = initialHeaderState,
  action: HeaderActions | RouterNavigationAction
): HeaderState {
  switch (action.type) {
    // TODO: Remove; This is available in the Router feature
    // case ROUTER_NAVIGATION:
    //   return {
    //     ...state,
    //     items: state.items.map(menuItem => ({
    //       ...menuItem,
    //       selected: action.payload.routerState.url.indexOf(menuItem.link) === 0,
    //     })),
    //     route: action.payload.routerState.url,
    //   };

    case 'ADD_HEADER_ITEM':
      return onAddHeaderItem(state, action);
    case 'REMOVE_HEADER_ITEM':
      return onRemoveHeaderItem(state, action);

    default:
      return state;
  }
}

function onAddHeaderItem(
  state: HeaderState,
  action: AddHeaderItem
): HeaderState {
  return {
    ...state,
    items: [...state.items, action.menuItem],
  };
}
function onRemoveHeaderItem(
  state: HeaderState,
  action: RemoveHeaderItem
): HeaderState {
  return {
    ...state,
    items: state.items.filter((item) => item.key !== action.key),
  };
}
