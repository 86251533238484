import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Validator, ValidatorRule } from '@models/Validator';
import { REGEX } from '@shared/utils';
import { warningMessagesGenerator } from '@store/validator/validator.helper';

@Component({
  selector: 'sgxb-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent {
  @Input() public type = 'text';
  @Input() public label = null;
  @Input() public mandatory = false;
  @Input() public maxlength = Infinity;
  @Input() public placeholder = '';
  @Output() public input = new EventEmitter();
  @Output() public focus = new EventEmitter<FocusEvent>();
  @Output() public blur = new EventEmitter<FocusEvent>();
  @Output() public validate = new EventEmitter<boolean>();

  private _disabled = false;
  private _validators: Validator[];
  private _value = '';
  private _fileSelected: boolean = false;
  public warnings: string[] = [];
  public touched = false;
  @Input() set value(value: string) {
    this._value = value ? value : '';
    if (value !== null && value !== '') {
      this.touched = true;
      this.validate.emit(this.validity);
    }
  }
  get value(): string {
    return this._value;
  }
  @Input()
  set validators(validators: Validator[]) {
    this._validators = validators || null;
  }
  get validators(): Validator[] {
    return this._validators;
  }
  @Input() set disabled(value: boolean) {
    this._disabled = value;
    this.touched = false;
    if (this._disabled) {
      this.validate.emit(true);
    }
  }
  get disabled(): boolean {
    return this._disabled;
  }

  set fileSelected(fileSelected: boolean) {
    this._fileSelected = fileSelected;
  }
  get fileSelected(): boolean {
    return this._fileSelected;
  }

  constructor() {}

  get required(): boolean {
    if (this.validators) {
      return (
        !this.disabled &&
        !!this.validators.find(
          (validator) => validator.rule === ValidatorRule.REQUIRED
        )
      );
    }
    return !this.disabled;
  }

  get validity(): boolean {
    this.warnings = [];

    if (!this.disabled && this.validators && this.validators.length > 0) {
      const conditions = this.validators
        .map((validator) => {
          switch (validator.rule) {
            case ValidatorRule.REGEX: {
              return {
                rule: validator.rule,
                shouldRaiseWarning:
                  this.value !== null &&
                  this.value !== '' &&
                  (validator.constraint
                    ? !REGEX.internalEmail.test(this.value)
                    : true),
              };
            }
            case ValidatorRule.REQUIRED: {
              return {
                rule: validator.rule,
                shouldRaiseWarning:
                  (this.type !== 'file' && this.value === '') ||
                  (this.type === 'file' && !this.fileSelected),
              };
            }
            case ValidatorRule.FORBIDDEN: {
              return {
                rule: validator.rule,
                shouldRaiseWarning: validator.constraint.includes(
                  this.value.toLowerCase()
                ),
              };
            }
            default:
              return;
          }
        })
        .filter((o) => o !== null && o !== undefined);
      this.warnings = warningMessagesGenerator(
        this.warnings,
        this.validators,
        conditions
      );
    }
    const valid =
      !this.validators ||
      (this.validators &&
        this.validators.length > 0 &&
        this.warnings &&
        this.warnings.length === 0);
    this.validate.emit(valid);
    return valid;
  }

  public onFocus(event: FocusEvent) {
    this.focus.emit(event);
  }

  public onBlur(event: FocusEvent) {
    this.touched = true;
    this.blur.emit(event);
  }

  /* No input event for type = file */
  public onChange(event) {
    if (this.type === 'file') {
      this._fileSelected = event.target.files.length > 0;
      this.input.emit(event);
    }
  }

  public onInput(event) {
    this.touched = true;
    this.input.emit(event);
    this.validate.emit(this.validity);
  }
}
