<div>
  <ol class="breadcrumb">
    <li>
      <a routerLink="/home">Home</a>
    </li>

    <ng-container *ngFor="let breadcrumbItem of breadcrumbItems">
      <li
        *ngIf="breadcrumbItem.hasLink; else itemWithoutLink"
        [class.active]="breadcrumbItem.active"
      >
        <a
          *ngIf="breadcrumbItem.routerLink; else linkWithoutRoute"
          [routerLink]="breadcrumbItem.routerLink"
          >{{ breadcrumbItem.label | titlecase }}</a
        >

        <ng-template #linkWithoutRoute>
          <a>{{ breadcrumbItem.label | titlecase }}</a>
        </ng-template>
      </li>
      <ng-template #itemWithoutLink>
        <li [class.active]="breadcrumbItem.active">
          {{ breadcrumbItem.label | titlecase }}
        </li>
      </ng-template>
    </ng-container>
  </ol>
</div>
