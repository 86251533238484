import {
  CtaQuestion,
  CtaQuestionType,
  MOCK_TAR_SPECIFIC,
} from '@models/ComplianceTravelApproval';
import { Scope } from '@models/Guideline';
import { v4 as uuidv4 } from 'uuid';
import {
  CtaSpecificActions,
  FetchPreviewFromQuestionsSuccess,
} from './cta-specific.actions';
import {
  CtaImportantLevel,
  CtaPresetValueType,
  CtaSpecificState,
  CtaValidationLevel,
  UITravelApprovalQuestion,
  initialCtaSpecificState,
} from './cta-specific.models';

export function ctaSpecificReducer(
  state: CtaSpecificState = initialCtaSpecificState,
  action: CtaSpecificActions
): CtaSpecificState {
  switch (action.type) {
    case 'FETCH_QUESTIONS':
      return { ...state, loading: true, saveOnGoing: true, questions: null };
    case 'FETCH_QUESTIONS_SUCCESS':
      return initUIState(state, action.questions);
    case 'FETCH_QUESTIONS_FAIL':
      return { ...state, questions: [], loading: false, saveOnGoing: false };
    case 'FETCH_QUESTIONS_FOR_DUPLICATION':
      return {
        ...state,
        loading: true,
        saveOnGoing: true,
        questions: state.questions,
      };
    case 'FETCH_QUESTIONS_FOR_DUPLICATION_SUCCESS':
      return initUIState(state, action.questions);
    case 'FETCH_QUESTIONS_FOR_DUPLICATION_FAIL':
      return { ...state, questions: [], loading: false, saveOnGoing: false };
    case 'FETCH_PREVIEW_FROM_QUESTIONS':
      return { ...state, fetchQuestionsPreviewOnGoing: true };
    case 'FETCH_PREVIEW_FROM_QUESTIONS_SUCCESS':
      return fetchPreiewFromQuestionsSuccess(state, action);
    case 'FETCH_PREVIEW_FROM_QUESTIONS_FAIL':
      return {
        ...state,
        fetchQuestionsPreviewOnGoing: false,
        questions: initialCtaSpecificState.questions,
      };
    case 'UPDATE_PREVIEW':
      return {
        ...state,
        taPreview: {
          ...state.taPreview,
          answers: action.cta.answers,
        },
      };
    case 'EDIT_DESCRIPTION':
      return editDescriptionFor(state, action.qIndex, action.newValue);
    case 'EDIT_OPTION':
      return editOptionFor(
        state,
        action.qIndex,
        action.oIndex,
        action.newValue
      );
    case 'EDIT_SCOPE':
      return editScopeFor(state, action.qIndex, action.newScope);
    case 'ADD_NEW_QUESTION':
      return addNewQuestion(state, action.payload);
    case 'ADD_NEW_QUESTION_WITH_SCOPE':
      return addNewQuestionWithScope(state, action.questionType, action.scope);
    case 'REMOVE_QUESTION':
      return removeQuestion(state, action.payload);
    case 'TOGGLE_QUESTION':
      return toggleQuestionPanel(state, action.payload);
    case 'CHANGE_QUESTION_TYPE':
      return changeQuestionType(state, action.qIndex, action.newType);
    case 'REORDER_QUESTIONS':
      return reorderQuestions(state, action.payload);
    case 'ADD_NEW_OPTION':
      return addNewOptionFor(state, action.payload);
    case 'REMOVE_OPTION':
      return removeOptionFor(state, action.qIndex, action.oIndex);
    case 'REORDER_OPTIONS':
      return reorderOptionsFor(state, action.qIndex, action.newIndexes);
    case 'CONDITIONAL_QUESTION_CHANGE':
      return conditionalQuestionChange(
        state,
        action.qIndex,
        action.conditional
      );
    case 'USE_OR_CHANGE':
      return useOrChange(state, action.qIndex, action.useOr);
    case 'CONDITIONAL_QUESTION_SELECTED':
      return conditionalQuestionSelected(
        state,
        action.qIndex,
        action.cIndex,
        action.qUuid
      );
    case 'CONDITIONAL_OPTION_SELECTED':
      return conditionalOptionSelected(
        state,
        action.qIndex,
        action.cIndex,
        action.oUuid
      );
    case 'ADD_NEW_CONDITIONAL_QUESTION':
      return conditionalQuestionAddNew(state, action.payload);
    case 'REMOVE_CONDITIONAL_QUESTION':
      return conditionalQuestionRemove(state, action.qIndex, action.cIndex);
    case 'SAVE_QUESTIONS':
      return { ...state, saveOnGoing: true };
    case 'CLEAR_QUESTIONS':
      return { ...state, questions: null };
    case 'FETCH_QUESTIONS_FOR_CONFIGURATION':
      return { ...state, loading: true, saveOnGoing: true, questions: null };
    case 'FETCH_QUESTIONS_FOR_CONFIGURATION_SUCCESS':
      return initUIState(state, action.ctaQuestions);
    case 'FETCH_QUESTIONS_FOR_CONFIGURATION_FAIL':
      return { ...state, loading: false, saveOnGoing: false };
    case 'CTA_SPECIFIC_FILTER_RESET':
      return { ...state, pointOfView: null };
    case 'CTA_SPECIFIC_FILTER_SELECT':
      return { ...state, pointOfView: action.businessUnit };
    case 'CTA_SPECIFIC_CONFIGURATION_FORM_CHANGED':
      return { ...state, isDirty: action.isDirty };
    case 'TEXT_PARAGRAPH_QUESTION_IMPORTANT_LEVEL_CHANGED':
      return ctaImportantLevelChanged(
        state,
        action.qIndex,
        action.importantLevel
      );
    case 'EMAIL_QUESTION_VALIDATION_LEVEL_CHANGED':
      return ctaValidationLevelChanged(
        state,
        action.qIndex,
        action.validationLevel
      );
    case 'EMAIL_QUESTION_PRESET_USER_TYPE_CHANGED':
      return presetValueTypeChanged(
        state,
        action.qIndex,
        action.presetValueType
      );
    case 'EMAIL_QUESTION_PRESET_USER_IS_NOT_EDITABLE_CHANGED':
      return presetUserIsNotEditableChanged(
        state,
        action.qIndex,
        action.isNotEditable
      );

    default:
      return state;
  }
}

function ctaImportantLevelChanged(
  state: CtaSpecificState,
  qIndex: number,
  importantLevel: CtaImportantLevel
): CtaSpecificState {
  const s = getStateForQuestionAt(state, qIndex);
  s.questions[qIndex].importantLevel = importantLevel;
  return s;
}

function ctaValidationLevelChanged(
  state: CtaSpecificState,
  qIndex: number,
  validationLevel: CtaValidationLevel
): CtaSpecificState {
  const s = getStateForQuestionAt(state, qIndex);
  s.questions[qIndex].validationLevel = validationLevel;
  s.questions[qIndex].isCCMandatory =
    validationLevel === 'NOT_REQUIRED' ? false : undefined;
  return s;
}

function presetUserIsNotEditableChanged(
  state: CtaSpecificState,
  qIndex: number,
  isNotEditable: boolean
): CtaSpecificState {
  const s = getStateForQuestionAt(state, qIndex);
  s.questions[qIndex].isNotEditable = isNotEditable;
  return s;
}

function presetValueTypeChanged(
  state: CtaSpecificState,
  qIndex: number,
  presetValueType: CtaPresetValueType
): CtaSpecificState {
  const s = getStateForQuestionAt(state, qIndex);
  s.questions[qIndex].presetValueType = presetValueType;
  s.questions[qIndex].isNotEditable =
    presetValueType === 'SUPERVISOR' ? false : undefined;
  return s;
}

function initUIState(state: CtaSpecificState, taqs: CtaQuestion[]) {
  const questions = [];
  taqs.forEach((item) => questions.push({ ...item, expanded: false }));
  return {
    ...state,
    questions,
    loading: false,
    saveOnGoing: false,
    isDirty: false,
  };
}

function conditionalQuestionSelected(
  state: CtaSpecificState,
  qIndex: number,
  cIndex: number,
  qUuid: string
): CtaSpecificState {
  const s = getStateForConditionAt(state, qIndex, cIndex);
  s.questions[qIndex].conditions[cIndex].question = qUuid;
  if (qUuid !== state.questions[qIndex].conditions[cIndex].question) {
    s.questions[qIndex].conditions[cIndex].option = undefined;
  }
  return s;
}

function conditionalOptionSelected(
  state: CtaSpecificState,
  qIndex: number,
  cIndex: number,
  oUuid: string
): CtaSpecificState {
  const s = getStateForConditionAt(state, qIndex, cIndex);
  s.questions[qIndex].conditions[cIndex].option = oUuid;
  return s;
}

function conditionalQuestionRemove(
  state: CtaSpecificState,
  qIndex: number,
  cIndex: number
): CtaSpecificState {
  const s = getStateForQuestionAt(state, qIndex);
  s.questions[qIndex].conditions = [
    ...state.questions[qIndex].conditions.slice(0, cIndex),
    ...state.questions[qIndex].conditions.slice(cIndex + 1),
  ];
  if (s.questions[qIndex].conditions.length <= 1) {
    s.questions[qIndex].useOr = false;
  }
  return s;
}

function conditionalQuestionAddNew(state: CtaSpecificState, qIndex: number) {
  const s = getStateForConditions(state, qIndex);
  s.questions[qIndex].conditions.push({
    question: undefined,
    option: undefined,
  });
  return s;
}

function conditionalQuestionChange(
  state: CtaSpecificState,
  qIndex: number,
  conditional: boolean
): CtaSpecificState {
  const s = getStateForQuestionAt(state, qIndex);
  s.questions[qIndex].conditions = conditional
    ? [{ question: undefined, option: undefined }]
    : undefined;
  return s;
}

function useOrChange(
  state: CtaSpecificState,
  qIndex: number,
  useOr: boolean
): CtaSpecificState {
  const s = getStateForQuestionAt(state, qIndex);
  s.questions[qIndex].useOr = useOr;
  return s;
}

function editOptionFor(
  state: CtaSpecificState,
  qIndex: number,
  oIndex: number,
  newValue: string
) {
  const s = getStateForOptionAt(state, qIndex, oIndex);
  s.questions[qIndex].options[oIndex].text = newValue;
  return s;
}

function editDescriptionFor(
  state: CtaSpecificState,
  qIndex: number,
  newDesc: string
) {
  const s = getStateForQuestionAt(state, qIndex);
  s.questions[qIndex].description = newDesc;
  return s;
}

function editScopeFor(
  state: CtaSpecificState,
  qIndex: number,
  newScope: Scope
) {
  const s = getStateForQuestionAt(state, qIndex);
  s.questions[qIndex].scope = newScope;
  return s;
}

function toggleQuestionPanel(state: CtaSpecificState, qIndex: number) {
  const s = getStateForQuestionAt(state, qIndex);
  s.questions[qIndex].expanded = !s.questions[qIndex].expanded;
  return s;
}

function changeQuestionType(
  state: CtaSpecificState,
  qIndex: number,
  newType: CtaQuestionType
): CtaSpecificState {
  const s = getStateForQuestions(state);
  s.questions[qIndex] = {
    expanded: true,
    ...MOCK_TAR_SPECIFIC[newType](state.questions[qIndex].scope),
    uuid: state.questions[qIndex].uuid,
    validationLevel: getInitValueForValidationLevel(newType),
  };
  return s;
}

function reorderQuestions(
  state: CtaSpecificState,
  newIndexes: number[]
): CtaSpecificState {
  const s = getStateForQuestions(state);
  for (let i = 0; i < newIndexes.length; i++) {
    s.questions[i] = state.questions[newIndexes[i]];
  }
  return s;
}

function removeQuestion(
  state: CtaSpecificState,
  qIndex: number
): CtaSpecificState {
  return {
    ...state,
    questions: [
      ...state.questions.slice(0, qIndex),
      ...state.questions.slice(qIndex + 1),
    ],
  };
}

function reorderOptionsFor(
  state: CtaSpecificState,
  qIndex: number,
  newIndexes: number[]
): CtaSpecificState {
  const s = getStateForOptions(state, qIndex);
  const previousState = { ...s.questions[qIndex].options };
  for (let i = 0; i < newIndexes.length; i++) {
    s.questions[qIndex].options[i] = previousState[newIndexes[i]];
  }
  return s;
}

function removeOptionFor(
  state: CtaSpecificState,
  qIndex: number,
  oIndex: number
) {
  const s = getStateForQuestionAt(state, qIndex);
  s.questions[qIndex].options = [
    ...s.questions[qIndex].options.slice(0, oIndex),
    ...s.questions[qIndex].options.slice(oIndex + 1),
  ];
  return s;
}

function addNewOptionFor(state: CtaSpecificState, qIndex: number) {
  const s = getStateForOptions(state, qIndex);
  s.questions[qIndex].options.push({
    uuid: uuidv4(),
    text: `Option ${s.questions[qIndex].options.length + 1}`,
  });
  return s;
}

function addNewQuestion(state: CtaSpecificState, questionType: string) {
  const s = getStateForQuestions(state);
  s.questions.push({ expanded: true, ...MOCK_TAR_SPECIFIC[questionType]() });
  return s;
}

function addNewQuestionWithScope(
  state: CtaSpecificState,
  questionType: string,
  scope: Scope
) {
  const s = getStateForQuestions(state);
  s.questions.push({
    expanded: true,
    ...MOCK_TAR_SPECIFIC[questionType](scope),
  });
  return s;
}

function fetchPreiewFromQuestionsSuccess(
  state: CtaSpecificState,
  action: FetchPreviewFromQuestionsSuccess
): CtaSpecificState {
  return {
    ...state,
    questions: getQuestionsForPreview(state, action),
    fetchQuestionsPreviewOnGoing: false,
  };
}

/* ********************************** *
 * Helper functions to get new states *
 * ********************************** */
function getStateForQuestions(state: CtaSpecificState): CtaSpecificState {
  return { ...state, questions: state.questions.slice() };
}
function getStateForQuestionAt(
  state: CtaSpecificState,
  qIndex: number
): CtaSpecificState {
  const s = getStateForQuestions(state);
  s.questions[qIndex] = { ...s.questions[qIndex] };
  return s;
}
function getStateForOptions(
  state: CtaSpecificState,
  qIndex: number
): CtaSpecificState {
  const s = getStateForQuestionAt(state, qIndex);
  s.questions[qIndex].options = s.questions[qIndex].options.slice();
  return s;
}
function getStateForOptionAt(
  state: CtaSpecificState,
  qIndex: number,
  oIndex: number
): CtaSpecificState {
  const s = getStateForOptions(state, qIndex);
  s.questions[qIndex].options[oIndex] = {
    ...s.questions[qIndex].options[oIndex],
  };
  return s;
}
function getStateForConditions(
  state: CtaSpecificState,
  qIndex: number
): CtaSpecificState {
  const s = getStateForQuestionAt(state, qIndex);
  s.questions[qIndex].conditions = s.questions[qIndex].conditions.slice();
  return s;
}
function getStateForConditionAt(
  state: CtaSpecificState,
  qIndex: number,
  cIndex: number
): CtaSpecificState {
  const s = getStateForConditions(state, qIndex);
  s.questions[qIndex].conditions[cIndex] = {
    ...s.questions[qIndex].conditions[cIndex],
  };
  return s;
}

/* ******************************************* *
 * Helper functions for question specific      *
 * ******************************************* */
function getInitValueForValidationLevel(
  type: CtaQuestionType
): CtaValidationLevel {
  return type === 'EMAIL' ? 'FOR_APPROVAL' : undefined;
}

function getQuestionsForPreview(
  state: CtaSpecificState,
  action: FetchPreviewFromQuestionsSuccess
): UITravelApprovalQuestion[] {
  return state && state.questions
    ? state.questions.map((ctaSpecificQuestion) => {
        const previewQuestion = action.questions.find(
          (q) => q.uuid === ctaSpecificQuestion.uuid
        );
        return {
          ...ctaSpecificQuestion,
          ...previewQuestion,
          expanded: false,
          isHiddenInPreview: previewQuestion ? undefined : true,
        };
      })
    : [];
}
