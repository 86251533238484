import { Profile } from '@models/Country';

export const MOCK_API_PROFILE: Profile = {
  name: 'Banking',
  uuid: 'd6a2c7e5-8068-439a-babc-9515e559c3fe',
};

export const MOCK_API_PROFILE_2: Profile = {
  name: 'Securities',
  uuid: 'd6a2c7e5-8068-439a-babc-9515e559c3fe',
};
