import { HttpErrorResponse } from '@angular/common/http';
import { FormFieldValidator } from '@models/Validator';
import { Action } from '@ngrx/store';

export type ValidatorActions =
  | FetchCommonFormValidator
  | FetchCommonFormValidatorSuccess
  | FetchCommonFormValidatorFail
  | FetchSupervisorFieldValidator
  | FetchSupervisorFieldValidatorSuccess
  | FetchSupervisorFieldValidatorFail;

export interface FetchCommonFormValidator extends Action {
  type: 'FETCH_COMMON_FORM_VALIDATOR';
  traveler: string;
}
export function fetchCommonFormValidator(
  traveler: string = undefined
): FetchCommonFormValidator {
  return {
    type: 'FETCH_COMMON_FORM_VALIDATOR',
    traveler,
  };
}

export interface FetchCommonFormValidatorSuccess extends Action {
  type: 'FETCH_COMMON_FORM_VALIDATOR_SUCCESS';
  formValidator: FormFieldValidator[];
}
export function fetchCommonFormValidatorSuccess(
  formValidator: FormFieldValidator[]
): FetchCommonFormValidatorSuccess {
  return {
    type: 'FETCH_COMMON_FORM_VALIDATOR_SUCCESS',
    formValidator,
  };
}

export interface FetchCommonFormValidatorFail extends Action {
  type: 'FETCH_COMMON_FORM_VALIDATOR_FAIL';
  error: HttpErrorResponse;
}
export function fetchCommonFormValidatorFail(
  error: HttpErrorResponse
): FetchCommonFormValidatorFail {
  return {
    type: 'FETCH_COMMON_FORM_VALIDATOR_FAIL',
    error,
  };
}

export interface FetchSupervisorFieldValidator extends Action {
  type: 'FETCH_SUPERVISOR_FIELD_VALIDATOR';
  countryCode: string;
}
export function fetchSupervisorFieldValidator(
  countryCode: string
): FetchSupervisorFieldValidator {
  return {
    type: 'FETCH_SUPERVISOR_FIELD_VALIDATOR',
    countryCode,
  };
}
export interface FetchSupervisorFieldValidatorSuccess extends Action {
  type: 'FETCH_SUPERVISOR_FIELD_VALIDATOR_SUCCESS';
  supervisorFieldValidator: FormFieldValidator;
}
export function fetchSupervisorFieldValidatorSuccess(
  supervisorFieldValidator: FormFieldValidator
): FetchSupervisorFieldValidatorSuccess {
  return {
    type: 'FETCH_SUPERVISOR_FIELD_VALIDATOR_SUCCESS',
    supervisorFieldValidator,
  };
}
export interface FetchSupervisorFieldValidatorFail extends Action {
  type: 'FETCH_SUPERVISOR_FIELD_VALIDATOR_FAIL';
  error: HttpErrorResponse;
}
export function fetchSupervisorFieldValidatorFail(
  error: HttpErrorResponse
): FetchSupervisorFieldValidatorFail {
  return {
    type: 'FETCH_SUPERVISOR_FIELD_VALIDATOR_FAIL',
    error,
  };
}
