import { HttpErrorResponse } from '@angular/common/http';
import { Guideline, Scope } from '@models/Guideline';
import { SourceVersionOwner } from '@models/SourceVersionOwner';
import { Action } from '@ngrx/store';

export type GuidelineEditActions =
  | ChapterAddNew
  | ChapterNameChange
  | ChapterCollapsibleChange
  | ChapterRemove
  | ChaptersReorder
  | SaveMajorVersion
  | SaveMajorVersionFail
  | SaveMajorVersionSuccess
  | SaveMinorVersion
  | SaveMinorVersionFail
  | SaveMinorVersionSuccess
  | SaveScopes
  | SaveScopesFail
  | SaveScopesSuccess
  | SectionAddNew
  | SectionNameChange
  | SectionRemove
  | SectionExpanded
  | SectionsReorder
  | SelectChapterAt
  | SubsectionAddNew
  | SubsectionAddNewWithScope
  | SubsectionContentChange
  | SubsectionRemove
  | SubsectionChangeMode
  | SubsectionUpdateScope
  | GuidelineEditFilterSelect
  | GuidelineEditFilterReset
  | FetchGuidelineForConfiguration
  | FetchGuidelineForConfigurationSuccess
  | FetchGuidelineForConfigurationFail
  | FetchPreviewFromGuideline
  | FetchPreviewFromGuidelineSuccess
  | FetchPreviewFromGuidelineFail
  | GuidelineConfigurationFormChanged
  | UpdateBusinessUnitsForReacknowledgement
  | FetchGuidelineSourceOwners
  | FetchGuidelineSourceOwnersSuccess
  | FetchGuidelineSourceOwnersFail
  | UpdateGuidelineSourceOwners;

export interface ChapterAddNew extends Action {
  type: 'CHAPTER_ADD_NEW';
}
export function chapterAddNew(): ChapterAddNew {
  return { type: 'CHAPTER_ADD_NEW' };
}

export interface ChaptersReorder extends Action {
  type: 'CHAPTERS_REORDER';
  payload: number[];
}
export function chaptersReorder(payload: number[]): ChaptersReorder {
  return { type: 'CHAPTERS_REORDER', payload };
}

export interface ChapterNameChange extends Action {
  type: 'CHAPTER_NAME_CHANGE';
  cIndex: number;
  newName: string;
}
export function chapterNameChange(
  cIndex: number,
  newName: string
): ChapterNameChange {
  return { type: 'CHAPTER_NAME_CHANGE', cIndex, newName };
}

export interface ChapterCollapsibleChange extends Action {
  type: 'CHAPTER_COLLAPSIBLE_CHANGE';
  cIndex: number;
  value: boolean;
}
export function chapterCollapsibleChange(
  cIndex: number,
  value: boolean
): ChapterCollapsibleChange {
  return { type: 'CHAPTER_COLLAPSIBLE_CHANGE', cIndex, value };
}

export interface ChapterRemove extends Action {
  type: 'CHAPTER_REMOVE';
  payload: number;
}
export function chapterRemove(payload: number): ChapterRemove {
  return { type: 'CHAPTER_REMOVE', payload };
}

export interface SaveMajorVersion extends Action {
  type: 'SAVE_MAJOR_VERSION';
}
export function saveMajorVersion(): SaveMajorVersion {
  return { type: 'SAVE_MAJOR_VERSION' };
}

export interface SaveMajorVersionFail extends Action {
  type: 'SAVE_MAJOR_VERSION_FAIL';
  payload: any;
}
export function saveMajorVersionFail(payload: any): SaveMajorVersionFail {
  return { type: 'SAVE_MAJOR_VERSION_FAIL', payload };
}

export interface SaveMajorVersionSuccess extends Action {
  type: 'SAVE_MAJOR_VERSION_SUCCESS';
  guideline: Guideline;
}
export function saveMajorVersionSuccess(
  guideline: Guideline
): SaveMajorVersionSuccess {
  return { type: 'SAVE_MAJOR_VERSION_SUCCESS', guideline };
}

export interface SaveScopes extends Action {
  type: 'SAVE_SCOPES';
}
export function saveScopes(): SaveScopes {
  return { type: 'SAVE_SCOPES' };
}

export interface SaveScopesFail extends Action {
  type: 'SAVE_SCOPES_FAIL';
  error: HttpErrorResponse;
}
export function saveScopesFail(error: HttpErrorResponse): SaveScopesFail {
  return { type: 'SAVE_SCOPES_FAIL', error };
}

export interface SaveScopesSuccess extends Action {
  type: 'SAVE_SCOPES_SUCCESS';
  guideline: Guideline;
}
export function saveScopesSuccess(guideline: Guideline): SaveScopesSuccess {
  return { type: 'SAVE_SCOPES_SUCCESS', guideline };
}

export interface SaveMinorVersion extends Action {
  type: 'SAVE_MINOR_VERSION';
}
export function saveMinorVersion(): SaveMinorVersion {
  return { type: 'SAVE_MINOR_VERSION' };
}

export interface SaveMinorVersionFail extends Action {
  type: 'SAVE_MINOR_VERSION_FAIL';
  error: HttpErrorResponse;
}
export function saveMinorVersionFail(
  error: HttpErrorResponse
): SaveMinorVersionFail {
  return { type: 'SAVE_MINOR_VERSION_FAIL', error };
}

export interface SaveMinorVersionSuccess extends Action {
  type: 'SAVE_MINOR_VERSION_SUCCESS';
  guideline: Guideline;
}
export function saveMinorVersionSuccess(
  guideline: Guideline
): SaveMinorVersionSuccess {
  return { type: 'SAVE_MINOR_VERSION_SUCCESS', guideline };
}

export interface SectionAddNew extends Action {
  type: 'SECTION_ADD_NEW';
  payload: number;
}
export function sectionAddNew(payload: number): SectionAddNew {
  return { type: 'SECTION_ADD_NEW', payload };
}

export interface SectionExpanded extends Action {
  type: 'SECTION_EXPANDED';
  cIndex: number;
  sIndex: number;
  isExpanded: boolean;
}
export function sectionExpanded(
  cIndex: number,
  sIndex: number,
  isExpanded: boolean
): SectionExpanded {
  return { type: 'SECTION_EXPANDED', cIndex, sIndex, isExpanded };
}

export interface SectionNameChange extends Action {
  type: 'SECTION_NAME_CHANGE';
  cIndex: number;
  sIndex: number;
  newName: string;
}
export function sectionNameChange(
  cIndex: number,
  sIndex: number,
  newName: string
): SectionNameChange {
  return { type: 'SECTION_NAME_CHANGE', cIndex, sIndex, newName };
}

export interface SectionRemove extends Action {
  type: 'SECTION_REMOVE';
  cIndex: number;
  sIndex: number;
}
export function sectionRemove(cIndex: number, sIndex: number): SectionRemove {
  return { type: 'SECTION_REMOVE', cIndex, sIndex };
}

export interface SectionsReorder extends Action {
  type: 'SECTIONS_REORDER';
  cIndex: number;
  newIndexes: number[];
}
export function sectionsReorder(
  cIndex: number,
  newIndexes: number[]
): SectionsReorder {
  return { type: 'SECTIONS_REORDER', cIndex, newIndexes };
}

export interface SelectChapterAt extends Action {
  type: 'SELECT_CHAPTER_AT';
  payload: number;
}
export function selectChapterAt(payload: number): SelectChapterAt {
  return { type: 'SELECT_CHAPTER_AT', payload };
}

export interface SubsectionAddNew extends Action {
  type: 'SUBSECTION_ADD_NEW';
  cIndex: number;
  sIndex: number;
}
export function subsectionAddNew(
  cIndex: number,
  sIndex: number
): SubsectionAddNew {
  return { type: 'SUBSECTION_ADD_NEW', cIndex, sIndex };
}

export interface SubsectionAddNewWithScope extends Action {
  type: 'SUBSECTION_ADD_NEW_WITH_SCOPE';
  cIndex: number;
  sIndex: number;
  scope: Scope;
}
export function subsectionAddNewWithScope(
  cIndex: number,
  sIndex: number,
  scope: Scope
): SubsectionAddNewWithScope {
  return { type: 'SUBSECTION_ADD_NEW_WITH_SCOPE', cIndex, sIndex, scope };
}

export interface SubsectionContentChange extends Action {
  type: 'SUBSECTION_CONTENT_CHANGE';
  cIndex: number;
  sIndex: number;
  ssIndex: number;
  newContent: string;
}
export function subsectionContentChange(
  cIndex: number,
  sIndex: number,
  ssIndex: number,
  newContent: string
): SubsectionContentChange {
  return {
    type: 'SUBSECTION_CONTENT_CHANGE',
    cIndex,
    sIndex,
    ssIndex,
    newContent,
  };
}

export interface SubsectionRemove extends Action {
  type: 'SUBSECTION_REMOVE';
  cIndex: number;
  sIndex: number;
  ssIndex: number;
}
export function subsectionRemove(
  cIndex: number,
  sIndex: number,
  ssIndex: number
): SubsectionRemove {
  return { type: 'SUBSECTION_REMOVE', cIndex, sIndex, ssIndex };
}

export interface SubsectionChangeMode extends Action {
  type: 'SUBSECTION_CHANGE_MODE';
  cIndex: number;
  sIndex: number;
  ssIndex: number;
  isEditing: boolean;
}
export function subsectionChangeMode(
  cIndex: number,
  sIndex: number,
  ssIndex: number,
  isEditing: boolean
): SubsectionChangeMode {
  return { type: 'SUBSECTION_CHANGE_MODE', cIndex, sIndex, ssIndex, isEditing };
}

export interface SubsectionUpdateScope extends Action {
  type: 'SUBSECTION_UPDATE_SCOPE';
  cIndex: number;
  sIndex: number;
  ssIndex: number;
  scope: Scope;
}
export function subsectionUpdateScope(
  cIndex: number,
  sIndex: number,
  ssIndex: number,
  scope: Scope
): SubsectionUpdateScope {
  return { type: 'SUBSECTION_UPDATE_SCOPE', cIndex, sIndex, ssIndex, scope };
}

export interface GuidelineEditFilterSelect extends Action {
  type: 'GUIDELINE_EDIT_FILTER_SELECT';
  businessUnit: string;
  fetch: boolean;
}
export function guidelineEditFilterSelect(
  businessUnit: string,
  fetch: boolean = true
): GuidelineEditFilterSelect {
  return { type: 'GUIDELINE_EDIT_FILTER_SELECT', businessUnit, fetch };
}
export interface GuidelineEditFilterReset extends Action {
  type: 'GUIDELINE_EDIT_FILTER_RESET';
}
export function guidelineEditFilterReset(): GuidelineEditFilterReset {
  return { type: 'GUIDELINE_EDIT_FILTER_RESET' };
}

export interface FetchGuidelineForConfiguration extends Action {
  type: 'FETCH_GUIDELINE_FOR_CONFIGURATION';
  countryCode: string;
  creationDate?: string;
  businessUnit?: string;
}
export function fetchGuidelineForConfiguration(
  countryCode: string,
  creationDate?: string,
  businessUnit?: string
): FetchGuidelineForConfiguration {
  return {
    type: 'FETCH_GUIDELINE_FOR_CONFIGURATION',
    countryCode,
    creationDate,
    businessUnit,
  };
}

export interface FetchGuidelineForConfigurationSuccess extends Action {
  type: 'FETCH_GUIDELINE_FOR_CONFIGURATION_SUCCESS';
  guideline: Guideline;
}
export function fetchGuidelineForConfigurationSuccess(
  guideline: Guideline
): FetchGuidelineForConfigurationSuccess {
  return { type: 'FETCH_GUIDELINE_FOR_CONFIGURATION_SUCCESS', guideline };
}

export interface FetchGuidelineForConfigurationFail extends Action {
  type: 'FETCH_GUIDELINE_FOR_CONFIGURATION_FAIL';
  error: HttpErrorResponse;
}
export function fetchGuidelineForConfigurationFail(
  error: HttpErrorResponse
): FetchGuidelineForConfigurationFail {
  return { type: 'FETCH_GUIDELINE_FOR_CONFIGURATION_FAIL', error };
}

export interface FetchPreviewFromGuideline extends Action {
  type: 'FETCH_PREVIEW_FROM_GUIDELINE';
  businessUnit: string;
}
export function fetchPreviewFromGuideline(
  businessUnit: string
): FetchPreviewFromGuideline {
  return { type: 'FETCH_PREVIEW_FROM_GUIDELINE', businessUnit };
}

export interface FetchPreviewFromGuidelineSuccess extends Action {
  type: 'FETCH_PREVIEW_FROM_GUIDELINE_SUCCESS';
  guideline: Guideline;
}
export function fetchPreviewFromGuidelineSuccess(
  guideline: Guideline
): FetchPreviewFromGuidelineSuccess {
  return { type: 'FETCH_PREVIEW_FROM_GUIDELINE_SUCCESS', guideline };
}

export interface FetchPreviewFromGuidelineFail extends Action {
  type: 'FETCH_PREVIEW_FROM_GUIDELINE_FAIL';
  error: HttpErrorResponse;
}
export function fetchPreviewFromGuidelineFail(
  error: HttpErrorResponse
): FetchPreviewFromGuidelineFail {
  return { type: 'FETCH_PREVIEW_FROM_GUIDELINE_FAIL', error };
}

export interface GuidelineConfigurationFormChanged extends Action {
  type: 'GUIDELINE_CONFIGURATION_FORM_CHANGED';
  isDirty: boolean;
}
export function guidelineConfigurationFormChanged(
  isDirty: boolean
): GuidelineConfigurationFormChanged {
  return { type: 'GUIDELINE_CONFIGURATION_FORM_CHANGED', isDirty };
}

export interface FetchGuidelineSourceOwners extends Action {
  type: 'FETCH_GUIDELINE_SOURCE_OWNERS';
  countryCode: string;
}

export function fetchGuidelineSourceOwners(
  countryCode: string
): FetchGuidelineSourceOwners {
  return {
    type: 'FETCH_GUIDELINE_SOURCE_OWNERS',
    countryCode,
  };
}

export interface FetchGuidelineSourceOwnersSuccess extends Action {
  type: 'FETCH_GUIDELINE_SOURCE_OWNERS_SUCCESS';
  sourceOwners: SourceVersionOwner[];
}

export function fetchGuidelineSourceOwnersSuccess(
  sourceOwners: SourceVersionOwner[]
): FetchGuidelineSourceOwnersSuccess {
  return {
    type: 'FETCH_GUIDELINE_SOURCE_OWNERS_SUCCESS',
    sourceOwners,
  };
}

export interface FetchGuidelineSourceOwnersFail extends Action {
  type: 'FETCH_GUIDELINE_SOURCE_OWNERS_FAIL';
  error: HttpErrorResponse;
}
export function fetchGuidelineSourceOwnersFail(
  error: HttpErrorResponse
): FetchGuidelineSourceOwnersFail {
  return { type: 'FETCH_GUIDELINE_SOURCE_OWNERS_FAIL', error };
}

export interface UpdateGuidelineSourceOwners extends Action {
  type: 'UPDATE_GUIDELINE_SOURCE_OWNERS';
  updatedGuideline: Guideline;
}

export function updateGuidelineSourceOwners(
  updatedGuideline: Guideline
): UpdateGuidelineSourceOwners {
  return {
    type: 'UPDATE_GUIDELINE_SOURCE_OWNERS',
    updatedGuideline,
  };
}

export interface UpdateBusinessUnitsForReacknowledgement extends Action {
  type: 'UPDATE_BUSINESS_UNITS_FOR_REACKNOWLEDGEMENT';
  payload: string[];
}
export function updateBusinessUnitsForReacknowledgement(
  payload: string[]
): UpdateBusinessUnitsForReacknowledgement {
  return {
    type: 'UPDATE_BUSINESS_UNITS_FOR_REACKNOWLEDGEMENT',
    payload,
  };
}
