<div #container class="panel panel-default">
  <div class="panel-heading">
    <div class="panel-title">
      <span (mousedown)="mousedown.emit($event)">
        <i class="fa fa-arrows"></i>
      </span>
      <div
        (click)="collapse($event)"
        class="expander-container"
        [class.collapsed]="!expanded"
      >
        <div class="dropdown dropdown-toggle">
          <a data-toggle="dropdown" href (click)="setStyle($event)">
            {{ displayTitle }}
            <span class="fa fa-angle-down" *ngIf="isArray(title)"></span>
          </a>
          <ul
            #dropdownMenu
            class="dropdown-menu"
            role="menu"
            *ngIf="isArray(title)"
          >
            <li
              role="presentation"
              *ngFor="let item of title; index as i"
              [class.active]="i === selectedIndex"
            >
              <a
                role="menuitem"
                tabindex="-1"
                href
                (click)="$event.preventDefault(); dropDownSelect.emit(i)"
                >{{ getOptionText(item) }}</a
              >
            </li>
          </ul>
        </div>
        <span
          class="hidden-xs hidden-sm question-preview"
          (click)="collapse($event)"
        >
          {{ description | truncate: [80, '...'] }}
        </span>
        <div class="scope-and-expand-toggle">
          <span class="scope" *ngIf="questionScope">
            Applies to
            {{
              questionScope.length === 0 || questionScope.indexOf('*') !== -1
                ? 'all'
                : '[' + questionScope.join(', ') + ']'
            }}
          </span>
          <span
            #collapseIndicator
            class="fa"
            [class.fa-angle-down]="!expanded"
            [class.fa-angle-up]="expanded"
          ></span>
        </div>
      </div>
    </div>
  </div>
  <div class="panel-body" *ngIf="expanded">
    <ng-content></ng-content>
  </div>
</div>
