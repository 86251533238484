import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  MOCK_API_TRAVEL_APPROVAL_QUESTIONS,
  MOCK_API_TRAVEL_APPROVAL_QUESTIONS_FOR_MARK,
} from 'app/mock-api/mocks/travel-approval.mocks';
import { ok } from 'app/mock-api/utils/response.utils';
import { Observable, of } from 'rxjs';
import { delay, dematerialize, materialize, mergeMap } from 'rxjs/operators';

@Injectable()
export class TaSpecificRequestInterceptor implements HttpInterceptor {
  constructor() {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return <Observable<HttpEvent<any>>>of(null).pipe(
      mergeMap(() => {
        if (
          request.url.match(/\/travelApprovals\/questions\/([A-Z]{2})$/) &&
          request.params
            .get('traveler')
            .match(
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(socgen|sgcib).(com)(.[a-z]{2})?$/
            ) &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_TRAVEL_APPROVAL_QUESTIONS_FOR_MARK);
        } else if (
          request.url.match(/\/travelApprovals\/questions\/([A-Z]{2})$/) &&
          request.method === 'PUT'
        ) {
          return ok(MOCK_API_TRAVEL_APPROVAL_QUESTIONS);
        } else if (
          request.url.match(
            /\/travelApprovals\/questions\/([A-Z]{2})\/forConfiguration$/
          ) &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_TRAVEL_APPROVAL_QUESTIONS);
        } else if (
          request.url.match(
            /\/travelApprovals\/questions\/([A-Z]{2})\/forConfiguration\?businessUnit=([A-Z]+)$/
          ) &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_TRAVEL_APPROVAL_QUESTIONS_FOR_MARK);
        } else if (
          request.url.match(/^\/travelApprovals\/questions\/preview$/) &&
          request.method === 'POST'
        ) {
          return ok(MOCK_API_TRAVEL_APPROVAL_QUESTIONS);
        } else if (
          request.url.match(
            /^\/travelApprovals\/questions\/preview\?businessUnit=([A-Z]+)$/
          ) &&
          request.method === 'POST'
        ) {
          return ok(MOCK_API_TRAVEL_APPROVAL_QUESTIONS);
        }

        return next.handle(request);
      }),
      materialize(),
      delay(300),
      dematerialize()
    );
  }
}
