import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { ModalComponent } from './components/modal/modal.component';
import { NotificationComponent } from './components/notification/notification.component';
import { PopinComponent } from './components/popin/popin.component';
import { SignoutComponent } from './components/signout/signout.component';
import { AppComponent } from './containers/app/app.component';
import { FooterComponent } from './containers/footer/footer.component';
import { HeaderComponent } from './containers/header/header.component';

export const COMPONENTS = [
  HeaderComponent,
  AppComponent,
  FooterComponent,
  NotificationComponent,
  SignoutComponent,
  ModalComponent,
  PopinComponent,
];

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [{ provide: Window, useValue: window }],
})
export class CoreModule {}
