import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, dematerialize, materialize, mergeMap } from 'rxjs/operators';
import {
  MOCK_API_BUSINESS_UNITS,
  MOCK_API_BUSINESS_UNITS_FILTER,
  MOCK_API_BUSINESS_UNITS_UNDER_PERIMETER,
} from '../mocks/business-units.mocks';
import { ok } from '../utils/response.utils';

@Injectable()
export class BusinessUnitRequestInterceptor implements HttpInterceptor {
  constructor() {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return <Observable<HttpEvent<any>>>of(null).pipe(
      mergeMap(() => {
        if (
          request.url.endsWith('/businessUnits') &&
          request.method === 'GET' &&
          request.params.get('underMyPerimeter') &&
          request.params.get('underMyPerimeter').match(/^(true)$/)
        ) {
          return ok(MOCK_API_BUSINESS_UNITS_UNDER_PERIMETER);
        } else if (
          request.url.match(/^\/businessUnits$/) &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_BUSINESS_UNITS);
        } else if (
          request.url.match(/^\/businessUnits\?country=([A-Z]{2})$/) &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_BUSINESS_UNITS_FILTER);
        }

        return next.handle(request);
      }),
      materialize(),
      delay(300),
      dematerialize()
    );
  }
}
