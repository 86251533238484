import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Acknowledgement } from '@models/Acknowledgement';
import { PiwikActions, PiwikCategories } from '@models/Piwik';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MatomoTracker } from '@ngx-matomo/tracker';
import { showDangerNotification } from '@store/header/header.actions';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
  ExportAcknowledgements,
  ExportAcknowledgementsSuccess,
  FetchAcknowledgements,
  exportAcknowledgementsFail,
  exportAcknowledgementsSuccess,
  fetchAcknowledgementsFail,
  fetchAcknowledgementsSuccess,
} from './acknowledgement.actions';

@Injectable()
export class AcknowledgementEffects {
  public fetchAcknowledgements$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchAcknowledgements>('FETCH_ACKNOWLEDGEMENTS'),
      switchMap(({ withExpired, traveler }) => {
        const params = setParamsForFetchAcknowledgementsRequest(
          withExpired,
          traveler
        );
        return this.http.get('gateway/acknowledgements', { params }).pipe(
          map((res: Acknowledgement[]) => {
            return fetchAcknowledgementsSuccess(res, withExpired);
          }),
          catchError((err) => {
            return [
              fetchAcknowledgementsFail(console.error(err)),
              showDangerNotification(
                `Couldn't retrieve your acknowledgments! Please reload the page.`
              ),
            ];
          })
        );
      })
    )
  );

  public exportAcknowledgements$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ExportAcknowledgements>('EXPORT_ACKNOWLEDGEMENTS'),
      switchMap(({ countryCode, countryName }) =>
        this.http
          .get(`export/acknowledgements/${countryCode}`, {
            responseType: 'blob',
          })
          .pipe(
            map((res: Blob) => {
              this.matomoTracker.trackEvent(
                PiwikCategories.ExportPage,
                PiwikActions.ExportAcknowledgements,
                `${countryName}`
              );
              return exportAcknowledgementsSuccess(res);
            }),
            catchError((err) => {
              return [
                exportAcknowledgementsFail(console.error(err)),
                showDangerNotification(`Couldn't export the acknowledgements.`),
              ];
            })
          )
      )
    )
  );

  public exportAcknowledgementsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ExportAcknowledgementsSuccess>(
          'EXPORT_ACKNOWLEDGEMENTS_SUCCESS'
        ),
        tap(({ blob: data }) => {
          const blob = new Blob([data], { type: data.type });
          const a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          a.download = 'acknowledgements.xlsx';
          a.target = '_blank';
          a.click();
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private matomoTracker: MatomoTracker
  ) {}
}

function setParamsForFetchAcknowledgementsRequest(
  withExpired: boolean,
  traveler: string
) {
  let params = new HttpParams();
  if (!withExpired) {
    params = params.set('withExpired', 'false');
  }

  if (traveler) {
    params = params.set('traveler', traveler.toString());
  }
  return params;
}
