export function stringToBase64(str: string): string {
  return btoa(str);
}

export const domStringToBase64 = utf16ToBase64;
export function utf16ToBase64(
  str: string | null | undefined
): string | null | undefined {
  return str === null || str === undefined
    ? str
    : stringToBase64(utf16ToUtf8(str));
}

export const domStringToUtf8 = utf16ToUtf8;
export function utf16ToUtf8(str: string): string {
  return encodeURIComponent(str);
}
