import { HttpErrorResponse } from '@angular/common/http';
import {
  ComplianceTravelApproval,
  CtaQuestion,
  CtaQuestionType,
} from '@models/ComplianceTravelApproval';
import { Scope } from '@models/Guideline';
import { Action } from '@ngrx/store';
import {
  CtaImportantLevel,
  CtaPresetValueType,
  CtaValidationLevel,
} from './cta-specific.models';

export type CtaSpecificActions =
  | AddNewConditionalQuestion
  | AddNewOption
  | AddNewQuestion
  | AddNewQuestionWithScope
  | ChangeQuestionType
  | ClearQuestions
  | ConditionalOptionSelected
  | ConditionalQuestionChange
  | ConditionalQuestionSelected
  | EditConditions
  | EditDescription
  | EditOption
  | EditScope
  | MandatoryChange
  | FetchQuestions
  | FetchQuestionsFail
  | FetchQuestionsSuccess
  | RemoveConditionalQuestion
  | RemoveOption
  | RemoveQuestion
  | ReorderOptions
  | ReorderQuestions
  | SaveQuestions
  | ToggleQuestion
  | UseOrChange
  | TextParagraphQuestionImportantLevelChanged
  | EmailQuestionValidationLevelChanged
  | EmailQuestionPresetUserIsNotEditableChanged
  | EmailQuestionPresetValueTypeChanged
  | CtaSpecificFilterSelect
  | CtaSpecificFilterReset
  | UseOrChange
  | FetchQuestionsForConfiguration
  | FetchQuestionsForConfigurationSuccess
  | FetchQuestionsForConfigurationFail
  | FetchPreviewFromQuestions
  | FetchPreviewFromQuestionsSuccess
  | FetchPreviewFromQuestionsFail
  | UpdatePreview
  | CtaSpecificConfigurationFormChanged
  | FetchQuestionsForDuplication
  | FetchQuestionsForDuplicationFail
  | FetchQuestionsForDuplicationSuccess;

export interface AddNewConditionalQuestion extends Action {
  type: 'ADD_NEW_CONDITIONAL_QUESTION';
  payload: number;
}
export function addNewConditionalQuestion(
  payload: number
): AddNewConditionalQuestion {
  return {
    type: 'ADD_NEW_CONDITIONAL_QUESTION',
    payload,
  };
}

export interface AddNewOption extends Action {
  type: 'ADD_NEW_OPTION';
  payload: number;
}
export function addNewOption(payload: number): AddNewOption {
  return {
    type: 'ADD_NEW_OPTION',
    payload,
  };
}
export interface AddNewQuestion extends Action {
  type: 'ADD_NEW_QUESTION';
  payload: string;
}
export function addNewQuestion(
  payload: string = 'MULTI_SELECT'
): AddNewQuestion {
  return {
    type: 'ADD_NEW_QUESTION',
    payload,
  };
}
export interface AddNewQuestionWithScope extends Action {
  type: 'ADD_NEW_QUESTION_WITH_SCOPE';
  questionType: string;
  scope: Scope;
}
export function addNewQuestionWithScope(
  questionType: string = 'MULTI_SELECT',
  scope: Scope
): AddNewQuestionWithScope {
  return {
    type: 'ADD_NEW_QUESTION_WITH_SCOPE',
    questionType,
    scope,
  };
}

export interface ChangeQuestionType extends Action {
  type: 'CHANGE_QUESTION_TYPE';
  qIndex: number;
  newType: CtaQuestionType;
}
export function changeQuestionType(
  qIndex: number,
  newType: CtaQuestionType
): ChangeQuestionType {
  return {
    type: 'CHANGE_QUESTION_TYPE',
    qIndex,
    newType,
  };
}
export interface ClearQuestions extends Action {
  type: 'CLEAR_QUESTIONS';
}
export function clearQuestions(): ClearQuestions {
  return {
    type: 'CLEAR_QUESTIONS',
  };
}

export interface ConditionalOptionSelected extends Action {
  type: 'CONDITIONAL_OPTION_SELECTED';
  qIndex: number;
  cIndex: number;
  oUuid: string;
}
export function conditionalOptionSelected(
  qIndex: number,
  cIndex: number,
  oUuid: string
): ConditionalOptionSelected {
  return {
    type: 'CONDITIONAL_OPTION_SELECTED',
    qIndex,
    cIndex,
    oUuid,
  };
}

export interface ConditionalQuestionChange extends Action {
  type: 'CONDITIONAL_QUESTION_CHANGE';
  qIndex: number;
  conditional: boolean;
}
export function conditionalQuestionChange(
  qIndex: number,
  conditional: boolean
): ConditionalQuestionChange {
  return {
    type: 'CONDITIONAL_QUESTION_CHANGE',
    qIndex,
    conditional,
  };
}
export interface ConditionalQuestionSelected extends Action {
  type: 'CONDITIONAL_QUESTION_SELECTED';
  qIndex: number;
  cIndex: number;
  qUuid: string;
}
export function conditionalQuestionSelected(
  qIndex: number,
  cIndex: number,
  qUuid: string
): ConditionalQuestionSelected {
  return {
    type: 'CONDITIONAL_QUESTION_SELECTED',
    qIndex,
    cIndex,
    qUuid,
  };
}

export interface EditConditions extends Action {
  type: 'EDIT_CONDITIONS';
  qIndex: number;
  uuids: Array<{ option: string }>;
}
export function editConditions(
  qIndex: number,
  uuids: Array<{ option: string }>
): EditConditions {
  return {
    type: 'EDIT_CONDITIONS',
    qIndex,
    uuids,
  };
}

export interface EditDescription extends Action {
  type: 'EDIT_DESCRIPTION';
  qIndex: number;
  newValue: string;
}
export function editDescription(
  qIndex: number,
  newValue: string
): EditDescription {
  return {
    type: 'EDIT_DESCRIPTION',
    qIndex,
    newValue,
  };
}
export interface EditOption extends Action {
  type: 'EDIT_OPTION';
  qIndex: number;
  oIndex: number;
  newValue: string;
}
export function editOption(
  qIndex: number,
  oIndex: number,
  newValue: string
): EditOption {
  return {
    type: 'EDIT_OPTION',
    qIndex,
    oIndex,
    newValue,
  };
}

export interface EditScope extends Action {
  type: 'EDIT_SCOPE';
  qIndex: number;
  newScope: Scope;
}
export function editScope(qIndex: number, newScope: Scope): EditScope {
  return {
    type: 'EDIT_SCOPE',
    qIndex,
    newScope,
  };
}

export interface FetchQuestions extends Action {
  type: 'FETCH_QUESTIONS';
  countryCode: string;
  travelerEmail: string;
}
export function fetchQuestions(
  countryCode: string,
  travelerEmail: string
): FetchQuestions {
  return {
    type: 'FETCH_QUESTIONS',
    countryCode,
    travelerEmail,
  };
}

export interface FetchQuestionsFail extends Action {
  type: 'FETCH_QUESTIONS_FAIL';
  payload: any;
}
export function fetchQuestionsFail(payload: any): FetchQuestionsFail {
  return {
    type: 'FETCH_QUESTIONS_FAIL',
    payload,
  };
}

export interface FetchQuestionsSuccess extends Action {
  type: 'FETCH_QUESTIONS_SUCCESS';
  questions: CtaQuestion[];
}
export function fetchQuestionsSuccess(
  questions: CtaQuestion[]
): FetchQuestionsSuccess {
  return {
    type: 'FETCH_QUESTIONS_SUCCESS',
    questions,
  };
}
export interface FetchQuestionsForDuplication extends Action {
  type: 'FETCH_QUESTIONS_FOR_DUPLICATION';
  countryCode: string;
  businessUnit: string;
  ctaDuplicatedId: string;
  traveler?: string;
}
export function fetchQuestionsForDuplication(
  countryCode: string,
  businessUnit: string,
  ctaDuplicatedId: string,
  traveler?: string
): FetchQuestionsForDuplication {
  return {
    type: 'FETCH_QUESTIONS_FOR_DUPLICATION',
    countryCode,
    businessUnit,
    ctaDuplicatedId,
    traveler,
  };
}

export interface FetchQuestionsForDuplicationFail extends Action {
  type: 'FETCH_QUESTIONS_FOR_DUPLICATION_FAIL';
  payload: any;
}
export function fetchQuestionsForDuplicationFail(
  payload: any
): FetchQuestionsForDuplicationFail {
  return {
    type: 'FETCH_QUESTIONS_FOR_DUPLICATION_FAIL',
    payload,
  };
}

export interface FetchQuestionsForDuplicationSuccess extends Action {
  type: 'FETCH_QUESTIONS_FOR_DUPLICATION_SUCCESS';
  questions: CtaQuestion[];
  ctaDuplicatedId;
}
export function fetchQuestionsForDuplicationSuccess(
  questions: CtaQuestion[],
  ctaDuplicatedId: string
): FetchQuestionsForDuplicationSuccess {
  return {
    type: 'FETCH_QUESTIONS_FOR_DUPLICATION_SUCCESS',
    questions,
    ctaDuplicatedId,
  };
}

export interface FetchPreviewFromQuestions extends Action {
  type: 'FETCH_PREVIEW_FROM_QUESTIONS';
  businessUnit: string;
}
export function fetchPreviewFromQuestions(
  businessUnit: string
): FetchPreviewFromQuestions {
  return { type: 'FETCH_PREVIEW_FROM_QUESTIONS', businessUnit };
}

export interface FetchPreviewFromQuestionsSuccess extends Action {
  type: 'FETCH_PREVIEW_FROM_QUESTIONS_SUCCESS';
  questions: CtaQuestion[];
}
export function fetchPreviewFromQuestionsSuccess(
  questions: CtaQuestion[]
): FetchPreviewFromQuestionsSuccess {
  return { type: 'FETCH_PREVIEW_FROM_QUESTIONS_SUCCESS', questions };
}

export interface FetchPreviewFromQuestionsFail extends Action {
  type: 'FETCH_PREVIEW_FROM_QUESTIONS_FAIL';
  payload: any;
}
export function fetchPreviewFromQuestionsFail(
  payload: any
): FetchPreviewFromQuestionsFail {
  return {
    type: 'FETCH_PREVIEW_FROM_QUESTIONS_FAIL',
    payload,
  };
}

export interface UpdatePreview extends Action {
  type: 'UPDATE_PREVIEW';
  cta: ComplianceTravelApproval;
}
export function updatePreview(cta: ComplianceTravelApproval): UpdatePreview {
  return { type: 'UPDATE_PREVIEW', cta };
}

export interface MandatoryChange extends Action {
  type: 'MANDATORY_CHANGE';
  qIndex: number;
  mandatory: boolean;
}
export function mandatoryChange(
  qIndex: number,
  mandatory: boolean
): MandatoryChange {
  return {
    type: 'MANDATORY_CHANGE',
    qIndex,
    mandatory,
  };
}

export interface RemoveConditionalQuestion extends Action {
  type: 'REMOVE_CONDITIONAL_QUESTION';
  qIndex: number;
  cIndex: number;
}
export function removeConditionalQuestion(
  qIndex: number,
  cIndex: number
): RemoveConditionalQuestion {
  return {
    type: 'REMOVE_CONDITIONAL_QUESTION',
    qIndex,
    cIndex,
  };
}
export interface RemoveOption extends Action {
  type: 'REMOVE_OPTION';
  qIndex: number;
  oIndex: number;
}
export function removeOption(qIndex: number, oIndex: number): RemoveOption {
  return {
    type: 'REMOVE_OPTION',
    qIndex,
    oIndex,
  };
}

export interface RemoveQuestion extends Action {
  type: 'REMOVE_QUESTION';
  payload: number;
}
export function removeQuestion(payload: number): RemoveQuestion {
  return {
    type: 'REMOVE_QUESTION',
    payload,
  };
}

export interface ReorderOptions extends Action {
  type: 'REORDER_OPTIONS';
  qIndex: number;
  newIndexes: number[];
}
export function reorderOptions(
  qIndex: number,
  newIndexes: number[]
): ReorderOptions {
  return {
    type: 'REORDER_OPTIONS',
    qIndex,
    newIndexes,
  };
}
export interface ReorderQuestions extends Action {
  type: 'REORDER_QUESTIONS';
  payload: number[];
}
export function reorderQuestions(payload: number[]): ReorderQuestions {
  return {
    type: 'REORDER_QUESTIONS',
    payload,
  };
}

export interface SaveQuestions extends Action {
  type: 'SAVE_QUESTIONS';
  countryCode: string;
  businessUnit?: string;
}
export function saveQuestions(
  countryCode: string,
  businessUnit?: string
): SaveQuestions {
  return {
    type: 'SAVE_QUESTIONS',
    countryCode,
    businessUnit,
  };
}

export interface ToggleQuestion extends Action {
  type: 'TOGGLE_QUESTION';
  payload: number;
}
export function toggleQuestion(payload: number): ToggleQuestion {
  return {
    type: 'TOGGLE_QUESTION',
    payload,
  };
}

export interface UseOrChange extends Action {
  type: 'USE_OR_CHANGE';
  qIndex: number;
  useOr: boolean;
}
export function useOrChange(qIndex: number, useOr: boolean): UseOrChange {
  return {
    type: 'USE_OR_CHANGE',
    qIndex,
    useOr,
  };
}

export type TextParagraphQuestionImportantLevelChanged = ReturnType<
  typeof textParagraphQuestionImportantLevelChanged
>;
export function textParagraphQuestionImportantLevelChanged(
  qIndex: number,
  importantLevel: CtaImportantLevel
) {
  return {
    type: 'TEXT_PARAGRAPH_QUESTION_IMPORTANT_LEVEL_CHANGED' as const,
    qIndex,
    importantLevel,
  };
}

export type EmailQuestionValidationLevelChanged = ReturnType<
  typeof emailQuestionValidationLevelChanged
>;
export function emailQuestionValidationLevelChanged(
  qIndex: number,
  validationLevel: CtaValidationLevel
) {
  return {
    type: 'EMAIL_QUESTION_VALIDATION_LEVEL_CHANGED' as const,
    qIndex,
    validationLevel,
  };
}

export type EmailQuestionPresetValueTypeChanged = ReturnType<
  typeof emailQuestionPresetValueTypeChanged
>;
export function emailQuestionPresetValueTypeChanged(
  qIndex: number,
  presetValueType: CtaPresetValueType
) {
  return {
    type: 'EMAIL_QUESTION_PRESET_USER_TYPE_CHANGED' as const,
    qIndex,
    presetValueType,
  };
}

export type EmailQuestionPresetUserIsNotEditableChanged = ReturnType<
  typeof emailQuestionPresetUserIsNotEditableChanged
>;
export function emailQuestionPresetUserIsNotEditableChanged(
  qIndex: number,
  isNotEditable: boolean
) {
  return {
    type: 'EMAIL_QUESTION_PRESET_USER_IS_NOT_EDITABLE_CHANGED' as const,
    qIndex,
    isNotEditable,
  };
}

export interface CtaSpecificFilterSelect extends Action {
  type: 'CTA_SPECIFIC_FILTER_SELECT';
  businessUnit: string;
  fetch: boolean;
}
export function ctaSpecificFilterSelect(
  businessUnit: string,
  fetch: boolean = true
): CtaSpecificFilterSelect {
  return { type: 'CTA_SPECIFIC_FILTER_SELECT', businessUnit, fetch };
}

export interface CtaSpecificFilterReset extends Action {
  type: 'CTA_SPECIFIC_FILTER_RESET';
}
export function ctaSpecificFilterReset(): CtaSpecificFilterReset {
  return { type: 'CTA_SPECIFIC_FILTER_RESET' };
}

export interface FetchQuestionsForConfiguration extends Action {
  type: 'FETCH_QUESTIONS_FOR_CONFIGURATION';
  countryCode: string;
  businessUnit?: string;
}
export function fetchQuestionsForConfiguration(
  countryCode: string,
  businessUnit?: string
): FetchQuestionsForConfiguration {
  return {
    type: 'FETCH_QUESTIONS_FOR_CONFIGURATION',
    countryCode,
    businessUnit,
  };
}

export interface FetchQuestionsForConfigurationSuccess extends Action {
  type: 'FETCH_QUESTIONS_FOR_CONFIGURATION_SUCCESS';
  ctaQuestions: CtaQuestion[];
}
export function fetchQuestionsForConfigurationSuccess(
  ctaQuestions: CtaQuestion[]
): FetchQuestionsForConfigurationSuccess {
  return { type: 'FETCH_QUESTIONS_FOR_CONFIGURATION_SUCCESS', ctaQuestions };
}

export interface FetchQuestionsForConfigurationFail extends Action {
  type: 'FETCH_QUESTIONS_FOR_CONFIGURATION_FAIL';
  error: HttpErrorResponse;
}
export function fetchQuestionsForConfigurationFail(
  error: HttpErrorResponse
): FetchQuestionsForConfigurationFail {
  return { type: 'FETCH_QUESTIONS_FOR_CONFIGURATION_FAIL', error };
}

export interface CtaSpecificConfigurationFormChanged extends Action {
  type: 'CTA_SPECIFIC_CONFIGURATION_FORM_CHANGED';
  isDirty: boolean;
}
export function ctaSpecificConfigurationFormChanged(
  isDirty: boolean
): CtaSpecificConfigurationFormChanged {
  return { type: 'CTA_SPECIFIC_CONFIGURATION_FORM_CHANGED', isDirty };
}
