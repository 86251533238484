import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guideline } from '@models/Guideline';
import { SourceVersionOwner } from '@models/SourceVersionOwner';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { encodeGuidelinesUpdateRequest } from '@shared/utils/guidelines/guidelines.utils';
import { ConfigureGuidelineRouteRegex } from '@shared/utils/regex/routes.regex';
import {
  redirectTo,
  showSuccessNotification,
  showWarningNotification,
} from '@store/header/header.actions';
import { AppState } from '@store/root/root.reducer';
import {
  getBusinessUnitsForReacknowledgement,
  getGuideline,
  getGuidelineToSaveOrPreview,
  getRouterStateUrl,
} from '@store/root/root.selectors';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { createGetGuidelinesUri } from '../guideline.helper';
import {
  FetchGuidelineForConfiguration,
  FetchGuidelineSourceOwners,
  FetchPreviewFromGuideline,
  GuidelineEditActions,
  GuidelineEditFilterSelect,
  SaveMajorVersion,
  SaveMajorVersionSuccess,
  SaveMinorVersion,
  SaveMinorVersionSuccess,
  fetchGuidelineForConfiguration,
  fetchGuidelineForConfigurationFail,
  fetchGuidelineForConfigurationSuccess,
  fetchGuidelineSourceOwnersFail,
  fetchGuidelineSourceOwnersSuccess,
  fetchPreviewFromGuidelineFail,
  fetchPreviewFromGuidelineSuccess,
  guidelineConfigurationFormChanged,
  saveMajorVersionFail,
  saveMajorVersionSuccess,
  saveMinorVersionFail,
  saveMinorVersionSuccess,
  updateBusinessUnitsForReacknowledgement,
} from './guideline-edit.actions';

@Injectable()
export class GuidelineEditEffects {
  // Route: /configure/guideline/${countryCode}
  public navigateToEdit$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RouterNavigationAction>(ROUTER_NAVIGATION),
      withLatestFrom(
        this.store$.select(getRouterStateUrl),
        (_action, router) => router
      ),
      filter((url) => ConfigureGuidelineRouteRegex.test(url)),
      map<string, string[]>((url) => {
        const matches = url.match(ConfigureGuidelineRouteRegex);
        return [matches[1], matches[3]];
      }),
      mergeMap(([countryCode, creationDate]) => [
        fetchGuidelineForConfiguration(countryCode, creationDate),
      ])
    )
  );

  public makeGuidelineConfigurationFormDirty$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GuidelineEditActions>(
        'CHAPTER_ADD_NEW',
        'CHAPTERS_REORDER',
        'CHAPTER_NAME_CHANGE',
        'CHAPTER_REMOVE',
        'SECTION_ADD_NEW',
        'SECTION_NAME_CHANGE',
        'SECTION_REMOVE',
        'SECTIONS_REORDER',
        'SUBSECTION_ADD_NEW',
        'SUBSECTION_ADD_NEW_WITH_SCOPE',
        'SUBSECTION_CONTENT_CHANGE',
        'SUBSECTION_REMOVE',
        'SUBSECTION_CHANGE_MODE',
        'SUBSECTION_UPDATE_SCOPE'
      ),
      switchMap(() => [guidelineConfigurationFormChanged(true)])
    )
  );

  public fetchGuidelineForConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchGuidelineForConfiguration>(
        'FETCH_GUIDELINE_FOR_CONFIGURATION'
      ),
      switchMap(({ countryCode, creationDate, businessUnit }) => {
        return this.http
          .get(
            `gateway/${createGetGuidelinesUri(countryCode, creationDate)}`,
            businessUnit
              ? {
                  params: {
                    businessUnit,
                  },
                }
              : undefined
          )
          .pipe(
            map((res: Guideline) => fetchGuidelineForConfigurationSuccess(res)),
            catchError((err: HttpErrorResponse) => {
              return [
                showWarningNotification(err.error.message),
                fetchGuidelineForConfigurationFail(err),
              ];
            })
          );
      })
    )
  );

  public fetchGuidelineByCountryAndBusinessUnitForEdit = createEffect(() =>
    this.actions$.pipe(
      ofType<GuidelineEditFilterSelect>('GUIDELINE_EDIT_FILTER_SELECT'),
      withLatestFrom(this.store$.select(getGuideline)),
      switchMap(([action, updatedGuideline]) => {
        const businessUnit =
          action.businessUnit === 'ALL' ? undefined : action.businessUnit;
        return action.fetch
          ? [
              fetchGuidelineForConfiguration(
                updatedGuideline.country.alpha2,
                undefined,
                businessUnit
              ),
            ]
          : [];
      })
    )
  );

  public saveMinorVersionSucess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveMinorVersionSuccess>('SAVE_MINOR_VERSION_SUCCESS'),
      switchMap(({ guideline }) => [
        showSuccessNotification(
          `You have saved a minor update for ${guideline.country.name} cross-border guidelines.`
        ),
        fetchGuidelineForConfiguration(guideline.country.alpha2),
      ])
    )
  );

  public saveMajorVersionSucess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveMajorVersionSuccess>('SAVE_MAJOR_VERSION_SUCCESS'),
      switchMap(({ guideline }) => [
        redirectTo('/configure', guideline.country.alpha2),
        showSuccessNotification(
          `You have saved a major update for ${guideline.country.name} cross-border guidelines.`
        ),
      ])
    )
  );

  public saveMinorVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveMinorVersion>('SAVE_MINOR_VERSION'),
      withLatestFrom(this.store$.select(getGuidelineToSaveOrPreview)),
      map(([_, guideline]) => guideline),
      switchMap((updatedGuideline) => {
        return this.http
          .post(
            `guidelines/${updatedGuideline.country.alpha2}/minor`,
            encodeGuidelinesUpdateRequest(updatedGuideline)
          )
          .pipe(
            map((res: Guideline) => saveMinorVersionSuccess(res)),
            catchError((err: HttpErrorResponse) => [
              showWarningNotification(err.error.message),
              saveMinorVersionFail(err),
            ])
          );
      })
    )
  );

  public saveMajorVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveMajorVersion>('SAVE_MAJOR_VERSION'),
      withLatestFrom(this.store$.select(getGuidelineToSaveOrPreview)),
      withLatestFrom(this.store$.select(getBusinessUnitsForReacknowledgement)),
      switchMap(([[_, guideline], businessUnits]) => {
        // if there are business units targeted for reacknowledgement
        const businessUnitsTargeted = businessUnits && businessUnits.length > 0;

        if (businessUnitsTargeted) {
          return this.http
            .post(
              `guidelines/${guideline.country.alpha2}/major`,
              encodeGuidelinesUpdateRequest(guideline),
              {
                params: {
                  reacknowledgeBusinessUnits: businessUnits.join(','),
                },
              }
            )
            .pipe(
              map((res: Guideline) => saveMajorVersionSuccess(res)),
              catchError((err: HttpErrorResponse) => [
                showWarningNotification(err.error.message),
                saveMajorVersionFail(err),
              ])
            );
        } else {
          return this.http
            .post(
              `guidelines/${guideline.country.alpha2}/major`,
              encodeGuidelinesUpdateRequest(guideline)
            )
            .pipe(
              map((res: Guideline) => saveMajorVersionSuccess(res)),
              catchError((err: HttpErrorResponse) => [
                showWarningNotification(err.error.message),
                saveMajorVersionFail(err),
              ])
            );
        }
      })
    )
  );

  public fetchGuidelinePreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchPreviewFromGuideline>('FETCH_PREVIEW_FROM_GUIDELINE'),
      withLatestFrom(this.store$.select(getGuidelineToSaveOrPreview)),
      map(([action, guideline]) => {
        return { action, guideline };
      }),
      switchMap(({ action, guideline }) => {
        const url = `guidelines/preview${
          action.businessUnit ? `?businessUnit=${action.businessUnit}` : ''
        }`;
        return this.http
          .post(url, encodeGuidelinesUpdateRequest(guideline))
          .pipe(
            map((res: Guideline) => fetchPreviewFromGuidelineSuccess(res)),
            catchError((err: HttpErrorResponse) => {
              return [
                showWarningNotification(err.error.message),
                fetchPreviewFromGuidelineFail(err),
              ];
            })
          );
      })
    )
  );

  public fetchGuidelineSourceOwners$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchGuidelineSourceOwners>('FETCH_GUIDELINE_SOURCE_OWNERS'),
      withLatestFrom(this.store$.select(getGuidelineToSaveOrPreview)),
      map(([action, guideline]) => {
        return { action, guideline };
      }),
      switchMap(({ action, guideline }) =>
        this.http
          .post(
            `guidelines/${action.countryCode}/sourceOwners`,
            encodeGuidelinesUpdateRequest(guideline)
          )
          .pipe(
            mergeMap((res: SourceVersionOwner[]) => {
              const businessUnitsForReack = res.map(
                (sourceOwner) => sourceOwner.businessUnit
              );
              return [
                updateBusinessUnitsForReacknowledgement(businessUnitsForReack),
                fetchGuidelineSourceOwnersSuccess(res),
              ];
            }),
            catchError((err: HttpErrorResponse) => {
              return [
                showWarningNotification(err.error.message),
                fetchGuidelineSourceOwnersFail(err),
              ];
            })
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store$: Store<AppState>
  ) {}
}
