<sgxb-radio-group
  displayWith="text"
  [label]="q.description"
  [options]="q.options"
  [value]="answer$ | async"
  [validators]="validators"
  (selected)="onSingleSelect($event)"
  (validate)="onValidate($event)"
>
</sgxb-radio-group>
