import { Guideline } from '@models/Guideline';
import {
  GuidelineState,
  GuidelineUiState,
} from '@store/guideline/guideline.models';
import {
  MOCK_API_PROFILE,
  MOCK_API_PROFILE_2,
} from 'app/mock-api/mocks/profile.mocks';
import {
  ChapterCollapsibleChange,
  ChapterNameChange,
  ChapterRemove,
  ChaptersReorder,
  SectionAddNew,
  SectionExpanded,
  SectionNameChange,
  SectionRemove,
  SectionsReorder,
  SubsectionAddNew,
  SubsectionAddNewWithScope,
  SubsectionChangeMode,
  SubsectionContentChange,
  SubsectionRemove,
  SubsectionUpdateScope,
} from './edit/guideline-edit.actions';

export function updateScopeSubsection(
  state: GuidelineState,
  { cIndex, sIndex, ssIndex, scope }: SubsectionUpdateScope
) {
  const s = getStatesForSubsectionAt(state, cIndex, sIndex, ssIndex);
  s.guideline.chapters[cIndex].sections[sIndex].subsections[ssIndex].scope =
    scope;
  return s;
}

export function changeModeSubsection(
  state: GuidelineState,
  { cIndex, sIndex, ssIndex, isEditing }: SubsectionChangeMode
) {
  const s = getStatesForSubsectionAt(state, cIndex, sIndex, ssIndex);
  s.uiState.chapters[cIndex].sections[sIndex].subsections[ssIndex].inEditMode =
    isEditing;
  return s;
}

export function expandSection(
  state: GuidelineState,
  { cIndex, sIndex, isExpanded }: SectionExpanded
): GuidelineState {
  const s = getStatesForSectionAt(state, cIndex, sIndex);
  s.uiState.chapters[cIndex].sections[sIndex].expanded = isExpanded;
  s.uiState.chapters[cIndex].sections[sIndex].subsections =
    state.uiState.chapters[cIndex].sections[sIndex].subsections.slice();
  s.uiState.chapters[cIndex].sections[sIndex].subsections.forEach(
    (_ss, ssIndex) => {
      s.uiState.chapters[cIndex].sections[sIndex].subsections[ssIndex] = {
        ...state.uiState.chapters[cIndex].sections[sIndex].subsections[ssIndex],
        inEditMode: false,
      };
    }
  );
  return s;
}

/** Reorder chapters */
export function reorderChapters(
  state: GuidelineState,
  action: ChaptersReorder
): GuidelineState {
  const newIndexes = action.payload;
  const s = getStatesForChapters(state);
  for (let i = 0; i < newIndexes.length; i++) {
    s.guideline.chapters[i] = state.guideline.chapters[newIndexes[i]];
    s.uiState.chapters[i] = state.uiState.chapters[newIndexes[i]];
  }
  return s;
}

/** Add new empty subsection in section */
export function addNewSubsection(
  state: GuidelineState,
  { cIndex, sIndex }: SubsectionAddNew
): GuidelineState {
  const s = getStatesForSubsections(state, cIndex, sIndex);
  s.guideline.chapters[cIndex].sections[sIndex].subsections.push({
    content: 'New subsection',
    scope: [],
    filtered: false,
  });
  s.uiState.chapters[cIndex].sections[sIndex].subsections.push({
    inEditMode: true,
  });
  return s;
}

/** Add new empty subsection in section, with a defined scope */
export function addNewSubsectionWithScope(
  state: GuidelineState,
  { cIndex, sIndex, scope }: SubsectionAddNewWithScope
): GuidelineState {
  const s = getStatesForSubsections(state, cIndex, sIndex);
  s.guideline.chapters[cIndex].sections[sIndex].subsections.push({
    content: 'New subsection',
    scope,
    filtered: false,
  });
  s.uiState.chapters[cIndex].sections[sIndex].subsections.push({
    inEditMode: true,
  });
  return s;
}

/** Add new empty section in chapter */
export function addNewSection(
  state: GuidelineState,
  action: SectionAddNew
): GuidelineState {
  const s = getStatesForSections(state, action.payload);
  s.guideline.chapters[action.payload].sections.push({
    name: 'New section',
    subsections: [],
  });
  s.uiState.chapters[action.payload].sections.push({
    expanded: true,
    subsections: [],
  });
  return s;
}

/** Add new empty chapter */
export function addNewChapter(state: GuidelineState): GuidelineState {
  const s = getStatesForChapters(state);
  s.guideline.chapters.push({
    name: 'New chapter',
    sections: [],
    collapsible: true,
  });
  s.uiState.chapters.push({ sections: [] });
  s.currentChapterIndex = s.guideline.chapters.length - 1;
  return s;
}

/** Update the chapter name */
export function updateChapterName(
  state: GuidelineState,
  { cIndex, newName }: ChapterNameChange
): GuidelineState {
  const s = getStatesForChapterAt(state, cIndex);
  s.guideline.chapters[cIndex].name = newName;
  return s;
}

/** Update the chapter collapsible flag */
export function updateChapterCollapsible(
  state: GuidelineState,
  { cIndex, value }: ChapterCollapsibleChange
): GuidelineState {
  const s = getStatesForChapterAt(state, cIndex);
  s.guideline.chapters[cIndex].collapsible = value;
  return s;
}

/** Update the section name */
export function updateSectionName(
  state: GuidelineState,
  { cIndex, sIndex, newName }: SectionNameChange
): GuidelineState {
  const s = getStatesForSectionAt(state, cIndex, sIndex);
  s.guideline.chapters[cIndex].sections[sIndex].name = newName;
  return s;
}

/** Remove one chapter */
export function removeChapter(
  state: GuidelineState,
  action: ChapterRemove
): GuidelineState {
  const s = getStatesForChapters(state);
  s.guideline.chapters.splice(action.payload, 1);
  s.uiState.chapters.splice(action.payload, 1);
  s.currentChapterIndex = Math.max(0, action.payload - 1);
  return s;
}

/** Remove one section */
export function removeSection(
  state: GuidelineState,
  { cIndex, sIndex }: SectionRemove
): GuidelineState {
  const s = getStatesForSections(state, cIndex);
  s.guideline.chapters[cIndex].sections.splice(sIndex, 1);
  s.uiState.chapters[cIndex].sections.splice(sIndex, 1);
  return s;
}

/** Remove one subsection */
export function removeSubsection(
  state: GuidelineState,
  { cIndex, sIndex, ssIndex }: SubsectionRemove
): GuidelineState {
  const s = getStatesForSubsections(state, cIndex, sIndex);
  s.guideline.chapters[cIndex].sections[sIndex].subsections.splice(ssIndex, 1);
  s.uiState.chapters[cIndex].sections[sIndex].subsections.splice(ssIndex, 1);
  return s;
}

/** Reorder the sections list */
export function reorderSections(
  state: GuidelineState,
  { cIndex, newIndexes }: SectionsReorder
): GuidelineState {
  const s = getStatesForSections(state, cIndex);
  for (let i = 0; i < newIndexes.length; i++) {
    s.guideline.chapters[cIndex].sections[i] =
      state.guideline.chapters[cIndex].sections[newIndexes[i]];
    s.uiState.chapters[cIndex].sections[i] =
      state.uiState.chapters[cIndex].sections[newIndexes[i]];
  }
  return s;
}

/** Update subsection name */
export function updateSubsectionContent(
  state: GuidelineState,
  { cIndex, sIndex, ssIndex, newContent }: SubsectionContentChange
): GuidelineState {
  const s = getStatesForSubsectionAt(state, cIndex, sIndex, ssIndex);
  s.guideline.chapters[cIndex].sections[sIndex].subsections[ssIndex].content =
    newContent;
  s.uiState.chapters[cIndex].sections[sIndex].subsections[ssIndex].inEditMode =
    false;
  return s;
}

/* ********************************** *
 * Helper export functions to get new states *
 * ********************************** */
export function getStatesForChapters(state: GuidelineState): GuidelineState {
  const g = { ...state.guideline, chapters: state.guideline.chapters.slice() };
  const u = { ...state.uiState, chapters: state.uiState.chapters.slice() };
  return { ...state, guideline: g, uiState: u };
}
export function getStatesForChapterAt(
  state: GuidelineState,
  cIndex: number
): GuidelineState {
  const s = getStatesForChapters(state);
  s.guideline.chapters[cIndex] = { ...state.guideline.chapters[cIndex] };
  s.uiState.chapters[cIndex] = { ...state.uiState.chapters[cIndex] };
  return s;
}
export function getStatesForSections(
  state: GuidelineState,
  cIndex: number
): GuidelineState {
  const s = getStatesForChapterAt(state, cIndex);
  s.guideline.chapters[cIndex].sections =
    state.guideline.chapters[cIndex].sections.slice();
  s.uiState.chapters[cIndex].sections =
    state.uiState.chapters[cIndex].sections.slice();
  return s;
}
export function getStatesForSectionAt(
  state: GuidelineState,
  cIndex: number,
  sIndex: number
): GuidelineState {
  const s = getStatesForSections(state, cIndex);
  s.guideline.chapters[cIndex].sections[sIndex] = {
    ...state.guideline.chapters[cIndex].sections[sIndex],
  };
  s.uiState.chapters[cIndex].sections[sIndex] = {
    ...state.uiState.chapters[cIndex].sections[sIndex],
  };
  return s;
}
export function getStatesForSubsections(
  state: GuidelineState,
  cIndex: number,
  sIndex: number
): GuidelineState {
  const s = getStatesForSectionAt(state, cIndex, sIndex);
  s.guideline.chapters[cIndex].sections[sIndex].subsections =
    state.guideline.chapters[cIndex].sections[sIndex].subsections.slice();
  s.uiState.chapters[cIndex].sections[sIndex].subsections =
    state.uiState.chapters[cIndex].sections[sIndex].subsections.slice();
  return s;
}
export function getStatesForSubsectionAt(
  state: GuidelineState,
  cIndex: number,
  sIndex: number,
  ssIndex: number
): GuidelineState {
  const s = getStatesForSubsections(state, cIndex, sIndex);
  s.guideline.chapters[cIndex].sections[sIndex].subsections[ssIndex] = {
    ...state.guideline.chapters[cIndex].sections[sIndex].subsections[ssIndex],
  };
  s.uiState.chapters[cIndex].sections[sIndex].subsections[ssIndex] = {
    ...state.uiState.chapters[cIndex].sections[sIndex].subsections[ssIndex],
  };
  return s;
}
export function getNewUiStateFromGuideline(g: Guideline): GuidelineUiState {
  const ui: GuidelineUiState = {
    chapters: [],
    displaySubmitPracticalCaseModule: true,
    profiles: [MOCK_API_PROFILE, MOCK_API_PROFILE_2],
  };
  if (g.chapters) {
    for (const c of g.chapters) {
      const newC = { sections: [] };
      if (c.sections) {
        for (const s of c.sections) {
          const newS = { subsections: [], expanded: false };
          if (s.subsections) {
            for (const _ss of s.subsections) {
              newS.subsections.push({ inEditMode: false, filtered: false });
            }
          }
          newC.sections.push(newS);
        }
      }
      ui.chapters.push(newC);
    }
  }
  return ui;
}

export function getFilteredState(guideline: Guideline): Guideline {
  const g: Guideline = JSON.parse(JSON.stringify(guideline));
  g.chapters.forEach((chapter) => {
    chapter.filtered = chapter.sections.every((section) =>
      section.subsections.every((subsection) => subsection.filtered)
    );
    chapter.sections.forEach((section) => {
      section.filtered = section.subsections.every(
        (subsection) => subsection.filtered
      );
      // @ts-ignore: 2339
      // TODO: Remove after scope.apply issue fixed
      // Has been commented out for using the mocks which implement the new Scope structure
      // section.subsections.forEach(subsection =>  (subsection.scope = subsection.scope.apply));
    });
  });
  g.filtered = g.chapters.every((chapter) => chapter.filtered);

  /**
   * Fix scope.apply issue
   */
  return g;
}

export function createGetGuidelinesUri(
  countryCode: string,
  creationDate?: string
): string {
  if (creationDate) {
    return `guidelines/${countryCode}/${creationDate}/forConfiguration`;
  }
  return `guidelines/${countryCode}/forConfiguration`;
}
