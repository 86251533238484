<div class="panel panel-card panel-card-condensed">
  <div class="panel-heading">BUSINESS UNITS</div>
  <div class="panel-body">
    <div
      class="checkbox col-xs-6 col-md-4"
      *ngFor="let businessUnit of checkboxBusinessUnits; index as i"
    >
      <label
        for="checkbox-{{ businessUnit.value }}"
        (click)="onClick($event, i)"
      >
        <input
          type="checkbox"
          id="checkbox-{{ businessUnit.value }}"
          [checked]="businessUnit.checked"
          [disabled]="businessUnit.readonly"
        />
        <span>{{ businessUnit.value }}</span>
      </label>
    </div>

    <div class="col-xs-12 col-md-12 row">
      <div class="warnings" *ngIf="!validity">
        <ng-container *ngFor="let warning of warnings">
          {{ warning }}<br />
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="formValidity"></ng-container>
