import { Component, Input } from '@angular/core';
import { ComplianceTravelApproval } from '@models/ComplianceTravelApproval';
import { Store } from '@ngrx/store';
import { UITravelApprovalQuestion } from '@store/cta-specific/cta-specific.models';
import { AppState } from '@store/root/root.reducer';
import { getIsQuestionVisible } from '@store/root/root.selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'sgxb-travel-approval-variable-fields',
  templateUrl: './travel-approval-variable-fields.component.html',
  styleUrls: ['./travel-approval-variable-fields.component.scss'],
})
export class TravelApprovalVariableFieldsComponent {
  @Input() public questions: UITravelApprovalQuestion[];
  @Input() public ta: ComplianceTravelApproval;

  constructor(private store$: Store<AppState>) {}

  public isQuestionVisible$(uuid: string): Observable<boolean> {
    return this.store$.select(getIsQuestionVisible, { uuid });
  }
}
