import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sgxb-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
  @Input() public currentPage: number;
  @Input() public totalPages: number;
  @Input() public itemsPerPage: number;
  @Output() public changePage = new EventEmitter();

  constructor() {}

  public isPreviousPageBtnDisabled(): boolean {
    return this.currentPage <= 1;
  }

  public isNextPageBtnDisabled(): boolean {
    return this.currentPage >= this.totalPages;
  }

  public navigateToFirstPage(): void {
    if (this.isPreviousPageBtnDisabled()) {
      return;
    }
    this.changePage.emit(1);
  }

  public navigateToLastPage(): void {
    if (this.isNextPageBtnDisabled()) {
      return;
    }
    this.changePage.emit(this.totalPages);
  }

  public navigateToPreviousPage(): void {
    if (this.isPreviousPageBtnDisabled()) {
      return;
    }
    this.changePage.emit(this.currentPage - 1);
  }

  public navigateToNextPage(): void {
    if (this.isNextPageBtnDisabled()) {
      return;
    }
    this.changePage.emit(this.currentPage + 1);
  }

  public navigateToPage(page: number): void {
    this.changePage.emit(page);
  }

  public isCurrentPage(page: number): boolean {
    return this.currentPage === page;
  }
}
