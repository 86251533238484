<ng-container *ngIf="ta && ta.user">
  <div class="row" *ngIf="ta?.cta_id">
    <div class="col-xs-12 col-md-12 form-group">
      <label>Travel ID</label>
      <span class="label label-reverse">{{ ta?.cta_id }}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-md-6 form-group">
      <label>User</label>
      <span
        >{{ ta.user.firstName }} {{ ta.user.lastName }} &lt;{{
          ta.user.email
        }}&gt;</span
      >
    </div>
    <div class="col-xs-12 col-md-6 form-group">
      <label>Department</label>
      <span>{{ ta.user.department }}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-md-6 form-group">
      <label>Country of your entity</label>
      <img
        #originFlag
        src="assets/img/flags/png/{{ ta.origin.alpha2 }}.png"
        alt="{{ ta.origin.name }}"
        class="country-flag flag-with-shadow"
        (error)="originFlag.src = 'assets/img/flags/png/default.png'"
      />
      <span>{{ ta.origin.name }}</span>
    </div>
    <div class="col-xs-12 col-md-6 form-group">
      <label>Destination</label>
      <img
        #destinationFlag
        src="assets/img/flags/png/{{ ta.destination.alpha2 }}.png"
        alt="{{ ta.destination.name }}"
        class="country-flag flag-with-shadow"
        (error)="originFlag.src = 'assets/img/flags/png/default.png'"
      />
      <span>{{ ta.destination.name }}</span>
    </div>
  </div>
  <!-- SPLIT -->
  <div class="row">
    <div class="col-xs-12 col-md-6 form-group">
      <label>From</label>
      <span>{{ ta.startDate | date: 'MMM d, y (EEE)' }}</span>
    </div>
    <div class="col-xs-12 col-md-6 form-group">
      <label>To</label>
      <span>{{ ta.endDate | date: 'MMM d, y (EEE)' }}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-md-6 form-group">
      <label>Manager</label>
      <ng-container *ngIf="ta.manager && ta.manager !== ''; else emptyField">
        <a href="mailto:{{ ta.manager }}" class="label label-default">{{
          ta.manager
        }}</a>
      </ng-container>
    </div>
    <div class="col-xs-12 col-md-6 form-group">
      <label>CC Contact</label>
      <ng-container *ngIf="ta.ccs && ta.ccs.length > 0; else emptyField">
        <a
          href="mailto:{{ cc }}"
          class="label label-default"
          *ngFor="let cc of ta.ccs"
          >{{ cc }}</a
        >
      </ng-container>
    </div>
  </div>

  <div
    class="row"
    *ngIf="isSupervisorDetailHidden; else supervisorDetailDisplayed"
  >
    <div class="col-xs-12 form-group">
      <label>Comments</label>
      <span>{{ ta.comments && ta.comments !== '' ? ta.comments : 'N/A' }}</span>
    </div>
  </div>
  <ng-template #supervisorDetailDisplayed>
    <div class="row">
      <div class="col-xs-12 col-md-6 form-group">
        <label>Supervisor</label>
        <ng-container
          *ngIf="ta.supervisor && ta.supervisor !== ''; else emptyField"
        >
          <a href="mailto:{{ ta.supervisor }}" class="label label-default">{{
            ta.supervisor
          }}</a>
        </ng-container>
      </div>
      <div class="col-xs-12 col-md-6 form-group">
        <label>Comments</label>
        <span>{{
          ta.comments && ta.comments !== '' ? ta.comments : 'N/A'
        }}</span>
      </div>
    </div>
  </ng-template>
  <ng-template #emptyField>
    <span>N/A</span>
  </ng-template>
</ng-container>
