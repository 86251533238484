import {
  Guideline,
  GuidelineHistory,
  GuidelineRequestToSaveOrPreview,
} from '@models/Guideline';
import { GuidelineState, GuidelineUiState } from './guideline.models';

export const getGuideline = (state: GuidelineState): Guideline =>
  state ? state.guideline : null;
export const getUiState = (state: GuidelineState): GuidelineUiState =>
  state.uiState;
export const getHistory = (state: GuidelineState): GuidelineHistory =>
  state.history;
export const getHistoryOnGoing = (state: GuidelineState): boolean =>
  state.fetchHistoryOnGoing;
export const getBusinessUnitsByCountry = (state: GuidelineState): string[] =>
  state.businessUnitByCountry;
export const getBusinessUnitForGuideline = (state: GuidelineState): string[] =>
  state.businessUnitForGuideline;
export const getBusinessUnitForGuidelineExport = (
  state: GuidelineState
): string[] => {
  const businessUnits = getBusinessUnitForGuideline(state);
  return businessUnits
    ? businessUnits.filter((businessUnit) => businessUnit !== 'ALL')
    : [];
};
export const getFetchBusinessUnitForGuidelineOnGoing = (
  state: GuidelineState
): boolean => state.fetchBusinessUnitForGuidelineOnGoing;
export const getGuidelineToSaveOrPreview = (
  state: GuidelineState
): GuidelineRequestToSaveOrPreview => {
  const g = getGuideline(state);
  return g
    ? {
        acknowledgement: g.acknowledgement,
        chapters: g.chapters.map((chapter) => ({
          name: chapter.name,
          collapsible: chapter.collapsible,
          sections: chapter.sections.map((section) => ({
            name: section.name,
            subsections: section.subsections.map((subsection) => ({
              content: subsection.content,
              scope: subsection.scope,
            })),
          })),
        })),
        country: g.country,
        major: g.major,
        sourceOwners: g.sourceOwners,
        validatedBy: g.validatedBy,
      }
    : null;
};
