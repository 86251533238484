import {
  ComplianceTravelApproval,
  CtaStats,
} from '@models/ComplianceTravelApproval';
import { Country } from '@models/Country';
import { Action } from '@ngrx/store';

export type CtaActions =
  | NewCta
  | UpdateCta
  | DeleteCta
  | DeleteCtaSuccess
  | DeleteCtaFail
  | CreateCta
  | CreateCtaSuccess
  | CreateCtaFail
  | SaveCta
  | SaveCtaSuccess
  | SaveCtaFail
  | FetchCta
  | FetchCtaSuccess
  | FetchCtaFail
  | FetchAllCta
  | FetchAllCtaSuccess
  | FetchAllCtaFail
  | FetchLastCta
  | FetchLastCtaSuccess
  | FetchLastCtaFail
  | FetchLatestCta
  | FetchLatestCtaSuccess
  | FetchLatestCtaFail
  | FetchCtaStats
  | FetchCtaStatsSuccess
  | FetchCtaStatsFail
  | FetchSpecificCtaForApproval
  | FetchSpecificCtaForApprovalSuccess
  | FetchSpecificCtaForApprovalFail
  | ClearSpecificCtaForApproval
  | FetchCtaForApprovalList
  | FetchCtaForApprovalListSuccess
  | FetchCtaForApprovalListFail
  | FetchCtaForApprovalListWithFirstCta
  | FetchCtaForApprovalListWithFirstCtaSuccess
  | FetchCtaForApprovalListWithFirstCtaFail
  | FetchCtaAlreadyProcessedList
  | FetchCtaAlreadyProcessedListSuccess
  | FetchCtaAlreadyProcessedListFail
  | RejectCta
  | RejectCtaSuccess
  | RejectCtaFail
  | ApproveCta
  | ApproveCtaSuccess
  | ApproveCtaFail
  | ChangeRejectModeEnabled
  | FocusCtaForApprovalAtIndex
  | FetchCountOfWorkingDays
  | FetchCountOfWorkingDaysSuccess
  | FetchCountOfWorkingDaysFail
  | FetchLateDeclarationWarning
  | FetchLateDeclarationWarningSuccess
  | FetchLateDeclarationWarningFail
  | ClearLateDeclarationWarning
  | DuplicateCta
  | DuplicateCtaSuccess
  | FetchCtaForDuplicationSuccess
  | ClearCtaDuplication
  | ExportCtaDashboard
  | ExportCtaDashboardSuccess
  | ExportCtaDashboardFail
  | UpdateCtaAnswer
  | UpdateCtaAnswerOnCcCheckbox
  | UpdateCtaAnswerOnMultiSelectAdd
  | UpdateCtaAnswerOnMultiSelectRemove
  | UpdateCtaAnswerValidity
  | ResetCta;

export interface NewCta extends Action {
  type: 'NEW_CTA';
}
export function newCta(): NewCta {
  return {
    type: 'NEW_CTA',
  };
}

export interface UpdateCta extends Action {
  type: 'UPDATE_CTA';
  payload: ComplianceTravelApproval;
}
export function updateCta(payload: ComplianceTravelApproval): UpdateCta {
  return {
    type: 'UPDATE_CTA',
    payload,
  };
}

export type UpdateCtaAnswer = ReturnType<typeof updateCtaAnswer>;
export function updateCtaAnswer(uuid: string, value: string) {
  return { type: 'UPDATE_TAR_ANSWER' as const, uuid, value };
}

export type UpdateCtaAnswerOnCcCheckbox = ReturnType<
  typeof updateCtaAnswerOnCcCheckbox
>;
export function updateCtaAnswerOnCcCheckbox(uuid: string, value: boolean) {
  return { type: 'UPDATE_TAR_ANSWER_ON_CC_CHECKBOX' as const, uuid, value };
}

export type UpdateCtaAnswerOnMultiSelectAdd = ReturnType<
  typeof updateCtaAnswerOnMultiSelectAdd
>;
export function updateCtaAnswerOnMultiSelectAdd(uuid: string, value: string) {
  return {
    type: 'UPDATE_TAR_ANSWER_ON_MULTI_SELECT_ADD' as const,
    uuid,
    value,
  };
}

export type UpdateCtaAnswerOnMultiSelectRemove = ReturnType<
  typeof updateCtaAnswerOnMultiSelectRemove
>;
export function updateCtaAnswerOnMultiSelectRemove(
  uuid: string,
  value: string
) {
  return {
    type: 'UPDATE_TAR_ANSWER_ON_MULTI_SELECT_REMOVE' as const,
    uuid,
    value,
  };
}

export type UpdateCtaAnswerValidity = ReturnType<
  typeof updateCtaAnswerValidity
>;
export function updateCtaAnswerValidity(uuid: string, isValidated: boolean) {
  return { type: 'UPDATE_TAR_ANSWER_VALIDITY' as const, uuid, isValidated };
}

export type ResetCta = ReturnType<typeof resetCta>;
export function resetCta() {
  return { type: 'RESET_CTA' as const };
}

export type CreateCta = ReturnType<typeof createCta>;
export function createCta(onDelegation: boolean = false) {
  return { type: 'CREATE_CTA' as const, onDelegation };
}

export interface CreateCtaSuccess extends Action {
  type: 'CREATE_TAR_SUCCESS';
  payload: ComplianceTravelApproval;
}
export function createCtaSuccess(
  payload: ComplianceTravelApproval
): CreateCtaSuccess {
  return {
    type: 'CREATE_TAR_SUCCESS',
    payload,
  };
}
export interface CreateCtaFail extends Action {
  type: 'CREATE_TAR_FAIL';
  payload: any;
}
export function createCtaFail(payload: any): CreateCtaFail {
  return {
    type: 'CREATE_TAR_FAIL',
    payload,
  };
}

export type DeleteCta = ReturnType<typeof deleteCta>;
export function deleteCta(onDelegation: boolean = false) {
  return { type: 'DELETE_CTA' as const, onDelegation };
}

export type DeleteCtaSuccess = ReturnType<typeof deleteCtaSuccess>;
export function deleteCtaSuccess() {
  return { type: 'DELETE_TAR_SUCCESS' as const };
}

export type DeleteCtaFail = ReturnType<typeof deleteCtaFail>;
export function deleteCtaFail(payload: any) {
  return { type: 'DELETE_TAR_FAIL' as const, payload };
}

export type SaveCta = ReturnType<typeof saveCta>;
export function saveCta(onDelegation: boolean = false) {
  return { type: 'SAVE_CTA' as const, onDelegation };
}

export type SaveCtaSuccess = ReturnType<typeof saveCtaSuccess>;
export function saveCtaSuccess(payload: ComplianceTravelApproval) {
  return { type: 'SAVE_TAR_SUCCESS' as const, payload };
}

export type SaveCtaFail = ReturnType<typeof saveCtaFail>;
export function saveCtaFail(payload: any) {
  return { type: 'SAVE_TAR_FAIL' as const, payload };
}

export interface FetchAllCta extends Action {
  type: 'FETCH_ALL_CTA';
}
export function fetchAllCta(): FetchAllCta {
  return {
    type: 'FETCH_ALL_CTA',
  };
}

export interface FetchAllCtaSuccess extends Action {
  type: 'FETCH_ALL_TAR_SUCCESS';
  payload: ComplianceTravelApproval[];
}
export function fetchAllCtaSuccess(
  payload: ComplianceTravelApproval[]
): FetchAllCtaSuccess {
  return {
    type: 'FETCH_ALL_TAR_SUCCESS',
    payload,
  };
}

export interface FetchAllCtaFail extends Action {
  type: 'FETCH_ALL_TAR_FAIL';
  payload: any;
}
export function fetchAllCtaFail(payload: any): FetchAllCtaFail {
  return {
    type: 'FETCH_ALL_TAR_FAIL',
    payload,
  };
}

export interface FetchCta extends Action {
  type: 'FETCH_CTA';
  ctaUuid: string;
  forDuplication: boolean;
}
export function fetchCta(
  ctaUuid: string,
  forDuplication: boolean = false
): FetchCta {
  return {
    type: 'FETCH_CTA',
    ctaUuid,
    forDuplication,
  };
}

export interface FetchCtaSuccess extends Action {
  type: 'FETCH_TAR_SUCCESS';
  payload: ComplianceTravelApproval;
}
export function fetchCtaSuccess(
  payload: ComplianceTravelApproval
): FetchCtaSuccess {
  return {
    type: 'FETCH_TAR_SUCCESS',
    payload,
  };
}

export interface FetchCtaFail extends Action {
  type: 'FETCH_TAR_FAIL';
  payload: any;
}
export function fetchCtaFail(payload: any): FetchCtaFail {
  return {
    type: 'FETCH_TAR_FAIL',
    payload,
  };
}

export interface FetchCtaForDuplicationSuccess extends Action {
  type: 'FETCH_TAR_FOR_DUPLICATION_SUCCESS';
  payload: ComplianceTravelApproval;
}
export function fetchCtaForDuplicationSuccess(
  payload: ComplianceTravelApproval
): FetchCtaForDuplicationSuccess {
  return {
    type: 'FETCH_TAR_FOR_DUPLICATION_SUCCESS',
    payload,
  };
}

export interface FetchLastCta extends Action {
  type: 'FETCH_LAST_CTA';
  travelerEmail: string;
}
export function fetchLastCta(travelerEmail: string = undefined): FetchLastCta {
  return {
    type: 'FETCH_LAST_CTA',
    travelerEmail,
  };
}

export interface FetchLastCtaSuccess extends Action {
  type: 'FETCH_LAST_TAR_SUCCESS';
  payload: ComplianceTravelApproval;
}
export function fetchLastCtaSuccess(
  payload: ComplianceTravelApproval
): FetchLastCtaSuccess {
  return {
    type: 'FETCH_LAST_TAR_SUCCESS',
    payload,
  };
}

export interface FetchLastCtaFail extends Action {
  type: 'FETCH_LAST_TAR_FAIL';
  payload: any;
}
export function fetchLastCtaFail(payload: any): FetchLastCtaFail {
  return {
    type: 'FETCH_LAST_TAR_FAIL',
    payload,
  };
}

export interface FetchLatestCta extends Action {
  type: 'FETCH_LATEST_CTA';
  payload: number;
}
export function fetchLatestCta(payload: number): FetchLatestCta {
  return {
    type: 'FETCH_LATEST_CTA',
    payload,
  };
}

export interface FetchLatestCtaSuccess extends Action {
  type: 'FETCH_LATEST_TAR_SUCCESS';
  payload: ComplianceTravelApproval[];
}
export function fetchLatestCtaSuccess(
  payload: ComplianceTravelApproval[]
): FetchLatestCtaSuccess {
  return {
    type: 'FETCH_LATEST_TAR_SUCCESS',
    payload,
  };
}

export interface FetchLatestCtaFail extends Action {
  type: 'FETCH_LATEST_TAR_FAIL';
  payload: any;
}
export function fetchLatestCtaFail(payload: any): FetchLatestCtaFail {
  return {
    type: 'FETCH_LATEST_TAR_FAIL',
    payload,
  };
}

export interface FetchCtaStats extends Action {
  type: 'FETCH_TAR_STATS';
  payload: {
    countryCode: string;
    startDate: Date;
    endDate: Date;
  };
}
export function fetchCtaStats(
  countryCode: string,
  startDate: Date,
  endDate: Date
): FetchCtaStats {
  return {
    type: 'FETCH_TAR_STATS',
    payload: {
      countryCode,
      startDate,
      endDate,
    },
  };
}

export interface FetchCtaStatsSuccess extends Action {
  type: 'FETCH_TAR_STATS_SUCCESS';
  payload: CtaStats;
}
export function fetchCtaStatsSuccess(payload: CtaStats): FetchCtaStatsSuccess {
  return {
    type: 'FETCH_TAR_STATS_SUCCESS',
    payload,
  };
}
export interface FetchCtaStatsFail extends Action {
  type: 'FETCH_TAR_STATS_FAIL';
  payload: any;
}
export function fetchCtaStatsFail(payload: any): FetchCtaStatsFail {
  return {
    type: 'FETCH_TAR_STATS_FAIL',
    payload,
  };
}

export interface FetchSpecificCtaForApproval extends Action {
  type: 'FETCH_SPECIFIC_TAR_FOR_APPROVAL';
  payload: string;
}
export function fetchSpecificCtaForApproval(
  payload: string
): FetchSpecificCtaForApproval {
  return {
    type: 'FETCH_SPECIFIC_TAR_FOR_APPROVAL',
    payload,
  };
}

export interface FetchSpecificCtaForApprovalSuccess extends Action {
  type: 'FETCH_SPECIFIC_TAR_FOR_APPROVAL_SUCCESS';
  payload: ComplianceTravelApproval;
}
export function fetchSpecificCtaForApprovalSuccess(
  payload: ComplianceTravelApproval
): FetchSpecificCtaForApprovalSuccess {
  return {
    type: 'FETCH_SPECIFIC_TAR_FOR_APPROVAL_SUCCESS',
    payload,
  };
}

export interface FetchSpecificCtaForApprovalFail extends Action {
  type: 'FETCH_SPECIFIC_TAR_FOR_APPROVAL_FAIL';
  payload: any;
}
export function fetchSpecificCtaForApprovalFail(
  payload: any
): FetchSpecificCtaForApprovalFail {
  return {
    type: 'FETCH_SPECIFIC_TAR_FOR_APPROVAL_FAIL',
    payload,
  };
}

export interface ClearSpecificCtaForApproval extends Action {
  type: 'CLEAR_SPECIFIC_TAR_FOR_APPROVAL';
}
export function clearSpecificCtaForApproval(): ClearSpecificCtaForApproval {
  return {
    type: 'CLEAR_SPECIFIC_TAR_FOR_APPROVAL',
  };
}

export interface FetchCtaAlreadyProcessedList extends Action {
  type: 'FETCH_TAR_ALREADY_PROCESSED_LIST';
}
export function fetchCtaAlreadyProcessedList(): FetchCtaAlreadyProcessedList {
  return {
    type: 'FETCH_TAR_ALREADY_PROCESSED_LIST',
  };
}
export interface FetchCtaAlreadyProcessedListSuccess extends Action {
  type: 'FETCH_TAR_ALREADY_PROCESSED_LIST_SUCCESS';
  payload: ComplianceTravelApproval[];
}
export function fetchCtaAlreadyProcessedListSuccess(
  payload: ComplianceTravelApproval[]
): FetchCtaAlreadyProcessedListSuccess {
  return {
    type: 'FETCH_TAR_ALREADY_PROCESSED_LIST_SUCCESS',
    payload,
  };
}
export interface FetchCtaAlreadyProcessedListFail extends Action {
  type: 'FETCH_TAR_ALREADY_PROCESSED_LIST_FAIL';
  payload: any;
}
export function fetchCtaAlreadyProcessedListFail(
  payload: any
): FetchCtaAlreadyProcessedListFail {
  return {
    type: 'FETCH_TAR_ALREADY_PROCESSED_LIST_FAIL',
    payload,
  };
}
export interface FetchCtaForApprovalList extends Action {
  type: 'FETCH_TAR_FOR_APPROVAL_LIST';
}
export function fetchCtaForApprovalList(): FetchCtaForApprovalList {
  return {
    type: 'FETCH_TAR_FOR_APPROVAL_LIST',
  };
}
export interface FetchCtaForApprovalListSuccess extends Action {
  type: 'FETCH_TAR_FOR_APPROVAL_LIST_SUCCESS';
  payload: ComplianceTravelApproval[];
}
export function fetchCtaForApprovalListSuccess(
  payload: ComplianceTravelApproval[]
): FetchCtaForApprovalListSuccess {
  return {
    type: 'FETCH_TAR_FOR_APPROVAL_LIST_SUCCESS',
    payload,
  };
}
export interface FetchCtaForApprovalListFail extends Action {
  type: 'FETCH_TAR_FOR_APPROVAL_LIST_FAIL';
  payload: any;
}
export function fetchCtaForApprovalListFail(
  payload: any
): FetchCtaForApprovalListFail {
  return {
    type: 'FETCH_TAR_FOR_APPROVAL_LIST_FAIL',
    payload,
  };
}

export interface FetchCtaForApprovalListWithFirstCta extends Action {
  type: 'FETCH_TAR_FOR_APPROVAL_LIST_WITH_FIRST_CTA';
}
export function fetchCtaForApprovalListWithFirstCta(): FetchCtaForApprovalListWithFirstCta {
  return {
    type: 'FETCH_TAR_FOR_APPROVAL_LIST_WITH_FIRST_CTA',
  };
}
export interface FetchCtaForApprovalListWithFirstCtaSuccess extends Action {
  type: 'FETCH_TAR_FOR_APPROVAL_LIST_WITH_FIRST_TAR_SUCCESS';
  payload: ComplianceTravelApproval[];
}
export function fetchCtaForApprovalListWithFirstCtaSuccess(
  payload: ComplianceTravelApproval[]
): FetchCtaForApprovalListWithFirstCtaSuccess {
  return {
    type: 'FETCH_TAR_FOR_APPROVAL_LIST_WITH_FIRST_TAR_SUCCESS',
    payload,
  };
}
export interface FetchCtaForApprovalListWithFirstCtaFail extends Action {
  type: 'FETCH_TAR_FOR_APPROVAL_LIST_WITH_FIRST_TAR_FAIL';
  payload: any;
}
export function fetchCtaForApprovalListWithFirstCtaFail(
  payload: any
): FetchCtaForApprovalListWithFirstCtaFail {
  return {
    type: 'FETCH_TAR_FOR_APPROVAL_LIST_WITH_FIRST_TAR_FAIL',
    payload,
  };
}

export interface RejectCta extends Action {
  type: 'REJECT_CTA';
  payload: {
    ctaId: string;
    rejectionComment: string;
  };
}
export function rejectCta(ctaId: string, rejectionComment: string): RejectCta {
  return {
    type: 'REJECT_CTA',
    payload: {
      ctaId,
      rejectionComment,
    },
  };
}

export interface RejectCtaSuccess extends Action {
  type: 'REJECT_TAR_SUCCESS';
}
export function rejectCtaSuccess(): RejectCtaSuccess {
  return {
    type: 'REJECT_TAR_SUCCESS',
  };
}

export interface RejectCtaFail extends Action {
  type: 'REJECT_TAR_FAIL';
  payload: any;
}
export function rejectCtaFail(payload: any): RejectCtaFail {
  return {
    type: 'REJECT_TAR_FAIL',
    payload,
  };
}

export interface ApproveCta extends Action {
  type: 'APPROVE_CTA';
  ctaId: string;
}
export function approveCta(ctaId: string): ApproveCta {
  return {
    type: 'APPROVE_CTA',
    ctaId,
  };
}

export interface ApproveCtaSuccess extends Action {
  type: 'APPROVE_TAR_SUCCESS';
}
export function approveCtaSuccess(): ApproveCtaSuccess {
  return {
    type: 'APPROVE_TAR_SUCCESS',
  };
}

export interface ApproveCtaFail extends Action {
  type: 'APPROVE_TAR_FAIL';
  payload: any;
}
export function approveCtaFail(payload: any): ApproveCtaFail {
  return {
    type: 'APPROVE_TAR_FAIL',
    payload,
  };
}

export interface ChangeRejectModeEnabled extends Action {
  type: 'CHANGE_REJECT_MODE_ENABLED';
  isEnabled: boolean;
}
export function changeRejectModeEnabled(
  isEnabled: boolean
): ChangeRejectModeEnabled {
  return {
    type: 'CHANGE_REJECT_MODE_ENABLED',
    isEnabled,
  };
}

export interface FocusCtaForApprovalAtIndex extends Action {
  type: 'FOCUS_TAR_FOR_APPROVAL_AT_INDEX';
  ctaIndex: number;
}
export function focusCtaForApprovalAtIndex(
  ctaIndex: number
): FocusCtaForApprovalAtIndex {
  return {
    type: 'FOCUS_TAR_FOR_APPROVAL_AT_INDEX',
    ctaIndex,
  };
}

export interface FetchCountOfWorkingDays extends Action {
  type: 'FETCH_COUNT_OF_WORKING_DAYS';
  country: Country;
  startDate: string;
  endDate: string;
  editedCtaUuid: string;
  traveler: string;
}
export function fetchCountOfWorkingDays(
  country: Country,
  startDate: string,
  endDate: string,
  editedCtaUuid: string,
  traveler: string = null
): FetchCountOfWorkingDays {
  return {
    type: 'FETCH_COUNT_OF_WORKING_DAYS',
    country,
    startDate,
    endDate,
    editedCtaUuid,
    traveler,
  };
}

export interface FetchCountOfWorkingDaysSuccess extends Action {
  type: 'FETCH_COUNT_OF_WORKING_DAYS_SUCCESS';
  payload: {
    level: string;
    title: string;
    message: string;
  };
}
export function fetchCountOfWorkingDaysSuccess(
  payload: any
): FetchCountOfWorkingDaysSuccess {
  return { type: 'FETCH_COUNT_OF_WORKING_DAYS_SUCCESS', payload };
}

export interface FetchCountOfWorkingDaysFail extends Action {
  type: 'FETCH_COUNT_OF_WORKING_DAYS_FAIL';
  payload: any;
}
export function fetchCountOfWorkingDaysFail(
  payload: any
): FetchCountOfWorkingDaysFail {
  return {
    type: 'FETCH_COUNT_OF_WORKING_DAYS_FAIL',
    payload,
  };
}

export interface FetchLateDeclarationWarning extends Action {
  type: 'FETCH_LATE_DECLARATION_WARNING';
  startDate: string;
}
export function fetchLateDeclarationWarning(
  startDate: string
): FetchLateDeclarationWarning {
  return { type: 'FETCH_LATE_DECLARATION_WARNING', startDate };
}

export interface FetchLateDeclarationWarningSuccess extends Action {
  type: 'FETCH_LATE_DECLARATION_WARNING_SUCCESS';
  payload: {
    title: string;
    message: string;
  };
}
export function fetchLateDeclarationWarningSuccess(
  payload: any
): FetchLateDeclarationWarningSuccess {
  return { type: 'FETCH_LATE_DECLARATION_WARNING_SUCCESS', payload };
}

export interface FetchLateDeclarationWarningFail extends Action {
  type: 'FETCH_LATE_DECLARATION_WARNING_FAIL';
  payload: any;
}
export function fetchLateDeclarationWarningFail(
  payload: any
): FetchLateDeclarationWarningFail {
  return {
    type: 'FETCH_LATE_DECLARATION_WARNING_FAIL',
    payload,
  };
}
export interface ClearLateDeclarationWarning extends Action {
  type: 'CLEAR_LATE_DECLARATION_WARNING';
}
export function clearLateDeclarationWarning(): ClearLateDeclarationWarning {
  return { type: 'CLEAR_LATE_DECLARATION_WARNING' };
}

export interface DuplicateCta extends Action {
  type: 'DUPLICATE_CTA';
  ctaUuid: string;
  traveler?: string;
}

export function duplicateCta(ctaUuid: string, traveler?: string): DuplicateCta {
  return {
    type: 'DUPLICATE_CTA',
    ctaUuid,
    traveler,
  };
}

export interface DuplicateCtaSuccess extends Action {
  type: 'DUPLICATE_TAR_SUCCESS';
}

export function duplicateCtaSuccess(): DuplicateCtaSuccess {
  return {
    type: 'DUPLICATE_TAR_SUCCESS',
  };
}
export interface ClearCtaDuplication extends Action {
  type: 'CLEAR_TAR_FOR_DUPLICATION';
}

export function clearCtaForDuplication(): ClearCtaDuplication {
  return {
    type: 'CLEAR_TAR_FOR_DUPLICATION',
  };
}

export interface ExportCtaDashboard extends Action {
  type: 'EXPORT_TAR_DASHBOARD';
  direction: string;
  country: string | null;
  businessUnit: string | null;
  startDate: string;
  endDate: string;
}
export function exportCtaDashboard(
  direction: string,
  country: string | null,
  businessUnit: string | null,
  startDate: string,
  endDate: string
): ExportCtaDashboard {
  return {
    type: 'EXPORT_TAR_DASHBOARD',
    direction,
    country,
    businessUnit,
    startDate,
    endDate,
  };
}
export interface ExportCtaDashboardSuccess extends Action {
  type: 'EXPORT_TAR_DASHBOARD_SUCCESS';
  blob: Blob;
}
export function exportCtaDashboardSuccess(
  blob: Blob
): ExportCtaDashboardSuccess {
  return {
    type: 'EXPORT_TAR_DASHBOARD_SUCCESS',
    blob,
  };
}
export interface ExportCtaDashboardFail extends Action {
  type: 'EXPORT_TAR_DASHBOARD_FAIL';
  payload: any;
}
export function exportCtaDashboardFail(payload: any): ExportCtaDashboardFail {
  return {
    type: 'EXPORT_TAR_DASHBOARD_FAIL',
    payload,
  };
}
