import { Component, Input, OnInit } from '@angular/core';
import {
  CtaAnswerResultType,
  CtaOption,
} from '@models/ComplianceTravelApproval';
import { Validator } from '@models/Validator';
import { Store } from '@ngrx/store';
import { getValidators } from '@routes/travel-approval/helpers/travel-approval.helper';
import { UITravelApprovalQuestion } from '@store/cta-specific/cta-specific.models';
import {
  updateCtaAnswer,
  updateCtaAnswerValidity,
} from '@store/cta/cta.actions';
import { AppState } from '@store/root/root.reducer';
import { getAnswer } from '@store/root/root.selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'sgxb-travel-approval-single-select',
  templateUrl: './travel-approval-single-select.component.html',
  styleUrls: ['./travel-approval-single-select.component.scss'],
})
export class TravelApprovalSingleSelectComponent implements OnInit {
  @Input('question') public q: UITravelApprovalQuestion;

  public answer$: Observable<CtaAnswerResultType>;
  public validators: Validator[];

  constructor(private store$: Store<AppState>) {}

  public ngOnInit() {
    this.answer$ = this.store$.select(getAnswer, { uuid: this.q.uuid });
    this.validators = getValidators(this.q.type);
  }

  public onSingleSelect(ctaOption: CtaOption): void {
    this.store$.dispatch(updateCtaAnswer(this.q.uuid, ctaOption.text));
  }

  public onValidate(isValidated: boolean): void {
    this.store$.dispatch(updateCtaAnswerValidity(this.q.uuid, isValidated));
  }
}
