export enum PiwikCategories {
  HomePage = 'Home Page',
  ExportPage = 'Export Page',
  DashboardPage = 'Dashboard Page',
  Country = 'Country',
  Guideline = 'Guideline',
  Questionnaire = 'Questionnaire',
  GuidelineViewer = 'Guideline Viewer',
  ComplianceTravelApproval = 'Travel Approval Request',
  ApprovalPage = 'Approval Page',
  ContactsDetailsPage = 'Contacts Details Page',
  DelegationPage = 'Delegation Page',
}

export enum PiwikActions {
  // Home
  ClickOnHomeRoute = 'Click On Home Route',
  SubmitARequestToSupport = 'Submit a request to support',
  ClickOnAllInfo = 'Click On All Info',
  // Export
  ClickOnExportRoute = 'Click On Export route',
  ExportAcknowledgements = 'Export Acknowledgements',
  ExportCtaDashboard = 'Export CTA Dashboard',
  // Dashboard
  ClickOnDashboardRoute = 'Click On Dashboard Route',
  ChangeCountry = 'Change Country',
  UpdateDashboard = 'Update Dashboard',
  // Country
  AddACountry = 'Add a Country',
  CreateACountry = 'Create a Country',
  CancelCountryCreation = 'Cancel country creation',
  EditACountry = 'Edit a Country',
  EditCountryDetails = 'Edit country details',
  AddAProfileToACountry = 'Add a profile to a Country',
  RemoveAProfileFromACountry = 'Remove a profile from a Country',
  SaveCountryDetails = 'Save country details',
  CancelCountryEdition = 'Cancel country edition',
  DeleteACountry = 'Delete a country',
  ExportGuidelinesOfACountry = 'Export guidelines of a country',
  LoadPreviousVersion = 'Load previous version',
  // Guidelines
  ConfigureGuidelines = 'Configure guidelines',
  AddAChapter = 'Add a chapter',
  RemoveAChapter = 'Remove a chapter',
  EnableDisableExcerpting = 'Enable / Disable excerpting',
  AddASection = 'Add a section',
  RemoveASection = 'Remove a section',
  AddASubsection = 'Add a sub-section',
  EditASubsection = 'Edit a sub-section',
  RemoveASubsection = 'Remove a sub-section',
  ChangeAScope = 'Change a scope',
  ResetAScope = 'Reset a scope',
  SelectDeselectAllScope = 'Select / Deselect all scope',
  SaveAScope = 'Save a scope',
  CloseScopeModal = 'Close the scope modal',
  PreviewGuidelineMobile = 'Preview a guideline for mobile',
  PreviewGuidelineDesktop = 'Preview a guideline for desktop',
  SaveMinorVersion = 'Save a minor version',
  SaveMajorVersion = 'Save a major version',
  ApplyFilterToGuideline = 'Apply filter to guideline',
  // Questionnaire
  ConfigureQuestionnaire = 'Configure a questionnaire',
  AddNewQuestion = 'Add a question',
  RemoveAQuestion = 'Remove a question',
  AddNewAnswer = 'Add a new answer',
  RemoveAnswer = 'Remove an answer',
  AddNewCondition = 'Add a new condition',
  RemoveCondition = 'Remove a condition',
  PreviewQuestionnaireMobile = 'Preview a questionnaire for mobile',
  PreviewQuestionnaireDesktop = 'Preview a questionnaire for desktop',
  SaveQuestionnaire = 'Save the questionnaire',
  ApplyFilterToQuestionnaire = 'Apply filter to questionnaire',
  // Guidelines viewer
  SelectCountryFromSearchBar = 'Select a country from search bar',
  SelectCountryFromBusinessPerimeter = 'Select a country from business perimeter',
  AcknowledgeGuidelines = 'Acknowledge guidelines',
  SubmitPracticalCaseToCompliance = 'Submit a practical case to Compliance',
  CancelPracticalCaseToCompliance = 'Cancel a practical case to Compliance',
  SendPracticalCaseToCompliance = 'Send a practical case to Compliance',
  // Travel Approval Request
  CreateNewCtaRecord = 'Create a new CTA record',
  SubmitNewCtaRecord = 'Submit a new CTA record',
  EditCtaRecord = 'Edit a CTA record',
  UpdateCtaRecord = 'Update a CTA record',
  CancelCtaRecord = 'Cancel a CTA record',
  DuplicateCtaRecord = 'Duplicate a CTA record',

  // Approval
  ClickOnApprovalRoute = 'Click On Approval Route',
  ApproveCta = 'Approve a CTA',
  RejectCta = 'Reject a CTA',

  // Contacts Details
  ClickOnContactsDetailsRoute = 'Click On Contacts Details Route',
  // Delegation
  ClickOnDelegationRoute = 'Click On Delegation Route',
  EditCTAOnDelegation = 'Edit a CTA record on Delegation',
}
