import {
  Chapter,
  Guideline,
  GuidelineHistory,
  Scope,
  Section,
  Subsection,
} from '@models/Guideline';
import {
  GuidelineAction,
  SourceVersionOwner,
} from '@models/SourceVersionOwner';
import { convertToDateString } from '@shared/utils/datetime/datetime.utils';
import { GuidelineUiState } from '@store/guideline/guideline.models';
import { MOCK_API_ACKNOWLEDGEMENT } from './acknowledgement.mocks';
import { MOCK_API_COUNTRY_HK } from './country.mocks';
import { MOCK_API_PROFILE, MOCK_API_PROFILE_2 } from './profile.mocks';
import { MOCK_API_USER } from './user.mocks';

const dummyGuidelineId = 'GUIDELINE_ID';
const dummyDisclaimer =
  "The guidelines are made on the best of compliance knowledge but can't include everything to make it readable therefore any unprecision/questions should be asked directly to compliance.";
const dummySubsectionContent1 = '<p><strong>Sub section 1</strong></p>\n';
const dummySubsectionContent2 = '<p>Sub section 2</p>\n';
const dummySectionName = 'SEC - Exclude GLBA';
const dummySectionContent = '<p>SBC - Exclude GLBA</p>\n';
const dummyDepartment = 'GBSU/CLD/DCI';
const dummyEmailAddress = 'jerome.perron@sgcib.com';
const dummyDisplayAs = 'Jerome PERRON, GBSU/CLD/DCI';

export const MOCK_API_SCOPE: Scope = ['GLFI', 'CORI'];

export const MOCK_API_SOURCE_OWNERS: SourceVersionOwner[] = [
  {
    businessUnit: 'MARK',
    sourceOwner: 'Legal Team for MARK',
    version: convertToDateString(new Date(2020, 1, 1)),
    action: GuidelineAction.ADD,
  },
  {
    businessUnit: 'GLBA',
    sourceOwner: 'Legal Team for GLBA',
    version: convertToDateString(new Date(2020, 2, 1)),
    action: GuidelineAction.ADD,
  },
  {
    businessUnit: 'GTPS',
    sourceOwner: 'Legal Team for GTPS',
    version: convertToDateString(new Date(2020, 3, 1)),
    action: GuidelineAction.DELETE,
  },
];

export const MOCK_API_SUBSECTIONS: Subsection[] = [
  {
    content:
      '<table style="width:100%"><tbody><tr><td style="height:2em">Courtesy Visit without Marketing</td><td style="height:2em; text-align:right">Ok (only with local chaperone)</td></tr><tr><td style="height:2em">One to one marketing meeting</td><td style="height:2em; text-align:right">Ok (only with local chaperone)</td></tr><tr><td style="height:2em">One to one meeting based on reverse enquiry</td><td style="height:2em; text-align:right">Ok (only with local chaperone)</td></tr><tr><td style="height:2em">Use local SG country premises to meet with client</td><td style="height:2em; text-align:right">Ok (only with local chaperone)</td></tr><tr><td style="height:2em">Conclude deal onshorezfcdf</td><td style="height:2em; text-align:right">Ok (only with local chaperone)</td></tr></tbody></table>',
    scope: MOCK_API_SCOPE,
  },
];

export const MOCK_API_SUBSECTIONS_2: Subsection[] = [
  {
    content:
      '<table style="width: 100%;"><tbody><tr style="height: 2em;"><td>Courtesy Call without Marketing</td><td style="color: #35bd5b; text-align: right;">Ok</td></tr><tr style="height: 2em;"><td>Mass marketing* conducted offshore into the country</td><td style="color: #b9292d; text-align: right;">Not Ok</td></tr><tr style="height: 2em;"><td>One to one marketing (conducted via different media including, but not limited to telephone or email)</td><td style="color: #f17100; text-align: right;">Ok (specifics restrictions)</td></tr><tr style="height: 2em;"><td>One to one Reverse Enquiry</td><td style="color: #f17100; text-align: right;">Ok (specifics restrictions)</td></tr><tr style="height: 2em;"><td>Meeting with client outside their country</td><td style="color: #35bd5b; text-align: right;">Ok</td></tr><tr style="height: 2em;"><td>Conclude deal offshore</td><td style="color: #f17100; text-align: right;">Ok (specifics restrictions)</td></tr></tbody></table>',
    scope: MOCK_API_SCOPE,
  },
];

export const MOCK_API_SECTIONS: Section[] = [
  {
    name: 'Onshore',
    subsections: MOCK_API_SUBSECTIONS,
  },
];

export const MOCK_API_SECTIONS_2: Section[] = [
  {
    name: 'Offshore',
    subsections: MOCK_API_SUBSECTIONS_2,
  },
];

export const MOCK_API_CHAPTERS: Chapter[] = [
  {
    collapsible: false,
    name: 'Summary',
    sections: MOCK_API_SECTIONS,
  },
  {
    collapsible: true,
    name: 'Marketing Restrictions',
    sections: MOCK_API_SECTIONS_2,
  },
];

export const MOCK_API_DISPLAY_SUBMIT_CASE_AND_PROFILES = {
  canSubmitPracticalCases: true,
  profiles: [MOCK_API_PROFILE, MOCK_API_PROFILE_2],
};

export const MOCK_API_GUIDELINE: Guideline = {
  acknowledgement: MOCK_API_ACKNOWLEDGEMENT,
  chapters: MOCK_API_CHAPTERS,
  country: MOCK_API_COUNTRY_HK,
  createdAt: new Date(2019, 0, 1),
  major: true,
  updatedAt: new Date(2019, 0, 1),
  validatedBy: MOCK_API_USER,
  sourceOwners: MOCK_API_SOURCE_OWNERS,
};

export const MOCK_API_GUIDELINE_FILTERED_FOR_EDIT: Guideline = {
  country: {
    name: 'Hong Kong',
    alpha2: 'HK',
    alpha3: 'HKG',
    numeric: 344,
    profiles: [
      {
        uuid: dummyGuidelineId,
        name: 'Profile 1',
      },
    ],
    disclaimer: dummyDisclaimer,
  },
  chapters: [
    {
      name: 'Chapter 1',
      collapsible: true,
      sections: [
        {
          name: 'Section 1',
          subsections: [
            {
              scope: ['MARK', 'GTPS'],
              content: dummySubsectionContent1,
              createdAt: 1563496529423,
              updatedAt: 1563496529423,
              filtered: false,
            },
            {
              scope: ['MARK'],
              content: dummySubsectionContent2,
              createdAt: 1563496529440,
              updatedAt: 1563496529440,
              filtered: false,
            },
          ],
          createdAt: 1563496529407,
          updatedAt: 1563496529407,
        },
        {
          name: 'Section 2',
          subsections: [],
          createdAt: 1563496529453,
          updatedAt: 1563496529453,
        },
      ],
      createdAt: 1563496529347,
      updatedAt: 1563496529347,
    },
    {
      name: 'Chapter 2',
      collapsible: true,
      sections: [
        {
          name: 'Section 1',
          subsections: [
            {
              scope: [],
              content: dummySubsectionContent2,
              createdAt: 1563496529470,
              updatedAt: 1563496529470,
              filtered: false,
            },
          ],
          createdAt: 1563496529470,
          updatedAt: 1563496529470,
        },
      ],
      createdAt: 1563496529453,
      updatedAt: 1563496529453,
    },
    {
      name: 'Chapter 3',
      collapsible: true,
      sections: [
        {
          name: dummySectionName,
          subsections: [
            {
              scope: [],
              content: dummySectionContent,
              createdAt: 1563496529533,
              updatedAt: 1563496529533,
              filtered: false,
            },
          ],
          createdAt: 1563496529487,
          updatedAt: 1563496529487,
        },
      ],
      createdAt: 1563496529487,
      updatedAt: 1563496529487,
    },
  ],
  major: true,
  validatedBy: {
    lastName: 'PERRON',
    firstName: 'Jerome',
    businessUnit: 'GBSU',
    department: dummyDepartment,
    email: dummyEmailAddress,
    displayAs: dummyDisplayAs,
    igg: '9999999999',
  },
  createdAt: 1563496529127,
  updatedAt: 1563496529127,
  sourceOwners: MOCK_API_SOURCE_OWNERS,
};

export const MOCK_API_GUIDELINE_FILTERED_FOR_EDIT_MARK: Guideline = {
  country: {
    name: 'Hong Kong',
    alpha2: 'HK',
    alpha3: 'HKG',
    numeric: 344,
    profiles: [
      {
        uuid: dummyGuidelineId,
        name: 'Profile 1',
      },
    ],
    disclaimer: dummyDisclaimer,
  },
  chapters: [
    {
      name: 'Chapter 1',
      collapsible: true,
      sections: [
        {
          name: 'Section 1',
          subsections: [
            {
              scope: ['MARK', 'GTPS'],
              content: dummySubsectionContent1,
              createdAt: 1563496529423,
              updatedAt: 1563496529423,
              filtered: false,
            },
            {
              scope: ['MARK'],
              content: dummySubsectionContent2,
              createdAt: 1563496529440,
              updatedAt: 1563496529440,
              filtered: false,
            },
          ],
          createdAt: 1563496529407,
          updatedAt: 1563496529407,
        },
        {
          name: 'Section 2',
          subsections: [],
          createdAt: 1563496529453,
          updatedAt: 1563496529453,
        },
      ],
      createdAt: 1563496529347,
      updatedAt: 1563496529347,
    },
    {
      name: 'Chapter 2',
      collapsible: true,
      sections: [
        {
          name: 'Section 1',
          subsections: [
            {
              scope: [],
              content: dummySubsectionContent2,
              createdAt: 1563496529470,
              updatedAt: 1563496529470,
              filtered: false,
            },
          ],
          createdAt: 1563496529470,
          updatedAt: 1563496529470,
        },
      ],
      createdAt: 1563496529453,
      updatedAt: 1563496529453,
    },
    {
      name: 'Chapter 3',
      collapsible: true,
      sections: [
        {
          name: dummySectionName,
          subsections: [
            {
              scope: [],
              content: dummySectionContent,
              createdAt: 1563496529533,
              updatedAt: 1563496529533,
              filtered: false,
            },
          ],
          createdAt: 1563496529487,
          updatedAt: 1563496529487,
        },
      ],
      createdAt: 1563496529487,
      updatedAt: 1563496529487,
    },
  ],
  major: true,
  validatedBy: {
    lastName: 'PERRON',
    firstName: 'Jerome',
    businessUnit: 'GBSU',
    department: dummyDepartment,
    email: dummyEmailAddress,
    displayAs: dummyDisplayAs,
    igg: '9999999999',
  },
  createdAt: 1563496529127,
  updatedAt: 1563496529127,
  sourceOwners: MOCK_API_SOURCE_OWNERS,
};

export const MOCK_API_GUIDELINE_FILTERED_FOR_EDIT_GLBA: Guideline = {
  country: {
    name: 'Hong Kong',
    alpha2: 'HK',
    alpha3: 'HKG',
    numeric: 344,
    profiles: [
      {
        uuid: dummyGuidelineId,
        name: 'Profile 1',
      },
    ],
    disclaimer: dummyDisclaimer,
  },
  chapters: [
    {
      name: 'Chapter 1',
      collapsible: true,
      sections: [
        {
          name: 'Section 1',
          subsections: [
            {
              scope: ['MARK', 'GTPS'],
              content: dummySubsectionContent1,
              createdAt: 1563496529423,
              updatedAt: 1563496529423,
              filtered: true,
            },
            {
              scope: ['MARK'],
              content: dummySubsectionContent2,
              createdAt: 1563496529440,
              updatedAt: 1563496529440,
              filtered: true,
            },
          ],
          createdAt: 1563496529407,
          updatedAt: 1563496529407,
        },
        {
          name: 'Section 2',
          subsections: [],
          createdAt: 1563496529453,
          updatedAt: 1563496529453,
        },
      ],
      createdAt: 1563496529347,
      updatedAt: 1563496529347,
    },
    {
      name: 'Chapter 2',
      collapsible: true,
      sections: [
        {
          name: 'Section 1',
          subsections: [
            {
              scope: [],
              content: dummySubsectionContent2,
              createdAt: 1563496529470,
              updatedAt: 1563496529470,
              filtered: false,
            },
          ],
          createdAt: 1563496529470,
          updatedAt: 1563496529470,
        },
      ],
      createdAt: 1563496529453,
      updatedAt: 1563496529453,
    },
    {
      name: 'Chapter 3',
      collapsible: true,
      sections: [
        {
          name: dummySectionName,
          subsections: [
            {
              scope: [],
              content: dummySectionContent,
              createdAt: 1563496529533,
              updatedAt: 1563496529533,
              filtered: true,
            },
          ],
          createdAt: 1563496529487,
          updatedAt: 1563496529487,
        },
      ],
      createdAt: 1563496529487,
      updatedAt: 1563496529487,
    },
  ],
  major: true,
  validatedBy: {
    lastName: 'PERRON',
    firstName: 'Jerome',
    businessUnit: 'GBSU',
    department: dummyDepartment,
    email: dummyEmailAddress,
    displayAs: dummyDisplayAs,
    igg: '9999999999',
  },
  createdAt: 1563496529127,
  updatedAt: 1563496529127,
  sourceOwners: MOCK_API_SOURCE_OWNERS,
};

export const MOCK_API_GUIDELINE_FILTERED_FOR_EDIT_GTPS: Guideline = {
  country: {
    name: 'Hong Kong',
    alpha2: 'HK',
    alpha3: 'HKG',
    numeric: 344,
    profiles: [
      {
        uuid: dummyGuidelineId,
        name: 'Profile 1',
      },
    ],
    disclaimer: dummyDisclaimer,
  },
  chapters: [
    {
      name: 'Chapter 1',
      collapsible: true,
      sections: [
        {
          name: 'Section 1',
          subsections: [
            {
              scope: ['MARK', 'GTPS'],
              content: dummySubsectionContent1,
              createdAt: 1563496529423,
              updatedAt: 1563496529423,
              filtered: false,
            },
            {
              scope: ['MARK'],
              content: dummySubsectionContent2,
              createdAt: 1563496529440,
              updatedAt: 1563496529440,
              filtered: true,
            },
          ],
          createdAt: 1563496529407,
          updatedAt: 1563496529407,
        },
        {
          name: 'Section 2',
          subsections: [],
          createdAt: 1563496529453,
          updatedAt: 1563496529453,
        },
      ],
      createdAt: 1563496529347,
      updatedAt: 1563496529347,
    },
    {
      name: 'Chapter 2',
      collapsible: true,
      sections: [
        {
          name: 'Section 1',
          subsections: [
            {
              scope: [],
              content: dummySubsectionContent2,
              createdAt: 1563496529470,
              updatedAt: 1563496529470,
              filtered: false,
            },
          ],
          createdAt: 1563496529470,
          updatedAt: 1563496529470,
        },
      ],
      createdAt: 1563496529453,
      updatedAt: 1563496529453,
    },
    {
      name: 'Chapter 3',
      collapsible: true,
      sections: [
        {
          name: dummySectionName,
          subsections: [
            {
              scope: [],
              content: dummySectionContent,
              createdAt: 1563496529533,
              updatedAt: 1563496529533,
              filtered: false,
            },
          ],
          createdAt: 1563496529487,
          updatedAt: 1563496529487,
        },
      ],
      createdAt: 1563496529487,
      updatedAt: 1563496529487,
    },
  ],
  major: true,
  validatedBy: {
    lastName: 'PERRON',
    firstName: 'Jerome',
    businessUnit: 'GBSU',
    department: dummyDepartment,
    email: dummyEmailAddress,
    displayAs: dummyDisplayAs,
    igg: '9999999999',
  },
  createdAt: 1563496529127,
  updatedAt: 1563496529127,
  sourceOwners: MOCK_API_SOURCE_OWNERS,
};

export const MOCK_API_GUIDELINE_FOR_PREVIEW_MARK: Guideline = {
  country: {
    name: 'Hong Kong',
    alpha2: 'HK',
    alpha3: 'HKG',
    numeric: 344,
    profiles: [
      {
        uuid: dummyGuidelineId,
        name: 'Profile 1',
      },
    ],
    disclaimer: dummyDisclaimer,
  },
  chapters: [
    {
      name: 'Chapter 1',
      collapsible: true,
      sections: [
        {
          name: 'Section 1',
          subsections: [
            {
              scope: ['MARK', 'GTPS'],
              content: dummySubsectionContent1,
              createdAt: 1563496529423,
              updatedAt: 1563496529423,
            },
            {
              scope: ['MARK'],
              content: dummySubsectionContent2,
              createdAt: 1563496529440,
              updatedAt: 1563496529440,
            },
          ],
          createdAt: 1563496529407,
          updatedAt: 1563496529407,
        },
      ],
      createdAt: 1563496529347,
      updatedAt: 1563496529347,
    },
    {
      name: 'Chapter 3',
      collapsible: true,
      sections: [
        {
          name: dummySectionName,
          subsections: [
            {
              scope: ['MARK', 'GTPS'],
              content: dummySectionContent,
              createdAt: 1563496529533,
              updatedAt: 1563496529533,
            },
          ],
          createdAt: 1563496529487,
          updatedAt: 1563496529487,
        },
      ],
      createdAt: 1563496529487,
      updatedAt: 1563496529487,
    },
  ],
  major: true,
  validatedBy: {
    lastName: 'PERRON',
    firstName: 'Jerome',
    businessUnit: 'GBSU',
    department: dummyDepartment,
    email: dummyEmailAddress,
    displayAs: dummyDisplayAs,
    igg: '9999999999',
  },
  createdAt: 1563496529127,
  updatedAt: 1563496529127,
  sourceOwners: MOCK_API_SOURCE_OWNERS,
};

export const MOCK_API_GUIDELINE_FOR_PREVIEW_GLBA: Guideline = {
  country: {
    name: 'Hong Kong',
    alpha2: 'HK',
    alpha3: 'HKG',
    numeric: 344,
    profiles: [
      {
        uuid: dummyGuidelineId,
        name: 'Profile 1',
      },
    ],
    disclaimer: dummyDisclaimer,
  },
  chapters: [
    {
      name: 'Chapter 2',
      collapsible: true,
      sections: [
        {
          name: 'Section 1',
          subsections: [
            {
              scope: ['GLBA'],
              content: dummySubsectionContent2,
              createdAt: 1563496529470,
              updatedAt: 1563496529470,
            },
          ],
          createdAt: 1563496529470,
          updatedAt: 1563496529470,
        },
      ],
      createdAt: 1563496529453,
      updatedAt: 1563496529453,
    },
  ],
  major: true,
  validatedBy: {
    lastName: 'PERRON',
    firstName: 'Jerome',
    businessUnit: 'GBSU',
    department: dummyDepartment,
    email: dummyEmailAddress,
    displayAs: dummyDisplayAs,
    igg: '9999999999',
  },
  createdAt: 1563496529127,
  updatedAt: 1563496529127,
  sourceOwners: MOCK_API_SOURCE_OWNERS,
};

export const MOCK_API_GUIDELINE_FOR_PREVIEW_GTPS: Guideline = {
  country: {
    name: 'Hong Kong',
    alpha2: 'HK',
    alpha3: 'HKG',
    numeric: 344,
    profiles: [
      {
        uuid: dummyGuidelineId,
        name: 'Profile 1',
      },
    ],
    disclaimer: dummyDisclaimer,
  },
  chapters: [
    {
      name: 'Chapter 1',
      collapsible: true,
      sections: [
        {
          name: 'Section 1',
          subsections: [
            {
              scope: ['MARK', 'GTPS'],
              content: dummySubsectionContent1,
              createdAt: 1563496529423,
              updatedAt: 1563496529423,
            },
          ],
          createdAt: 1563496529407,
          updatedAt: 1563496529407,
        },
      ],
      createdAt: 1563496529347,
      updatedAt: 1563496529347,
    },
    {
      name: 'Chapter 3',
      collapsible: true,
      sections: [
        {
          name: dummySectionName,
          subsections: [
            {
              scope: ['MARK', 'GTPS'],
              content: dummySectionContent,
              createdAt: 1563496529533,
              updatedAt: 1563496529533,
            },
          ],
          createdAt: 1563496529487,
          updatedAt: 1563496529487,
        },
      ],
      createdAt: 1563496529487,
      updatedAt: 1563496529487,
    },
  ],
  major: true,
  validatedBy: {
    lastName: 'PERRON',
    firstName: 'Jerome',
    businessUnit: 'GBSU',
    department: dummyDepartment,
    email: dummyEmailAddress,
    displayAs: dummyDisplayAs,
    igg: '9999999999',
  },
  createdAt: 1563496529127,
  updatedAt: 1563496529127,
  sourceOwners: MOCK_API_SOURCE_OWNERS,
};

export const MOCK_API_GUIDELINE_HISTORY: GuidelineHistory = [
  {
    createdAt: new Date(2019, 2, 1),
    major: true,
    updatedAt: new Date(2019, 2, 1),
    validatedBy: MOCK_API_USER,
  },
  {
    createdAt: new Date(2019, 1, 1),
    major: false,
    updatedAt: new Date(2019, 1, 1),
    validatedBy: MOCK_API_USER,
  },
  {
    createdAt: new Date(2019, 0, 1),
    major: false,
    updatedAt: new Date(2019, 0, 1),
    validatedBy: MOCK_API_USER,
  },
];

export const MOCK_API_GUIDELINE_EXPORT: Blob = new Blob(['']);

export const MOCK_UI_STATE: GuidelineUiState = {
  chapters: [
    {
      sections: [
        {
          subsections: [
            {
              inEditMode: true,
            },
          ],
          expanded: true,
        },
      ],
    },
  ],
  displaySubmitPracticalCaseModule: true,
  profiles: [MOCK_API_PROFILE, MOCK_API_PROFILE_2],
};
