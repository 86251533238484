import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  hasAccessToContactsDetailsPage,
  hasAccessToDashboardPage,
  hasAccessToDelegationPage,
  hasAccessToExportPage,
} from '@shared/utils/access/access.utils';
import { AppState } from '@store/root/root.reducer';
import * as rootSelectors from '@store/root/root.selectors';
import {
  FetchCurrentUserAccessesSucceed,
  FetchCurrentUserSucceed,
} from '@store/user/user.actions';
import { filter, first, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  addHeaderItem,
  RedirectTo,
  RedirectToHome,
  RedirectWithQueryParamsTo,
  removeHeaderItem,
  ShowDangerNotification,
  ShowInfoNotification,
  ShowNotification,
  ShowSuccessNotification,
  ShowWarningNotification,
} from './header.actions';

@Injectable()
export class HeaderEffects {
  public addApprovalsHeaderItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCurrentUserSucceed>('FETCH_CURRENT_USER_SUCCEED'),
      first(),
      mergeMap(() => {
        return [
          addHeaderItem({
            key: 'approvals',
            label: 'Approvals',
            selected: false,
            link: '/approvals',
          }),
        ];
      })
    )
  );

  public addDelegationHeaderItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCurrentUserAccessesSucceed>(
        'FETCH_CURRENT_USER_ACCESSES_SUCCEED'
      ),
      withLatestFrom(this.store$.select(rootSelectors.getCurrentUserAccesses)),
      filter(([_, access]) => hasAccessToDelegationPage(access)),
      mergeMap(() => {
        return [
          addHeaderItem({
            key: 'delegation',
            label: 'Delegation',
            selected: false,
            link: '/delegation',
          }),
        ];
      })
    )
  );

  public addExportHeaderItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCurrentUserAccessesSucceed>(
        'FETCH_CURRENT_USER_ACCESSES_SUCCEED'
      ),
      withLatestFrom(this.store$.select(rootSelectors.getCurrentUserAccesses)),
      filter(([_, access]) => hasAccessToExportPage(access)),
      first(),
      mergeMap(() => {
        return [
          removeHeaderItem('export'),
          addHeaderItem({
            key: 'export',
            label: 'Export',
            selected: false,
            link: '/export',
          }),
        ];
      })
    )
  );

  public addDashboardHeaderItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCurrentUserAccessesSucceed>(
        'FETCH_CURRENT_USER_ACCESSES_SUCCEED'
      ),
      withLatestFrom(this.store$.select(rootSelectors.getCurrentUserAccesses)),
      filter(([_, access]) => hasAccessToDashboardPage(access)),
      mergeMap(() => {
        return [
          removeHeaderItem('dashboard'),
          addHeaderItem({
            key: 'dashboard',
            label: 'Dashboard',
            selected: false,
            link: '/dashboard/gatekeeper',
          }),
        ];
      })
    )
  );

  public addContactDetailsHeaderItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCurrentUserAccessesSucceed>(
        'FETCH_CURRENT_USER_ACCESSES_SUCCEED'
      ),
      withLatestFrom(this.store$.select(rootSelectors.getCurrentUserAccesses)),
      filter(([_, access]) => hasAccessToContactsDetailsPage(access)),
      mergeMap(() => {
        return [
          removeHeaderItem('contactsDetails'),
          addHeaderItem({
            key: 'contactsDetails',
            label: 'Contacts Details',
            selected: false,
            link: '/contacts-details',
          }),
        ];
      })
    )
  );

  public redirectHome$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<RedirectToHome>('REDIRECT_TO_HOME'),
        withLatestFrom(this.store$.select(rootSelectors.getHeaderMenuItems)),
        tap(([_action, menuItems]) => this.router.navigate([menuItems[0].link]))
      ),
    { dispatch: false }
  );

  public redirectTo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<RedirectTo>('REDIRECT_TO'),
        tap((action) => this.router.navigate([action.url, ...action.params]))
      ),
    { dispatch: false }
  );

  public redirectWithQueryParamsTo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<RedirectWithQueryParamsTo>('REDIRECT_WITH_QUERY_PARAMS_TO'),
        tap(({ url, queryParams }) =>
          this.router.navigate([url], { queryParams })
        )
      ),
    { dispatch: false }
  );

  public showNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<
          | ShowNotification
          | ShowSuccessNotification
          | ShowInfoNotification
          | ShowWarningNotification
          | ShowDangerNotification
        >(
          'SHOW_NOTIFICATION',
          'SHOW_SUCCESS_NOTIFICATION',
          'SHOW_INFO_NOTIFICATION',
          'SHOW_WARNING_NOTIFICATION',
          'SHOW_DANGER_NOTIFICATION'
        ),
        tap((action) => {
          window
            .$('.sgb-notifications.top-center')
            .sgbNotification({
              message: action.isHTML
                ? { html: action.message }
                : { text: action.message },
              type: action.notificationType,
              closable: !action.sticky || action.dismissible,
              fadeOut: {
                enabled: !action.sticky,
                delay: action.notificationType === 'danger' ? 10000 : 5000,
              },
            })
            .show();
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private router: Router
  ) {}
}
