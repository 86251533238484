import { Action } from '@ngrx/store';

export type BusinessUnitActions =
  | FetchBusinessUnits
  | FetchBusinessUnitsSuccess
  | FetchBusinessUnitsFail;

export interface FetchBusinessUnits extends Action {
  type: 'FETCH_BUSINESS_UNITS';
  underPerimeter: boolean;
}
export function fetchBusinessUnits(
  underPerimeter: boolean = false
): FetchBusinessUnits {
  return {
    type: 'FETCH_BUSINESS_UNITS',
    underPerimeter,
  };
}

export interface FetchBusinessUnitsSuccess extends Action {
  type: 'FETCH_BUSINESS_UNITS_SUCCESS';
  businessUnits: string[];
  underPerimeter: boolean;
}
export function fetchBusinessUnitsSuccess(
  businessUnits: string[],
  underPerimeter: boolean = false
): FetchBusinessUnitsSuccess {
  return {
    type: 'FETCH_BUSINESS_UNITS_SUCCESS',
    businessUnits,
    underPerimeter,
  };
}

export interface FetchBusinessUnitsFail extends Action {
  type: 'FETCH_BUSINESS_UNITS_FAIL';
  payload: any;
  underPerimeter: boolean;
}
export function fetchBusinessUnitsFail(
  payload: any,
  underPerimeter: boolean = false
): FetchBusinessUnitsFail {
  return {
    type: 'FETCH_BUSINESS_UNITS_FAIL',
    payload,
    underPerimeter,
  };
}
