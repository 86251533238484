<sgxb-header></sgxb-header>

<sgxb-modal [modal]="modal$ | async"></sgxb-modal>
<sgxb-notification></sgxb-notification>

<ng-container *ngIf="currentUser$ | async; else loading">
  <main [class.no-margin-bottom]="!(marginBottom$ | async)">
    <router-outlet></router-outlet>
  </main>
</ng-container>

<sgxb-footer></sgxb-footer>

<ng-template #loading>
  <sgxb-loading-screen></sgxb-loading-screen>
</ng-template>
