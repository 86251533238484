import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatBoolean',
})
export class FormatBooleanPipe implements PipeTransform {
  transform(value: boolean): string {
    switch (value) {
      case true:
        return 'Yes';
      case false:
        return 'No';
      default:
        return 'Nil';
    }
  }
}
