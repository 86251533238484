import { HttpErrorResponse } from '@angular/common/http';
import { Access } from '@models/Access';
import { User } from '@models/User';
import { Action } from '@ngrx/store';

export type UserActions =
  | FetchCurrentUser
  | FetchCurrentUserSucceed
  | FetchCurrentUserFail
  | FetchCurrentUserAccesses
  | FetchCurrentUserAccessesSucceed
  | FetchCurrentUserAccessesFail
  | SetFakeSSOUser
  | FetchUser
  | FetchUserSuccess
  | FetchUserFail;

export interface FetchCurrentUser extends Action {
  type: 'FETCH_CURRENT_USER';
}
export function fetchCurrentUser(): FetchCurrentUser {
  return {
    type: 'FETCH_CURRENT_USER',
  };
}

export interface FetchCurrentUserSucceed extends Action {
  type: 'FETCH_CURRENT_USER_SUCCEED';
  user: User;
}
export function fetchCurrentUserSucceed(user: User): FetchCurrentUserSucceed {
  return {
    type: 'FETCH_CURRENT_USER_SUCCEED',
    user,
  };
}

export interface FetchCurrentUserFail extends Action {
  type: 'FETCH_CURRENT_USER_FAIL';
  error: HttpErrorResponse;
}
export function fetchCurrentUserFail(
  error: HttpErrorResponse
): FetchCurrentUserFail {
  return {
    type: 'FETCH_CURRENT_USER_FAIL',
    error,
  };
}

export interface FetchCurrentUserAccesses extends Action {
  type: 'FETCH_CURRENT_USER_ACCESSES';
}
export function fetchCurrentUserAccesses(): FetchCurrentUserAccesses {
  return {
    type: 'FETCH_CURRENT_USER_ACCESSES',
  };
}

export interface FetchCurrentUserAccessesSucceed extends Action {
  type: 'FETCH_CURRENT_USER_ACCESSES_SUCCEED';
  access: Access;
}
export function fetchCurrentUserAccessesSucceed(
  access: Access
): FetchCurrentUserAccessesSucceed {
  return {
    type: 'FETCH_CURRENT_USER_ACCESSES_SUCCEED',
    access,
  };
}

export interface FetchCurrentUserAccessesFail extends Action {
  type: 'FETCH_CURRENT_USER_ACCESSES_FAIL';
  error: HttpErrorResponse;
}
export function fetchCurrentUserAccessesFail(
  error: HttpErrorResponse
): FetchCurrentUserAccessesFail {
  return {
    type: 'FETCH_CURRENT_USER_ACCESSES_FAIL',
    error,
  };
}

export interface SetFakeSSOUser extends Action {
  type: 'SET_FAKE_SSO_USER';
  fakeSSOUser: string;
}
export function setFakeSSOUser(fakeSSOUser: string): SetFakeSSOUser {
  return {
    type: 'SET_FAKE_SSO_USER',
    fakeSSOUser,
  };
}

export interface FetchUser extends Action {
  type: 'FETCH_USER';
  email: string;
}
export function fetchUser(email: string): FetchUser {
  return {
    type: 'FETCH_USER',
    email,
  };
}

export interface FetchUserSuccess extends Action {
  type: 'FETCH_USER_SUCCESS';
  user: User;
}
export function fetchUserSuccess(user: User): FetchUserSuccess {
  return {
    type: 'FETCH_USER_SUCCESS',
    user,
  };
}

export interface FetchUserFail extends Action {
  type: 'FETCH_USER_FAIL';
  error: HttpErrorResponse;
}
export function fetchUserFail(error: HttpErrorResponse): FetchUserFail {
  return {
    type: 'FETCH_USER_FAIL',
    error,
  };
}
