import { Country } from '@models/Country';

export interface CountryState {
  allCountries: Country[];
  allCountriesWithGuidelines: Country[];
  perimeterCountries: Country[];
  perimeterCountriesWithGuidelines: Country[];
  countryUnderOwnership: Country[]; // to be removed
  countryWithGuidelines: Country[]; // to be removed
  currentCountry: Country;
  popinDisplayed: boolean;
  requestPopinDisplayed: boolean;
  requestSubmitOnGoing: boolean;
  resetOnGoing: boolean;
  saveOnGoing: boolean;
}

export const initialCountryState: CountryState = {
  allCountries: null,
  allCountriesWithGuidelines: null,
  perimeterCountries: null,
  perimeterCountriesWithGuidelines: null,
  countryUnderOwnership: null,
  countryWithGuidelines: null,
  currentCountry: null,
  popinDisplayed: false,
  requestPopinDisplayed: false,
  requestSubmitOnGoing: false,
  resetOnGoing: false,
  saveOnGoing: false,
};
