import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'sgxb-business-line-filter',
  templateUrl: './business-line-filter.component.html',
  styleUrls: ['./business-line-filter.component.scss'],
})
export class BusinessLineFilterComponent {
  @Input() public businessUnit: string[];
  @Input() public label: string;
  @Input() public selectedPointOfView: string;
  @Input() public hidePanel: boolean = false;
  @Output() public businessLineFilterChange = new EventEmitter<string>();
  @Output() public businessLineInputFieldClick = new EventEmitter<string>();
  @Output() public businessLineInputFieldBlur = new EventEmitter<FocusEvent>();

  public editPointOfView$: Observable<string>;

  constructor() {}

  public onFilterChange(event) {
    if (event) {
      this.businessLineFilterChange.emit(event);
    }
  }

  public onInputFieldClick(event) {
    if (event.target.value !== '') {
      this.businessLineInputFieldClick.emit(event);
    }
  }

  public onInputFieldBlur(event) {
    this.businessLineInputFieldBlur.emit(event);
  }
}
