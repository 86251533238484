import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@models/User';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  FetchCurrentUser,
  FetchCurrentUserAccesses,
  FetchCurrentUserSucceed,
  FetchUser,
  fetchCurrentUserAccesses,
  fetchCurrentUserAccessesFail,
  fetchCurrentUserAccessesSucceed,
  fetchCurrentUserFail,
  fetchCurrentUserSucceed,
  fetchUserFail,
  fetchUserSuccess,
} from './user.actions';

@Injectable()
export class UserEffects {
  public fetchCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCurrentUser>('FETCH_CURRENT_USER'),
      switchMap(() =>
        this.http$.get('users/myself').pipe(
          map((res: User) => fetchCurrentUserSucceed(res)),
          catchError((err: HttpErrorResponse) => of(fetchCurrentUserFail(err)))
        )
      )
    )
  );

  public fetchCurrentUserAccessesOnFetchCurrentUserSucceed$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCurrentUserSucceed>('FETCH_CURRENT_USER_SUCCEED'),
      map(() => fetchCurrentUserAccesses())
    )
  );

  public fetchCurrentUserAccesses$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCurrentUserAccesses>('FETCH_CURRENT_USER_ACCESSES'),
      switchMap(() =>
        this.http$.get('users/myself/permissions').pipe(
          map(fetchCurrentUserAccessesSucceed),
          catchError((err: HttpErrorResponse) =>
            of(fetchCurrentUserAccessesFail(err))
          )
        )
      )
    )
  );

  public fetchUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchUser>('FETCH_USER'),
      switchMap(({ email }) =>
        this.http$.get(`users/${email}`).pipe(
          map((res: User) => fetchUserSuccess(res)),
          catchError((err: HttpErrorResponse) => of(fetchUserFail(err)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private http$: HttpClient) {}
}
