import {
  AccessiblePages,
  SGIAMPermissions,
} from '@mock-api/mocks/access.mocks';
import { Access } from '@models/Access';

// Access to pages checking
export function hasAccessToExportPage(userAccesses: Access) {
  return (
    userAccesses &&
    userAccesses.pages &&
    userAccesses.pages.includes(AccessiblePages.EXPORT)
  );
}
export function hasAccessToDashboardPage(userAccesses: Access) {
  return (
    userAccesses &&
    userAccesses.pages &&
    userAccesses.pages.includes(AccessiblePages.DASHBOARD)
  );
}
export function hasAccessToContactsDetailsPage(userAccesses: Access) {
  return (
    userAccesses &&
    userAccesses.pages &&
    userAccesses.pages.includes(AccessiblePages.CONTACTS_DETAILS)
  );
}
export function hasAccessToDelegationPage(userAccesses: Access) {
  return (
    userAccesses &&
    userAccesses.pages &&
    userAccesses.pages.includes(AccessiblePages.DELEGATION)
  );
}

// Single SG|IAM permissions
export function hasExportAcknowledgementsPermission(
  userAccesses: Access
): boolean {
  return hasPermission(userAccesses, SGIAMPermissions.EXPORT_ACK);
}
export function hasExportCtaPermission(userAccesses: Access): boolean {
  return hasPermission(userAccesses, SGIAMPermissions.EXPORT_CTA);
}
export function hasExportGuidelinePermission(userAccesses: Access): boolean {
  return hasPermission(userAccesses, SGIAMPermissions.EXPORT_GUIDELINES);
}
export function hasViewDashboardPermission(userAccesses: Access): boolean {
  return hasPermission(userAccesses, SGIAMPermissions.VIEW_DASHBOARD);
}
export function hasViewCtaAllPermission(userAccesses: Access): boolean {
  return hasPermission(userAccesses, SGIAMPermissions.VIEW_TAR_ALL);
}
export function hasReadWriteGuidelinesPermission(
  userAccesses: Access
): boolean {
  return hasPermission(userAccesses, SGIAMPermissions.READ_WRITE_GUIDELINES);
}
export function hasReadOnlyGuidelinesPermission(userAccesses: Access): boolean {
  return hasPermission(userAccesses, SGIAMPermissions.READONLY_GUIDELINES);
}
export function hasManageComplianceOfficersPermission(
  userAccesses: Access
): boolean {
  return hasPermission(
    userAccesses,
    SGIAMPermissions.MANAGE_COMPLIANCE_OFFICERS
  );
}
export function hasManageOtherApproversPermission(
  userAccesses: Access
): boolean {
  return hasPermission(userAccesses, SGIAMPermissions.MANAGE_OTHER_APPROVERS);
}
export function hasManageSupervisorsPermission(userAccesses: Access): boolean {
  return hasPermission(userAccesses, SGIAMPermissions.MANAGE_SUPERVISORS);
}

function hasPermission(userAccesses: Access, permission: string): boolean {
  return (
    userAccesses.permissions.length > 0 &&
    !!userAccesses.permissions.includes(permission)
  );
}
