import { Country } from '@models/Country';
import { ContactType, ContactsResponse } from './contacts-details.models';

export type ContactsDetailsActions =
  | FetchContacts
  | FetchContactsSuccess
  | FetchContactsFail;

export type FetchDataForContactsDetailsPage = ReturnType<
  typeof fetchDataForContactsDetailsPage
>;
export function fetchDataForContactsDetailsPage(contactType: ContactType) {
  return { type: 'FETCH_DATA_FOR_CONTACTS_DETAILS_PAGE' as const, contactType };
}

export type FetchContacts = ReturnType<typeof fetchContacts>;
export function fetchContacts(
  contactType: ContactType,
  page: number,
  size: number,
  businessUnitFilter?: string,
  countryCodeFilter?: string
) {
  return {
    type: 'FETCH_CONTACTS' as const,
    contactType,
    page,
    size,
    businessUnitFilter,
    countryCodeFilter,
  };
}

export type FetchContactsSuccess = ReturnType<typeof fetchContactsSuccess>;
export function fetchContactsSuccess(
  contactType: ContactType,
  payload: ContactsResponse
) {
  return { type: 'FETCH_CONTACTS_SUCCESS' as const, contactType, payload };
}

export type FetchContactsFail = ReturnType<typeof fetchContactsFail>;
export function fetchContactsFail() {
  return { type: 'FETCH_CONTACTS_FAIL' as const };
}

export type CountryFilterApplied = ReturnType<typeof countryFilterApplied>;
export function countryFilterApplied(country: Country) {
  return { type: 'COUNTRY_FILTER_APPLIED' as const, country };
}

export type BusinessUnitFilterApplied = ReturnType<
  typeof businessUnitFilterApplied
>;
export function businessUnitFilterApplied(businessUnit: string) {
  return { type: 'BUSINESS_UNIT_FILTER_APPLIED' as const, businessUnit };
}

export type SelectContact = ReturnType<typeof selectContact>;
export function selectContact(uuid: string) {
  return { type: 'SELECT_CONTACT' as const, uuid };
}
