import { Component } from '@angular/core';

import { sgwtConnect } from '../../../sgwt-connect';

@Component({
  selector: 'sgxb-signout',
  templateUrl: './signout.component.html',
  styleUrls: ['./signout.component.scss'],
})
export class SignoutComponent {
  constructor() {
    // Discard local Auth data
    sgwtConnect.discardAuthorization();
    window.location.assign('https://info.sgmarkets.com/en/index.html');
    // window.location.assign(`${window.location.protocol}//${window.location.host}`);
  }
}
