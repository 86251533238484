import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComplianceTravelApproval } from '@models/ComplianceTravelApproval';
import {
  Delegate,
  DelegatesResponse,
  DelegationDeletionStatusResponse,
  PrincipalsResponse,
} from '@models/Delegate';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { showWarningNotification } from '@store/header/header.actions';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  AddDelegate,
  DeleteDelegate,
  FetchDelegatedCtas,
  FetchDelegates,
  FetchPrincipals,
  addDelegateFail,
  addDelegateSuccess,
  deleteDelegateFail,
  deleteDelegateSuccess,
  fetchDelegatedCtasFail,
  fetchDelegatedCtasSuccess,
  fetchDelegates,
  fetchDelegatesFail,
  fetchDelegatesSuccess,
  fetchPrincipalsFail,
  fetchPrincipalsSuccess,
} from './delegation.actions';

@Injectable()
export class DelegationEffects {
  public fetchDelegates$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchDelegates>('FETCH_DELEGATES'),
      switchMap(() =>
        this.http$.get('delegations').pipe(
          map((res: DelegatesResponse) =>
            fetchDelegatesSuccess(res.delegates ?? [])
          ),
          catchError((err: HttpErrorResponse) => of(fetchDelegatesFail(err)))
        )
      )
    )
  );

  public addDelegate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddDelegate>('ADD_DELEGATE'),
      switchMap((action) =>
        this.http$
          .post('delegations', {
            delegateEmail: action.delegate,
          })
          .pipe(
            mergeMap((res: Delegate) => {
              return [addDelegateSuccess(res)];
            }),
            catchError((err: HttpErrorResponse) => {
              return [
                addDelegateFail(err),
                showWarningNotification(
                  err.error.message,
                  undefined,
                  true,
                  true
                ),
              ];
            })
          )
      )
    )
  );

  public deleteDelegate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteDelegate>('DELETE_DELEGATE'),
      switchMap((action) =>
        this.http$.delete(`delegations/${action.uuid}`).pipe(
          mergeMap((_res: DelegationDeletionStatusResponse) => {
            return [deleteDelegateSuccess(), fetchDelegates()];
          }),
          catchError((err: HttpErrorResponse) => {
            return [
              deleteDelegateFail(err),
              showWarningNotification(err.error.message),
            ];
          })
        )
      )
    )
  );

  public fetchPrincipals$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchPrincipals>('FETCH_PRINCIPALS'),
      switchMap(() =>
        this.http$.get('delegations/principals').pipe(
          map((res: PrincipalsResponse) =>
            fetchPrincipalsSuccess(res.principals ?? [])
          ),
          catchError((err: HttpErrorResponse) => of(fetchPrincipalsFail(err)))
        )
      )
    )
  );

  public fetchDelegatedCtas$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchDelegatedCtas>('FETCH_DELEGATED_CTAS'),
      switchMap(({ limit }) => {
        let params = new HttpParams();
        if (limit) {
          params = params.set('limit', limit.toString());
        }
        return this.http$
          .get('gateway/travelApprovals/delegates', { params })
          .pipe(
            map((res: ComplianceTravelApproval[]) =>
              fetchDelegatedCtasSuccess(res)
            ),
            catchError((err: HttpErrorResponse) =>
              of(fetchDelegatedCtasFail(err))
            )
          );
      })
    )
  );

  constructor(private actions$: Actions, private http$: HttpClient) {}
}
