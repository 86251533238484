export interface Validator {
  constraint: string[] | null;
  message: string;
  rule: ValidatorRule;
}

export enum ValidatorRule {
  REQUIRED = 'REQUIRED',
  REGEX = 'REGEX',
  UNIQUE = 'UNIQUE',
  FORBIDDEN = 'FORBIDDEN',
  NO_DUPLICATE = 'NO_DUPLICATE',
}

export interface FormFieldValidator {
  field: string;
  display: boolean;
  conditions: Validator[];
  default?: string;
}

export interface CtaFormFieldsValidity {
  standard: boolean;
  variable: boolean;
}
