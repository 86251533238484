import { UUID } from '@models/UUID';

export interface Country {
  alpha2: string;
  alpha3: string;
  description?: string;
  disclaimer?: string;
  guidelineLastUpdatedAt?: Date;
  name: string;
  numeric: number;
  profiles?: Profile[];
  splitReason?: string;
  delegationAllowed?: boolean;
}

export interface Profile {
  name: string;
  uuid: UUID;
}

export const MOCK_PROFILE: Profile = {
  name: 'Profile name',
  uuid: null,
};

export const MOCK_COUNTRY: Country = {
  alpha2: '',
  alpha3: '',
  description: '',
  disclaimer:
    "The guidelines are made on the best of compliance knowledge but can't include everything to make it readable therefore any unprecision/questions should be asked directly to compliance.",
  name: '',
  numeric: null,
  profiles: [MOCK_PROFILE],
  splitReason: '',
  delegationAllowed: false,
};

export const AllValueInCountry: Country = {
  alpha2: 'ALL',
  alpha3: 'ALL',
  name: 'ALL',
  numeric: 0,
};

export interface CountriesResponse {
  countries?: Country[];
}
