import { ComplianceTravelApproval } from '@models/ComplianceTravelApproval';
import { Delegate } from '@models/Delegate';
import { User } from '@models/User';

export interface DelegationState {
  allDelegates: Delegate[];
  fetchDelegatesOnGoing: boolean;
  addDelegateOnGoing: boolean;
  deleteDelegateOnGoing: boolean;
  allPrincipals: User[];
  fetchPrincipalsOnGoing: boolean;
  delegatedCtas: ComplianceTravelApproval[];
  fetchDelegatedCtasOnGoing: boolean;
}

export const initialDelegationState: DelegationState = {
  allDelegates: [],
  fetchDelegatesOnGoing: false,
  addDelegateOnGoing: false,
  deleteDelegateOnGoing: false,
  allPrincipals: [],
  fetchPrincipalsOnGoing: false,
  delegatedCtas: [],
  fetchDelegatedCtasOnGoing: false,
};
