import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { sgwtConnectPredicate } from 'app/sgwt-connect';
import { HTTP_HEADERS } from 'app/utils/header.utils';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class CorrelationIdInterceptor implements HttpInterceptor {
  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      !sgwtConnectPredicate(request) ||
      request.url.startsWith(window.configurations.backend.baseLAN)
    ) {
      return next.handle(request);
    }

    const headers = { [HTTP_HEADERS.correlationId]: uuidv4() };
    request = request.clone({ setHeaders: headers });

    return next.handle(request);
  }
}
