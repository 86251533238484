import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FetchBusinessUnitsSuccess } from '@store/business-unit/business-unit.actions';
import { FetchCountriesSuccess } from '@store/country/country.actions';
import { showWarningNotification } from '@store/header/header.actions';
import { combineLatest } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import {
  FetchContacts,
  fetchContactsFail,
  fetchContactsSuccess,
} from './contacts-details.actions';
import { ContactsResponse } from './contacts-details.models';

@Injectable()
export class ContactsDetailsEffects {
  public fetchContacts$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType<FetchContacts>('FETCH_CONTACTS')),
      this.actions$.pipe(
        ofType<FetchCountriesSuccess>('FETCH_COUNTRIES_SUCCESS'),
        take(1)
      ),
      this.actions$.pipe(
        ofType<FetchBusinessUnitsSuccess>('FETCH_BUSINESS_UNITS_SUCCESS'),
        take(1)
      ),
    ]).pipe(
      map(([payload]) => payload),
      mergeMap(
        ({
          contactType,
          page,
          size,
          businessUnitFilter,
          countryCodeFilter,
        }) => {
          const params = setParamsForFetchContactsRequest(
            page,
            size,
            businessUnitFilter,
            countryCodeFilter
          );
          return this.http.get(`gateway/${contactType}`, { params }).pipe(
            map((res: ContactsResponse) =>
              fetchContactsSuccess(contactType, res)
            ),
            catchError((err: HttpErrorResponse) => {
              return [
                fetchContactsFail(),
                showWarningNotification(err.error.message),
              ];
            })
          );
        }
      )
    )
  );

  constructor(private actions$: Actions, private http: HttpClient) {}
}

function setParamsForFetchContactsRequest(
  pageNumber: number,
  pageSize: number,
  businessUnitFilter: string,
  countryCodeFilter: string
) {
  let params = new HttpParams();
  if (pageNumber) {
    params = params.set('pageNumber', pageNumber.toString());
  }
  if (pageSize) {
    params = params.set('pageSize', pageSize.toString());
  }
  if (businessUnitFilter) {
    params = params.set('businessUnit', businessUnitFilter);
  }
  if (countryCodeFilter) {
    params = params.set('countryCode', countryCodeFilter);
  }

  return params;
}
