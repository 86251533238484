import { Chapter, Guideline } from '@models/Guideline';
import { GuidelineEditActions } from '@store/guideline/edit/guideline-edit.actions';
import { GuidelineViewActions } from '@store/guideline/view/guideline-view.actions';
import { GuidelineActions } from './guideline.actions';
import {
  addNewChapter,
  addNewSection,
  addNewSubsection,
  addNewSubsectionWithScope,
  changeModeSubsection,
  expandSection,
  getFilteredState,
  getNewUiStateFromGuideline,
  removeChapter,
  removeSection,
  removeSubsection,
  reorderChapters,
  reorderSections,
  updateChapterCollapsible,
  updateChapterName,
  updateScopeSubsection,
  updateSectionName,
  updateSubsectionContent,
} from './guideline.helper';
import { GuidelineState, initialGuidelineState } from './guideline.models';

type Actions = GuidelineActions | GuidelineEditActions | GuidelineViewActions;

export function guidelineReducer(
  state: GuidelineState = initialGuidelineState,
  action: Actions
): GuidelineState {
  switch (action.type) {
    case 'FETCH_HISTORY':
      return { ...state, fetchHistoryOnGoing: true };
    case 'FETCH_HISTORY_SUCCESS':
      return { ...state, fetchHistoryOnGoing: false, history: action.history };
    case 'FETCH_HISTORY_FAIL':
      return {
        ...state,
        fetchHistoryOnGoing: false,
        history: initialGuidelineState.history,
      };
    /**
     * BUSINESS_LINE_FOR_GUIDELINE
     */
    case 'FETCH_BUSINESS_UNIT_FOR_GUIDELINE':
      return { ...state, fetchBusinessUnitForGuidelineOnGoing: true };
    case 'FETCH_BUSINESS_UNIT_FOR_GUIDELINE_SUCCESS':
      return {
        ...state,
        fetchBusinessUnitForGuidelineOnGoing: false,
        businessUnitForGuideline: action.businessUnit,
        guidelineViewPointOfView: action.businessUnit[0],
      };
    case 'FETCH_BUSINESS_UNIT_FOR_GUIDELINE_FAIL':
      return {
        ...state,
        fetchBusinessUnitForGuidelineOnGoing: false,
        businessUnitForGuideline:
          initialGuidelineState.businessUnitForGuideline,
      };
    /**
     * BUSINESS_LINE_BY_COUNTRY
     */
    case 'FETCH_BUSINESS_UNIT_BY_COUNTRY':
      return { ...state, fetchBusinessUnitsByCountryOnGoing: true };
    case 'FETCH_BUSINESS_UNIT_BY_COUNTRY_SUCCESS':
      return {
        ...state,
        fetchBusinessUnitsByCountryOnGoing: false,
        businessUnitByCountry: action.businessUnit,
      };
    case 'FETCH_BUSINESS_UNIT_BY_COUNTRY_FAIL':
      return {
        ...state,
        fetchBusinessUnitsByCountryOnGoing: false,
        businessUnitByCountry: initialGuidelineState.businessUnitByCountry,
      };

    /**
     * Guideline-edit
     */
    case 'FETCH_GUIDELINE_FOR_CONFIGURATION':
      return { ...state, guideline: null };
    case 'FETCH_GUIDELINE_FOR_CONFIGURATION_SUCCESS':
      return {
        ...state,
        guideline: getFilteredState(action.guideline),
        uiState: getNewUiStateFromGuideline(action.guideline),
        isDirty: false,
      };
    case 'FETCH_GUIDELINE_FOR_CONFIGURATION_FAIL':
      return { ...state, guideline: initialGuidelineState.guideline };
    case 'SELECT_CHAPTER_AT':
      return { ...state, currentChapterIndex: action.payload };
    case 'FETCH_PREVIEW_FROM_GUIDELINE':
      return { ...state, fetchPreviewOnGoing: true };
    case 'FETCH_PREVIEW_FROM_GUIDELINE_SUCCESS':
      return {
        ...state,
        fetchPreviewOnGoing: false,
        preview: action.guideline,
      };
    case 'FETCH_PREVIEW_FROM_GUIDELINE_FAIL':
      return {
        ...state,
        fetchPreviewOnGoing: false,
        preview: initialGuidelineState.guideline,
      };
    case 'GUIDELINE_EDIT_FILTER_SELECT':
      return { ...state, guidelineEditPointOfView: action.businessUnit };
    case 'GUIDELINE_EDIT_FILTER_RESET':
      return { ...state, guidelineEditPointOfView: null };
    case 'SAVE_MINOR_VERSION':
    case 'SAVE_MAJOR_VERSION':
      return { ...state, saveOnGoing: true };
    case 'SAVE_MINOR_VERSION_SUCCESS':
    case 'SAVE_MAJOR_VERSION_SUCCESS':
      return {
        ...state,
        guideline: action.guideline,
        saveOnGoing: false,
        businessUnitsForReacknowledgement:
          initialGuidelineState.businessUnitsForReacknowledgement,
      };
    case 'SAVE_MINOR_VERSION_FAIL':
    case 'SAVE_MAJOR_VERSION_FAIL':
      return { ...state, saveOnGoing: false };
    case 'CHAPTER_ADD_NEW':
      return addNewChapter(state);
    case 'CHAPTER_REMOVE':
      return removeChapter(state, action);
    case 'CHAPTER_NAME_CHANGE':
      return updateChapterName(state, action);
    case 'CHAPTER_COLLAPSIBLE_CHANGE':
      return updateChapterCollapsible(state, action);
    case 'CHAPTERS_REORDER':
      return reorderChapters(state, action);
    case 'SECTION_ADD_NEW':
      return addNewSection(state, action);
    case 'SECTION_NAME_CHANGE':
      return updateSectionName(state, action);
    case 'SECTION_REMOVE':
      return removeSection(state, action);
    case 'SECTIONS_REORDER':
      return reorderSections(state, action);
    case 'SECTION_EXPANDED':
      return expandSection(state, action);
    case 'SUBSECTION_ADD_NEW':
      return addNewSubsection(state, action);
    case 'SUBSECTION_ADD_NEW_WITH_SCOPE':
      return addNewSubsectionWithScope(state, action);
    case 'SUBSECTION_CONTENT_CHANGE':
      return state.guideline ? updateSubsectionContent(state, action) : state;
    case 'SUBSECTION_REMOVE':
      return removeSubsection(state, action);
    case 'SUBSECTION_CHANGE_MODE':
      return changeModeSubsection(state, action);
    case 'SUBSECTION_UPDATE_SCOPE':
      return updateScopeSubsection(state, action);
    case 'GUIDELINE_CONFIGURATION_FORM_CHANGED':
      return { ...state, isDirty: action.isDirty };
    case 'UPDATE_BUSINESS_UNITS_FOR_REACKNOWLEDGEMENT':
      return { ...state, businessUnitsForReacknowledgement: action.payload };
    case 'FETCH_GUIDELINE_SOURCE_OWNERS':
      return {
        ...state,
        guideline: {
          ...state.guideline,
          sourceOwners: [],
        },
        fetchSourceOwnersOnGoing: true,
      };
    case 'FETCH_GUIDELINE_SOURCE_OWNERS_SUCCESS':
      return {
        ...state,
        guideline: {
          ...state.guideline,
          sourceOwners: action.sourceOwners,
        },
        fetchSourceOwnersOnGoing: false,
      };
    case 'FETCH_GUIDELINE_SOURCE_OWNERS_FAIL':
      return {
        ...state,
        guideline: {
          ...state.guideline,
          sourceOwners: [],
        },
        fetchSourceOwnersOnGoing: false,
      };
    case 'UPDATE_GUIDELINE_SOURCE_OWNERS':
      return {
        ...state,
        guideline: {
          ...state.guideline,
          sourceOwners: action.updatedGuideline.sourceOwners,
        },
        fetchSourceOwnersOnGoing: false,
      };

    /**
     * Guideline-view
     */
    case 'FETCH_GUIDELINE':
      return { ...state, guideline: null, guidelineForDisplay: null };
    case 'FETCH_GUIDELINE_SUCCESS':
      return {
        ...state,
        guideline: getFilteredState(action.guideline),
        guidelineForDisplay: reduceGuidelineForDisplay(action.guideline),
        uiState: getNewUiStateFromGuideline(action.guideline),
      };
    case 'FETCH_GUIDELINE_FAIL':
      return { ...state, guideline: initialGuidelineState.guideline };
    case 'GUIDELINE_VIEW_FILTER_SELECT':
      return { ...state, guidelineViewPointOfView: action.businessUnit };
    case 'GUIDELINE_VIEW_FILTER_RESET':
      return { ...state, guidelineViewPointOfView: null };
    case 'ACKNOWLEDGE':
      return { ...state, acknowledgeOnGoing: true };
    case 'ACKNOWLEDGE_SUCCESS':
      return {
        ...state,
        guideline: {
          ...state.guideline,
          acknowledgement: action.acknowledgement,
        },
        acknowledgeOnGoing: false,
      };
    case 'ACKNOWLEDGE_FAIL':
      return { ...state, acknowledgeOnGoing: false };
    case 'TOGGLE_MAIL_POPIN':
      return { ...state, mailPopinDisplayed: action.payload };
    case 'FETCH_DISPLAY_SUBMIT_CASE_AND_PROFILES':
      return {
        ...state,
        uiState: {
          ...state.uiState,
          displaySubmitPracticalCaseModule:
            initialGuidelineState.uiState.displaySubmitPracticalCaseModule,
          profiles: initialGuidelineState.uiState.profiles,
        },
      };
    case 'FETCH_DISPLAY_SUBMIT_CASE_AND_PROFILES_SUCCESS':
      return {
        ...state,
        uiState: {
          ...state.uiState,
          displaySubmitPracticalCaseModule:
            action.payload.canSubmitPracticalCases,
          profiles: action.payload.profiles,
        },
      };
    case 'FETCH_DISPLAY_SUBMIT_CASE_AND_PROFILES_FAIL':
      return {
        ...state,
        uiState: {
          ...state.uiState,
          displaySubmitPracticalCaseModule:
            initialGuidelineState.uiState.displaySubmitPracticalCaseModule,
          profiles: initialGuidelineState.uiState.profiles,
        },
      };
    case 'SUBMIT_QUESTION':
      return { ...state, submitQuestionOnGoing: true };
    case 'SUBMIT_QUESTION_DONE':
      return {
        ...state,
        submitQuestionOnGoing: false,
        mailPopinDisplayed: false,
      };

    default:
      return state;
  }
}

function reduceGuidelineForDisplay(guideline: Guideline): Guideline {
  const chaptersToBeDisplayed = guideline.chapters
    .map((chapter) => {
      const sectionsToBeDisplayed = chapter.sections
        .map((section) => {
          const subsectionsToBeDisplayed = section.subsections.filter(
            (subsection) => !subsection.filtered
          );
          return {
            ...section,
            subsections: subsectionsToBeDisplayed,
            filtered: subsectionsToBeDisplayed.length === 0,
          };
        })
        .filter(
          (section) => !section.filtered && section.subsections.length > 0
        );
      return {
        ...chapter,
        sections: sectionsToBeDisplayed,
        filtered: sectionsToBeDisplayed.length === 0,
      } as Chapter;
    })
    .filter((chapter) => {
      return !chapter.filtered && chapter.sections.length > 0;
    });

  return {
    ...guideline,
    chapters: chaptersToBeDisplayed,
    filtered: chaptersToBeDisplayed.every((chapter) => chapter.filtered),
  };
}
