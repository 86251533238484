import { RouterReducerState, routerReducer } from '@ngrx/router-store';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import {
  AcknowledgementState,
  initialAcknowledgementState,
} from '@store/acknowledgement/acknowledgement.models';
import { acknowledgementReducer } from '@store/acknowledgement/acknowledgement.reducer';
import {
  BusinessUnitState,
  initialBusinessUnitState,
} from '@store/business-unit/business-unit.models';
import { businessUnitReducer } from '@store/business-unit/business-unit.reducer';
import {
  ContactsDetailsState,
  initialContactsDetailsState,
} from '@store/contacts-details/contacts-details.models';
import { contactsDetailsReducer } from '@store/contacts-details/contacts-details.reducer';
import {
  CountryState,
  initialCountryState,
} from '@store/country/country.models';
import { countryReducer } from '@store/country/country.reducer';
import {
  CtaSpecificState,
  initialCtaSpecificState,
} from '@store/cta-specific/cta-specific.models';
import { ctaSpecificReducer } from '@store/cta-specific/cta-specific.reducer';
import { CtaState, initialCtaState } from '@store/cta/cta.models';
import { ctaReducer } from '@store/cta/cta.reducer';
import {
  DelegationState,
  initialDelegationState,
} from '@store/delegation/delegation.models';
import { delegationReducer } from '@store/delegation/delegation.reducer';
import {
  GuidelineState,
  initialGuidelineState,
} from '@store/guideline/guideline.models';
import { guidelineReducer } from '@store/guideline/guideline.reducer';
import { HeaderState, initialHeaderState } from '@store/header/header.models';
import { headerReducer } from '@store/header/header.reducer';
import { ModalState, initialModalState } from '@store/modal/modal.models';
import { modalReducer } from '@store/modal/modal.reducer';
import { RouterStateUrl } from '@store/router/router.state.models';
import { UserState, initialUserState } from '@store/user/user.models';
import { userReducer } from '@store/user/user.reducer';
import {
  ValidatorState,
  initialValidatorState,
} from '@store/validator/validator.models';
import { validatorReducer } from '@store/validator/validator.reducer';
import { storeFreeze } from 'ngrx-store-freeze';

export interface AppState {
  acknowledgement: AcknowledgementState;
  businessUnit: BusinessUnitState;
  contactsDetails: ContactsDetailsState;
  country: CountryState;
  cta: CtaState;
  ctaSpecific: CtaSpecificState;
  delegation: DelegationState;
  guideline: GuidelineState;
  header: HeaderState;
  modal: ModalState;
  router: RouterReducerState<RouterStateUrl>;
  user: UserState;
  validator: ValidatorState;
}

export const rootReducer: ActionReducerMap<AppState> = {
  acknowledgement: acknowledgementReducer,
  businessUnit: businessUnitReducer,
  contactsDetails: contactsDetailsReducer,
  country: countryReducer,
  cta: ctaReducer,
  ctaSpecific: ctaSpecificReducer,
  delegation: delegationReducer,
  guideline: guidelineReducer,
  header: headerReducer,
  modal: modalReducer,
  router: routerReducer,
  user: userReducer,
  validator: validatorReducer,
};

export const initialAppState: AppState = {
  acknowledgement: initialAcknowledgementState,
  businessUnit: initialBusinessUnitState,
  contactsDetails: initialContactsDetailsState,
  country: initialCountryState,
  cta: initialCtaState,
  ctaSpecific: initialCtaSpecificState,
  delegation: initialDelegationState,
  guideline: initialGuidelineState,
  header: initialHeaderState,
  modal: initialModalState,
  router: null,
  user: initialUserState,
  validator: initialValidatorState,
};

export function logger(
  reducer: ActionReducer<AppState>
): ActionReducer<AppState> {
  return (state: AppState, action: any): AppState => {
    return reducer(state, action);
  };
}

export const metaReducers: Array<MetaReducer<AppState>> =
  window.configurations && window.configurations.production
    ? []
    : [logger, storeFreeze];
