import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MOCK_API_ACCESS } from 'app/mock-api/mocks/access.mocks';
import { MOCK_API_USER, MOCK_API_USER_2 } from 'app/mock-api/mocks/user.mocks';
import { ok } from 'app/mock-api/utils/response.utils';
import { Observable, of } from 'rxjs';
import { delay, dematerialize, materialize, mergeMap } from 'rxjs/operators';

@Injectable()
export class UserRequestInterceptor implements HttpInterceptor {
  constructor() {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return <Observable<HttpEvent<any>>>of(null).pipe(
      mergeMap(() => {
        if (request.url.endsWith('/users/myself') && request.method === 'GET') {
          return ok(MOCK_API_USER);
        } else if (
          request.method === 'GET' &&
          request.url.match(/\/users$/) &&
          request.params.get('email') &&
          request.params
            .get('email')
            .match(
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(socgen|sgcib).(com)(.[a-z]{2})?$/
            )
        ) {
          return ok(MOCK_API_USER_2);
        } else if (
          request.url.endsWith('/accesses/myself') &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_ACCESS);
        }
        return next.handle(request);
      }),
      materialize(),
      delay(300),
      dematerialize()
    );
  }
}
