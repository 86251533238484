<header class="topheader">
  <nav class="navbar navbar-default" role="navigation">
    <div class="{{ containerClass }}">
      <div class="navbar-header">
        <button
          type="button"
          class="navbar-toggle collapsed"
          data-toggle="collapse"
          data-target="#sgxb-menu-navbar"
        >
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <a class="navbar-brand" href="https://www.sgmarkets.com/">
          <img src="assets/img/mini-brand.png" alt="Application logo" />
        </a>
        <div class="sgwt-account-center-container visible-xs visible-sm">
          <sgwt-account-center
            id="sgwtAccountCenterMobile"
            producer-code="sgm_crossborder"
            authentication="sg-connect-v2"
            mode="sg-markets"
            [environment]="envName ?? ''"
            hide-current-language
          >
          </sgwt-account-center>
        </div>
      </div>

      <div class="collapse navbar-collapse" id="sgxb-menu-navbar">
        <ul class="nav navbar-nav visible-xs">
          <li>
            <img
              src="assets/img/sub-brand-mini.png"
              alt="SG Markets Cross Border"
            />
          </li>
        </ul>
        <ul class="nav navbar-nav">
          <li
            *ngFor="let i of menuItems$ | async"
            [class.active]="i.selected"
            [class.disabled]="i.disabled"
          >
            <a [routerLink]="i.link" (click)="closeMenu(i)">{{ i.label }}</a>
          </li>
        </ul>

        <ul class="nav navbar-nav navbar-right visible-md visible-lg">
          <li>
            <sgwt-account-center
              id="sgwtAccountCenter"
              producer-code="sgm_crossborder"
              authentication="sg-connect-v2"
              mode="sg-markets"
              [environment]="envName"
              hide-current-language
            >
            </sgwt-account-center>
          </li>
          <li>
            <a class="navbar-sub-brand-mini" routerLink="/home">
              <img
                src="assets/img/sub-brand-mini-sgxb.svg"
                alt="SG Markets Cross Border"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
