import { Country, Profile } from '@models/Country';
import { User } from '@models/User';
import { UUID } from '@models/UUID';
import {
  CtaImportantLevel,
  CtaPresetValueType,
  CtaValidationLevel,
} from '@store/cta-specific/cta-specific.models';
import { v4 as uuidv4 } from 'uuid';
import { Scope } from './Guideline';

export enum TravelDirection {
  FROM = 'FROM',
  TO = 'TO',
}

export enum CtaStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  LATE_APPROVAL = 'LATE APPROVAL',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
  LATE_REJECTION = 'LATE REJECTION',
  LATE_DECLARATION = 'LATE DECLARATION',
}

export interface ComplianceTravelApproval {
  answers?: CtaAnswer[];
  ccs: string[];
  comments: string;
  destination: Country;
  endDate: string;
  manager: string;
  mock?: boolean;
  origin: Country;
  profile?: Profile;
  startDate: string;
  user?: User;
  uuid?: UUID;
  status?: CtaStatus;
  rejectReason?: string;
  breakdownStatus?: CtaBreakdownStatus;
  canEdit?: boolean;
  canCancel?: boolean;
  canDuplicate?: boolean;
  supervisor?: string;
  cta_id?: string;
}

export type CtaRequest = Omit<
  ComplianceTravelApproval,
  'status' | 'breakdownStatus'
> & {
  origin: Country;
  destination: Country;
};

export interface CtaQuestion {
  conditions?: CtaQuestionCondition[];
  description: string;
  display?: boolean;
  options?: CtaOption[];
  ordering?: number;
  scope: Scope;
  type: CtaQuestionType;
  useOr?: boolean;
  uuid: UUID;
  filtered?: boolean;
  validationLevel?: CtaValidationLevel;
  isCCMandatory?: boolean;
  presetValueType?: CtaPresetValueType;
  presetValue?: string;
  isNotEditable?: boolean;
  importantLevel?: CtaImportantLevel;
  answer?: string;
}

export type CtaQuestionType =
  | 'ACKNOWLEDGEMENT'
  | 'CHECKBOX'
  | 'DATE'
  | 'EMAIL'
  | 'FILE'
  | 'INPUT'
  | 'MULTI_SELECT'
  | 'PLAIN_TEXT'
  | 'SINGLE_SELECT'
  | 'TEXTAREA'
  | 'YES_NO';

export interface CtaQuestionCondition {
  option: UUID;
  question: UUID;
}

export interface CtaAnswer {
  answer: string;
  ccEmail?: boolean;
  questionLabel: string;
  type: CtaQuestionType;
  questionUuid?: UUID;
  validity?: boolean;
}

export type CtaAnswerResultType =
  | string
  | Date
  | CtaOption
  | CtaOption[]
  | boolean
  | null;

export interface CtaOption {
  ordering?: number;
  text: string;
  uuid: UUID;
}

export interface CtaStats {
  country: Country;
  endDate: string;
  rows: CtaStatsRow[];
  startDate: string;
}

export interface CtaStatsRow {
  days: number;
  questions: CtaStatsRowQuestion[];
  trips: number;
  user: User;
}

export interface CtaStatsRowQuestion {
  answers: CtaStatsRowQuestionAnswer[];
  question: string;
}

export interface CtaStatsRowQuestionAnswer {
  answer: string;
  days: number;
  trips: number;
}

export interface CtaBreakdownStatus {
  owner?: ApproverStatus[];
  managers: ApproverStatus[];
  complianceOfficers: ApproverStatus[];
  others: ApproverStatus[];
}

export interface ApproverStatus {
  approverMailingList: string;
  approverEmailsList: string[];
  validator?: string;
  status: CtaStatus;
  description?: string;
}

export const MOCK_CTA: ComplianceTravelApproval = {
  answers: [],
  ccs: [],
  comments: '',
  destination: null,
  endDate: null,
  manager: '',
  mock: true,
  origin: null,
  profile: null,
  startDate: null,
  user: null,
  status: null,
  breakdownStatus: {
    managers: [],
    complianceOfficers: [],
    others: [],
  },
  cta_id: null,
};

export const MOCK_TAR_QUESTION: CtaQuestion = {
  description: 'Multi-select Question',
  options: [
    { uuid: uuidv4(), text: 'Option 1' },
    { uuid: uuidv4(), text: 'Option 2' },
  ],
  type: 'MULTI_SELECT',
  uuid: uuidv4(),
  scope: ['MARK', 'GLBA', 'GTPS'],
};

export const MOCK_TAR_SPECIFIC = {
  MULTI_SELECT(scope: Scope) {
    return {
      description: 'Multi-select Question',
      options: [
        { uuid: uuidv4(), text: 'Option 1' },
        { uuid: uuidv4(), text: 'Option 2' },
      ],
      type: 'MULTI_SELECT',
      uuid: uuidv4(),
      scope,
    };
  },
  SINGLE_SELECT(scope: Scope) {
    return {
      description: 'Single-select Question',
      options: [
        { uuid: uuidv4(), text: 'Option 1' },
        { uuid: uuidv4(), text: 'Option 2' },
      ],
      type: 'SINGLE_SELECT',
      uuid: uuidv4(),
      scope,
    };
  },
  YES_NO(scope: Scope) {
    return {
      description: 'Yes/No Question',
      options: [
        { uuid: uuidv4(), text: 'Yes' },
        { uuid: uuidv4(), text: 'No' },
      ],
      type: 'SINGLE_SELECT',
      uuid: uuidv4(),
      scope,
    };
  },
  ACKNOWLEDGEMENT(scope: Scope) {
    return {
      uuid: uuidv4(),
      type: 'ACKNOWLEDGEMENT',
      description: 'Acknowledgement description',
      scope,
    };
  },
  TEXTAREA(scope: Scope) {
    return {
      uuid: uuidv4(),
      type: 'TEXTAREA',
      description: 'Describe the text you expect from the user',
      scope,
    };
  },
  EMAIL(scope: Scope) {
    return {
      uuid: uuidv4(),
      type: 'EMAIL',
      description: 'Describe whose email you expect',
      scope,
    };
  },
  DATE(scope: Scope) {
    return {
      uuid: uuidv4(),
      type: 'DATE',
      description: 'Describe which date you expect from the user',
      scope,
    };
  },
  PLAIN_TEXT(scope: Scope) {
    return {
      uuid: uuidv4(),
      type: 'PLAIN_TEXT',
      description: 'Enter your text paragraph here',
      scope,
    };
  },
  FILE(scope: Scope) {
    return {
      uuid: uuidv4(),
      type: 'FILE',
      description: 'Describe the file you expect from the user',
      scope,
    };
  },
};

export interface TravelStatsRow {
  user: UserStats;
  cta: CommonTravelDataStats;
}

export interface UserStats {
  lastName: string;
  firstName: string;
  department: string;
  email: string;
}
export interface CommonTravelDataStats {
  uuid: string;
  startDate: string;
  endDate: string;
  origin: {
    name: string;
  };
  destinationName: {
    name: string;
  };
  profile: string;
  manager: string;
  status: string;
  rejectReason: string | null;
  workingDuration: number;
}
