import {
  ComplianceTravelApproval,
  CtaStats,
  MOCK_CTA,
} from '@models/ComplianceTravelApproval';

export interface CtaState {
  all: ComplianceTravelApproval[];
  current: ComplianceTravelApproval;
  fetchOnGoing: boolean;
  latest: ComplianceTravelApproval[];
  stats: CtaStats;
  submitOnGoing: boolean;
  uiState: {
    current: ComplianceTravelApproval;
    rejectModeEnabled: boolean;
    selectedCtaForApprovalIndex: number;
    specificCtaForApproval: ComplianceTravelApproval;
    workingDaysMessage: {
      level: string;
      title: string;
      message: string;
    };
    lateDeclarationWarning: {
      title: string;
      message: string;
    };
  };
  forDuplication: ComplianceTravelApproval;
  specificCtaForApproval: ComplianceTravelApproval;
  forApproval: ComplianceTravelApproval[];
  alreadyProcessed: ComplianceTravelApproval[];
  approvalOnGoing: boolean;
  exportCtaDashboardOnGoing: boolean;
  fetchStatsOnGoing: boolean;
}

export const initialCtaState: CtaState = {
  all: null,
  current: MOCK_CTA,
  fetchOnGoing: false,
  latest: null,
  stats: null,
  submitOnGoing: false,
  uiState: {
    current: MOCK_CTA,
    rejectModeEnabled: false,
    selectedCtaForApprovalIndex: null,
    specificCtaForApproval: MOCK_CTA,
    workingDaysMessage: null,
    lateDeclarationWarning: null,
  },
  forDuplication: null,
  specificCtaForApproval: MOCK_CTA,
  forApproval: null,
  alreadyProcessed: null,
  approvalOnGoing: false,
  exportCtaDashboardOnGoing: false,
  fetchStatsOnGoing: false,
};

export interface RejectCtaRequest {
  rejectionComment: string;
}
