import { Acknowledgement } from '@models/Acknowledgement';

export interface AcknowledgementState {
  acknowledgements: Acknowledgement[];
  acknowledgementsNotExpired: Acknowledgement[];
  exportAckOnGoing: boolean;
}

export const initialAcknowledgementState: AcknowledgementState = {
  acknowledgements: null,
  acknowledgementsNotExpired: null,
  exportAckOnGoing: false,
};
