import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
  transform(wholeText: string, stringToHighlight: string): string {
    if (!stringToHighlight) {
      return wholeText;
    }
    const re = new RegExp(stringToHighlight, 'gi');
    const highlighted = wholeText.replace(re, '<mark>$&</mark>');
    return highlighted;
  }
}
