import { Component, Input } from '@angular/core';
import { Section } from '@models/Guideline';
import * as htmlToText from 'html-to-text';

@Component({
  selector: 'sgxb-section-excerpt',
  templateUrl: './section-excerpt.component.html',
  styleUrls: ['./section-excerpt.component.scss'],
})
export class SectionExcerptComponent {
  @Input() public id = '';
  @Input() public section: Section;
  @Input() public limit = 300;
  @Input() public searchQuery: string = '';

  public open: boolean;

  constructor() {
    this.open = false;
  }

  get excerpt(): string {
    let excerpt = this.section.subsections
      .map((o) => {
        if (o.filtered) {
          return;
        }
        return htmlToText.fromString(o.content, {
          wordwrap: false,
          uppercaseHeadings: false,
        });
      })
      .join(' ');
    excerpt =
      excerpt.length > this.limit
        ? excerpt.substr(0, this.limit) + '...'
        : excerpt + '...';
    return excerpt;
  }

  public toggle() {
    this.open = !this.open;
  }

  public close() {
    this.open = false;
  }

  public scrollTo(event: MouseEvent) {
    // @ts-ignore: 2339
    const target = event.srcElement.getAttribute('href');
    const elem = document.querySelector(target);

    if (!elem) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    this.internalScroll(elem);
  }

  private internalScroll(elem) {
    const headerHeight = document
      .querySelector('sgxb-header')
      .getBoundingClientRect().height;
    let scrollTop = elem.offsetTop;
    while (elem.offsetParent) {
      scrollTop += elem.offsetParent.offsetTop;
      elem = elem.offsetParent;
    }
    document.documentElement.scrollTop = scrollTop - headerHeight;
  }
}
