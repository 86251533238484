import { Pipe, PipeTransform } from '@angular/core';
import { CtaStatus } from '@models/ComplianceTravelApproval';

@Pipe({
  name: 'ctaStatusColorPalette',
})
export class CtaStatusColorPalettePipe implements PipeTransform {
  public transform(value: CtaStatus): string {
    switch (value) {
      case CtaStatus.PENDING:
        return 'info';
      case CtaStatus.APPROVED:
        return 'success';
      case CtaStatus.LATE_APPROVAL:
        return 'warning';
      case CtaStatus.REJECTED:
      case CtaStatus.LATE_REJECTION:
        return 'danger';
      case CtaStatus.LATE_DECLARATION:
      case CtaStatus.CANCELLED:
        return 'reverse';
      default:
        return 'default';
    }
  }
}
