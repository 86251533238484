<div class="combo-box form-group" [class.has-error]="touched && !validity">
  <label class="control-label" *ngIf="label">
    <span [innerHTML]="label | nl2br | safeHtml"></span>
    <b *ngIf="required">*</b>
  </label>

  <div class="input-group" [class.disabled]="disabled">
    <div class="form-control" [class.active]="isActive">
      <ng-container *ngIf="tags && tags.length > 0">
        <a
          class="label label-reverse"
          *ngFor="let tag of tags; let i = index; trackBy: tagsTrackingFn"
          (mousedown)="onTagClick($event, i)"
        >
          {{ tag }} <i class="fa fa-close" *ngIf="!disabled"></i>
        </a>
      </ng-container>

      <input
        type="text"
        autocomplete="off"
        aria-autocomplete="none"
        [(ngModel)]="inputValue"
        [placeholder]="placeholder"
        [disabled]="disabled"
        [class.disabled]="disabled"
        (focus)="onFocus($event)"
        (blur)="onBlur($event)"
        (keydown.enter)="onEnter($event)"
        (keydown.esc)="onEsc($event)"
        (keydown.backspace)="onBackspace($event)"
        (keydown.tab)="onTab($event)"
        (keydown.shift.tab)="onTab($event)"
        (keydown.arrowup)="onArrowUp($event); $event.preventDefault()"
        (keydown.arrowdown)="onArrowDown($event); $event.preventDefault()"
        #input
      />
    </div>
  </div>

  <div
    class="warnings"
    *ngIf="
      touched &&
      !validity &&
      !(isActive && suggestions && suggestions.length > 0)
    "
  >
    <ng-container *ngFor="let warning of warnings">
      {{ warning }}<br />
    </ng-container>
  </div>

  <div
    class="combo-box-scroll no-icon"
    *ngIf="isActive && suggestions && suggestions.length > 0"
  >
    <div #suggestionsBox class="list-group">
      <a
        *ngFor="let suggestion of suggestions; let i = index"
        class="list-group-item"
        [class.active]="i === suggestionIndex"
        (mouseover)="suggestionIndex = i"
        (mousedown)="onSuggestionClick($event)"
      >
        {{ displayWith ? suggestion[displayWith] : suggestion }}
      </a>
    </div>
  </div>
</div>
