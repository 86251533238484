import { Country } from '@models/Country';
import { CtaValidationLevel } from '@store/cta-specific/cta-specific.models';

export interface ContactsDetailsState {
  contacts: Contact[];
  fetchContactsOnGoing: boolean;
  page?: number;
  totalPages?: number;
  form?: ContactsDetailsForm;
}

export const initialContactsDetailsState: ContactsDetailsState = {
  contacts: [],
  fetchContactsOnGoing: false,
};

export interface ContactsDetailsForm {
  uuid: string;
  country: Country;
  businessUnit: string;
  entity: Entity;
  actionLevel: CtaValidationLevel;
  emails: string[];
  saveContactOnGoing: boolean;
  deleteContactOnGoing: boolean;
}

export type Entity = 'ALL' | 'Branch' | 'Security';

export interface Contact {
  type: ContactType;
  businessUnit: string;
  country: Country;
  entity: Entity;
  actionLevel: CtaValidationLevel;
  updatedAt: string;
  updatedBy: string;
}

export type ContactType = 'complianceOfficers' | 'otherApprovers';

export interface FetchContactsDetailsRequest {
  pageNumber: number;
  pageSize: number;
  businessUnit: string;
  countryCode: string;
}
export interface ContactsResponse {
  entries: Entry[];
  pageInfo: PageInfo;
}

export interface Entry {
  action: CtaValidationLevel;
  businessUnit: string;
  country: Country;
  contacts: string[];
  updatedAt: string;
  updatedBy: string;
  profile: Entity;
  uuid: string;
}
export interface PageInfo {
  page: number;
  size: number;
  totalPages: number;
}
