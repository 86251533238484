<div class="mobile-screen">
  <ng-container *ngFor="let q of questions">
    <div class="row" *ngIf="isQuestionVisible$(q.uuid) | async">
      <div class="col-xs-12 form-group" [ngSwitch]="q.type">
        <ng-container *ngSwitchCase="'PLAIN_TEXT'">
          <sgxb-travel-approval-plain-text
            [question]="q"
          ></sgxb-travel-approval-plain-text>
        </ng-container>

        <ng-container *ngSwitchCase="'ACKNOWLEDGEMENT'">
          <sgxb-travel-approval-acknowledgement
            [question]="q"
          ></sgxb-travel-approval-acknowledgement>
        </ng-container>

        <ng-container *ngSwitchCase="'DATE'">
          <sgxb-travel-approval-datepicker
            [question]="q"
          ></sgxb-travel-approval-datepicker>
        </ng-container>

        <ng-container *ngSwitchCase="'INPUT'">
          <sgxb-travel-approval-input
            [question]="q"
          ></sgxb-travel-approval-input>
        </ng-container>

        <ng-container *ngSwitchCase="'FILE'">
          <sgxb-travel-approval-file [question]="q"></sgxb-travel-approval-file>
        </ng-container>

        <ng-container *ngSwitchCase="'EMAIL'">
          <sgxb-travel-approval-email
            [question]="q"
          ></sgxb-travel-approval-email>
        </ng-container>

        <ng-container *ngSwitchCase="'TEXTAREA'">
          <sgxb-travel-approval-textarea
            [question]="q"
          ></sgxb-travel-approval-textarea>
        </ng-container>

        <ng-container *ngSwitchCase="'MULTI_SELECT'">
          <sgxb-travel-approval-multi-select
            [question]="q"
          ></sgxb-travel-approval-multi-select>
        </ng-container>

        <ng-container *ngSwitchCase="'SINGLE_SELECT'">
          <sgxb-travel-approval-single-select
            [question]="q"
          ></sgxb-travel-approval-single-select>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
