import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { Validator, ValidatorRule } from '@models/Validator';
import { warningMessagesGenerator } from '@store/validator/validator.helper';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'sgxb-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
  @Input() public value = false;
  @Input() public label = '';
  @Input() public validators: Validator[] = [];
  @Output() public toggle = new EventEmitter<boolean>();
  @Output() public change = new EventEmitter();
  @Output() public validate = new EventEmitter<boolean>();

  public nativeElement = null;
  public uuid = uuidv4();
  public touched = false;
  public warnings: string[] = [];
  private _disabled = false;
  @Input() set disabled(value: boolean) {
    this._disabled = value;
    this.touched = false;
    if (this._disabled) {
      this.validate.emit(true);
    }
  }
  get disabled(): boolean {
    return this._disabled;
  }

  constructor(private ref: ElementRef) {
    this.nativeElement = this.ref.nativeElement;
  }

  get required(): boolean {
    return (
      !this.disabled &&
      this.validators
        .map((o: Validator) => o.rule)
        .indexOf(ValidatorRule.REQUIRED) !== -1
    );
  }

  get validity(): boolean {
    this.warnings = [];
    if (!this.disabled) {
      const conditions = this.validators
        .map((validator) => {
          switch (validator.rule) {
            case ValidatorRule.REQUIRED: {
              return {
                rule: validator.rule,
                shouldRaiseWarning: !this.value,
              };
            }
            default:
              return;
          }
        })
        .filter((o) => o !== null && o !== undefined);
      this.warnings = warningMessagesGenerator(
        this.warnings,
        this.validators,
        conditions
      );
    }

    const valid =
      this.disabled || (this.warnings && this.warnings.length === 0);
    this.validate.emit(valid);
    return valid;
  }

  @HostListener('click')
  public hostClick() {
    if (this.disabled) {
      return;
    }
    this.touched = true;
  }

  public onChange(event): void {
    if (this.disabled) {
      return;
    }
    this.change.emit(event);
    this.toggle.emit(event.target.checked);
    this.touched = true;
  }
}
