export interface BusinessUnitState {
  allBusinessUnits: string[];
  perimeterBusinessUnits: string[];
  fetchBusinessUnitsOnGoing: boolean;
}

export const initialBusinessUnitState: BusinessUnitState = {
  allBusinessUnits: null,
  perimeterBusinessUnits: null,
  fetchBusinessUnitsOnGoing: false,
};

export interface BusinessUnitCheckboxState {
  value: string;
  checked: boolean;
  readonly: boolean;
}
