import {
  ComplianceTravelApproval,
  CtaAnswer,
  CtaRequest,
} from '@models/ComplianceTravelApproval';
import { User } from '@models/User';
import { domStringToBase64 } from '@shared/utils/encode-decode/encode-decode.utils';
import { RejectCtaRequest } from './cta.models';

export function encodeRejectCtaRequest(
  request: RejectCtaRequest
): RejectCtaRequest {
  return {
    ...request,
    rejectionComment: domStringToBase64(request.rejectionComment),
  };
}

export function getCreateOrUpdateCtaRequest(
  taData: ComplianceTravelApproval,
  travelerUser: User
): CtaRequest {
  return {
    origin: {
      alpha2: taData.origin.alpha2,
      alpha3: taData.origin.alpha3,
      name: taData.origin.name,
      numeric: taData.origin.numeric,
    },
    destination: {
      alpha2: taData.destination.alpha2,
      alpha3: taData.destination.alpha3,
      name: taData.destination.name,
      numeric: taData.destination.numeric,
    },
    profile: taData.profile,
    startDate: taData.startDate,
    endDate: taData.endDate,
    ccs: taData.ccs,
    manager: taData.manager,
    // supervisor: taData.supervisor,
    comments: domStringToBase64(
      taData.comments && taData.comments !== '' ? taData.comments : null
    ),
    answers: taData.answers
      .filter(
        (o) =>
          o.answer !== null && o.answer !== undefined && o.answer.length > 0
      )
      .map((o: CtaAnswer) => {
        return {
          questionLabel: undefined,
          answer: domStringToBase64(o.answer),
          ccEmail: o.ccEmail,
          type: o.type,
          questionUuid: o.questionUuid,
        };
      }),
    user: {
      email: taData && taData.user ? taData.user.email : travelerUser.email,
    },
  };
}
