import { Guideline, Scope } from '@models/Guideline';
import { SourceVersionOwner } from '@models/SourceVersionOwner';
import { convertGuidelineStylesToClasses } from '@shared/utils/guidelines/guidelines.utils';
import { GuidelineState } from '@store/guideline/guideline.models';
import { isUndefined } from 'app/utils/predicates.utils';

export const getCurrentChapterIndex = (state: GuidelineState): number =>
  state.currentChapterIndex;
export const getSaveOnGoing = (state: GuidelineState): boolean =>
  state.saveOnGoing;
export const getGuidelineEditPointOfView = (state: GuidelineState): string => {
  return state.guidelineEditPointOfView;
};
export const getDefaultScopeForCreateSubsection = (
  state: GuidelineState
): Scope =>
  state &&
  state.guidelineEditPointOfView &&
  state.guidelineEditPointOfView !== 'ALL'
    ? [state.guidelineEditPointOfView]
    : [];
export const getPreviewOnGoing = (state: GuidelineState): boolean =>
  state.fetchPreviewOnGoing;
export const getGuidelinePreview = (state: GuidelineState): Guideline => {
  const guideline = state?.preview;
  if (isUndefined(guideline)) {
    return undefined;
  }
  return convertGuidelineStylesToClasses(guideline);
};
export const getGuidelineConfigurationFormIsDirty = (
  state: GuidelineState
): boolean => state.isDirty;
export const getFetchBusinessUnitsByCountryOnGoing = (
  state: GuidelineState
): boolean => state.fetchBusinessUnitsByCountryOnGoing;
export const getBusinessUnitsForReacknowledgement = (
  state: GuidelineState
): string[] => state.businessUnitsForReacknowledgement;
export const getGuidelineSourceOwners = (
  state: GuidelineState
): SourceVersionOwner[] =>
  state.guideline ? state.guideline.sourceOwners : [];
export const getFetchSourceOwnersOnGoing = (state: GuidelineState): boolean =>
  state.fetchSourceOwnersOnGoing;
