export type checkDefineAndEmptyType = null | undefined | '';

export function isDefined<T>(x: T | null | undefined): x is T {
  return x !== undefined && x !== null;
}

export function isUndefined<T>(x: T | null | undefined): x is null | undefined {
  return x === undefined || x === null;
}

export function isNonEmpty<T>(
  x: T | checkDefineAndEmptyType
): x is Exclude<T, ''> {
  return x !== undefined && x !== null && x !== '';
}
