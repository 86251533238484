<ng-container *ngIf="ta">
  <div class="row" *ngFor="let a of ta.answers">
    <div class="col-xs-12 form-group">
      <label class="question-label">{{ a.questionLabel }}</label
      ><br />
      <span class="label label-default email-cc-label" *ngIf="a.ccEmail"
        >Email CC</span
      >
      <span [innerHTML]="a.answer | nl2br | safeHtml"></span>
      <!-- <span [innerHTML]="a.type !== 'DATE' ? (a.answer | nl2br | safeHtml) : (a.answer | date: 'd MMM y (EEE)' | nl2br | safeHtml)"></span>-->
    </div>
  </div>
</ng-container>
