import { CountryActions } from './country.actions';
import { CountryState, initialCountryState } from './country.models';

export function countryReducer(
  state: CountryState = initialCountryState,
  action: CountryActions
): CountryState {
  switch (action.type) {
    case 'TOGGLE_POPIN':
      return { ...state, popinDisplayed: action.payload };
    case 'TOGGLE_REQUEST_POPIN':
      return { ...state, requestPopinDisplayed: action.payload };

    case 'FETCH_COUNTRY':
    case 'FETCH_COUNTRY_FAIL': {
      return { ...state, currentCountry: initialCountryState.currentCountry };
    }
    case 'FETCH_COUNTRY_SUCCESS': {
      return { ...state, currentCountry: action.payload };
    }

    case 'RESET_CURRENT_COUNTRY': {
      return { ...state, currentCountry: initialCountryState.currentCountry };
    }
    case 'UPDATE_CURRENT_COUNTRY': {
      return { ...state, currentCountry: action.payload };
    }

    case 'SAVE_GUIDELINE_CONFIGURATION': {
      return { ...state, currentCountry: action.payload, saveOnGoing: true };
    }
    case 'SAVE_GUIDELINE_CONFIGURATION_SUCCESS': {
      return {
        ...state,
        currentCountry: action.payload,
        saveOnGoing: false,
        popinDisplayed: false,
      };
    }
    case 'SAVE_GUIDELINE_CONFIGURATION_FAIL': {
      return { ...state, saveOnGoing: false };
    }

    case 'FETCH_COUNTRIES':
      return { ...state };
    case 'FETCH_COUNTRIES_FAIL': {
      const { underPerimeter, withGuidelines } = action;

      // Case: Fetch countries restricted in user's perimeter and containing guidelines
      if (underPerimeter && withGuidelines) {
        return {
          ...state,
          perimeterCountriesWithGuidelines:
            initialCountryState.perimeterCountriesWithGuidelines,
        };
      }
      // Case: Fetch countries restricted in user's perimeter
      else if (underPerimeter) {
        return {
          ...state,
          perimeterCountries: initialCountryState.perimeterCountries,
        };
      } else if (withGuidelines) {
        // Case: Fetch all countries without perimeter restriction and  containing guidelines
        return {
          ...state,
          allCountriesWithGuidelines:
            initialCountryState.allCountriesWithGuidelines,
        };
      } else {
        // Case: Fetch all countries
        return { ...state, allCountries: initialCountryState.allCountries };
      }
    }
    case 'FETCH_COUNTRIES_SUCCESS': {
      const { countries, underPerimeter, withGuidelines } = action;

      // Case: Fetch countries restricted in user's perimeter and containing guidelines
      if (underPerimeter && withGuidelines) {
        return { ...state, perimeterCountriesWithGuidelines: countries };
      }
      // Case: Fetch countries restricted in user's perimeter
      else if (underPerimeter) {
        return { ...state, perimeterCountries: countries };
      } else if (withGuidelines) {
        // Case: Fetch all countries without perimeter restriction and  containing guidelines
        return { ...state, allCountriesWithGuidelines: countries };
      } else {
        // Case: Fetch all countries
        return { ...state, allCountries: countries };
      }
    }

    case 'FETCH_COUNTRIES_UNDER_OWNERSHIP': {
      return {
        ...state,
        countryUnderOwnership: initialCountryState.countryUnderOwnership,
      };
    }
    case 'FETCH_COUNTRIES_UNDER_OWNERSHIP_FAIL': {
      return { ...state, countryUnderOwnership: [] };
    }
    case 'FETCH_COUNTRIES_UNDER_OWNERSHIPS_SUCCESS': {
      return { ...state, countryUnderOwnership: action.payload };
    }

    default: {
      return state;
    }
  }
}
