import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Scope } from '@models/Guideline';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'sgxb-draggable-panel',
  templateUrl: './draggable-panel.component.html',
  styleUrls: ['./draggable-panel.component.scss'],
})
export class DraggablePanelComponent {
  @ViewChild('dropdownMenu', { static: false }) public dropdownMenu: ElementRef;
  @ViewChild('collapseIndicator', { static: false })
  public collapseIndicator: ElementRef;
  @ViewChild('container', { static: false }) public container: ElementRef;
  @Output() public mousedown = new EventEmitter<MouseEvent>();
  @Output() public toggle = new EventEmitter<boolean>();
  @Output() public dropDownSelect = new EventEmitter<number>();
  @Input() public title = '';
  @Input() public selectedItem = null;
  @Input() public expanded = false;
  @Input() public displayAs = null;
  @Input() public description = '';

  private _questionScope: Scope;
  public isArray = Array.isArray;
  get displayTitle(): string {
    const item = Array.isArray(this.title) ? this.selectedItem : this.title;
    return this.displayAs && item ? item[this.displayAs] : item;
  }

  @Input()
  set questionScope(questionScope: Scope) {
    this._questionScope = cloneDeep(questionScope);
  }
  get questionScope(): Scope {
    return this._questionScope;
  }

  constructor() {}

  public getOptionText(obj): string {
    return this.displayAs && obj ? obj[this.displayAs] : obj;
  }

  public setStyle(event) {
    if (!this.dropdownMenu) {
      return;
    }
    this.dropdownMenu.nativeElement.style.left =
      event.currentTarget.offsetLeft + 'px';
    this.dropdownMenu.nativeElement.style.top = `calc(1.5em + ${event.currentTarget.offsetTop}px`;
  }

  public collapse(event) {
    if (
      event.target === event.currentTarget ||
      event.target === this.collapseIndicator.nativeElement
    ) {
      this.toggle.emit(!this.expanded);
    }
  }
}
