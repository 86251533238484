<div class="form-group" [class.has-error]="touched && !validity">
  <label class="control-label" *ngIf="label">
    <span [innerHTML]="label | nl2br | safeHtml"></span>
    <b *ngIf="required">*</b>
  </label>

  <div class="input-group">
    <input
      class="form-control"
      autocomplete="off"
      aria-autocomplete="none"
      [type]="type"
      [value]="value"
      [attr.maxlength]="maxlength"
      [placeholder]="placeholder"
      [disabled]="disabled"
      [style.paddingTop.px]="type === 'file' ? 5 : 0"
      (focus)="onFocus($event)"
      (blur)="onBlur($event)"
      (input)="onInput($event)"
      (change)="onChange($event)"
      (keydown.enter)="$event.preventDefault()"
      [class.no-addon]="!ref || !ref.children.length"
      accept=".pdf,.doc,.docx"
    />
    <div class="input-group-addon" [class.empty]="!ref || !ref.children.length">
      <div #ref><ng-content></ng-content></div>
    </div>
  </div>

  <div class="warnings" *ngIf="touched && !validity">
    <ng-container *ngFor="let warning of warnings">
      {{ warning }}<br />
    </ng-container>
  </div>
</div>
