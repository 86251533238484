<p [id]="id" [hidden]="open">
  <span [innerHTML]="excerpt | highlight:searchQuery | safeHtml"></span>
  <a href="#{{ id }}" (click)="toggle(); scrollTo($event)">Read More</a>
</p>

<div [hidden]="!open">
  <div
    id="{{ id }}-{{ i }}"
    *ngFor="let subsection of section.subsections; let i = index"
  >
    <p
      *ngIf="!subsection.filtered"
      [innerHTML]="subsection.content | highlight:searchQuery | safeHtml"
    ></p>
  </div>
</div>
