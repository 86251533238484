<div class="form-group" [class.has-error]="touched && !validity">
  <label class="control-label" *ngIf="label">
    <span [innerHTML]="label | nl2br | safeHtml"></span>
    <b *ngIf="required">*</b>
  </label>

  <ng-container *ngIf="options && options.length > 0; else noOptions">
    <div
      class="checkbox"
      [class.disabled]="disabled"
      *ngFor="let o of options; let i = index"
    >
      <label for="checkbox-{{ uuid }}-{{ i }}">
        <input
          type="checkbox"
          id="checkbox-{{ uuid }}-{{ i }}"
          [name]="uuid"
          [value]="getDisplayText(o)"
          [checked]="isSelected(i)"
          (click)="onToggle($event, i)"
        />
        <span>{{ getDisplayText(o) }}</span>
      </label>
    </div>
  </ng-container>

  <ng-template #noOptions>
    <div class="checkbox disabled">
      <label>
        <input type="checkbox" name="checkbox" />
        <span>No option is available</span>
      </label>
    </div>
  </ng-template>

  <div class="warnings" *ngIf="touched && !validity">
    <ng-container *ngFor="let warning of warnings">
      {{ warning }}<br />
    </ng-container>
  </div>
</div>
