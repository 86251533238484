import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  MOCK_API_ALL_COUNTRIES,
  MOCK_API_ALL_COUNTRIES_WITH_GUIDELINES,
  MOCK_API_COUNTRY_HK,
  MOCK_API_PERIMETER_COUNTRIES,
  MOCK_API_PERIMETER_COUNTRIES_WITH_GUIDELINES,
} from 'app/mock-api/mocks/country.mocks';
import { ok } from 'app/mock-api/utils/response.utils';
import { Observable, of } from 'rxjs';
import { delay, dematerialize, materialize, mergeMap } from 'rxjs/operators';

@Injectable()
export class CountryRequestInterceptor implements HttpInterceptor {
  constructor() {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return <Observable<HttpEvent<any>>>of(null).pipe(
      mergeMap(() => {
        if (
          request.url.match(/\/countries\/([A-Z]{2})$/) &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_COUNTRY_HK);
        } else if (
          request.url.endsWith('/countries') &&
          request.method === 'GET' &&
          request.params.get('underMyPerimeter') &&
          request.params.get('underMyPerimeter').match(/^(true)$/) &&
          request.params.get('withGuidelines') &&
          request.params.get('withGuidelines').match(/^(true)$/)
        ) {
          return ok(MOCK_API_PERIMETER_COUNTRIES_WITH_GUIDELINES);
        } else if (
          request.url.endsWith('/countries') &&
          request.method === 'GET' &&
          request.params.get('underMyPerimeter') &&
          request.params.get('underMyPerimeter').match(/^(true)$/) &&
          (!request.params.get('withGuidelines') ||
            (request.params.get('withGuidelines') &&
              request.params.get('withGuidelines').match(/^(false)$/)))
        ) {
          return ok(MOCK_API_PERIMETER_COUNTRIES);
        } else if (
          request.url.endsWith('/countries') &&
          request.method === 'GET' &&
          (!request.params.get('underMyPerimeter') ||
            (request.params.get('underMyPerimeter') &&
              request.params.get('underMyPerimeter').match(/^(false)$/))) &&
          request.params.get('withGuidelines') &&
          request.params.get('withGuidelines').match(/^(true)$/)
        ) {
          return ok(MOCK_API_ALL_COUNTRIES_WITH_GUIDELINES);
        } else if (
          request.url.endsWith('/countries') &&
          request.method === 'GET' &&
          (!request.params.get('underMyPerimeter') ||
            (request.params.get('underMyPerimeter') &&
              request.params.get('underMyPerimeter').match(/^(false)$/))) &&
          (!request.params.get('withGuidelines') ||
            (request.params.get('withGuidelines') &&
              request.params.get('withGuidelines').match(/^(false)$/)))
        ) {
          return ok(MOCK_API_ALL_COUNTRIES);
        } else if (
          request.url.endsWith('/countries') &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_ALL_COUNTRIES);
        } else if (
          request.url.endsWith('/countries/underMyOwnership') &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_ALL_COUNTRIES);
        } else if (
          request.url.match(/\/countries\/([A-Z]{2})\/reset$/) &&
          request.method === 'POST'
        ) {
          return ok(MOCK_API_COUNTRY_HK);
        } else if (
          request.url.match(/\/countries\/([A-Z]{2})$/) &&
          request.method === 'PUT'
        ) {
          return ok(MOCK_API_COUNTRY_HK);
        }

        return next.handle(request);
      }),
      materialize(),
      delay(300),
      dematerialize()
    );
  }
}
