import { Component } from '@angular/core';

@Component({
  selector: 'sgxb-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss'],
})
export class LoadingScreenComponent {
  constructor() {}
}
