import { CtaImportantLevel } from '@store/cta-specific/cta-specific.models';

export const getImportantLevelColor = (
  importantLevel: CtaImportantLevel
): string | undefined => {
  const importantLevelColorMap: Record<CtaImportantLevel, string> = {
    INFORMATIVE: '',
    IMPORTANT: 'text-warning',
    VERY_IMPORTANT: 'text-danger',
  };

  return importantLevel ? importantLevelColorMap[importantLevel] : undefined;
};
