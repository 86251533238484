import { ApiEndpoint } from '@models/ApiEndpoint';

export const lanEndpoints: ApiEndpoint[] = [
  {
    method: 'POST',
    uri: /^guidelines\/preview(\?businessUnit=([A-Z\/]{4,}))?$/,
  },
  {
    method: 'POST',
    uri: /^guidelines\/([A-Z]{2})\/sourceOwners$/,
  },
  {
    method: 'POST',
    uri: /^guidelines\/([A-Z]{2})\/major$/,
  },
  {
    method: 'POST',
    uri: /^guidelines\/([A-Z]{2})\/minor$/,
  },
  {
    method: 'GET',
    uri: /^export\/acknowledgements\/([A-Z]{2}|all)$/,
  },
  {
    method: 'GET',
    uri: /^export\/guidelines\/([A-Z]{2})$/,
  },
  {
    method: 'GET',
    uri: /^v2\/export\/travelApprovals$/,
  },
];
