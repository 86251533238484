<ng-template [ngIf]="!hidePanel" [ngIfElse]="field">
  <div class="panel panel-card panel-card-condensed">
    <div class="panel-body">
      <div class="col-md-9"></div>
      <div class="col-md-3">
        <ng-container *ngTemplateOutlet="field"></ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #field>
  <div class="flex-container">
    <label>{{ label }}:</label>
    <sgxb-combo-box
      placeholder="Enter business line name"
      [items]="businessUnit"
      [value]="selectedPointOfView"
      (selected)="onFilterChange($event)"
      (click)="onInputFieldClick($event)"
      (blur)="onInputFieldBlur($event)"
    ></sgxb-combo-box>
  </div>
</ng-template>
