import {
  ComplianceTravelApproval,
  CtaAnswer,
  CtaQuestion,
  CtaQuestionType,
} from '@models/ComplianceTravelApproval';
import { CtaState } from './cta.models';

export function getAnswerForDuplicationSuccess(
  answersFromCta: CtaAnswer[],
  answersFromDuplication: ComplianceTravelApproval
): CtaAnswer[] {
  // For each answers, find the answer from duplication payload.
  // If it doesn't exist, use the answer from configuration.-
  return answersFromCta.map((answerFromCta) => {
    const answerFromDuplication = getAnswerFromDuplicationPayload(
      answersFromDuplication,
      answerFromCta
    );
    const answerToBeUpdated = getAnswerFromDuplicationOrCta(
      answerFromDuplication,
      answerFromCta
    );
    return {
      ...answerFromCta,
      answer: answerToBeUpdated,
      validity: getValidityForDuplicationSuccess(
        answerToBeUpdated,
        answerFromCta.type
      ),
    };
  });
}

export function getAnswerFromDuplicationPayload(
  answersFromDuplication: ComplianceTravelApproval,
  answerFromCta: CtaAnswer
): CtaAnswer {
  return answersFromDuplication.answers.find(
    (answerFromDuplication) =>
      answerFromDuplication.questionUuid === answerFromCta.questionUuid
  );
}

export function getAnswerFromDuplicationOrCta(
  answerFromDuplication: CtaAnswer,
  answerFromCta: CtaAnswer
): string {
  return answerFromDuplication && answerFromDuplication.answer
    ? answerFromDuplication.answer
    : answerFromCta.answer;
}

export function getValidityForDuplicationSuccess(
  answer: string,
  type: CtaQuestionType
): boolean {
  return answer !== null || type === 'PLAIN_TEXT';
}

export function isValidationRequiredWhenInit(
  type: CtaQuestionType,
  presetValue: string
): boolean {
  const hasPresetValue = !(
    presetValue === '' ||
    presetValue === null ||
    presetValue === undefined
  );
  switch (type) {
    case 'PLAIN_TEXT':
      return true;
    case 'EMAIL':
      return hasPresetValue;
    default:
      return false;
  }
}

export function patchUiStateCurrentCtaAnswers(
  state: CtaState,
  questions: CtaQuestion[]
): CtaState {
  return {
    ...state,
    uiState: {
      ...state.uiState,
      current: {
        ...state.uiState.current,
        answers: questions.map((q: CtaQuestion) => {
          return {
            questionLabel: q.description,
            answer: q.presetValue,
            ccEmail: null,
            type: q.type,
            questionUuid: q.uuid,
            validity: isValidationRequiredWhenInit(q.type, q.presetValue),
          };
        }),
      },
    },
  };
}

export function patchUiStateCurrentCtaAnswer(
  state: CtaState,
  uuid: string,
  key: string,
  value: string | boolean
): CtaState {
  const index = state.uiState.current.answers.findIndex(
    (answer) => answer.questionUuid === uuid
  );
  return index === -1
    ? { ...state }
    : {
        ...state,
        uiState: {
          ...state.uiState,
          current: {
            ...state.uiState.current,
            answers: [
              ...state.uiState.current.answers.slice(0, index),
              {
                ...state.uiState.current.answers[index],
                [key]: value,
              },
              ...state.uiState.current.answers.slice(index + 1),
            ],
          },
        },
      };
}
