import { Delegate } from '@models/Delegate';
import { DelegationState } from './delegation.models';
import { User } from '@models/User';
import { ComplianceTravelApproval } from '@models/ComplianceTravelApproval';

export const getAllDelegates = (state: DelegationState): Delegate[] =>
  state.allDelegates;
export const getFetchDelegatesOnGoing = (state: DelegationState): boolean =>
  state.fetchDelegatesOnGoing;
export const getAddDelegateOnGoing = (state: DelegationState): boolean =>
  state.addDelegateOnGoing;
export const getDeleteDelegateOnGoing = (state: DelegationState): boolean =>
  state.deleteDelegateOnGoing;
export const getAllPrincipals = (state: DelegationState): User[] =>
  state.allPrincipals;
export const getFetchPrincipalsOnGoing = (state: DelegationState): boolean =>
  state.fetchPrincipalsOnGoing;
export const getDelegatedCtas = (
  state: DelegationState
): ComplianceTravelApproval[] => state.delegatedCtas;
export const getFetchDelegatedCtasOnGoing = (state: DelegationState): boolean =>
  state.fetchDelegatedCtasOnGoing;
