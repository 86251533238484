import { FormFieldValidator } from '@models/Validator';
import { ValidatorActions } from './validator.actions';
import { ValidatorState, initialValidatorState } from './validator.models';

export function validatorReducer(
  state: ValidatorState = initialValidatorState,
  action: ValidatorActions
): ValidatorState {
  switch (action.type) {
    case 'FETCH_COMMON_FORM_VALIDATOR':
      return {
        ...state,
        commonFormValidator: null,
        supervisorFieldValidator: null,
      };
    case 'FETCH_COMMON_FORM_VALIDATOR_SUCCESS':
      return {
        ...state,
        commonFormValidator: convertForbiddenConstraintsToLowercase(
          action.formValidator
        ),
      };
    case 'FETCH_COMMON_FORM_VALIDATOR_FAIL':
      return {
        ...state,
        commonFormValidator: null,
      };
    case 'FETCH_SUPERVISOR_FIELD_VALIDATOR':
      return {
        ...state,
        supervisorFieldValidator: null,
      };
    case 'FETCH_SUPERVISOR_FIELD_VALIDATOR_SUCCESS':
      return {
        ...state,
        supervisorFieldValidator: action.supervisorFieldValidator,
      };
    case 'FETCH_SUPERVISOR_FIELD_VALIDATOR_FAIL':
      return {
        ...state,
        supervisorFieldValidator: null,
      };
    default: {
      return state;
    }
  }
}

function convertForbiddenConstraintsToLowercase(
  validators: FormFieldValidator[]
) {
  return validators.map((validator) => {
    if (validator.field !== 'manager') {
      return validator;
    }

    const managerConditions = validator.conditions.map((condition) => {
      if (condition.rule !== 'FORBIDDEN') {
        return condition;
      }

      return {
        ...condition,
        constraint: condition.constraint.map((c) => c.toLowerCase()),
      };
    });

    return {
      ...validator,
      conditions: managerConditions,
    };
  });
}
