import { Component, Input } from '@angular/core';
import { ComplianceTravelApproval } from '@models/ComplianceTravelApproval';

@Component({
  selector: 'sgxb-travel-approval-blotter',
  templateUrl: './travel-approval-blotter.component.html',
  styleUrls: ['./travel-approval-blotter.component.scss'],
})
export class TravelApprovalBlotterComponent {
  @Input() public columnDefs: string[];
  @Input() public ctas: ComplianceTravelApproval[];

  constructor() {}
}
