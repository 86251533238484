import * as moment from 'moment';

export const momentDateFormat: string = 'YYYY-MM-DD';

export function convertToDateString(date: Date): string {
  return moment(date).format(momentDateFormat);
}

export function convertToDate(date: string): Date {
  return moment(date, momentDateFormat).toDate();
}
