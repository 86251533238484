<ul
  class="list-group list-group-pills"
  *ngIf="tas && tas.length !== 0; else emptyState"
>
  <ng-content></ng-content>
  <a
    class="list-group-item"
    routerLink="/travel-approval/{{ ta.uuid }}"
    *ngFor="let ta of tas"
    (click)="trackEditCTA(ta)"
  >
    {{ ta.destination.name }}
    <small>
      <span class="hidden-xs"
        >{{ ta.startDate | date: 'MMM d, y' }} -
        {{ ta.endDate | date: 'MMM d, y' }}</span
      >
      <span
        *ngIf="ta.status"
        class="label label-{{ ta.status | ctaStatusColorPalette }}"
        >{{ ta.status }}</span
      >
    </small>
  </a>
</ul>

<ng-template #emptyState>
  <div class="empty-state">
    <i class="pe-7s-plane"></i>
  </div>
</ng-template>
