import { CountriesResponse, Country } from '@models/Country';
import { MOCK_API_PROFILE, MOCK_API_PROFILE_2 } from './profile.mocks';

const dummyCountryDescription = 'Version 01 January 2019';
const dummyDisclaimer =
  "The guidelines are made on the best of compliance knowledge but can't include everything to make it readable therefore any unprecision/questions should be asked directly to compliance.";

export const MOCK_API_COUNTRY_HK: Country = {
  alpha2: 'HK',
  alpha3: 'HKG',
  description: dummyCountryDescription,
  disclaimer: dummyDisclaimer,
  name: 'Hong Kong',
  numeric: 344,
  profiles: [MOCK_API_PROFILE, MOCK_API_PROFILE_2],
  splitReason: 'Entity firewall',
};

export const MOCK_API_COUNTRY_UK: Country = {
  alpha2: 'GB',
  alpha3: 'GBR',
  description: dummyCountryDescription,
  disclaimer: dummyDisclaimer,
  name: 'United Kingdom',
  numeric: 826,
  profiles: [],
};

export const MOCK_API_COUNTRY_CHINA: Country = {
  alpha2: 'CN',
  alpha3: 'CHN',
  description: dummyCountryDescription,
  disclaimer: dummyDisclaimer,
  name: 'China',
  numeric: 156,
  profiles: [MOCK_API_PROFILE],
};

export const MOCK_API_COUNTRY_USA: Country = {
  alpha2: 'US',
  alpha3: 'USA',
  description: dummyCountryDescription,
  disclaimer: dummyDisclaimer,
  name: 'United States of America',
  numeric: 840,
  profiles: [MOCK_API_PROFILE],
};

export const MOCK_API_ALL_COUNTRIES: CountriesResponse = {
  countries: [
    MOCK_API_COUNTRY_HK,
    MOCK_API_COUNTRY_UK,
    MOCK_API_COUNTRY_CHINA,
    MOCK_API_COUNTRY_USA,
  ],
};

export const MOCK_API_ALL_COUNTRIES_WITH_GUIDELINES: CountriesResponse = {
  countries: [MOCK_API_COUNTRY_HK, MOCK_API_COUNTRY_CHINA],
};

export const MOCK_API_PERIMETER_COUNTRIES: CountriesResponse = {
  countries: [MOCK_API_COUNTRY_HK, MOCK_API_COUNTRY_UK],
};

export const MOCK_API_PERIMETER_COUNTRIES_WITH_GUIDELINES: CountriesResponse = {
  countries: [MOCK_API_COUNTRY_HK],
};
