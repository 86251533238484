<div class="form-group" [class.has-error]="touched && !validity">
  <label class="control-label" *ngIf="label">
    <span [innerHTML]="label | nl2br | safeHtml"></span>
    <b *ngIf="required">*</b>
  </label>

  <ng-container *ngIf="options && options.length > 0; else noOptions">
    <div
      class="radio"
      [class.disabled]="disabled"
      *ngFor="let o of options; let i = index"
    >
      <label for="radio-{{ uuid }}-{{ i }}">
        <input
          type="radio"
          id="radio-{{ uuid }}-{{ i }}"
          [name]="uuid"
          [value]="getDisplayText(o)"
          [checked]="selectedIndex === i"
          (click)="onSelect($event, i)"
        />
        <span>{{ getDisplayText(o) }}</span>
      </label>
    </div>
  </ng-container>

  <ng-template #noOptions>
    <div class="radio disabled">
      <label>
        <input type="radio" name="radio" />
        <span>No option is available</span>
      </label>
    </div>
  </ng-template>

  <div class="warnings" *ngIf="touched && !validity">
    <ng-container *ngFor="let warning of warnings">
      {{ warning }}<br />
    </ng-container>
  </div>
</div>
