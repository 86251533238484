import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEndpointOnLAN } from 'app/utils/endpoint.utils';
import { Observable } from 'rxjs';
import * as urlToolkit from 'url-toolkit';

@Injectable()
export class ApiBaseInterceptor implements HttpInterceptor {
  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.match(/^http(s)?:\/\/.+$/)) {
      return next.handle(request);
    }

    const callEndpointOnLAN = isEndpointOnLAN(request.method, request.url);
    const baseUrl = callEndpointOnLAN
      ? window.configurations.backend.baseLAN
      : window.configurations.backend.base;

    const url = urlToolkit.buildAbsoluteURL(baseUrl, request.url, {
      alwaysNormalize: true,
    });
    request = request.clone({ url });
    return next.handle(request);
  }
}
