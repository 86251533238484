import { CtaQuestion } from '@models/ComplianceTravelApproval';
import { domStringToBase64 } from '@shared/utils/encode-decode/encode-decode.utils';

export function encodeCtaQuestionsRequest(
  questions: CtaQuestion[]
): CtaQuestion[] {
  return questions.map((question) => ({
    ...question,
    description: domStringToBase64(question.description),
  }));
}
