import { MenuItem } from '@models/MenuItem';
import { Action } from '@ngrx/store';

export type HeaderActions =
  | AddHeaderItem
  | RemoveHeaderItem
  | RedirectTo
  | RedirectToHome
  | ShowNotification
  | ShowSuccessNotification
  | ShowInfoNotification
  | ShowWarningNotification
  | ShowDangerNotification;

export interface AddHeaderItem extends Action {
  type: 'ADD_HEADER_ITEM';
  menuItem: MenuItem;
}
export function addHeaderItem(menuItem: MenuItem): AddHeaderItem {
  return {
    type: 'ADD_HEADER_ITEM',
    menuItem,
  };
}

export interface RemoveHeaderItem extends Action {
  type: 'REMOVE_HEADER_ITEM';
  key: string;
}
export function removeHeaderItem(key: string): RemoveHeaderItem {
  return {
    type: 'REMOVE_HEADER_ITEM',
    key,
  };
}

export interface RedirectTo extends Action {
  type: 'REDIRECT_TO';
  url: string;
  params: any[];
}
export function redirectTo(url: string, ...params: any[]): RedirectTo {
  return {
    type: 'REDIRECT_TO',
    url,
    params,
  };
}

export interface RedirectWithQueryParamsTo extends Action {
  type: 'REDIRECT_WITH_QUERY_PARAMS_TO';
  url: string;
  queryParams: any;
}
export function redirectWithQueryParamsTo(
  url: string,
  queryParams: any
): RedirectWithQueryParamsTo {
  return {
    type: 'REDIRECT_WITH_QUERY_PARAMS_TO',
    url,
    queryParams,
  };
}

export interface RedirectToHome extends Action {
  type: 'REDIRECT_TO_HOME';
}
export function redirectToHome(): RedirectToHome {
  return {
    type: 'REDIRECT_TO_HOME',
  };
}

export interface ShowNotification extends Action {
  type: 'SHOW_NOTIFICATION';
  notificationType: string;
  message: string;
  isHTML: boolean;
  sticky: boolean;
  dismissible: boolean;
}
export function showNotification(
  type: string,
  message: string,
  isHTML: boolean = false,
  sticky: boolean = false,
  dismissible: boolean = false
): ShowNotification {
  return {
    type: 'SHOW_NOTIFICATION',
    notificationType: type,
    message,
    isHTML,
    sticky,
    dismissible,
  };
}

export interface ShowSuccessNotification extends Action {
  type: 'SHOW_SUCCESS_NOTIFICATION';
  notificationType: string;
  message: string;
  isHTML: boolean;
  sticky: boolean;
  dismissible: boolean;
}
export function showSuccessNotification(
  message: string,
  isHTML: boolean = false,
  sticky: boolean = false,
  dismissible: boolean = true
): ShowSuccessNotification {
  return {
    type: 'SHOW_SUCCESS_NOTIFICATION',
    notificationType: 'success',
    message,
    isHTML,
    sticky,
    dismissible,
  };
}

export interface ShowInfoNotification extends Action {
  type: 'SHOW_INFO_NOTIFICATION';
  notificationType: string;
  message: string;
  isHTML: boolean;
  sticky: boolean;
  dismissible: boolean;
}
export function showInfoNotification(
  message: string,
  isHTML: boolean = false,
  sticky: boolean = false,
  dismissible: boolean = true
): ShowInfoNotification {
  return {
    type: 'SHOW_INFO_NOTIFICATION',
    notificationType: 'info',
    message,
    isHTML,
    sticky,
    dismissible,
  };
}

export interface ShowWarningNotification extends Action {
  type: 'SHOW_WARNING_NOTIFICATION';
  notificationType: string;
  message: string;
  isHTML: boolean;
  sticky: boolean;
  dismissible: boolean;
}
export function showWarningNotification(
  message: string,
  isHTML: boolean = false,
  sticky: boolean = false,
  dismissible: boolean = true
): ShowWarningNotification {
  return {
    type: 'SHOW_WARNING_NOTIFICATION',
    notificationType: 'warning',
    message,
    isHTML,
    sticky,
    dismissible,
  };
}

export interface ShowDangerNotification extends Action {
  type: 'SHOW_DANGER_NOTIFICATION';
  notificationType: string;
  message: string;
  isHTML: boolean;
  sticky: boolean;
  dismissible: boolean;
}
export function showDangerNotification(
  message: string,
  isHTML: boolean = false,
  sticky: boolean = false,
  dismissible: boolean = true
): ShowDangerNotification {
  return {
    type: 'SHOW_DANGER_NOTIFICATION',
    notificationType: 'danger',
    message,
    isHTML,
    sticky,
    dismissible,
  };
}
