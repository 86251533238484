import { Validator } from '@models/Validator';

export function warningMessagesGenerator(
  warningsArray: string[],
  validatorsArray: Validator[],
  conditions: any[]
) {
  return validatorsArray.reduce<string[]>((accumulator, validator, index) => {
    const { message } = validator;

    if (conditions[index] && conditions[index].shouldRaiseWarning) {
      accumulator.push(message);
    }
    return accumulator;
  }, warningsArray);
}
