import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  MOCK_API_DELEGATES,
  MOCK_API_DELEGATE_POST_RESPONSE,
} from '@mock-api/mocks/delegate.mocks';
import { ok } from 'app/mock-api/utils/response.utils';
import { Observable, of } from 'rxjs';
import { delay, dematerialize, materialize, mergeMap } from 'rxjs/operators';
import { MOCK_API_USER_2 } from '@mock-api/mocks/user.mocks';

@Injectable()
export class DelegationRequestInterceptor implements HttpInterceptor {
  constructor() {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return <Observable<HttpEvent<any>>>of(null).pipe(
      mergeMap(() => {
        if (request.url.match(/^\/delegates$/) && request.method === 'GET') {
          return ok(MOCK_API_DELEGATES);
        } else if (
          request.url.match(/^\/delegates$/) &&
          request.method === 'POST'
        ) {
          return ok(MOCK_API_DELEGATE_POST_RESPONSE);
        } else if (
          request.url.match(/^\/delegates\/([0-9a-zA-Z\-]+)$/) &&
          request.method === 'DELETE'
        ) {
          return ok(undefined);
        } else if (
          request.url.endsWith('/principals') &&
          request.method === 'GET'
        ) {
          return ok([MOCK_API_USER_2]);
        }
        return next.handle(request);
      }),
      materialize(),
      delay(300),
      dematerialize()
    );
  }
}
