<div class="form-group" [class.has-error]="touched && !validity">
  <label class="control-label" *ngIf="label">
    <span [innerHTML]="label | nl2br | safeHtml"></span>
    <b *ngIf="required">*</b>
  </label>

  <textarea
    class="form-control"
    [rows]="rows"
    [value]="value"
    [attr.maxlength]="maxlength"
    [placeholder]="placeholder"
    [disabled]="disabled"
    (focus)="onFocus($event)"
    (blur)="onBlur($event)"
    (input)="onInput($event)"
  ></textarea>

  <div class="warnings" *ngIf="touched && !validity">
    <ng-container *ngFor="let warning of warnings">
      {{ warning }}<br />
    </ng-container>
  </div>
</div>
