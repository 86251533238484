import { Component, Input } from '@angular/core';
import { ComplianceTravelApproval } from '@models/ComplianceTravelApproval';

@Component({
  selector: 'sgxb-travel-approval-variable-details',
  templateUrl: './travel-approval-variable-details.component.html',
  styleUrls: ['./travel-approval-variable-details.component.scss'],
})
export class TravelApprovalVariableDetailsComponent {
  @Input() public ta: ComplianceTravelApproval;

  constructor() {}
}
