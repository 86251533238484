import {
  ComplianceTravelApproval,
  CtaAnswer,
  CtaOption,
  CtaQuestionCondition,
  CtaStats,
} from '@models/ComplianceTravelApproval';
import { CtaSpecificState } from '@store/cta-specific/cta-specific.models';
import { getQuestionByUUID } from '@store/cta-specific/cta-specific.selectors';
import * as moment from 'moment';
import { CtaState, initialCtaState } from './cta.models';
import {
  getExpectedAnswerByOptionUUID,
  getIsAnswerMatched,
} from './cta.selectors.helper';

export const getAllCtas = (state: CtaState): ComplianceTravelApproval[] =>
  state ? state.all : initialCtaState.all;
export const getCtaStats = (state: CtaState): CtaStats =>
  state ? state.stats : initialCtaState.stats;
export const getCurrentCta = (state: CtaState): ComplianceTravelApproval =>
  state ? state.uiState.current : initialCtaState.current;
export const getFetchCtaOnGoing = (state: CtaState): boolean =>
  state ? state.fetchOnGoing : initialCtaState.fetchOnGoing;
export const getLatestCtas = (state: CtaState): ComplianceTravelApproval[] =>
  state ? state.latest : initialCtaState.latest;
export const getSubmitCtaOnGoing = (state: CtaState): boolean =>
  state ? state.submitOnGoing : initialCtaState.submitOnGoing;
export const getSpecificCtaForApproval = (
  state: CtaState
): ComplianceTravelApproval =>
  state ? state.specificCtaForApproval : initialCtaState.specificCtaForApproval;
export const getCtasForApproval = (
  state: CtaState
): ComplianceTravelApproval[] =>
  state ? state.forApproval : initialCtaState.forApproval;
export const getCtasAlreadyProcessed = (
  state: CtaState
): ComplianceTravelApproval[] =>
  state ? state.alreadyProcessed : initialCtaState.alreadyProcessed;
export const getApprovalCtaOnGoing = (state: CtaState): boolean =>
  state ? state.approvalOnGoing : initialCtaState.approvalOnGoing;
export const getRejectModeEnabled = (state: CtaState): boolean =>
  state
    ? state.uiState.rejectModeEnabled
    : initialCtaState.uiState.rejectModeEnabled;
export const getSelectedCtaForApprovalIndex = (state: CtaState): number =>
  state
    ? state.uiState.selectedCtaForApprovalIndex
    : initialCtaState.uiState.selectedCtaForApprovalIndex;
export const getWorkingDaysMessage = (state: CtaState): any =>
  state
    ? state.uiState.workingDaysMessage
    : initialCtaState.uiState.workingDaysMessage;
export const getSupervisor = (state: CtaState): string =>
  state ? state.current.supervisor : null;
export const getFetchSupervisorOnGoing = (state: CtaState): boolean =>
  state ? state.fetchOnGoing : initialCtaState.fetchOnGoing;
export const getLateDeclarationWarning = (state: CtaState): any =>
  state
    ? state.uiState.lateDeclarationWarning
    : initialCtaState.uiState.lateDeclarationWarning;
export const getCtaForDuplication = (
  state: CtaState
): ComplianceTravelApproval =>
  state ? state.forDuplication : initialCtaState.forDuplication;
export const getExportCtaDashboardOnGoing = (state: CtaState): boolean => {
  return state.exportCtaDashboardOnGoing;
};
export const getFetchStatsOnGoing = (state: CtaState): boolean =>
  state ? state.fetchStatsOnGoing : initialCtaState.fetchStatsOnGoing;

/**
 * cta page
 */
export const getCtaAnswerByUUID = (
  state: CtaState,
  uuid: string
): CtaAnswer | undefined =>
  state &&
  state.uiState &&
  state.uiState.current &&
  state.uiState.current.answers
    ? state.uiState.current.answers.find((ans) => ans.questionUuid === uuid)
    : undefined;

export const getCtaAnswers = (state: CtaState): CtaAnswer[] | undefined =>
  state &&
  state.uiState &&
  state.uiState.current &&
  state.uiState.current.answers
    ? state.uiState.current.answers
    : undefined;

export const getIsQuestionVisible = (
  ctaState: CtaState,
  ctaSpecificState: CtaSpecificState,
  uuid: string
): boolean => {
  const question = getQuestionByUUID(ctaSpecificState, uuid);
  if (!question || !ctaState || question.isHiddenInPreview) {
    return false;
  }
  const hasConditions =
    !question.conditions || question.conditions.length === 0;
  if (hasConditions) {
    return true;
  } else {
    const conditionsMatchingResult = getConditionsMatchingResult(
      ctaState,
      ctaSpecificState,
      question.conditions
    );
    return question.useOr
      ? conditionsMatchingResult.some((result) => !!result)
      : conditionsMatchingResult.every((result) => !!result);
  }
};

export const getConditionsMatchingResult = (
  ctaState: CtaState,
  ctaSpecificState: CtaSpecificState,
  conditions: CtaQuestionCondition[]
): boolean[] =>
  // 1. We check all the conditions, because one question can have more one conditions
  conditions.map((condition) => {
    const conditionQuestion = getQuestionByUUID(
      ctaSpecificState,
      condition.question
    );
    if (!conditionQuestion) {
      return false;
    }
    // 2. For each questions, we find the expected answer and actual answer
    const expectedAnswer = getExpectedAnswerByOptionUUID(
      conditionQuestion,
      condition.option
    );
    const actualAnswer = getCtaAnswerByUUID(ctaState, condition.question);

    // 3. We match the answer, multiple answers require special handlings, we need to use \r\n to split it.
    // 4. We return a list of array [true, false, true] to know which questions are matched
    return getIsAnswerMatched(actualAnswer, expectedAnswer);
  });

export const getAnswer = (
  ctaState: CtaState,
  ctaSpecificState: CtaSpecificState,
  uuid: string
): string | Date | CtaOption | CtaOption[] | boolean | undefined => {
  const answer = getCtaAnswerByUUID(ctaState, uuid);
  const question = getQuestionByUUID(ctaSpecificState, uuid);
  if (!answer || !question) {
    return null;
  }
  switch (answer.type) {
    case 'INPUT':
    case 'EMAIL':
      return answer.answer;
    case 'TEXTAREA':
      return answer.answer;
    case 'DATE':
      return answer.answer
        ? moment(answer.answer, 'DD MMM YYYY').toDate()
        : null;
    case 'SINGLE_SELECT':
      return answer.answer
        ? question.options.find((o: CtaOption) => o.text === answer.answer)
        : null;
    case 'MULTI_SELECT':
      if (!answer.answer) {
        return null;
      }
      return answer.answer.split('\r\n').map((text: string) => {
        const option = question.options.find((o: CtaOption) => o.text === text);
        return option ? option : null;
      });
    case 'ACKNOWLEDGEMENT':
      return answer.answer && answer.answer === 'Yes' ? true : false;
    default:
      return answer.answer;
  }
};

export const getIsVariableQuestionnaireValidated = (
  ctaState: CtaState,
  ctaSpecificState: CtaSpecificState
): boolean => {
  const answers = getCtaAnswers(ctaState);
  return answers
    ? answers
        .filter((answer) =>
          getIsQuestionVisible(ctaState, ctaSpecificState, answer.questionUuid)
        )
        .every((answer) => answer.validity === true)
    : false;
};

export const getIsEmailFieldCcCheckboxChecked = (
  ctaState: CtaState,
  ctaSpecificState: CtaSpecificState,
  uuid: string
): boolean => {
  const question = getQuestionByUUID(ctaSpecificState, uuid);
  const answer = getCtaAnswerByUUID(ctaState, uuid);
  return question && answer ? question.isCCMandatory || answer.ccEmail : false;
};

export const getCurrentCtaWithVisibleQuestion = (
  ctaState: CtaState,
  ctaSpecificState: CtaSpecificState
): ComplianceTravelApproval => {
  return ctaState && ctaState.uiState && ctaState.uiState.current
    ? {
        ...ctaState.uiState.current,
        answers: ctaState.uiState.current.answers.filter(
          (answer) =>
            getIsQuestionVisible(
              ctaState,
              ctaSpecificState,
              answer.questionUuid
            ) === true
        ),
      }
    : initialCtaState.current;
};

export const getCurrentCtaQuestion = (
  ctaState: CtaState
): ComplianceTravelApproval => {
  return ctaState && ctaState.uiState && ctaState.uiState.current
    ? ctaState.uiState.current
    : initialCtaState.current;
};
