import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Validator, ValidatorRule } from '@models/Validator';
import { warningMessagesGenerator } from '@store/validator/validator.helper';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'sgxb-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
})
export class RadioGroupComponent implements OnInit {
  @Input() public options: any[] = null;
  @Input() public displayWith: string = null;
  @Input() public label: string = null;
  @Input() public validators: Validator[] = [];
  @Output() public selected = new EventEmitter();
  @Output() public validate = new EventEmitter<boolean>();

  public uuid = uuidv4();
  public touched = false;
  public warnings: string[] = [];
  public selectedItem = null;
  public selectedIndex = null;
  public validity = false;
  private _value = null;
  private _disabled = false;
  @Input() set value(v) {
    this._value = v;
    if (v === null) {
      this.selectOption(null);
    } else {
      if (this.options) {
        this.selectOption(
          this.options.findIndex((o: any) => {
            return o === v;
          })
        );
      }
    }
  }
  get value() {
    return this._value;
  }
  @Input() set disabled(value: boolean) {
    this._disabled = value;
    this.touched = false;
    if (this._disabled) {
      this.validate.emit(true);
    }
  }
  get disabled(): boolean {
    return this._disabled;
  }

  constructor() {}

  public ngOnInit() {
    if (this.value && this.options) {
      this.selectOption(
        this.options.findIndex((o: any) => {
          return o === this.value;
        })
      );
    }
  }

  get required(): boolean {
    return (
      !this.disabled &&
      this.validators
        .map((o: Validator) => o.rule)
        .indexOf(ValidatorRule.REQUIRED) !== -1
    );
  }

  public checkValidity(): void {
    this.warnings = [];
    if (!this.disabled) {
      const conditions = this.validators
        .map((validator) => {
          switch (validator.rule) {
            case ValidatorRule.REQUIRED: {
              return {
                rule: validator.rule,
                shouldRaiseWarning: !this.selectedItem,
              };
            }
          }
        })
        .filter((o) => o !== null && o !== undefined);
      this.warnings = warningMessagesGenerator(
        this.warnings,
        this.validators,
        conditions
      );
    }
    this.validity = this.warnings && this.warnings.length === 0;
  }

  @HostListener('click')
  public hostClick() {
    if (this.disabled) {
      return;
    }
    this.touched = true;
  }

  public onSelect(_event, index: number): void {
    const isIndexValid = index != null && index >= 0;
    if (isIndexValid && this.options) {
      this.selected.emit(this.options[index]);
      this.selectedItem = this.options[index];
      this.selectedIndex = index;
      this.touched = index !== null && index !== undefined ? true : false;
      this.checkValidity();
      this.validate.emit(this.validity);
    }
  }

  public getDisplayText(item: any): string {
    return this.displayWith
      ? item[this.displayWith]
        ? item[this.displayWith]
        : null
      : item;
  }

  private selectOption(index: number): void {
    if (!this.options) {
      return;
    }
    this.selectedItem = this.options[index];
    this.selectedIndex = index;
  }
}
