<sgxb-input
  [label]="q.description"
  [maxlength]="255"
  [value]="answer$ | async"
  [validators]="validators"
  [disabled]="isEmailFieldDisabled$ | async"
  (input)="onEmailFieldInput($event)"
  (validate)="onValidate($event)"
>
  <sgxb-checkbox
    *ngIf="isCcCheckboxVisible$ | async"
    label="CC"
    class="cc-checkbox"
    title="CC him / her this Travel Approval Request"
    data-toggle="tooltip"
    data-placement="top"
    [value]="isCcCheckboxChecked$ | async"
    [validators]="[]"
    (change)="onEmailCCCheckboxChange($event)"
    #emailCcCheckbox
  >
  </sgxb-checkbox>
</sgxb-input>
