import { Delegate, DelegatesResponse } from '@models/Delegate';

export const MOCK_API_DELEGATE_1: Delegate = {
  delegateEmail: 'delegate1@sgcib.com',
  uuid: '37437f2a-1c0f-4a38-b5ed-a7ad4fcec3ed',
};
export const MOCK_API_DELEGATE_2: Delegate = {
  delegateEmail: 'delegate2@sgcib.com',
  uuid: '935b1d43-5d3f-44f2-bb25-4a8dba4f7e75',
};

export const MOCK_API_DELEGATE_POST_RESPONSE: Delegate = {
  delegateEmail: 'new.delegate@sgcib.com',
  uuid: '111fecd8-d35b-4132-82d8-266714afb771',
};

export const MOCK_API_DELEGATES: DelegatesResponse = {
  delegates: [MOCK_API_DELEGATE_1, MOCK_API_DELEGATE_2],
};
