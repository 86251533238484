import { HttpErrorResponse } from '@angular/common/http';
import { Acknowledgement } from '@models/Acknowledgement';
import { Country, Profile } from '@models/Country';
import { Guideline } from '@models/Guideline';
import { Action } from '@ngrx/store';

export type GuidelineViewActions =
  | Acknowledge
  | AcknowledgeFail
  | AcknowledgeSuccess
  | FetchGuideline
  | FetchGuidelineFail
  | FetchGuidelineSuccess
  | FetchDisplaySubmitCaseAndProfiles
  | FetchDisplaySubmitCaseAndProfilesSuccess
  | FetchDisplaySubmitCaseAndProfilesFail
  | SubmitQuestion
  | SubmitQuestionDone
  | ToggleMailPopin
  | GuidelineViewFilterSelect
  | GuidelineViewFilterReset;

export interface Acknowledge extends Action {
  type: 'ACKNOWLEDGE';
  country: Country;
}
export function acknowledge(country: Country): Acknowledge {
  return { type: 'ACKNOWLEDGE', country };
}

export interface AcknowledgeFail extends Action {
  type: 'ACKNOWLEDGE_FAIL';
  payload: any;
}
export function acknowledgeFail(payload: any): AcknowledgeFail {
  return { type: 'ACKNOWLEDGE_FAIL', payload };
}

export interface AcknowledgeSuccess extends Action {
  type: 'ACKNOWLEDGE_SUCCESS';
  acknowledgement: Acknowledgement;
}
export function acknowledgeSuccess(
  acknowledgement: Acknowledgement
): AcknowledgeSuccess {
  return { type: 'ACKNOWLEDGE_SUCCESS', acknowledgement };
}

export interface FetchGuideline extends Action {
  type: 'FETCH_GUIDELINE';
  countryCode: string;
  businessUnit?: string;
}
export function fetchGuideline(
  countryCode: string,
  businessUnit?: string
): FetchGuideline {
  return { type: 'FETCH_GUIDELINE', countryCode, businessUnit };
}

export interface FetchGuidelineSuccess extends Action {
  type: 'FETCH_GUIDELINE_SUCCESS';
  guideline: Guideline;
}
export function fetchGuidelineSuccess(
  guideline: Guideline
): FetchGuidelineSuccess {
  return { type: 'FETCH_GUIDELINE_SUCCESS', guideline };
}

export interface FetchGuidelineFail extends Action {
  type: 'FETCH_GUIDELINE_FAIL';
  error: HttpErrorResponse;
}
export function fetchGuidelineFail(
  error: HttpErrorResponse
): FetchGuidelineFail {
  return { type: 'FETCH_GUIDELINE_FAIL', error };
}

export interface FetchDisplaySubmitCaseAndProfiles extends Action {
  type: 'FETCH_DISPLAY_SUBMIT_CASE_AND_PROFILES';
}
export function fetchDisplaySubmitCaseAndProfiles(): FetchDisplaySubmitCaseAndProfiles {
  return { type: 'FETCH_DISPLAY_SUBMIT_CASE_AND_PROFILES' };
}

export interface FetchDisplaySubmitCaseAndProfilesSuccess extends Action {
  type: 'FETCH_DISPLAY_SUBMIT_CASE_AND_PROFILES_SUCCESS';
  payload: {
    canSubmitPracticalCases: boolean;
    profiles: Profile[];
  };
}
export function fetchDisplaySubmitCaseAndProfilesSuccess(
  payload: any
): FetchDisplaySubmitCaseAndProfilesSuccess {
  return { type: 'FETCH_DISPLAY_SUBMIT_CASE_AND_PROFILES_SUCCESS', payload };
}

export interface FetchDisplaySubmitCaseAndProfilesFail extends Action {
  type: 'FETCH_DISPLAY_SUBMIT_CASE_AND_PROFILES_FAIL';
  error: HttpErrorResponse;
}
export function fetchDisplaySubmitCaseAndProfilesFail(
  error: HttpErrorResponse
): FetchDisplaySubmitCaseAndProfilesFail {
  return { type: 'FETCH_DISPLAY_SUBMIT_CASE_AND_PROFILES_FAIL', error };
}

export interface SubmitQuestion extends Action {
  type: 'SUBMIT_QUESTION';
  profile: string;
  question: string;
}
export function submitQuestion(
  profile: string,
  question: string
): SubmitQuestion {
  return { type: 'SUBMIT_QUESTION', profile, question };
}

export interface SubmitQuestionDone extends Action {
  type: 'SUBMIT_QUESTION_DONE';
  status: boolean;
  message: string;
}
export function submitQuestionDone(
  status: boolean,
  message: string
): SubmitQuestionDone {
  return { type: 'SUBMIT_QUESTION_DONE', status, message };
}

export interface ToggleMailPopin extends Action {
  type: 'TOGGLE_MAIL_POPIN';
  payload: boolean;
}
export function toggleMailPopin(payload: boolean) {
  return { type: 'TOGGLE_MAIL_POPIN', payload };
}

export interface GuidelineViewFilterSelect extends Action {
  type: 'GUIDELINE_VIEW_FILTER_SELECT';
  businessUnit: string;
}
export function guidelineViewFilterSelect(
  businessUnit: string
): GuidelineViewFilterSelect {
  return { type: 'GUIDELINE_VIEW_FILTER_SELECT', businessUnit };
}

export interface GuidelineViewFilterReset extends Action {
  type: 'GUIDELINE_VIEW_FILTER_RESET';
}
export function guidelineViewFilterReset(): GuidelineViewFilterReset {
  return { type: 'GUIDELINE_VIEW_FILTER_RESET' };
}
