import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { Validator, ValidatorRule } from '@models/Validator';
import { warningMessagesGenerator } from '@store/validator/validator.helper';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'sgxb-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss'],
})
export class CheckboxGroupComponent {
  @Input() public options: any[] = null;
  @Input() public displayWith: string = null;
  @Input() public label: string = null;
  @Input() public validators: Validator[] = [];
  @Output() public add = new EventEmitter();
  @Output() public remove = new EventEmitter();
  @Output() public validate = new EventEmitter<boolean>();

  public uuid = uuidv4();
  public touched = false;
  public warnings: string[] = [];
  public selectedIndexes: number[] = [];
  private _disabled = false;
  @Input() set value(v: any[]) {
    if (!v) {
      return;
    }
    this.selectedIndexes = v.map((i) => i.ordering);
  }
  @Input() set disabled(value: boolean) {
    this._disabled = value;
    this.touched = false;
    if (this._disabled) {
      this.validate.emit(true);
    }
  }
  get disabled(): boolean {
    return this._disabled;
  }

  constructor() {}

  get required(): boolean {
    return (
      !this.disabled &&
      this.validators
        .map((o: Validator) => o.rule)
        .indexOf(ValidatorRule.REQUIRED) !== -1
    );
  }

  get validity(): boolean {
    this.warnings = [];
    if (!this.disabled) {
      const conditions = this.validators
        .map((validator) => {
          switch (validator.rule) {
            case ValidatorRule.REQUIRED: {
              return {
                rule: validator.rule,
                shouldRaiseWarning:
                  !this.selectedIndexes || this.selectedIndexes.length === 0,
              };
            }
            default:
              return;
          }
        })
        .filter((o) => o !== null && o !== undefined);
      this.warnings = warningMessagesGenerator(
        this.warnings,
        this.validators,
        conditions
      );
    }
    const valid = this.warnings && this.warnings.length === 0;
    return valid;
  }

  @HostListener('click')
  public hostClick() {
    if (this.disabled) {
      return;
    }
    this.touched = true;
  }

  public onToggle(_event: MouseEvent, index: number): void {
    this.toggleOption(index != null && index >= 0 ? index : null);
  }

  public getDisplayText(item: any): string {
    return this.displayWith
      ? item[this.displayWith]
        ? item[this.displayWith]
        : null
      : item;
  }

  public isSelected(index: number): boolean {
    return this.selectedIndexes && this.selectedIndexes.indexOf(index) > -1;
  }

  private toggleOption(index: number): void {
    if (!this.options || index == null || index < 0) {
      return;
    }
    const wasChecked = this.selectedIndexes.indexOf(index) >= 0;

    if (wasChecked) {
      this.remove.emit(this.options[index]);
      this.selectedIndexes = this.selectedIndexes.filter(
        (selectedIndex) => selectedIndex !== index
      );
      this.validate.emit(this.validity);
    } else {
      this.add.emit(this.options[index]);
      this.selectedIndexes.push(index);
      this.validate.emit(this.validity);
    }
  }
}
