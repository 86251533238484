import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  ViewChild,
} from '@angular/core';
import { Validator, ValidatorRule } from '@models/Validator';
import { REGEX } from '@shared/utils';
import { warningMessagesGenerator } from '@store/validator/validator.helper';

@Component({
  selector: 'sgxb-tag-input',
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.scss'],
})
export class TagInputComponent {
  @ViewChild('input', { static: false }) public input: ElementRef;
  @ViewChild('suggestionsBox', { static: false })
  public suggestionsBox: ElementRef;

  @Input() public items: any[] = null;
  @Input() public displayWith: string = null;
  @Input() public placeholder = '';
  @Input() public warning = '';
  @Input() public label = null;
  @Output() public blur = new EventEmitter<FocusEvent>();
  @Output() public focus = new EventEmitter<FocusEvent>();
  @Output() public addTag = new EventEmitter<any>();
  @Output() public removeTag = new EventEmitter<any>();
  @Output() public validate = new EventEmitter<boolean>();

  private _validators: Validator[];
  private _disabled = false;
  private _tags: any[] = [];
  public warnings: string[] = [];
  public inputValue = '';
  public isActive = false;
  public touched = false;
  public selectedItem = null;
  public suggestionIndex = 0;
  @Input() set tags(tags: any[]) {
    this._tags = tags;
    if (tags && tags.length > 0) {
      this.touched = true;
    }
  }
  get tags(): any[] {
    return this._tags;
  }
  @Input()
  set validators(validators: Validator[]) {
    this._validators = validators || null;
  }
  get validators(): Validator[] {
    return this._validators;
  }

  @Input() set disabled(value: boolean) {
    this._disabled = value;
    this.touched = false;
    if (this._disabled) {
      this.validate.emit(true);
    }
  }
  get disabled(): boolean {
    return this._disabled;
  }

  constructor(private ngZone: NgZone) {}

  get required(): boolean {
    if (this.validators) {
      return (
        !this.disabled &&
        !!this.validators.find(
          (validator) => validator.rule === ValidatorRule.REQUIRED
        )
      );
    }
    return !this.disabled;
  }

  get validity(): boolean {
    this.warnings = [];
    if (!this.disabled && this.validators) {
      const conditions = this.validators
        .map((validator) => {
          switch (validator.rule) {
            case ValidatorRule.REGEX: {
              return {
                rule: validator.rule,
                shouldRaiseWarning:
                  this.inputValue !== null &&
                  this.inputValue !== '' &&
                  (validator.constraint
                    ? !REGEX.internalEmail.test(this.inputValue)
                    : true),
              };
            }
            case ValidatorRule.REQUIRED: {
              return {
                rule: validator.rule,
                shouldRaiseWarning: !this.tags || this.tags.length === 0,
              };
            }
            case ValidatorRule.UNIQUE: {
              return {
                rule: validator.rule,
                shouldRaiseWarning:
                  new Set(this.tags.map((o: string) => o.toLowerCase()))
                    .size !== this.tags.length ||
                  this.tags
                    .map((o: string) => o.toLowerCase())
                    .indexOf(this.inputValue.toLowerCase()) !== -1,
              };
            }
            default:
              return;
          }
        })
        .filter((o) => o !== null && o !== undefined);
      this.warnings = warningMessagesGenerator(
        this.warnings,
        this.validators,
        conditions
      );
    }
    const valid =
      this.disabled ||
      (!!this.validators && this.warnings && this.warnings.length === 0);
    this.validate.emit(valid);
    return valid;
  }

  get suggestions() {
    if (!this.items || this.inputValue.length === 0) {
      return null;
    }
    const suggestions = this.items.filter((item: any): boolean => {
      const text = this.displayWith ? item[this.displayWith] : item;
      return text.toLowerCase().indexOf(this.inputValue.toLowerCase()) !== -1;
    });

    if (suggestions.length) {
      const firstSuggestion = this.displayWith
        ? suggestions[0][this.displayWith]
        : suggestions[0];
      if (suggestions.length === 1 && this.inputValue === firstSuggestion) {
        return suggestions;
      }
    }

    this.selectedItem = null;
    if (this.suggestionIndex >= suggestions.length) {
      this.suggestionIndex = 0;
    }
    return suggestions;
  }

  public tagsTrackingFn(index: number) {
    return index;
  }

  public onFocus(event: FocusEvent): void {
    this.isActive = true;
    this.focus.emit(event);
  }

  public onBlur(event: FocusEvent): void {
    this.blur.emit(event);
    this.onLeave();
    if (this.inputValue && this.inputValue !== '') {
      this.addNewTag();
    }
  }

  public onTagClick(_event: MouseEvent, index: number): void {
    if (this.disabled) {
      return;
    }
    this.removeTagAtIndex(index);
  }

  public onEnter(event: KeyboardEvent): void {
    event.preventDefault();
    this.touched = true;
    this.selectSuggestion();
    this.addNewTag();
  }

  public onEsc(_event: KeyboardEvent): void {
    this.clearInputValue();
  }

  public onBackspace(_event: KeyboardEvent): void {
    this.removeLastTagIfInputEmpty();
  }

  public onTab(_event: KeyboardEvent): void {
    this.input.nativeElement.blur();
  }

  public onLeave(): void {
    this.touched = true;
    this.isActive = false;
  }

  public onArrowUp(_event: KeyboardEvent): void {
    this.changeSuggestion(
      (this.suggestions.length + this.suggestionIndex - 1) %
        this.suggestions.length
    );
  }

  public onArrowDown(_event: KeyboardEvent): void {
    this.changeSuggestion((this.suggestionIndex + 1) % this.suggestions.length);
  }

  public onSuggestionClick() {
    event.preventDefault();
    this.selectSuggestion();
  }

  private addNewTag(): void {
    if (!this.inputValue || this.inputValue === '') {
      return;
    }
    if (
      !!this.validators.find(
        (validator) => validator.rule === ValidatorRule.REQUIRED
      ) &&
      this.tags
        .map((o: string) => o.toLowerCase())
        .indexOf(this.inputValue.toLowerCase()) !== -1
    ) {
      this.touched = true;
    }
    if (this.validity) {
      this.addTag.emit(this.inputValue);
      this.clearInputValue();
    }
  }

  private removeTagAtIndex(index: number): void {
    this.removeTag.emit(index);
  }

  private removeLastTagIfInputEmpty(): void {
    if (this.tags && this.tags.length > 0 && this.inputValue === '') {
      this.removeTagAtIndex(this.tags.length - 1);
    }
  }

  private clearInputValue(): void {
    this.inputValue = '';
  }

  private selectSuggestion(): void {
    if (!this.suggestions || !this.suggestions.length) {
      return;
    }
    const item = this.suggestions[this.suggestionIndex];
    this.inputValue = '';
    this.suggestionIndex = 0;
    this.selectedItem = this.displayWith ? item[this.displayWith] : item;
    this.addTag.emit(this.selectedItem);
    this.focusInput();
  }

  private changeSuggestion(newIndex) {
    this.ngZone.runOutsideAngular(() => {
      if (!this.suggestionsBox) {
        return;
      }
      if (!this.isActive) {
        return;
      }
      if (!this.suggestions.length) {
        return;
      }
      const elem = this.suggestionsBox.nativeElement.children[newIndex];
      const rect = elem.getBoundingClientRect();
      if (
        newIndex < this.suggestionIndex ||
        rect.top < 0 ||
        rect.left < 0 ||
        rect.bottom >
          (window.innerHeight || document.documentElement.clientHeight) ||
        rect.right > (window.innerWidth || document.documentElement.clientWidth)
      ) {
        elem.scrollIntoView(false);
      }
      this.ngZone.run(() => (this.suggestionIndex = newIndex));
    });
  }

  private focusInput(): void {
    this.input.nativeElement.focus();
  }
}
