<div class="form-group" [class.has-error]="touched && !validity">
  <div class="checkbox">
    <label for="checkbox-{{ uuid }}">
      <input
        type="checkbox"
        id="checkbox-{{ uuid }}"
        [name]="uuid"
        [value]="value"
        [checked]="value"
        [disabled]="disabled"
        (click)="onChange($event)"
      />
      <span [innerHTML]="label | nl2br | safeHtml"></span>
      <b *ngIf="required">*</b>
    </label>

    <div class="warnings" *ngIf="touched && !validity">
      <ng-container *ngFor="let warning of warnings">
        {{ warning }}<br />
      </ng-container>
    </div>
  </div>
</div>
