import { FormFieldValidator } from '@models/Validator';

export interface ValidatorState {
  commonFormValidator: FormFieldValidator[];
  supervisorFieldValidator: FormFieldValidator;
}

export const initialValidatorState: ValidatorState = {
  commonFormValidator: null,
  supervisorFieldValidator: null,
};
