<button
  type="button"
  class="btn btn-primary btn-toggle"
  data-toggle="modal"
  data-target="#sgxb-modal"
  #toggleButton
></button>

<div
  class="modal fade"
  id="sgxb-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="sgxb-modal-label"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="sgxb-modal-label">
          {{ modal.title }}
        </h4>
      </div>
      <div class="modal-body" [innerHTML]="modal.content | safeHtml"></div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          (click)="dismiss()"
          data-dismiss="modal"
        >
          No
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="ok()"
          data-dismiss="modal"
        >
          Yes
        </button>
      </div>
    </div>
  </div>
</div>
