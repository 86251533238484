import { EndpointLocation } from '@models/EndpointLocation';
import { lanEndpoints } from 'app/configuration';

export const getEndpointLocation = (
  method: string,
  url: string
): EndpointLocation => {
  return lanEndpoints.some(
    (endpoint) => endpoint.method === method && endpoint.uri.test(url)
  )
    ? 'LAN'
    : 'AZURE';
};

export const isEndpointOnLAN = (method: string, url: string): boolean => {
  return getEndpointLocation(method, url) === 'LAN';
};
