<h6 id="tar-blotter">FOR INFORMATION - TARs already processed</h6>
<div class="cta-already-processed-blotter">
  <table
    class="table table-condensed table-bordered table-striped"
    aria-describedby="tar-blotter"
  >
    <thead>
      <tr>
        <th *ngFor="let columnName of columnDefs">{{ columnName }}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        class="table-row cta-already-processed-row"
        *ngFor="let ctaAlreadyProcessed of ctas; index as i"
      >
        <td>
          {{ ctaAlreadyProcessed.user.firstName }}
          {{ ctaAlreadyProcessed.user.lastName }}
        </td>
        <td>{{ ctaAlreadyProcessed.user.department }}</td>
        <td>{{ ctaAlreadyProcessed.origin.name }}</td>
        <td>{{ ctaAlreadyProcessed.destination.name }}</td>
        <td>{{ ctaAlreadyProcessed.startDate | date: 'MMM d, y' }}</td>
        <td>{{ ctaAlreadyProcessed.endDate | date: 'MMM d, y' }}</td>
        <td class="cta-status-column">
          <span
            class="label label-{{
              ctaAlreadyProcessed.status | ctaStatusColorPalette
            }}"
            >{{ ctaAlreadyProcessed.status }}
          </span>
        </td>
        <td class="cta-comments-column">
          {{
            ctaAlreadyProcessed.rejectReason &&
            ctaAlreadyProcessed.rejectReason !== ''
              ? ctaAlreadyProcessed.rejectReason
              : ''
          }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
