import { Component, Input, OnInit } from '@angular/core';
import { UITravelApprovalQuestion } from '@store/cta-specific/cta-specific.models';
import { getImportantLevelColor } from './travel-approval-plan-text.component.helper';

@Component({
  selector: 'sgxb-travel-approval-plain-text',
  templateUrl: './travel-approval-plain-text.component.html',
  styleUrls: ['./travel-approval-plain-text.component.scss'],
})
export class TravelApprovalPlainTextComponent implements OnInit {
  @Input('question') public q: UITravelApprovalQuestion;

  public importantLevelColor: string | undefined;

  constructor() {}

  public ngOnInit() {
    this.importantLevelColor = getImportantLevelColor(this.q.importantLevel);
  }
}
