import { Country } from '@models/Country';
import { domStringToBase64 } from '@shared/utils/encode-decode/encode-decode.utils';

export function encodeGuidelineConfigurationUpdateRequest(
  country: Country
): Country {
  return {
    ...country,
    description: domStringToBase64(country.description),
    disclaimer: domStringToBase64(country.disclaimer),
  };
}
