import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'sgxb-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent implements AfterViewInit {
  @ViewChild('switch', { static: false }) public switch: ElementRef;

  @Input() public type = 'toggle';
  @Input() public value = false;
  @Output() public toggle = new EventEmitter<boolean>();

  constructor(private ngZone: NgZone) {}

  public ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      this.renderSwitch();
    });
  }

  public renderSwitch() {
    window.$(this.switch.nativeElement).sgbSwitch({
      type: this.type,
      isChecked: this.value,
      onChange: (value: boolean): void => {
        this.toggle.emit(value);
      },
    });
  }
}
