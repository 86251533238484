import {
  ComplianceTravelApproval,
  CtaQuestion,
  CtaStats,
  CtaStatus,
} from '@models/ComplianceTravelApproval';
import { UITravelApprovalQuestion } from '@store/cta-specific/cta-specific.models';
import {
  MOCK_API_COUNTRY_CHINA,
  MOCK_API_COUNTRY_HK,
  MOCK_API_COUNTRY_UK,
} from './country.mocks';
import { MOCK_API_PROFILE, MOCK_API_PROFILE_2 } from './profile.mocks';
import { MOCK_API_USER, MOCK_API_USER_2 } from './user.mocks';

/**
 * CTA Mocks
 */
export const MOCK_API_TAR_PENDING: ComplianceTravelApproval = {
  cta_id: 'TAR-HKCN000001',
  uuid: 'b02dbd61-f29f-4c53-85c9-61ee2d3a7686',
  user: MOCK_API_USER,
  origin: MOCK_API_COUNTRY_HK,
  destination: MOCK_API_COUNTRY_CHINA,
  startDate: '2020-10-05',
  endDate: '2049-10-13',
  ccs: ['john.doe@sgcib.com'],
  manager: 'jane.doe@sgcib.com',
  comments: 'N/A',
  answers: [
    {
      answer: 'Yes',
      questionLabel:
        'I have completed the eLearning on cross-border business activities.',
      type: 'ACKNOWLEDGEMENT',
      questionUuid: '06f19dd4-b353-4e3f-839f-df4856606a25',
    },
    {
      answer: 'Yes',
      questionLabel:
        'Do you intend to conduct business activities with local clients?',
      type: 'SINGLE_SELECT',
      questionUuid: 'ed5e7d17-81bd-4a54-9797-7694d7ff7b7e',
    },
    {
      answer: 'Textarea duplicated',
      questionLabel: 'Test textarea',
      type: 'TEXTAREA',
      questionUuid: 'd780413f-6f7d-452a-85fc-747978b7fbe8',
    },
    {
      answer: '01 Jan 2020',
      questionLabel: 'Test date',
      type: 'DATE',
      questionUuid: '00000002-0002-0002-0002-00000002',
    },
    {
      answer: 'toto@sgcib.com',
      questionLabel: 'Test email',
      type: 'EMAIL',
      questionUuid: '00000003-0003-0003-0003-00000003',
    },
    {
      answer: 'fake.docx',
      questionLabel: 'Test file upload',
      type: 'FILE',
      questionUuid: '00000004-0004-0004-0004-00000004',
    },
    {
      answer: 'Option 1',
      questionLabel: 'Test multi select',
      type: 'MULTI_SELECT',
      questionUuid: '00000007-0007-0007-0007-00000007',
    },
  ],
  profile: MOCK_API_PROFILE,
  mock: false,
  status: CtaStatus.PENDING,
  rejectReason: null,
  canEdit: true,
  canCancel: true,
  canDuplicate: true,
  breakdownStatus: {
    managers: [
      {
        approverEmailsList: ['jane.doe@sgcib.com'],
        approverMailingList: null,
        status: CtaStatus.PENDING,
        validator: null,
      },
    ],
    complianceOfficers: [
      {
        approverEmailsList: ['jack.poe@sgcib.com', 'jake.toe@sgcib.com'],
        approverMailingList: 'asi-cpl-coo@sgcib.com',
        status: CtaStatus.PENDING,
        validator: null,
      },
      {
        approverEmailsList: ['toe.toe@sgcib.com'],
        approverMailingList: null,
        status: CtaStatus.PENDING,
        validator: null,
      },
    ],
    others: [],
  },
};
export const MOCK_API_TAR_PENDING_2: ComplianceTravelApproval = {
  cta_id: 'TAR-HKUK000002',
  uuid: '7606d9d0-a3df-4c94-aa60-66e543ce4a13',
  user: MOCK_API_USER_2,
  origin: MOCK_API_COUNTRY_HK,
  destination: MOCK_API_COUNTRY_UK,
  startDate: '2047-11-25',
  endDate: '2047-11-27',
  ccs: ['john.doe@sgcib.com'],
  manager: 'jack.toe@sgcib.com',
  comments: 'N/A',
  answers: [
    {
      answer: 'Yes',
      questionLabel:
        'I have completed the eLearning on cross-border business activities.',
      type: 'ACKNOWLEDGEMENT',
      questionUuid: '06f19dd4-b353-4e3f-839f-df4856606a25',
    },
    {
      answer: 'No',
      questionLabel:
        'Do you intend to conduct business activities with local clients?',
      type: 'SINGLE_SELECT',
      questionUuid: 'ed5e7d17-81bd-4a54-9797-7694d7ff7b7e',
    },
  ],
  profile: MOCK_API_PROFILE_2,
  mock: false,
  status: CtaStatus.PENDING,
  rejectReason: null,
  canEdit: true,
  canCancel: true,
  breakdownStatus: {
    managers: [
      {
        approverEmailsList: ['jane.doe@sgcib.com'],
        approverMailingList: null,
        status: CtaStatus.PENDING,
        validator: null,
      },
    ],
    complianceOfficers: [
      {
        approverEmailsList: ['jack.poe@sgcib.com', 'jake.toe@sgcib.com'],
        approverMailingList: 'asi-cpl-coo@sgcib.com',
        status: CtaStatus.PENDING,
        validator: null,
      },
      {
        approverEmailsList: ['toe.toe@sgcib.com'],
        approverMailingList: null,
        status: CtaStatus.PENDING,
        validator: null,
      },
    ],
    others: [],
  },
};

export const MOCK_API_TAR_APPROVED: ComplianceTravelApproval = {
  cta_id: 'TAR-HKCN000003',
  uuid: 'd9c3a768-03dc-48c5-a040-6f8e80a94aa6',
  user: MOCK_API_USER,
  origin: MOCK_API_COUNTRY_HK,
  destination: MOCK_API_COUNTRY_CHINA,
  startDate: '2047-01-01',
  endDate: '2047-01-08',
  ccs: ['john.doe@sgcib.com'],
  manager: 'jane.doe@sgcib.com',
  comments: 'N/A',
  answers: [
    {
      answer: 'Yes',
      questionLabel:
        'I have completed the eLearning on cross-border business activities.',
      type: 'ACKNOWLEDGEMENT',
      questionUuid: '06f19dd4-b353-4e3f-839f-df4856606a25',
    },
    {
      answer: 'No',
      questionLabel:
        'Do you intend to conduct business activities with local clients?',
      type: 'SINGLE_SELECT',
      questionUuid: 'ed5e7d17-81bd-4a54-9797-7694d7ff7b7e',
    },
  ],
  profile: MOCK_API_PROFILE,
  mock: false,
  status: CtaStatus.APPROVED,
  rejectReason: null,
  canEdit: true,
  canCancel: true,
  breakdownStatus: {
    managers: [
      {
        approverEmailsList: ['jane.doe@sgcib.com'],
        approverMailingList: null,
        status: CtaStatus.APPROVED,
        validator: 'jane.doe@sgcib.com',
      },
    ],
    complianceOfficers: [
      {
        approverEmailsList: ['jack.poe@sgcib.com', 'jake.toe@sgcib.com'],
        approverMailingList: 'asi-cpl-coo@sgcib.com',
        status: CtaStatus.APPROVED,
        validator: 'jack.poe@sgcib.com',
      },
      {
        approverEmailsList: ['toe.toe@sgcib.com'],
        approverMailingList: null,
        status: CtaStatus.APPROVED,
        validator: 'toe.toe@sgcib.com',
      },
    ],
    others: [],
  },
};

export const MOCK_API_TAR_REJECTED: ComplianceTravelApproval = {
  cta_id: 'TAR-HKUK000004',
  uuid: '1c1c93b4-5d86-4228-bdc7-2ae71c039dd3',
  user: MOCK_API_USER,
  origin: MOCK_API_COUNTRY_HK,
  destination: MOCK_API_COUNTRY_UK,
  startDate: '2047-02-15',
  endDate: '2047-02-22',
  ccs: [],
  manager: 'jane.doe@sgcib.com',
  comments: 'N/A',
  answers: [
    {
      answer: 'Yes',
      questionLabel:
        'I have completed the eLearning on cross-border business activities.',
      type: 'ACKNOWLEDGEMENT',
      questionUuid: '06f19dd4-b353-4e3f-839f-df4856606a25',
    },
    {
      answer: 'No',
      questionLabel:
        'Do you intend to conduct business activities with local clients?',
      type: 'SINGLE_SELECT',
      questionUuid: 'ed5e7d17-81bd-4a54-9797-7694d7ff7b7e',
    },
  ],
  profile: MOCK_API_PROFILE_2,
  mock: false,
  status: CtaStatus.REJECTED,
  rejectReason: 'The dates are wrong',
  canEdit: true,
  canCancel: false,
  breakdownStatus: {
    managers: [
      {
        approverEmailsList: ['jane.doe@sgcib.com'],
        approverMailingList: null,
        status: CtaStatus.REJECTED,
        validator: 'jane.doe@sgcib.com',
      },
    ],
    complianceOfficers: [
      {
        approverEmailsList: ['jack.poe@sgcib.com', 'jake.toe@sgcib.com'],
        approverMailingList: 'asi-cpl-coo@sgcib.com',
        status: CtaStatus.APPROVED,
        validator: 'jack.poe@sgcib.com',
      },
      {
        approverEmailsList: ['toe.toe@sgcib.com'],
        approverMailingList: null,
        status: CtaStatus.APPROVED,
        validator: 'toe.toe@sgcib.com',
      },
    ],
    others: [],
  },
};

export const MOCK_API_TAR_CANCELLED: ComplianceTravelApproval = {
  cta_id: 'TAR-HKCN000005',
  uuid: '1069643d-b4b5-4708-a5fb-f7bed617884d',
  user: MOCK_API_USER,
  origin: MOCK_API_COUNTRY_HK,
  destination: MOCK_API_COUNTRY_CHINA,
  startDate: '2047-03-10',
  endDate: '2047-03-17',
  ccs: [],
  manager: 'jane.doe@sgcib.com',
  comments: 'N/A',
  answers: [
    {
      answer: 'Yes',
      questionLabel:
        'I have completed the eLearning on cross-border business activities.',
      type: 'ACKNOWLEDGEMENT',
      questionUuid: '06f19dd4-b353-4e3f-839f-df4856606a25',
    },
    {
      answer: 'No',
      questionLabel:
        'Do you intend to conduct business activities with local clients?',
      type: 'SINGLE_SELECT',
      questionUuid: 'ed5e7d17-81bd-4a54-9797-7694d7ff7b7e',
    },
  ],
  profile: MOCK_API_PROFILE,
  mock: false,
  status: CtaStatus.CANCELLED,
  rejectReason: null,
  canEdit: false,
  canCancel: false,
  breakdownStatus: {
    owner: [
      {
        approverEmailsList: ['john.doe@sgcib.com'],
        approverMailingList: null,
        status: CtaStatus.CANCELLED,
        validator: 'john.doe@sgcib.com',
      },
    ],
    managers: [
      {
        approverEmailsList: ['jane.doe@sgcib.com'],
        approverMailingList: null,
        status: CtaStatus.PENDING,
        validator: null,
      },
    ],
    complianceOfficers: [
      {
        approverEmailsList: ['jack.poe@sgcib.com', 'jake.toe@sgcib.com'],
        approverMailingList: 'asi-cpl-coo@sgcib.com',
        status: CtaStatus.PENDING,
        validator: null,
      },
      {
        approverEmailsList: ['toe.toe@sgcib.com'],
        approverMailingList: null,
        status: CtaStatus.PENDING,
        validator: null,
      },
    ],
    others: [],
  },
};

export const MOCK_API_TAR_LATE_APPROVAL: ComplianceTravelApproval = {
  cta_id: 'TAR-HKCN000006',
  uuid: '693a88b4-3be2-4a56-acc4-d3ec12b5acea',
  user: MOCK_API_USER,
  origin: MOCK_API_COUNTRY_HK,
  destination: MOCK_API_COUNTRY_CHINA,
  startDate: '2047-04-01',
  endDate: '2047-04-05',
  ccs: [],
  manager: 'jane.doe@sgcib.com',
  comments: 'N/A',
  answers: [
    {
      answer: 'Yes',
      questionLabel:
        'I have completed the eLearning on cross-border business activities.',
      type: 'ACKNOWLEDGEMENT',
      questionUuid: '06f19dd4-b353-4e3f-839f-df4856606a25',
    },
    {
      answer: 'No',
      questionLabel:
        'Do you intend to conduct business activities with local clients?',
      type: 'SINGLE_SELECT',
      questionUuid: 'ed5e7d17-81bd-4a54-9797-7694d7ff7b7e',
    },
  ],
  profile: MOCK_API_PROFILE,
  mock: false,
  status: CtaStatus.LATE_APPROVAL,
  rejectReason: null,
  canEdit: false,
  canCancel: false,
  breakdownStatus: {
    managers: [
      {
        approverEmailsList: ['jane.doe@sgcib.com'],
        approverMailingList: null,
        status: CtaStatus.LATE_APPROVAL,
        validator: null,
      },
    ],
    complianceOfficers: [
      {
        approverEmailsList: ['jack.poe@sgcib.com', 'jake.toe@sgcib.com'],
        approverMailingList: 'asi-cpl-coo@sgcib.com',
        status: CtaStatus.APPROVED,
        validator: null,
      },
      {
        approverEmailsList: ['toe.toe@sgcib.com'],
        approverMailingList: null,
        status: CtaStatus.APPROVED,
        validator: null,
      },
    ],
    others: [],
  },
};

export const MOCK_API_TAR_LATE_REJECTION: ComplianceTravelApproval = {
  cta_id: 'TAR-HKUK000007',
  uuid: 'c721d405-5353-40d4-b034-83f0c7ac5d31',
  user: MOCK_API_USER,
  origin: MOCK_API_COUNTRY_HK,
  destination: MOCK_API_COUNTRY_UK,
  startDate: '2047-06-15',
  endDate: '2047-06-22',
  ccs: [],
  manager: 'jane.doe@sgcib.com',
  comments: 'N/A',
  answers: [
    {
      answer: 'Yes',
      questionLabel:
        'I have completed the eLearning on cross-border business activities.',
      type: 'ACKNOWLEDGEMENT',
      questionUuid: '06f19dd4-b353-4e3f-839f-df4856606a25',
    },
    {
      answer: 'No',
      questionLabel:
        'Do you intend to conduct business activities with local clients?',
      type: 'SINGLE_SELECT',
      questionUuid: 'ed5e7d17-81bd-4a54-9797-7694d7ff7b7e',
    },
  ],
  profile: MOCK_API_PROFILE_2,
  mock: false,
  status: CtaStatus.LATE_REJECTION,
  rejectReason: 'The dates are wrong',
  canEdit: false,
  canCancel: false,
  breakdownStatus: {
    managers: [
      {
        approverEmailsList: ['jane.doe@sgcib.com'],
        approverMailingList: null,
        status: CtaStatus.APPROVED,
        validator: 'jane.doe@sgcib.com',
      },
    ],
    complianceOfficers: [
      {
        approverEmailsList: ['jack.poe@sgcib.com', 'jake.toe@sgcib.com'],
        approverMailingList: 'asi-cpl-coo@sgcib.com',
        status: CtaStatus.LATE_REJECTION,
        validator: 'jack.poe@sgcib.com',
      },
      {
        approverEmailsList: ['toe.toe@sgcib.com'],
        approverMailingList: null,
        status: CtaStatus.PENDING,
        validator: null,
      },
    ],
    others: [],
  },
};

export const MOCK_API_TAR_LATE_DECLARATION: ComplianceTravelApproval = {
  cta_id: 'TAR-HKCN000008',
  uuid: 'd6e65c44-62ba-4566-a190-a9a0bf90651c',
  user: MOCK_API_USER,
  origin: MOCK_API_COUNTRY_HK,
  destination: MOCK_API_COUNTRY_CHINA,
  startDate: '2047-12-25',
  endDate: '2047-12-31',
  ccs: ['john.doe@sgcib.com'],
  manager: 'jane.doe@sgcib.com',
  comments: 'N/A',
  answers: [
    {
      answer: 'Yes',
      questionLabel:
        'I have completed the eLearning on cross-border business activities.',
      type: 'ACKNOWLEDGEMENT',
      questionUuid: '06f19dd4-b353-4e3f-839f-df4856606a25',
    },
    {
      answer: 'No',
      questionLabel:
        'Do you intend to conduct business activities with local clients?',
      type: 'SINGLE_SELECT',
      questionUuid: 'ed5e7d17-81bd-4a54-9797-7694d7ff7b7e',
    },
  ],
  profile: MOCK_API_PROFILE,
  mock: false,
  status: CtaStatus.PENDING,
  rejectReason: null,
  canEdit: false,
  canCancel: false,
  breakdownStatus: {
    owner: [
      {
        approverEmailsList: ['john.doe@sgcib.com'],
        approverMailingList: null,
        status: CtaStatus.PENDING,
        validator: 'john.doe@sgcib.com',
      },
    ],
    managers: [],
    complianceOfficers: [],
    others: [],
  },
};

export const MOCK_API_TAR_FOR_APPROVAL: ComplianceTravelApproval[] = [
  MOCK_API_TAR_PENDING,
  MOCK_API_TAR_PENDING_2,
];
export const MOCK_API_TAR_FOR_APPROVAL_EMPTY: ComplianceTravelApproval[] = [];

export const MOCK_API_TAR_ALREADY_PROCESSED: ComplianceTravelApproval[] = [
  MOCK_API_TAR_APPROVED,
  MOCK_API_TAR_REJECTED,
  MOCK_API_TAR_CANCELLED,
  MOCK_API_TAR_LATE_APPROVAL,
  MOCK_API_TAR_LATE_REJECTION,
];

export const MOCK_API_CTAs: ComplianceTravelApproval[] = [
  MOCK_API_TAR_PENDING,
  MOCK_API_TAR_APPROVED,
  MOCK_API_TAR_LATE_APPROVAL,
  MOCK_API_TAR_REJECTED,
  MOCK_API_TAR_CANCELLED,
  MOCK_API_TAR_LATE_REJECTION,
];

export const MOCK_API_TAR_STATS: CtaStats = {
  country: MOCK_API_COUNTRY_HK,
  startDate: '2018-01-01',
  endDate: '2019-01-01',
  rows: [
    {
      user: MOCK_API_USER,
      trips: 3,
      days: 10,
      questions: [
        {
          question: 'Do you have a Hong Kong regulatory license?',
          answers: [
            {
              answer: 'No',
              trips: 1,
              days: 3,
            },
            {
              answer: 'SFC',
              trips: 2,
              days: 7,
            },
          ],
        },
        {
          question: 'Purpose of visit',
          answers: [
            {
              answer: 'Internal meetings',
              trips: 1,
              days: 3,
            },
            {
              answer: 'Client visit to discuss securities related transactions',
              trips: 2,
              days: 7,
            },
          ],
        },
      ],
    },
    {
      user: MOCK_API_USER_2,
      trips: 1,
      days: 5,
      questions: [
        {
          question: 'Do you have a Hong Kong regulatory license?',
          answers: [
            {
              answer: 'SFC',
              trips: 1,
              days: 5,
            },
          ],
        },
        {
          question: 'Purpose of visit',
          answers: [
            {
              answer: 'Internal meetings',
              trips: 1,
              days: 5,
            },
          ],
        },
      ],
    },
  ],
};

export const MOCK_API_DUPLICATED_CTA: ComplianceTravelApproval = {
  cta_id: 'TAR-HKCN000009',
  uuid: '7cc7ed8e-375d-463b-9877-4e6f0157715d',
  user: MOCK_API_USER,
  origin: MOCK_API_COUNTRY_HK,
  destination: MOCK_API_COUNTRY_CHINA,
  startDate: null,
  endDate: null,
  ccs: ['john.doe@sgcib.com'],
  manager: 'john.doe@sgcib.com',
  comments: 'N/A',
  answers: [
    {
      answer: 'Yes',
      questionLabel:
        'I have completed the eLearning on cross-border business activities.',
      type: 'ACKNOWLEDGEMENT',
      questionUuid: '06f19dd4-b353-4e3f-839f-df4856606a25',
    },
    {
      answer: 'No',
      questionLabel:
        'Do you intend to conduct business activities with local clients?',
      type: 'SINGLE_SELECT',
      questionUuid: 'ed5e7d17-81bd-4a54-9797-7694d7ff7b7e',
    },
  ],
  profile: null,
  mock: false,
  status: CtaStatus.PENDING,
  rejectReason: null,
  canEdit: true,
  canCancel: true,
  breakdownStatus: {
    managers: [],
    complianceOfficers: [],
    others: [],
  },
};

/**
 * CTA questionnaires mocks
 */
export const MOCK_API_TRAVEL_APPROVAL_QUESTION: CtaQuestion = {
  uuid: '06f19dd4-b353-4e3f-839f-df4856606a25',
  type: 'ACKNOWLEDGEMENT',
  description:
    'I have completed the eLearning on cross-border business activities.',
  options: [],
  useOr: false,
  conditions: [],
  display: null,
  isCCMandatory: null,
  ordering: 0,
  scope: ['MARK', 'GLBA', 'GTPS'],
};

export const MOCK_API_TRAVEL_APPROVAL_QUESTIONS: CtaQuestion[] = [
  {
    uuid: '06f19dd4-b353-4e3f-839f-df4856606a25',
    type: 'ACKNOWLEDGEMENT',
    description:
      'I have completed the eLearning on cross-border business activities.',
    options: [],
    useOr: false,
    conditions: [],
    display: null,
    isCCMandatory: null,
    ordering: 0,
    scope: ['MARK'],
  },
  {
    uuid: 'ed5e7d17-81bd-4a54-9797-7694d7ff7b7e',
    type: 'SINGLE_SELECT',
    description:
      'Do you intend to conduct business activities with local clients?',
    options: [
      {
        ordering: 0,
        text: 'Yes',
        uuid: '98107a02-6160-4cb2-b54b-406b4ceeb8b2',
      },
      {
        ordering: 1,
        text: 'No',
        uuid: '7b94fb9d-fe62-484f-9d07-87d897c35dd6',
      },
    ],
    useOr: false,
    conditions: [],
    display: null,
    isCCMandatory: null,
    ordering: 1,
    scope: ['GLBA'],
  },
  {
    uuid: 'd780413f-6f7d-452a-85fc-747978b7fbe8',
    type: 'TEXTAREA',
    description: 'Please describe (C1 level only)',
    options: [],
    useOr: false,
    conditions: [
      {
        option: '98107a02-6160-4cb2-b54b-406b4ceeb8b2',
        question: 'ed5e7d17-81bd-4a54-9797-7694d7ff7b7e',
      },
    ],
    display: null,
    isCCMandatory: null,
    ordering: 2,
    scope: ['GTPS'],
  },
  {
    uuid: '00000002-0002-0002-0002-00000002',
    type: 'DATE',
    description: 'Test date',
    options: [],
    useOr: false,
    conditions: [],
    display: null,
    isCCMandatory: null,
    ordering: 0,
    scope: ['MARK'],
  },
  {
    uuid: '00000003-0003-0003-0003-00000003',
    type: 'EMAIL',
    description: 'Test email',
    options: [],
    useOr: false,
    conditions: [],
    display: null,
    validationLevel: null,
    isCCMandatory: null,
    presetValueType: 'SUPERVISOR',
    presetValue: 'john.don@sgcib.com',
    isNotEditable: true,
    ordering: 0,
    scope: ['MARK'],
  },
  {
    uuid: '00000004-0004-0004-0004-00000004',
    type: 'FILE',
    description: 'Test file upload',
    options: [],
    useOr: false,
    conditions: [],
    display: null,
    isCCMandatory: null,
    ordering: 0,
    scope: ['MARK'],
  },
  {
    uuid: '00000007-0007-0007-0007-00000007',
    type: 'MULTI_SELECT',
    description: 'Test multi select',
    options: [
      {
        ordering: 0,
        text: 'Option 1',
        uuid: '11111117-1117-1117-1117-11111117',
      },
      {
        ordering: 1,
        text: 'Option 2',
        uuid: '22222227-2227-2227-2227-22222227',
      },
    ],
    useOr: false,
    conditions: [],
    display: null,
    isCCMandatory: null,
    ordering: 0,
    scope: ['MARK'],
  },
  {
    uuid: '00000008-0008-0008-0008-00000008',
    type: 'PLAIN_TEXT',
    description: 'This is a plain text description.↵This is a new line.',
    options: [],
    useOr: false,
    conditions: [],
    display: null,
    isCCMandatory: null,
    importantLevel: 'VERY_IMPORTANT',
    ordering: 0,
    scope: ['MARK'],
  },
];

export const MOCK_API_TRAVEL_APPROVAL_QUESTIONS_FOR_MARK: CtaQuestion[] = [
  {
    uuid: '06f19dd4-b353-4e3f-839f-df4856606a25',
    type: 'ACKNOWLEDGEMENT',
    description:
      'I have completed the eLearning on cross-border business activities.',
    options: [],
    useOr: false,
    conditions: [],
    display: null,
    isCCMandatory: null,
    ordering: 0,
    scope: ['MARK'],
    filtered: false,
  },
  {
    uuid: 'ed5e7d17-81bd-4a54-9797-7694d7ff7b7e',
    type: 'SINGLE_SELECT',
    description:
      'Do you intend to conduct business activities with local clients?',
    options: [
      {
        ordering: 0,
        text: 'Yes',
        uuid: '98107a02-6160-4cb2-b54b-406b4ceeb8b2',
      },
      {
        ordering: 1,
        text: 'No',
        uuid: '7b94fb9d-fe62-484f-9d07-87d897c35dd6',
      },
    ],
    useOr: false,
    conditions: [],
    display: null,
    isCCMandatory: null,
    ordering: 1,
    scope: ['GLBA'],
    filtered: true,
  },
  {
    uuid: 'd780413f-6f7d-452a-85fc-747978b7fbe8',
    type: 'TEXTAREA',
    description: 'Please describe (C1 level only)',
    options: [],
    useOr: false,
    conditions: [
      {
        option: '98107a02-6160-4cb2-b54b-406b4ceeb8b2',
        question: 'ed5e7d17-81bd-4a54-9797-7694d7ff7b7e',
      },
    ],
    display: null,
    isCCMandatory: null,
    ordering: 2,
    scope: ['GTPS'],
    filtered: true,
  },
];

export const MOCK_API_TAR_APPROVAL_ERROR_MESSAGE = {
  message: `You are not able to submit your decision for this Travel Approval Request, due to one of the following reasons :<br/>
    <li>You have already submit your decision</li>
    <li>This CTA is no longer required for your decision due to a decision from another validator. Please see the status breakdown for more details.</li>`,
};

export const MOCK_API_TAR_WORKING_DAYS_MESSAGE = {
  level: 'danger',
  title: 'Alert',
  message: `<strong>10</strong> working days for this travel<br/>
    <strong> 12 </strong> working days for the past 12 months prior to departure date<br/><br/>
    Including this travel and the past 12 months prior to departure, you would have spent <strong>22</strong> working days in United Kingdom.`,
};

export const MOCK_API_TAR_WORKING_DAYS_MESSAGE_DELEGATION = {
  level: 'danger',
  title: 'Alert',
  message: `<strong>15</strong> working days for this travel<br/>
    <strong> 12 </strong> working days for the past 12 months prior to departure date<br/><br/>
    Including this travel and the past 12 months prior to departure, the traveler would have spent <strong>27</strong> working days in United Kingdom.`,
};

export const MOCK_API_SUPERVISOR = {
  email: 'fake.supervisor@sgcib.com',
  department: 'GBSU/CLD/DIT',
};

export const MOCK_API_TAR_LATE_DECLARATION_WARNING = {
  title: 'Late Declaration',
  message: `The late declaration notification is for travels executed already, for which the protocol for pre-registration was not executed on due time.<br>
  It enables to regularize the travel history in the database. It is not subject to validation but is highlighted for information so that the relevant parties
   can take action if action is needed, as well as for control and audit trail purpose.`,
};

// Old Mock for CTA Dashboard
export const MOCK_API_TAR_DASHBOARD_EXPORT: Blob = new Blob(
  [
    `CTA DASHBOARD,,,,,,,,,,,,,
  Name,Department,Email,Country from,#TotalWorkDays,Trip,From,To,#WorkDays,Supervisor,Chaperone,Meeting clients,Visiting SG London Offices,Manager of UK Certified Person(s)
  John DOE,GBSU/CLD/DIT,john.doe@sgcib.com,Dubai,9,2,2/1/2019,8/1/2019,5,Jane DOE,Jack SMITH,Yes,Yes,No
  John DOE,GBSU/CLD/DIT,john.doe@sgcib.com,Dubai,9,2,3/5/2019,8/5/2019,4,Jane DOE,Jack SMITH,No,Yes,No
  `,
  ],
  { type: 'application/vnd.ms-excel' }
);

/**
 * Dashboard data sample
 */

export const SAMPLE_DASHBOARD_DATA = [
  {
    user: {
      lastName: 'LIEU',
      firstName: 'Alexis',
      department: 'GBSU/CLD/DIT',
      email: 'alexis.lieu@sgcib.com',
    },
    cta: {
      cta_id: 'TAR-FRKR999999',
      uuid: 'efe37a22-04e4-4e1e-af73-9c1b85a438cd',
      startDate: '2019-07-08',
      endDate: '2019-07-10',
      origin: {
        name: 'France',
      },
      destination: {
        name: 'South Korea',
      },
      profile: {
        name: 'Securities',
      },
      manager: 'jerome.perron@sgcib.com',
      status: 'APPROVED',
      rejectReason: null,
      workingDuration: 3,
    },
  },
  {
    user: {
      lastName: 'LIEU',
      firstName: 'Alexis',
      department: 'GBSU/CLD/DIT',
      email: 'alexis.lieu@sgcib.com',
    },
    cta: {
      cta_id: 'TAR-FRHK999998',
      uuid: 'abc18d45-04e4-4e1e-af73-9c1b85b4fa12',
      startDate: '2019-11-25',
      endDate: '2019-11-29',
      origin: {
        name: 'France',
      },
      destination: {
        name: 'Hong Kong',
      },
      profile: {
        name: null,
      },
      manager: 'jerome.perron@sgcib.com',
      status: 'APPROVED',
      rejectReason: null,
      workingDuration: 5,
    },
  },
  {
    user: {
      lastName: 'PERRON',
      firstName: 'Jerome',
      department: 'GBSU/CLD/DIT',
      email: 'jerome.perron@sgcib.com',
    },
    cta: {
      cta_id: 'TAR-HKCN999997',
      uuid: 'abc18d45-04e4-4e1e-af73-1234567890ab',
      startDate: '2019-11-18',
      endDate: '2019-11-22',
      origin: {
        name: 'Hong Kong',
      },
      destination: {
        name: 'China',
      },
      profile: {
        name: null,
      },
      manager: 'john.doe@sgcib.com',
      status: 'APPROVED',
      rejectReason: null,
      workingDuration: 5,
    },
  },
];

export const MOCK_TAR_CONFIGURATION_FOR_VISIBILITY: UITravelApprovalQuestion[] =
  [
    {
      uuid: 'eabc3312-e7bf-4b85-bccb-64b12f971e4a',
      type: 'SINGLE_SELECT',
      ordering: 0,
      description: 'What do you want to display?',
      conditions: [],
      useOr: null,
      scope: ['MARK'],
      filtered: false,
      validationLevel: null,
      presetValueType: null,
      isNotEditable: null,
      isCCMandatory: null,
      presetValue: null,
      options: [
        {
          uuid: '3cc735a8-0dad-45eb-b5d9-a26ef1d128ce',
          ordering: 0,
          text: 'Option 1',
        },
        {
          uuid: '929f29e4-b15e-46b2-b720-fca9403641b2',
          ordering: 1,
          text: 'Option 2',
        },
        {
          uuid: 'cfa3e463-4867-41ec-a4ab-ed8793fa1a06',
          ordering: 2,
          text: 'Mark Supervisor / Not Required / Editable',
        },
        {
          uuid: '4fcd126d-a495-4dbb-b01a-64eeb6ce112c',
          ordering: 3,
          text: 'Only Email Input / For Approval',
        },
        {
          uuid: 'dc5a2080-1c1c-4a54-8b4d-9eabe9f5cf2e',
          ordering: 4,
          text: 'Only Email Input / For Information',
        },
        {
          uuid: '9b74dde7-adf8-4565-8d13-02a1a97b536e',
          ordering: 5,
          text: 'Only Email Input / Not Required',
        },
      ],
      expanded: false,
    },
    {
      uuid: 'c6c883cc-61bf-4ea5-af3f-dc8e8ee28547',
      type: 'EMAIL',
      ordering: 1,
      description:
        'It should be pre-filled with the supervisor for Afghanistan / MARK and it should not be editable. The supervisor  should receive a request for approval.',
      conditions: [
        {
          question: 'eabc3312-e7bf-4b85-bccb-64b12f971e4a',
          option: '3cc735a8-0dad-45eb-b5d9-a26ef1d128ce',
        },
      ],
      useOr: null,
      scope: ['MARK'],
      filtered: false,
      validationLevel: null,
      presetValueType: 'SUPERVISOR',
      isNotEditable: true,
      isCCMandatory: null,
      presetValue: 'mark.afganistan@sgcib.com\r',
      expanded: false,
    },
    {
      uuid: '943f05c7-766f-4865-af2f-86426195595e',
      type: 'EMAIL',
      ordering: 2,
      description:
        'It should be pre-filled with the supervisor for Afghanistan / MARK and it should be editable. The supervisor  should receive a email for information.',
      conditions: [
        {
          question: 'eabc3312-e7bf-4b85-bccb-64b12f971e4a',
          option: '929f29e4-b15e-46b2-b720-fca9403641b2',
        },
      ],
      useOr: null,
      scope: ['MARK'],
      filtered: false,
      validationLevel: 'FOR_INFORMATION',
      presetValueType: 'SUPERVISOR',
      isNotEditable: false,
      isCCMandatory: null,
      presetValue: 'mark.afganistan@sgcib.com\r',
      expanded: false,
    },
    {
      uuid: '9a070d2d-58ce-45b7-91ff-4f81a8ab392a',
      type: 'EMAIL',
      ordering: 3,
      description:
        'It should be pre-filled with the supervisor for Afghanistan / MARK and it should be editable. The supervisor  should receive a email only if the user selected it.',
      conditions: [
        {
          question: 'eabc3312-e7bf-4b85-bccb-64b12f971e4a',
          option: 'cfa3e463-4867-41ec-a4ab-ed8793fa1a06',
        },
      ],
      useOr: null,
      scope: ['MARK'],
      filtered: false,
      validationLevel: 'NOT_REQUIRED',
      presetValueType: 'SUPERVISOR',
      isNotEditable: false,
      isCCMandatory: false,
      presetValue: 'mark.afganistan@sgcib.com\r',
      expanded: false,
    },
    {
      uuid: 'b97d2e2d-5cc8-435c-8d88-8f36311951a9',
      type: 'EMAIL',
      ordering: 4,
      description:
        'It should NOT be pre-filled. The email entered should receive a request for approval.',
      conditions: [
        {
          question: 'eabc3312-e7bf-4b85-bccb-64b12f971e4a',
          option: '4fcd126d-a495-4dbb-b01a-64eeb6ce112c',
        },
      ],
      useOr: null,
      scope: ['MARK'],
      filtered: false,
      validationLevel: null,
      presetValueType: null,
      isNotEditable: null,
      isCCMandatory: null,
      presetValue: null,
      expanded: false,
    },
    {
      uuid: 'a0c1fccc-ec52-475c-a991-fea3ec8c5642',
      type: 'EMAIL',
      ordering: 5,
      description:
        'It should NOT be pre-filled. The email entered should receive an email for information.',
      conditions: [
        {
          question: 'eabc3312-e7bf-4b85-bccb-64b12f971e4a',
          option: 'dc5a2080-1c1c-4a54-8b4d-9eabe9f5cf2e',
        },
      ],
      useOr: null,
      scope: ['MARK'],
      filtered: false,
      validationLevel: 'FOR_INFORMATION',
      presetValueType: null,
      isNotEditable: null,
      isCCMandatory: null,
      presetValue: null,
      expanded: false,
    },
    {
      uuid: 'f5b00d85-8462-4d1d-9d20-ed896277662b',
      type: 'EMAIL',
      ordering: 6,
      description:
        'It should NOT be pre-filled. The email entered should receive an email only if the user ticked the CC checkbox.',
      conditions: [
        {
          question: 'eabc3312-e7bf-4b85-bccb-64b12f971e4a',
          option: '9b74dde7-adf8-4565-8d13-02a1a97b536e',
        },
      ],
      useOr: null,
      scope: ['MARK'],
      filtered: false,
      validationLevel: 'NOT_REQUIRED',
      presetValueType: null,
      isNotEditable: null,
      isCCMandatory: false,
      presetValue: null,
      expanded: false,
    },
    {
      uuid: 'ef4f3818-50d8-4257-8d2d-2f77a73586bc',
      type: 'EMAIL',
      ordering: 7,
      description: '[General] Should be prefilled',
      conditions: [],
      useOr: null,
      scope: ['MARK'],
      filtered: false,
      validationLevel: 'NOT_REQUIRED',
      presetValueType: 'SUPERVISOR',
      isNotEditable: true,
      isCCMandatory: false,
      presetValue: 'mark.afganistan@sgcib.com\r',
      expanded: false,
    },
  ];

export const MOCK_TAR_ANSWER_FOR_VISIBILITY: ComplianceTravelApproval = {
  answers: [
    {
      questionUuid: 'eabc3312-e7bf-4b85-bccb-64b12f971e4a',
      questionLabel: 'What do you want to display?',
      answer: 'Mark Supervisor / Not Required / Editable',
      type: 'SINGLE_SELECT',
      ccEmail: null,
    },
    {
      questionUuid: 'c6c883cc-61bf-4ea5-af3f-dc8e8ee28547',
      questionLabel:
        'It should be pre-filled with the supervisor for Afghanistan / MARK and it should not be editable. The supervisor  should receive a request for approval.',
      answer: 'mark.afganistan@sgcib.com\r',
      type: 'EMAIL',
      ccEmail: false,
    },
    {
      questionUuid: '943f05c7-766f-4865-af2f-86426195595e',
      questionLabel:
        'It should be pre-filled with the supervisor for Afghanistan / MARK and it should be editable. The supervisor  should receive a email for information.',
      answer: 'mark.afganistan@sgcib.com\r',
      type: 'EMAIL',
      ccEmail: false,
    },
    // Should be visible
    {
      questionUuid: '9a070d2d-58ce-45b7-91ff-4f81a8ab392a',
      questionLabel:
        'It should be pre-filled with the supervisor for Afghanistan / MARK and it should be editable. The supervisor  should receive a email only if the user selected it.',
      answer: 'mark.afganistan@sgcib.com\r',
      type: 'EMAIL',
      ccEmail: false,
    },
    {
      questionUuid: 'b97d2e2d-5cc8-435c-8d88-8f36311951a9',
      questionLabel:
        'It should NOT be pre-filled. The email entered should receive a request for approval.',
      answer: null,
      type: 'EMAIL',
      ccEmail: false,
    },
    {
      questionUuid: 'a0c1fccc-ec52-475c-a991-fea3ec8c5642',
      questionLabel:
        'It should NOT be pre-filled. The email entered should receive an email for information.',
      answer: null,
      type: 'EMAIL',
      ccEmail: false,
    },
    {
      questionUuid: 'f5b00d85-8462-4d1d-9d20-ed896277662b',
      questionLabel:
        'It should NOT be pre-filled. The email entered should receive an email only if the user ticked the CC checkbox.',
      answer: null,
      type: 'EMAIL',
      ccEmail: false,
    },
  ],
  ccs: [],
  comments: '',
  destination: {
    name: 'Afghanistan',
    alpha2: 'AF',
    alpha3: 'AFG',
    numeric: 4,
    profiles: [],
    description:
      'Country to test various combinations of the filter on Guidelines and Questions',
    disclaimer:
      "The guidelines are made on the best of compliance knowledge but can't include everything to make it readable therefore any unprecision/questions should be asked directly to compliance.",
  },
  endDate: null,
  manager: 'stern.ho@sgcib.com',
  mock: true,
  origin: {
    name: 'Afghanistan',
    alpha2: 'AF',
    alpha3: 'AFG',
    numeric: 4,
    profiles: [],
    description:
      'Country to test various combinations of the filter on Guidelines and Questions',
    disclaimer:
      "The guidelines are made on the best of compliance knowledge but can't include everything to make it readable therefore any unprecision/questions should be asked directly to compliance.",
  },
  profile: null,
  startDate: null,
  user: null,
  status: null,
  breakdownStatus: {
    managers: [],
    complianceOfficers: [],
    others: [],
  },
  cta_id: null,
};
