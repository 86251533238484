import { MenuItem } from '@models/MenuItem';

export interface HeaderState {
  items: MenuItem[];
  route: string;
}

export const initialHeaderState: HeaderState = {
  items: [{ key: 'home', label: 'Home', selected: true, link: '/home' }],
  route: null,
};
