import { AllValueInCountry, Country } from '@models/Country';
import { CountryState } from './country.models';

export const getAllCountries = (state: CountryState): Country[] =>
  state.allCountries;
export const getAllCountriesWithAllValue = (state: CountryState): Country[] =>
  [AllValueInCountry].concat(state.allCountries);
export const getAllCountriesWithGuidelines = (state: CountryState): Country[] =>
  state.allCountriesWithGuidelines;
export const getPerimeterCountries = (state: CountryState): Country[] =>
  state.perimeterCountries;
export const getPerimeterCountriesWithGuidelines = (
  state: CountryState
): Country[] => state.perimeterCountriesWithGuidelines;
export const getCountriesUnderOwnership = (state: CountryState): Country[] =>
  state.countryUnderOwnership;
export const getCurrentCountry = (state: CountryState): Country =>
  state.currentCountry;
export const getPopinDisplayed = (state: CountryState): boolean =>
  state.popinDisplayed;
export const getRequestPopinDisplayed = (state: CountryState): boolean =>
  state.requestPopinDisplayed;
export const getRequestSubmitOnGoing = (state: CountryState): boolean =>
  state.requestSubmitOnGoing;
export const getResetOnGoing = (state: CountryState): boolean =>
  state.resetOnGoing;
export const getSaveOnGoing = (state: CountryState): boolean =>
  state.saveOnGoing;
