<div id="datepicker-questionnaire-{{ q.uuid }}">
  <sgxb-datepicker
    [label]="q.description"
    [value]="answer$ | async"
    [validators]="validators"
    (update)="onDateChange($event)"
    (validate)="onValidate($event)"
    [container]="['datepicker-questionnaire-' + q.uuid]"
    [orientation]="'left top'"
  >
  </sgxb-datepicker>
</div>
