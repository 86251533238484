import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AcknowledgementRequestInterceptor } from './services/acknowledgement-request-interceptor/acknowledgement-request-interceptor.service';
import { BusinessUnitRequestInterceptor } from './services/business-unit-request-interceptor.service';
import { CountryRequestInterceptor } from './services/country-request-interceptor/country-request-interceptor.service';
import { DelegationRequestInterceptor } from './services/delegation-request-interceptor/delegation-request-interceptor.service';
import { FormValidatorRequestInterceptor } from './services/form-validator-request-interceptor/form-validator-request-interceptor.service';
import { GuidelineRequestInterceptor } from './services/guideline-request-interceptor/guideline-request-interceptor.service';
import { MockApiLogger } from './services/mock-api-logger/mock-api-logger.service';
import { TaSpecificRequestInterceptor } from './services/ta-specific-request-interceptor/ta-specific-request-interceptor.service';
import { TravelApprovalRequestInterceptor } from './services/travel-approval-request-interceptor/travel-approval-request-interceptor.service';
import { UserRequestInterceptor } from './services/user-request-interceptor/user-request-interceptor.service';

@NgModule({
  imports: [HttpClientModule],
  declarations: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AcknowledgementRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BusinessUnitRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CountryRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DelegationRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FormValidatorRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GuidelineRequestInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: MockApiLogger, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TaSpecificRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TravelApprovalRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UserRequestInterceptor,
      multi: true,
    },
  ],
})
export class MockApiModule {}
