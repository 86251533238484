import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sgxb-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationComponent {
  constructor() {}
}
