import { Access } from '@models/Access';
import { User } from '@models/User';
import { UserState } from './user.models';

export const getCurrentUser = (state: UserState): User => state.currentUser;
export const getFakeSSOUser = (state: UserState): string => state.fakeSSOUser;
export const getCurrentUserAccesses = (state: UserState): Access =>
  state.accesses;
export const getTravelerUser = (state: UserState): User => state.travelerUser;
export const getUserForCta = (state: UserState, onDelegation: boolean): User =>
  onDelegation ? state.travelerUser : state.currentUser;
