import { Profile } from '@models/Country';
import { Guideline, GuidelineHistory } from '@models/Guideline';

export interface GuidelineUiState {
  chapters: Array<{
    sections?: Array<{
      expanded?: boolean;
      subsections?: Array<{
        inEditMode?: boolean;
      }>;
    }>;
  }>;
  displaySubmitPracticalCaseModule: boolean;
  profiles: Profile[];
}

export interface GuidelineState {
  guideline: Guideline;
  guidelineForDisplay?: Guideline;
  history: GuidelineHistory;
  saveOnGoing: boolean;
  acknowledgeOnGoing: boolean;
  fetchHistoryOnGoing: boolean;
  currentChapterIndex: number;
  submitQuestionOnGoing: boolean;
  mailPopinDisplayed: boolean;
  uiState: GuidelineUiState;
  fetchBusinessUnitsByCountryOnGoing: boolean;
  fetchBusinessUnitForGuidelineOnGoing: boolean;
  businessUnitForGuideline: string[];
  businessUnitByCountry: string[];
  guidelineEditPointOfView: string;
  guidelineViewPointOfView: string;
  fetchPreviewOnGoing: boolean;
  preview: Guideline;
  isDirty: boolean;
  businessUnitsForReacknowledgement: string[];
  fetchSourceOwnersOnGoing: boolean;
}

export const initialGuidelineState: GuidelineState = {
  guideline: null,
  guidelineForDisplay: undefined,
  history: null,
  saveOnGoing: false,
  fetchHistoryOnGoing: false,
  acknowledgeOnGoing: false,
  currentChapterIndex: 0,
  submitQuestionOnGoing: false,
  mailPopinDisplayed: false,
  uiState: {
    chapters: [],
    displaySubmitPracticalCaseModule: false,
    profiles: [],
  },
  fetchBusinessUnitsByCountryOnGoing: false,
  fetchBusinessUnitForGuidelineOnGoing: false,
  businessUnitForGuideline: null,
  businessUnitByCountry: null,
  guidelineEditPointOfView: 'ALL',
  guidelineViewPointOfView: 'ALL',
  fetchPreviewOnGoing: false,
  preview: null,
  isDirty: false,
  businessUnitsForReacknowledgement: [],
  fetchSourceOwnersOnGoing: false,
};

export interface SubmitQuestionRequest {
  question: string;
}
