import { Pipe, PipeTransform } from '@angular/core';
import { CtaValidationLevel } from '@store/cta-specific/cta-specific.models';

@Pipe({
  name: 'ctaValidationLevel',
})
export class CtaValidationLevelPipe implements PipeTransform {
  public transform(value: CtaValidationLevel): string {
    switch (value) {
      case 'FOR_APPROVAL':
        return 'For Approval';
      case 'FOR_INFORMATION':
        return 'For Information';
      case 'NOT_REQUIRED':
        return 'Not Required';
      default:
        return '-';
    }
  }
}
