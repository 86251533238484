import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MOCK_API_BUSINESS_UNITS_FILTER } from 'app/mock-api/mocks/business-units.mocks';
import { MOCK_API_GENERIC_SUCCESS_RESPONSE } from 'app/mock-api/mocks/generic-response.mocks';
import {
  MOCK_API_DISPLAY_SUBMIT_CASE_AND_PROFILES,
  MOCK_API_GUIDELINE,
  MOCK_API_GUIDELINE_EXPORT,
  MOCK_API_GUIDELINE_FILTERED_FOR_EDIT,
  MOCK_API_GUIDELINE_FILTERED_FOR_EDIT_GLBA,
  MOCK_API_GUIDELINE_FILTERED_FOR_EDIT_GTPS,
  MOCK_API_GUIDELINE_FILTERED_FOR_EDIT_MARK,
  MOCK_API_GUIDELINE_FOR_PREVIEW_GLBA,
  MOCK_API_GUIDELINE_FOR_PREVIEW_GTPS,
  MOCK_API_GUIDELINE_FOR_PREVIEW_MARK,
  MOCK_API_GUIDELINE_HISTORY,
  MOCK_API_SOURCE_OWNERS,
} from 'app/mock-api/mocks/guideline.mocks';
import { ok } from 'app/mock-api/utils/response.utils';
import { Observable, of } from 'rxjs';
import { delay, dematerialize, materialize, mergeMap } from 'rxjs/operators';

@Injectable()
export class GuidelineRequestInterceptor implements HttpInterceptor {
  constructor() {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return <Observable<HttpEvent<any>>>of(null).pipe(
      mergeMap(() => {
        if (
          request.url.match(/^\/guidelines\/([A-Z]{2})$/) &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_GUIDELINE);
        } else if (
          request.url.match(/^\/guidelines\/([A-Z]{2})\/forConfiguration$/) &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_GUIDELINE_FILTERED_FOR_EDIT);
        } else if (
          request.url.match(/^\/guidelines\/([A-Z]{2})\/history$/) &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_GUIDELINE_HISTORY);
        } else if (
          request.url.match(/^\/guidelines\/([A-Z]{2})\/sourceOwners$/) &&
          request.method === 'POST'
        ) {
          return ok(MOCK_API_SOURCE_OWNERS);
        } else if (
          request.url.match(/^\/guidelines\/([A-Z]{2})\/major$/) &&
          request.method === 'POST'
        ) {
          return ok(MOCK_API_GUIDELINE);
        } else if (
          request.url.match(/^\/guidelines\/([A-Z]{2})\/minor$/) &&
          request.method === 'POST'
        ) {
          return ok(MOCK_API_GUIDELINE);
        } else if (
          request.url.match(/\/actions\/([A-Z]{2})\/submitPracticalCases$/) &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_DISPLAY_SUBMIT_CASE_AND_PROFILES);
        } else if (
          request.url.match(/\/ask\/([A-Z]{2})$/) &&
          request.method === 'POST'
        ) {
          return ok(MOCK_API_GENERIC_SUCCESS_RESPONSE);
        } else if (
          request.url.match(/\/ask\/([A-Z]{2})\/([0-9a-zA-Z\-]+)$/) &&
          request.method === 'POST'
        ) {
          return ok(MOCK_API_GENERIC_SUCCESS_RESPONSE);
        } else if (
          request.url.match(/^\/export\/guidelines\/([A-Z]{2})$/) &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_GUIDELINE_EXPORT);
        } else if (
          request.url.match(
            /^\/guidelines\/([A-Z]{2})\?businessUnit=([A-Z]+)$/
          ) &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_GUIDELINE_FILTERED_FOR_EDIT);
        } else if (
          request.url.match(/^\/guidelines\/([A-Z]{2})\/businessUnit$/) &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_BUSINESS_UNITS_FILTER);
        } else if (
          request.url.match(
            /^\/guidelines\/([A-Z]{2})\/forConfiguration\?businessUnit=MARK$/
          ) &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_GUIDELINE_FILTERED_FOR_EDIT_MARK);
        } else if (
          request.url.match(
            /^\/guidelines\/([A-Z]{2})\/forConfiguration\?businessUnit=GLBA$/
          ) &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_GUIDELINE_FILTERED_FOR_EDIT_GLBA);
        } else if (
          request.url.match(
            /^\/guidelines\/([A-Z]{2})\/forConfiguration\?businessUnit=GTPS$/
          ) &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_GUIDELINE_FILTERED_FOR_EDIT_GTPS);
        } else if (
          request.url.match(/^\/guidelines\/preview$/) &&
          request.method === 'POST'
        ) {
          return ok(MOCK_API_GUIDELINE_FILTERED_FOR_EDIT);
        } else if (
          request.url.match(/^\/guidelines\/preview\?businessUnit=MARK$/) &&
          request.method === 'POST'
        ) {
          return ok(MOCK_API_GUIDELINE_FOR_PREVIEW_MARK);
        } else if (
          request.url.match(/^\/guidelines\/preview\?businessUnit=GLBA$/) &&
          request.method === 'POST'
        ) {
          return ok(MOCK_API_GUIDELINE_FOR_PREVIEW_GLBA);
        } else if (
          request.url.match(/^\/guidelines\/preview\?businessUnit=GTPS$/) &&
          request.method === 'POST'
        ) {
          return ok(MOCK_API_GUIDELINE_FOR_PREVIEW_GTPS);
        }

        return next.handle(request);
      }),
      materialize(),
      delay(300),
      dematerialize()
    );
  }
}
