export enum GuidelineAction {
  ADD = 'ADD',
  DELETE = 'DELETE',
}

export interface SourceVersionOwner {
  businessUnit: string;
  sourceOwner: string;
  version: string;
  action: GuidelineAction;
}
