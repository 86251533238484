<div class="form-group" [class.has-error]="touched && !validity">
  <label class="control-label" *ngIf="label">
    <span [innerHTML]="label | nl2br | safeHtml"></span>
    <b *ngIf="required">*</b>
  </label>

  <div class="input-group">
    <input
      class="form-control date"
      type="text"
      [placeholder]="placeholder"
      [disabled]="disabled"
      autocomplete="off"
      aria-autocomplete="none"
      (focus)="onFocus($event)"
      (blur)="onBlur($event)"
      (tab)="onTab($event)"
      #datepicker
    />
    <span class="input-group-btn">
      <button
        class="btn btn-default btn-icon"
        tabindex="-1"
        [disabled]="disabled"
        (click)="show($event); focusInput()"
      >
        <i class="fa fa-calendar-o"></i>
      </button>
    </span>
  </div>
  <div class="warnings" *ngIf="touched && !validity">
    <ng-container *ngFor="let warning of warnings">
      {{ warning }}<br />
    </ng-container>
  </div>
</div>
