<div>
	<div class="main-container">
		<div class="editor-container editor-container_classic-editor" #editorContainerElement>
			<div class="editor-container__editor">
				<div #editorElement>
					<ckeditor 
					[config]="editorConfig" 
					[(ngModel)]="content">
					</ckeditor>
				</div>
			</div>
		</div>
	</div>
</div>