import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from "@angular/core";

@Component({
  selector: "sgxb-ckeditor-wrapper",
  templateUrl: "./ckeditor-wrapper.component.html",
  styleUrls: ["./ckeditor-wrapper.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CkeditorWrapperComponent {
  @Input() public content = "";
  @Input() public isHidden = false;
  @Output() public contentChange = new EventEmitter<string>();

  public emitContent(): void {
    this.contentChange.emit(this.content);
  }

  public editorConfig = {
    toolbar: [
      { name: "basicStyles", items: ["Bold", "Italic", "Underline"] },
      {
        name: "paragraph",
        items: ["NumberedList", "BulletedList", "Indent", "Outdent"],
      },
      { name: "colors", items: ["TextColor", "BGColor"] },
      { name: "links", items: ["Link", "Unlink", "Image"] },
      {
        name: "editing",
        items: [
          "Undo",
          "Redo",
          "Find",
          "Replace",
          "Cut",
          "Copy",
          "Paste",
          "PasteText",
          "PasteFromWord",
        ],
      },
      { name: "styles", items: ["Format", "Font", "FontSize"] },
      { name: "insert", items: ["Table", "Blockquote"] },
    ],
    colorButton_colors: "529aff,ff563c,00d750,f98d00,000000,ffffff",
    colorButton_enableAutomatic: false,
    colorButton_enableMore: false,
    fontSize_sizes: "10/10px;12/12px;14/14px;default;18/18px;20/20px;22/22px;",
    removeButtons: "Subscript,Superscript",
  };
}
