import { Params } from '@angular/router';
import { RouterStateUrlReducerState } from '@shared/utils/router/router.utils';

export const getRouterStateUrl = (
  router: RouterStateUrlReducerState
): string => {
  return router ? router.state.url : null;
};
export const getRouterStateParams = (
  router: RouterStateUrlReducerState
): Params => {
  return router ? router.state.params : null;
};
export const getRouterStateQueryParams = (
  router: RouterStateUrlReducerState
): Params => {
  return router && router.state && router.state.queryParams
    ? router.state.queryParams
    : null;
};
