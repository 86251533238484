import { FormFieldValidator, ValidatorRule } from '@models/Validator';
import { MOCK_API_USER, MOCK_API_USER_2 } from './user.mocks';

export const MOCK_FORM_VALIDATOR: FormFieldValidator[] = [
  {
    field: 'origin',
    display: true,
    conditions: [
      {
        rule: ValidatorRule.REQUIRED,
        message: 'Please select the country of your entity',
        constraint: null,
      },
    ],
  },
  {
    field: 'destination',
    display: true,
    conditions: [
      {
        rule: ValidatorRule.REQUIRED,
        message: 'Please select your travel destination',
        constraint: null,
      },
    ],
  },
  {
    field: 'startDate',
    display: true,
    conditions: [
      {
        rule: ValidatorRule.REQUIRED,
        message: 'Please select your travel start date',
        constraint: null,
      },
    ],
  },
  {
    field: 'endDate',
    display: true,
    conditions: [
      {
        rule: ValidatorRule.REQUIRED,
        message: 'Please select your travel end date',
        constraint: null,
      },
    ],
  },
  {
    field: 'ccs',
    display: true,
    conditions: [
      {
        rule: ValidatorRule.REGEX,
        message: 'Please enter a valid internal email address',
        constraint: [
          `^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(socgen|sgcib).(com)(.[a-z]{2})?$`,
        ],
      },
      {
        rule: ValidatorRule.UNIQUE,
        message: 'Duplicate email address',
        constraint: null,
      },
    ],
  },
  {
    field: 'manager',
    display: true,
    conditions: [
      {
        rule: ValidatorRule.REQUIRED,
        message: 'The manager email address is required',
        constraint: null,
      },
      {
        rule: ValidatorRule.REGEX,
        message: 'Please enter a valid internal email address',
        constraint: [
          `^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(socgen|sgcib).(com)(.[a-z]{2})?$`,
        ],
      },
      {
        rule: ValidatorRule.FORBIDDEN,
        message:
          'You are not allowed to put yourself or the traveler as your own manager',
        constraint: [MOCK_API_USER.email],
      },
    ],
  },
];

export const MOCK_FORM_VALIDATOR_MULTIPLE_FORBIDDEN_MANAGERS: FormFieldValidator[] =
  [
    {
      field: 'origin',
      display: true,
      conditions: [
        {
          rule: ValidatorRule.REQUIRED,
          message: 'Please select the country of your entity',
          constraint: null,
        },
      ],
    },
    {
      field: 'destination',
      display: true,
      conditions: [
        {
          rule: ValidatorRule.REQUIRED,
          message: 'Please select your travel destination',
          constraint: null,
        },
      ],
    },
    {
      field: 'startDate',
      display: true,
      conditions: [
        {
          rule: ValidatorRule.REQUIRED,
          message: 'Please select your travel start date',
          constraint: null,
        },
      ],
    },
    {
      field: 'endDate',
      display: true,
      conditions: [
        {
          rule: ValidatorRule.REQUIRED,
          message: 'Please select your travel end date',
          constraint: null,
        },
      ],
    },
    {
      field: 'ccs',
      display: true,
      conditions: [
        {
          rule: ValidatorRule.REGEX,
          message: 'Please enter a valid internal email address',
          constraint: [
            `^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(socgen|sgcib).(com)(.[a-z]{2})?$`,
          ],
        },
        {
          rule: ValidatorRule.UNIQUE,
          message: 'Duplicate email address',
          constraint: null,
        },
      ],
    },
    {
      field: 'manager',
      display: true,
      conditions: [
        {
          rule: ValidatorRule.REQUIRED,
          message: 'The manager email address is required',
          constraint: null,
        },
        {
          rule: ValidatorRule.REGEX,
          message: 'Please enter a valid internal email address',
          constraint: [
            `^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(socgen|sgcib).(com)(.[a-z]{2})?$`,
          ],
        },
        {
          rule: ValidatorRule.FORBIDDEN,
          message:
            'You are not allowed to put yourself or the traveler as your own manager',
          constraint: [MOCK_API_USER.email, MOCK_API_USER_2.email],
        },
      ],
    },
  ];

export const MOCK_SUPERVISOR_VALIDATOR: FormFieldValidator = {
  field: 'supervisor',
  display: true,
  conditions: [
    {
      rule: ValidatorRule.REQUIRED,
      message: 'The supervisor email address is required',
      constraint: null,
    },
    {
      rule: ValidatorRule.REGEX,
      message: 'Please enter a valid internal email address',
      constraint: [
        `^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(socgen|sgcib).(com)(.[a-z]{2})?$`,
      ],
    },
    {
      rule: ValidatorRule.FORBIDDEN,
      message:
        'You are not allowed to put yourself or the traveler as your own manager',
      constraint: [MOCK_API_USER.email, MOCK_API_USER_2.email],
    },
  ],
  default: 'abc@sgcib.com',
};
