import { AcknowledgementActions } from './acknowledgement.actions';
import {
  AcknowledgementState,
  initialAcknowledgementState,
} from './acknowledgement.models';

export function acknowledgementReducer(
  state: AcknowledgementState = initialAcknowledgementState,
  action: AcknowledgementActions
): AcknowledgementState {
  switch (action.type) {
    case 'FETCH_ACKNOWLEDGEMENTS':
      if (!action.withExpired) {
        return { ...state, acknowledgementsNotExpired: null };
      }
      return { ...state, acknowledgements: null };
    case 'FETCH_ACKNOWLEDGEMENTS_SUCCESS':
      const acknowledgementsFetched = action.acknowledgements;
      if (!action.withExpired) {
        return {
          ...state,
          acknowledgementsNotExpired: acknowledgementsFetched,
        };
      }
      return { ...state, acknowledgements: acknowledgementsFetched };
    case 'FETCH_ACKNOWLEDGEMENTS_FAIL':
      if (!action.withExpired) {
        return { ...state, acknowledgementsNotExpired: [] };
      }
      return { ...state, acknowledgements: [] };
    case 'EXPORT_ACKNOWLEDGEMENTS':
      return { ...state, exportAckOnGoing: true };
    case 'EXPORT_ACKNOWLEDGEMENTS_SUCCESS':
      return { ...state, exportAckOnGoing: false };
    case 'EXPORT_ACKNOWLEDGEMENTS_FAIL':
      return { ...state, exportAckOnGoing: false };
    default: {
      return state;
    }
  }
}
