import {
  ComplianceTravelApproval,
  CtaQuestion,
  MOCK_CTA,
} from '@models/ComplianceTravelApproval';
import { Validator, ValidatorRule } from '@models/Validator';

export interface UITravelApprovalQuestion extends CtaQuestion {
  expanded: boolean;
  isHiddenInPreview?: boolean;
}

export interface CtaSpecificState {
  loading: boolean;
  questions: UITravelApprovalQuestion[];
  saveOnGoing: boolean;
  pointOfView: string;
  fetchQuestionsPreviewOnGoing: boolean;
  taPreview: ComplianceTravelApproval;
  isDirty: boolean;
}

export const initialCtaSpecificState: CtaSpecificState = {
  loading: false,
  questions: null,
  saveOnGoing: false,
  pointOfView: 'ALL',
  fetchQuestionsPreviewOnGoing: false,
  taPreview: MOCK_CTA,
  isDirty: false,
};

export const validatorsMap: Record<string, Validator[]> = {
  SINGLE_SELECT: [
    {
      rule: ValidatorRule.REQUIRED,
      message: 'Please select your option.',
      constraint: null,
    },
  ],
  MULTI_SELECT: [
    {
      rule: ValidatorRule.REQUIRED,
      message: 'Please select your option(s).',
      constraint: null,
    },
  ],
  ACKNOWLEDGEMENT: [
    {
      rule: ValidatorRule.REQUIRED,
      message: 'Please acknowledge.',
      constraint: null,
    },
  ],
  EMAIL: [
    {
      rule: ValidatorRule.REQUIRED,
      message: 'Please fill in the field.',
      constraint: null,
    },
    {
      rule: ValidatorRule.REGEX,
      constraint: [
        `^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(socgen|sgcib|bernsteinsg).(com)(.[a-z]{2})?$`,
      ],
      message: 'Please enter a valid internal email address.',
    },
  ],
  PLAIN_TEXT: [],
  DEFAULT: [
    {
      rule: ValidatorRule.REQUIRED,
      message: 'Please fill in the field.',
      constraint: null,
    },
  ],
};

export type CtaValidationLevel =
  | 'FOR_APPROVAL'
  | 'FOR_INFORMATION'
  | 'NOT_REQUIRED';
export interface CtaValidationLevelSelect {
  label: string;
  value: CtaValidationLevel;
}

export type CtaPresetValueType = 'SUPERVISOR' | null;
export interface CtaPresetValueTypeSelect {
  label: string;
  value: CtaPresetValueType;
}

export type CtaImportantLevel = 'INFORMATIVE' | 'IMPORTANT' | 'VERY_IMPORTANT';
export interface CtaImportantLevelSelect {
  label: string;
  value: CtaImportantLevel;
}
