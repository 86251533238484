<div class="panel-group" id="status-breakdown-collapse" role="tablist">
  <div class="panel panel-default">
    <div class="panel-heading">
      <h4 class="panel-title">
        <a
          class="icon-left"
          [class.collapsed]="collapsed"
          data-toggle="collapse"
          data-parent="#status-breakdown-collapse"
          href="#status-breakdown-collapse-panel"
        >
          Travel Approval Request Validation Status
          <span
            class="label label-{{
              cta.status | ctaStatusColorPalette
            }} pull-right"
            >{{ cta.status }}</span
          >
        </a>
      </h4>
    </div>
    <div
      id="status-breakdown-collapse-panel"
      class="panel-collapse collapse"
      [class.in]="!collapsed"
    >
      <div class="panel-body">
        <div role="alert" class="alert alert-danger" *ngIf="cta.rejectReason">
          <strong>Reason of the TAR rejection</strong>
          <br />{{ cta.rejectReason }}
        </div>

        <ul class="list-group list-group-pills" *ngIf="cta.breakdownStatus">
          <ng-container
            *ngFor="
              let category of cta.breakdownStatus
                | keyvalue: disableSortingInPipe
            "
          >
            <a class="list-group-item" *ngIf="category.value.length">
              <div class="container-fluid">
                <b>{{ category.key | formatApproverCategory }}</b>
                <div
                  class="col-xs-12 col-md-12"
                  *ngFor="
                    let approver of category.value;
                    last as lastApproverGroup
                  "
                >
                  <div class="col-xs-12 col-md-6">
                    <div
                      *ngFor="
                        let approverEmail of approver.approverEmailsList;
                        first as firstApproverEmail
                      "
                    >
                      <b *ngIf="!firstApproverEmail">OR </b>
                      <span>{{ approverEmail }}</span>
                    </div>
                  </div>
                  <div class="status-validator col-xs-12 col-md-6">
                    <span
                      class="label label-{{
                        approver.status | ctaStatusColorPalette
                      }}"
                    >
                      {{ approver.status }}
                    </span>
                    <div class="validator" *ngIf="approver.validator">
                      <span class="text-reverse"
                        >Decision from <b>{{ approver.validator }}</b></span
                      >
                    </div>
                  </div>
                  <div class="col-xs-12 col-md-12">
                    <hr *ngIf="!lastApproverGroup" />
                  </div>
                </div>
              </div>
            </a>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</div>
