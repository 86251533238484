import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'fromNow',
})
export class FromNowPipe implements PipeTransform {
  public transform(value: any, _args?: any): any {
    return moment(value).fromNow();
  }
}
