import { HttpErrorResponse } from '@angular/common/http';
import { GuidelineHistory } from '@models/Guideline';
import { Action } from '@ngrx/store';

export type GuidelineActions =
  | FetchHistory
  | FetchHistoryFail
  | FetchHistorySuccess
  | FetchBusinessUnitByCountry
  | FetchBusinessUnitByCountrySuccess
  | FetchBusinessUnitByCountryFail
  | FetchBusinessUnitForGuideline
  | FetchBusinessUnitForGuidelineSuccess
  | FetchBusinessUnitForGuidelineFail;

export interface FetchHistory extends Action {
  type: 'FETCH_HISTORY';
  countryCode: string;
}
export function fetchHistory(countryCode: string): FetchHistory {
  return { type: 'FETCH_HISTORY', countryCode };
}

export interface FetchHistorySuccess extends Action {
  type: 'FETCH_HISTORY_SUCCESS';
  history: GuidelineHistory;
}
export function fetchHistorySuccess(
  history: GuidelineHistory
): FetchHistorySuccess {
  return { type: 'FETCH_HISTORY_SUCCESS', history };
}

export interface FetchHistoryFail extends Action {
  type: 'FETCH_HISTORY_FAIL';
  error: HttpErrorResponse;
}
export function fetchHistoryFail(error: HttpErrorResponse): FetchHistoryFail {
  return { type: 'FETCH_HISTORY_FAIL', error };
}

export interface FetchBusinessUnitForGuideline extends Action {
  type: 'FETCH_BUSINESS_UNIT_FOR_GUIDELINE';
  countryCode: string;
  businessUnit?: string;
}
export function fetchBusinessUnitForGuideline(
  countryCode: string,
  businessUnit?: string
): FetchBusinessUnitForGuideline {
  return {
    type: 'FETCH_BUSINESS_UNIT_FOR_GUIDELINE',
    countryCode,
    businessUnit,
  };
}
export interface FetchBusinessUnitForGuidelineSuccess extends Action {
  type: 'FETCH_BUSINESS_UNIT_FOR_GUIDELINE_SUCCESS';
  businessUnit: string[];
}
export function fetchBusinessUnitForGuidelineSuccess(
  businessUnit: string[]
): FetchBusinessUnitForGuidelineSuccess {
  return { type: 'FETCH_BUSINESS_UNIT_FOR_GUIDELINE_SUCCESS', businessUnit };
}

export interface FetchBusinessUnitForGuidelineFail extends Action {
  type: 'FETCH_BUSINESS_UNIT_FOR_GUIDELINE_FAIL';
  error: HttpErrorResponse;
}
export function fetchBusinessUnitForGuidelineFail(
  error: HttpErrorResponse
): FetchBusinessUnitForGuidelineFail {
  return { type: 'FETCH_BUSINESS_UNIT_FOR_GUIDELINE_FAIL', error };
}

export interface FetchBusinessUnitByCountry extends Action {
  type: 'FETCH_BUSINESS_UNIT_BY_COUNTRY';
  country: string;
}
export function fetchBusinessUnitByCountry(
  country: string
): FetchBusinessUnitByCountry {
  return { type: 'FETCH_BUSINESS_UNIT_BY_COUNTRY', country };
}

export interface FetchBusinessUnitByCountrySuccess extends Action {
  type: 'FETCH_BUSINESS_UNIT_BY_COUNTRY_SUCCESS';
  businessUnit: string[];
}
export function fetchBusinessUnitByCountrySuccess(
  businessUnit: string[]
): FetchBusinessUnitByCountrySuccess {
  return { type: 'FETCH_BUSINESS_UNIT_BY_COUNTRY_SUCCESS', businessUnit };
}

export interface FetchBusinessUnitByCountryFail extends Action {
  type: 'FETCH_BUSINESS_UNIT_BY_COUNTRY_FAIL';
  error: HttpErrorResponse;
}
export function fetchBusinessUniteByCountryFail(
  error: HttpErrorResponse
): FetchBusinessUnitByCountryFail {
  return { type: 'FETCH_BUSINESS_UNIT_BY_COUNTRY_FAIL', error };
}
