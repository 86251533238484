import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterStateUrlReducerState } from '@shared/utils/router/router.utils';
import { AcknowledgementState } from '@store/acknowledgement/acknowledgement.models';
import * as acknowledgementSelectors from '@store/acknowledgement/acknowledgement.selectors';
import { BusinessUnitState } from '@store/business-unit/business-unit.models';
import * as businessUnitSelectors from '@store/business-unit/business-unit.selectors';
import { ContactsDetailsState } from '@store/contacts-details/contacts-details.models';
import * as contactsDetailsSelectors from '@store/contacts-details/contacts-details.selectors';
import { CountryState } from '@store/country/country.models';
import * as countrySelectors from '@store/country/country.selectors';
import { CtaSpecificState } from '@store/cta-specific/cta-specific.models';
import * as ctaSpecificSelectors from '@store/cta-specific/cta-specific.selectors';
import { CtaState } from '@store/cta/cta.models';
import * as ctaSelectors from '@store/cta/cta.selectors';
import { DelegationState } from '@store/delegation/delegation.models';
import * as delegationSelectors from '@store/delegation/delegation.selectors';
import * as guidelineEditSelectors from '@store/guideline/edit/guideline-edit.selectors';
import { GuidelineState } from '@store/guideline/guideline.models';
import * as guidelineSelectors from '@store/guideline/guideline.selectors';
import * as guidelineViewSelectors from '@store/guideline/view/guideline-view.selectors';
import { HeaderState } from '@store/header/header.models';
import * as headerSelectors from '@store/header/header.selectors';
import { ModalState } from '@store/modal/modal.models';
import * as modalSelectors from '@store/modal/modal.selectors';
import * as routerStateSelectors from '@store/router/router-state.selectors';
import { UserState } from '@store/user/user.models';
import * as userSelectors from '@store/user/user.selectors';
import { ValidatorState } from '@store/validator/validator.models';
import * as validatorSelectors from '@store/validator/validator.selectors';

/**
 * Router selector
 */
export const getRouterReducerState =
  createFeatureSelector<RouterStateUrlReducerState>('router');
export const getRouterStateUrl = createSelector(
  getRouterReducerState,
  routerStateSelectors.getRouterStateUrl
);
export const getRouterStateParams = createSelector(
  getRouterReducerState,
  routerStateSelectors.getRouterStateParams
);
export const getRouterStateQueryParams = createSelector(
  getRouterReducerState,
  routerStateSelectors.getRouterStateQueryParams
);

/**
 * Header selector
 */
export const getHeaderState = createFeatureSelector<HeaderState>('header');
export const getHeaderMenuItems = createSelector(
  getHeaderState,
  headerSelectors.getMenuItems
);
export const getRoute = createSelector(
  getHeaderState,
  headerSelectors.getRoute
);

/**
 * Modal selector
 */
export const getModalState = createFeatureSelector<ModalState>('modal');
export const getModal = createSelector(getModalState, modalSelectors.getModal);

/**
 * Country selector
 */
export const getCountryState = createFeatureSelector<CountryState>('country');
export const getAllCountries = createSelector(
  getCountryState,
  countrySelectors.getAllCountries
);
export const getAllCountriesWithGuidelines = createSelector(
  getCountryState,
  countrySelectors.getAllCountriesWithGuidelines
);
export const getPerimeterCountries = createSelector(
  getCountryState,
  countrySelectors.getPerimeterCountries
);
export const getPerimeterCountriesWithGuidelines = createSelector(
  getCountryState,
  countrySelectors.getPerimeterCountriesWithGuidelines
);
export const getCountriesUnderOwnership = createSelector(
  getCountryState,
  countrySelectors.getCountriesUnderOwnership
);
export const getCurrentCountry = createSelector(
  getCountryState,
  countrySelectors.getCurrentCountry
);
export const getCountrySaveOnGoing = createSelector(
  getCountryState,
  countrySelectors.getSaveOnGoing
);
export const getCountryResetOnGoing = createSelector(
  getCountryState,
  countrySelectors.getResetOnGoing
);
export const getCountryPopinDisplayed = createSelector(
  getCountryState,
  countrySelectors.getPopinDisplayed
);
export const getCountryRequestPopinDisplayed = createSelector(
  getCountryState,
  countrySelectors.getRequestPopinDisplayed
);
export const getCountryRequestSubmitOnGoing = createSelector(
  getCountryState,
  countrySelectors.getRequestSubmitOnGoing
);
export const getAllCountriesWithAllValue = createSelector(
  getCountryState,
  countrySelectors.getAllCountriesWithAllValue
);
/**
 * Guideline selector
 */
export const getGuidelineState =
  createFeatureSelector<GuidelineState>('guideline');
export const getGuidelineUiState = createSelector(
  getGuidelineState,
  guidelineSelectors.getUiState
);
export const getGuideline = createSelector(
  getGuidelineState,
  guidelineSelectors.getGuideline
);
export const getHistory = createSelector(
  getGuidelineState,
  guidelineSelectors.getHistory
);
export const getHistoryOnGoing = createSelector(
  getGuidelineState,
  guidelineSelectors.getHistoryOnGoing
);
export const getBusinessUnitForGuideline = createSelector(
  getGuidelineState,
  guidelineSelectors.getBusinessUnitForGuideline
);
export const getFetchBusinessUnitForGuidelineOnGoing = createSelector(
  getGuidelineState,
  guidelineSelectors.getFetchBusinessUnitForGuidelineOnGoing
);
export const getBusinessUnitForGuidelineExport = createSelector(
  getGuidelineState,
  guidelineSelectors.getBusinessUnitForGuidelineExport
);
export const getGuidelineToSaveOrPreview = createSelector(
  getGuidelineState,
  guidelineSelectors.getGuidelineToSaveOrPreview
);

/**
 * Guideline-view selector
 */
export const getAcknowledgeOnGoing = createSelector(
  getGuidelineState,
  guidelineViewSelectors.getAcknowledgeOnGoing
);
export const getSubmitQuestionOnGoing = createSelector(
  getGuidelineState,
  guidelineViewSelectors.getSubmitQuestionOnGoing
);
export const getMailPopinDisplayed = createSelector(
  getGuidelineState,
  guidelineViewSelectors.getMailPopinDisplayed
);
export const getGuidelineViewPointOfView = createSelector(
  getGuidelineState,
  guidelineViewSelectors.getGuidelineViewPointOfView
);
export const getDisplaySubmitPracticalCaseModule = createSelector(
  getGuidelineState,
  guidelineViewSelectors.getDisplaySubmitPracticalCaseModule
);
export const getCountryProfiles = createSelector(
  getGuidelineState,
  guidelineViewSelectors.getCountryProfiles
);
export const getGuidelineForDisplay = createSelector(
  getGuidelineState,
  guidelineViewSelectors.getGuidelineForDisplay
);

/**
 * Guideline-edit selector
 */
export const getCurrentChapterIndex = createSelector(
  getGuidelineState,
  guidelineEditSelectors.getCurrentChapterIndex
);
export const getSaveOnGoing = createSelector(
  getGuidelineState,
  guidelineEditSelectors.getSaveOnGoing
);
export const getGuidelineEditPointOfView = createSelector(
  getGuidelineState,
  guidelineEditSelectors.getGuidelineEditPointOfView
);
export const getDefaultScopeForCreateSubsection = createSelector(
  getGuidelineState,
  guidelineEditSelectors.getDefaultScopeForCreateSubsection
);
export const getPreviewOnGoing = createSelector(
  getGuidelineState,
  guidelineEditSelectors.getPreviewOnGoing
);
export const getGuidelinePreview = createSelector(
  getGuidelineState,
  guidelineEditSelectors.getGuidelinePreview
);
export const getGuidelineConfigurationFormIsDirty = createSelector(
  getGuidelineState,
  guidelineEditSelectors.getGuidelineConfigurationFormIsDirty
);
export const getBusinessUnitsForReacknowledgement = createSelector(
  getGuidelineState,
  guidelineEditSelectors.getBusinessUnitsForReacknowledgement
);
export const getGuidelineSourceOwners = createSelector(
  getGuidelineState,
  guidelineEditSelectors.getGuidelineSourceOwners
);
export const getFetchSourceOwnersOnGoing = createSelector(
  getGuidelineState,
  guidelineEditSelectors.getFetchSourceOwnersOnGoing
);

/**
 * User selector
 */
export const getUserState = createFeatureSelector<UserState>('user');
export const getCurrentUser = createSelector(
  getUserState,
  userSelectors.getCurrentUser
);
export const getFakeSSOUser = createSelector(
  getUserState,
  userSelectors.getFakeSSOUser
);
export const getCurrentUserAccesses = createSelector(
  getUserState,
  userSelectors.getCurrentUserAccesses
);
export const getTravelerUser = createSelector(
  getUserState,
  userSelectors.getTravelerUser
);
export const getUserForCta = createSelector(
  getUserState,
  (userState, { onDelegation }) =>
    userSelectors.getUserForCta(userState, onDelegation)
);
/**
 * Acknowledgement selector
 */
export const getAcknowledgementState =
  createFeatureSelector<AcknowledgementState>('acknowledgement');
export const getAcknowledgements = createSelector(
  getAcknowledgementState,
  acknowledgementSelectors.getAcknowledgements
);
export const getAcknowledgedCountries = createSelector(
  getAcknowledgementState,
  acknowledgementSelectors.getAcknowledgedCountries
);
export const getAcknowledgedAndNotExpiredCountries = createSelector(
  getAcknowledgementState,
  acknowledgementSelectors.getAcknowledgedAndNotExpiredCountries
);
export const getExportAckOngoing = createSelector(
  getAcknowledgementState,
  acknowledgementSelectors.getExportAckOngoing
);

/**
 * CTA selector
 */
export const getTAState = createFeatureSelector<CtaState>('cta');
export const getAllCtas = createSelector(getTAState, ctaSelectors.getAllCtas);
export const getLatestCtas = createSelector(
  getTAState,
  ctaSelectors.getLatestCtas
);
export const getCurrentCta = createSelector(
  getTAState,
  ctaSelectors.getCurrentCta
);
export const getCtaStats = createSelector(getTAState, ctaSelectors.getCtaStats);
export const getSubmitCtaOnGoing = createSelector(
  getTAState,
  ctaSelectors.getSubmitCtaOnGoing
);
export const getFetchCtaOnGoing = createSelector(
  getTAState,
  ctaSelectors.getFetchCtaOnGoing
);

export const getSpecificCtaForApproval = createSelector(
  getTAState,
  ctaSelectors.getSpecificCtaForApproval
);

export const getCtasForApproval = createSelector(
  getTAState,
  ctaSelectors.getCtasForApproval
);
export const getCtasAlreadyProcessed = createSelector(
  getTAState,
  ctaSelectors.getCtasAlreadyProcessed
);
export const getApprovalCtaOnGoing = createSelector(
  getTAState,
  ctaSelectors.getApprovalCtaOnGoing
);
export const getRejectModeEnabled = createSelector(
  getTAState,
  ctaSelectors.getRejectModeEnabled
);
export const getSelectedCtaForApprovalIndex = createSelector(
  getTAState,
  ctaSelectors.getSelectedCtaForApprovalIndex
);
export const getWorkingDaysMessage = createSelector(
  getTAState,
  ctaSelectors.getWorkingDaysMessage
);
export const getSupervisor = createSelector(
  getTAState,
  ctaSelectors.getSupervisor
);
export const getFetchSupervisorOnGoing = createSelector(
  getTAState,
  ctaSelectors.getFetchSupervisorOnGoing
);
export const getLateDeclarationWarning = createSelector(
  getTAState,
  ctaSelectors.getLateDeclarationWarning
);
export const getCtaForDuplication = createSelector(
  getTAState,
  ctaSelectors.getCtaForDuplication
);

export const getExportCtaDashboardOnGoing = createSelector(
  getTAState,
  ctaSelectors.getExportCtaDashboardOnGoing
);
export const getFetchStatsOnGoing = createSelector(
  getTAState,
  ctaSelectors.getFetchStatsOnGoing
);

/**
 * CTA specific selector
 */
export const selectTaSpecific =
  createFeatureSelector<CtaSpecificState>('ctaSpecific');
export const getTaSpecificQuestions = createSelector(
  selectTaSpecific,
  ctaSpecificSelectors.getQuestions
);
export const getTaSpecificSaveOnGoing = createSelector(
  selectTaSpecific,
  ctaSpecificSelectors.getSaveOnGoing
);
export const getTaSpecificLoading = createSelector(
  selectTaSpecific,
  ctaSpecificSelectors.getLoading
);
export const getTaSpecificPointOfView = createSelector(
  selectTaSpecific,
  ctaSpecificSelectors.getTaSpecificPointOfView
);

export const getDefaultScopeForCreateQuestion = createSelector(
  selectTaSpecific,
  ctaSpecificSelectors.getDefaultScopeForCreateQuestion
);

export const getQuestionsPreviewOnGoing = createSelector(
  selectTaSpecific,
  ctaSpecificSelectors.getQuestionsPreviewOnGoing
);

export const getTaPreview = createSelector(
  selectTaSpecific,
  ctaSpecificSelectors.getTaPreview
);
export const getCtaSpecificConfigurationFormIsDirty = createSelector(
  selectTaSpecific,
  ctaSpecificSelectors.getCtaSpecificConfigurationFormIsDirty
);
export const getTaSpecificQuestionsToSaveOrPreview = createSelector(
  selectTaSpecific,
  ctaSpecificSelectors.getTaSpecificQuestionsToSaveOrPreview
);
export const getCtaIsNotEditableCheckboxVisibility = createSelector(
  selectTaSpecific,
  (state, { qIndex }) =>
    ctaSpecificSelectors.getCtaIsNotEditableCheckboxVisibility(state, qIndex)
);
export const getIsEmailFieldDisabled = createSelector(
  selectTaSpecific,
  (state, { uuid }) => ctaSpecificSelectors.getIsEmailFieldDisabled(state, uuid)
);
export const getIsEmailFieldCcCheckboxVisible = createSelector(
  selectTaSpecific,
  (state, { uuid }) =>
    ctaSpecificSelectors.getIsEmailFieldCcCheckboxVisible(state, uuid)
);

/**
 * CTA and CTA specific multiple state selector
 */
export const getIsVariableQuestionnaireValidated = createSelector(
  getTAState,
  selectTaSpecific,
  ctaSelectors.getIsVariableQuestionnaireValidated
);
export const getAnswer = createSelector(
  getTAState,
  selectTaSpecific,
  (taState, taSpecificState, { uuid }) =>
    ctaSelectors.getAnswer(taState, taSpecificState, uuid)
);
export const getIsEmailFieldCcCheckboxChecked = createSelector(
  getTAState,
  selectTaSpecific,
  (taState, taSpecificState, { uuid }) =>
    ctaSelectors.getIsEmailFieldCcCheckboxChecked(
      taState,
      taSpecificState,
      uuid
    )
);
export const getIsQuestionVisible = createSelector(
  getTAState,
  selectTaSpecific,
  (taState, taSpecificState, { uuid }) =>
    ctaSelectors.getIsQuestionVisible(taState, taSpecificState, uuid)
);
export const getCurrentCtaWithVisibleQuestion = createSelector(
  getTAState,
  selectTaSpecific,
  ctaSelectors.getCurrentCtaWithVisibleQuestion
);
export const getCurrentCtaQuestion = createSelector(
  getTAState,
  selectTaSpecific,
  ctaSelectors.getCurrentCtaQuestion
);

/**
 * Validator selector
 */
export const selectValidator =
  createFeatureSelector<ValidatorState>('validator');
export const getCommonFormValidator = createSelector(
  selectValidator,
  validatorSelectors.getCommonFormValidator
);
export const getSupervisorFieldValidator = createSelector(
  selectValidator,
  validatorSelectors.getSupervisorFieldValidator
);

/**
 * Business Unit selector
 */
export const selectBusinessUnit =
  createFeatureSelector<BusinessUnitState>('businessUnit');
export const getAllBusinessUnits = createSelector(
  selectBusinessUnit,
  businessUnitSelectors.getAllBusinessUnits
);
export const getPerimeterBusinessUnits = createSelector(
  selectBusinessUnit,
  businessUnitSelectors.getPerimeterBusinessUnits
);
export const getAllBusinessUnitsWithAllValue = createSelector(
  selectBusinessUnit,
  businessUnitSelectors.getAllBusinessUnitsWithAllValue
);
export const getPerimeterBusinessUnitsWithAllValue = createSelector(
  selectBusinessUnit,
  businessUnitSelectors.getPerimeterBusinessUnitsWithAllValue
);
export const getFetchBusinessUnitOnGoing = createSelector(
  selectBusinessUnit,
  businessUnitSelectors.getFetchBusinessUnitsOnGoing
);

/**
 * Contacts Details selector
 */
export const selectContactsDetails =
  createFeatureSelector<ContactsDetailsState>('contactsDetails');
export const getAllContacts = createSelector(
  selectContactsDetails,
  contactsDetailsSelectors.getAllContacts
);
export const getCurrentPageNumber = createSelector(
  selectContactsDetails,
  contactsDetailsSelectors.getCurrentPageNumber
);
export const getTotalPageNumber = createSelector(
  selectContactsDetails,
  contactsDetailsSelectors.getTotalPageNumber
);
export const getFetchContactsOnGoing = createSelector(
  selectContactsDetails,
  contactsDetailsSelectors.getFetchContactsOnGoing
);

/**
 * Delegate selector
 */
export const selectDelegate =
  createFeatureSelector<DelegationState>('delegation');
export const getAllDelegates = createSelector(
  selectDelegate,
  delegationSelectors.getAllDelegates
);
export const getFetchDelegatesOnGoing = createSelector(
  selectDelegate,
  delegationSelectors.getFetchDelegatesOnGoing
);
export const getAddDelegateOnGoing = createSelector(
  selectDelegate,
  delegationSelectors.getAddDelegateOnGoing
);
export const getDeleteDelegateOnGoing = createSelector(
  selectDelegate,
  delegationSelectors.getDeleteDelegateOnGoing
);
export const getAllPrincipals = createSelector(
  selectDelegate,
  delegationSelectors.getAllPrincipals
);
export const getFetchPrincipalsOnGoing = createSelector(
  selectDelegate,
  delegationSelectors.getFetchPrincipalsOnGoing
);
export const getDelegatedCtas = createSelector(
  selectDelegate,
  delegationSelectors.getDelegatedCtas
);
export const getFetchDelegatedCtasOnGoing = createSelector(
  selectDelegate,
  delegationSelectors.getFetchDelegatedCtasOnGoing
);
