import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import { Validator, ValidatorRule } from '@models/Validator';
import { warningMessagesGenerator } from '@store/validator/validator.helper';

@Component({
  selector: 'sgxb-combo-box',
  templateUrl: './combo-box.component.html',
  styleUrls: ['./combo-box.component.scss'],
})
export class ComboBoxComponent {
  @ViewChild('input', { static: false }) public input: ElementRef;
  @ViewChild('suggestionsBox', { static: false })
  public suggestionsBox: ElementRef;

  @Input() public displayWith: string = null;
  @Input() public items: any[] = null;
  @Input() public label: string = null;
  @Input() public placeholder = '';
  @Input() public icon: string = null;
  @Input() public iconStyle: string = null;

  @Input() public tabAsEnter = false;

  @Output() public selected = new EventEmitter();
  @Output() public focus = new EventEmitter<FocusEvent>();
  @Output() public blur = new EventEmitter<FocusEvent>();
  @Output() public validate = new EventEmitter<boolean>();

  private _disabled = false;
  private _validators: Validator[];
  private _value: any = null;
  public inputValue = '';
  public suggestionIndex = 0;
  public active = false;
  public touched = false;
  public warnings: string[] = [];
  public clickInside = false;
  public selectedItem: any | null = null;
  @Input() set value(value: any) {
    this._value = value;
    if (!value) {
      this.clearInputValue();
      return;
    }
    const text = this.getDisplayText(value);
    if (!text || text === '' || typeof text !== 'string') {
      this.clearInputValue();
    } else {
      this.inputValue = text;
      this.selectedItem = this.items
        ? this.items.findIndex((o) => o === value)
        : null;
    }
    if (
      (this.displayWith && value !== null && value[this.displayWith]) ||
      (!this.displayWith && value !== '')
    ) {
      this.touched = true;
    }
  }
  get value(): any {
    return this._value;
  }
  @Input() set disabled(value: boolean) {
    this._disabled = value;
    this.touched = false;
    if (this._disabled) {
      this.validate.emit(true);
    }
  }
  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set validators(validators: Validator[]) {
    this._validators = validators || null;
  }
  get validators(): Validator[] {
    return this._validators;
  }

  constructor() {}

  get required(): boolean {
    if (this.validators) {
      return (
        !this.disabled &&
        !!this.validators.find(
          (validator) => validator.rule === ValidatorRule.REQUIRED
        )
      );
    }
    return !this.disabled;
  }

  get validity(): boolean {
    this.warnings = [];

    if (!this.disabled && this.validators && this.validators.length > 0) {
      const conditions = this.validators
        .map((validator) => {
          switch (validator.rule) {
            case ValidatorRule.REQUIRED: {
              return {
                rule: validator.rule,
                shouldRaiseWarning:
                  !this.value ||
                  (typeof this.value === 'string' && this.value === '') ||
                  (typeof this.value === 'object' &&
                    this.displayWith &&
                    this.value[this.displayWith] === ''),
              };
            }
            default:
              return;
          }
        })
        .filter((o) => o !== null && o !== undefined);
      this.warnings = warningMessagesGenerator(
        this.warnings,
        this.validators,
        conditions
      );
    }

    /* Combo-box is valid when :
        - Validator rules are undefined (dashboard, filter as, find country,  )
        - List of validator rules are defined AND there is no warnings (CTA Form)
    */
    const valid =
      !this.validators ||
      (this.validators &&
        this.validators.length > 0 &&
        this.warnings &&
        this.warnings.length === 0);
    this.validate.emit(valid);
    return valid;
  }

  get suggestions(): any[] {
    if (this.disabled) {
      return [];
    }
    if (this.inputValue.length === 0) {
      return this.items;
    }
    const suggestions = this.items.filter((item) => {
      // Items are normalized (diacritics removed) before comparaison with the input values
      return (
        this.getDisplayText(item)
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .indexOf(this.inputValue.toLowerCase()) === 0
      );
    });
    if (suggestions.length) {
      const firstSuggestion = this.getDisplayText(suggestions[0]);
      if (suggestions.length === 1 && this.inputValue === firstSuggestion) {
        return [];
      }
    }
    this.selectedItem = null;
    if (this.suggestionIndex >= suggestions.length) {
      this.suggestionIndex = 0;
    }
    return suggestions;
  }

  get suggestedText(): string {
    if (this.disabled) {
      return '';
    }
    if (!this.active) {
      return '';
    }
    if (!this.suggestions || !this.suggestions.length) {
      return '';
    }

    const text = this.getDisplayText(this.suggestions[this.suggestionIndex]);
    return this.inputValue + text.substr(this.inputValue.length);
  }

  @HostListener('click') public hostClick() {
    if (this.disabled) {
      return;
    }

    this.clickInside = true;
  }

  @HostListener('document:click') public documentClick() {
    if (this.disabled) {
      return;
    }

    if (!this.clickInside && this.selectedItem === null) {
      this.input.nativeElement.blur();
    }
    this.active = this.clickInside;
    this.clickInside = false;
  }

  public onBlur(event: FocusEvent) {
    this.blur.emit(event);
    if (this.selectedItem === null) {
      this.clearInputValue();
    }
    this.touched = true;
  }

  public onFocus(event: FocusEvent) {
    this.focus.emit(event);
    this.active = true;
  }

  public onTab(_event: KeyboardEvent): void {
    this.onLeave();
    if (this.tabAsEnter) {
      this.selectSuggestion();
    }
  }

  public onShiftTab(_event: KeyboardEvent): void {
    this.onLeave();
  }

  public onLeave(): void {
    this.active = false;
  }

  public onBackspace(_event: KeyboardEvent): void {
    this.selected.emit(null);
    this.suggestionIndex = 0;
    this.selectedItem = null;
  }

  public onEsc(_event: KeyboardEvent): void {
    this.clearInputValue();
  }

  public onEnter(event: KeyboardEvent): void {
    event.preventDefault();
    this.selectSuggestion();
  }

  public onArrowUp(_event: KeyboardEvent): void {
    this.previousSuggestion();
  }

  public onArrowDown(_event: KeyboardEvent): void {
    this.nextSuggestion();
  }

  public onButtonClick(event: MouseEvent): void {
    event.preventDefault();
    this.clearInputValue();
    this.focusInput();
  }

  public onSuggestionClick(_event: MouseEvent): void {
    this.selectSuggestion();
  }

  public getDisplayText(item: any): string {
    return this.displayWith
      ? item[this.displayWith]
        ? item[this.displayWith]
        : null
      : item;
  }

  // private isAnOption(value: any): boolean {
  //   if (!this.items) {
  //     return false;
  //   }
  //   if (this.displayWith) {
  //     return this.items.map(o => o[this.displayWith]).indexOf(value[this.displayWith]) >= 0;
  //   } else {
  //     return this.items.indexOf(value) >= 0;
  //   }
  // }

  private focusInput(): void {
    this.input.nativeElement.focus();
  }

  private clearInputValue(): void {
    this.inputValue = '';
    this.selectedItem = null;
    this.selected.emit(null);
  }

  private previousSuggestion(): void {
    if (!this.suggestionsBox) {
      return;
    }
    if (event.defaultPrevented) {
      return;
    }
    this.suggestionIndex =
      (this.suggestions.length + this.suggestionIndex - 1) %
      this.suggestions.length;
    this.suggestionsBox.nativeElement.children[
      this.suggestionIndex
    ].scrollIntoView(false);
  }

  private nextSuggestion(): void {
    if (!this.suggestionsBox) {
      return;
    }
    if (event.defaultPrevented) {
      return;
    }
    this.suggestionIndex = (this.suggestionIndex + 1) % this.suggestions.length;
    this.suggestionsBox.nativeElement.children[
      this.suggestionIndex
    ].scrollIntoView(false);
  }

  private selectSuggestion(): void {
    if (this.disabled) {
      return;
    }
    if (!this.suggestions.length) {
      return;
    }
    const item = this.suggestions[this.suggestionIndex];
    this.inputValue = this.getDisplayText(item);
    this.suggestionIndex = 0;
    this.selectedItem = item;
    this.selected.emit(item);
    this.focusInput();
    this.active = false;
  }
}
