import { Acknowledgement } from '@models/Acknowledgement';
import { Action } from '@ngrx/store';

export type AcknowledgementActions =
  | FetchAcknowledgements
  | FetchAcknowledgementsSuccess
  | FetchAcknowledgementsFail
  | ExportAcknowledgements
  | ExportAcknowledgementsSuccess
  | ExportAcknowledgementsFail;

export interface FetchAcknowledgements extends Action {
  type: 'FETCH_ACKNOWLEDGEMENTS';
  withExpired: boolean;
  traveler: string;
}
export function fetchAcknowledgements(
  withExpired: boolean = true,
  traveler?: string
): FetchAcknowledgements {
  return {
    type: 'FETCH_ACKNOWLEDGEMENTS',
    withExpired,
    traveler,
  };
}

export interface FetchAcknowledgementsSuccess extends Action {
  type: 'FETCH_ACKNOWLEDGEMENTS_SUCCESS';
  acknowledgements: Acknowledgement[];
  withExpired: boolean;
}
export function fetchAcknowledgementsSuccess(
  acknowledgements: Acknowledgement[],
  withExpired = true
): FetchAcknowledgementsSuccess {
  return {
    type: 'FETCH_ACKNOWLEDGEMENTS_SUCCESS',
    acknowledgements,
    withExpired,
  };
}

export interface FetchAcknowledgementsFail extends Action {
  type: 'FETCH_ACKNOWLEDGEMENTS_FAIL';
  payload: any;
  withExpired: boolean;
}
export function fetchAcknowledgementsFail(
  payload: any,
  withExpired = true
): FetchAcknowledgementsFail {
  return {
    type: 'FETCH_ACKNOWLEDGEMENTS_FAIL',
    payload,
    withExpired,
  };
}

export interface ExportAcknowledgements extends Action {
  type: 'EXPORT_ACKNOWLEDGEMENTS';
  countryCode: string;
  countryName: string;
}
export function exportAcknowledgements(
  countryCode: string,
  countryName: string
): ExportAcknowledgements {
  return {
    type: 'EXPORT_ACKNOWLEDGEMENTS',
    countryCode,
    countryName,
  };
}
export interface ExportAcknowledgementsSuccess extends Action {
  type: 'EXPORT_ACKNOWLEDGEMENTS_SUCCESS';
  blob: Blob;
}
export function exportAcknowledgementsSuccess(
  blob: Blob
): ExportAcknowledgementsSuccess {
  return {
    type: 'EXPORT_ACKNOWLEDGEMENTS_SUCCESS',
    blob,
  };
}
export interface ExportAcknowledgementsFail extends Action {
  type: 'EXPORT_ACKNOWLEDGEMENTS_FAIL';
  payload: any;
}
export function exportAcknowledgementsFail(
  payload: any
): ExportAcknowledgementsFail {
  return {
    type: 'EXPORT_ACKNOWLEDGEMENTS_FAIL',
    payload,
  };
}
