import { HttpErrorResponse } from '@angular/common/http';
import { Delegate } from '@models/Delegate';
import { Action } from '@ngrx/store';
import { User } from '@models/User';
import { ComplianceTravelApproval } from '@models/ComplianceTravelApproval';

export type DelegationActions =
  | FetchDelegates
  | FetchDelegatesSuccess
  | FetchDelegatesFail
  | AddDelegate
  | AddDelegateSuccess
  | AddDelegateFail
  | DeleteDelegate
  | DeleteDelegateSuccess
  | DeleteDelegateFail
  | FetchPrincipals
  | FetchPrincipalsSuccess
  | FetchPrincipalsFail
  | FetchDelegatedCtas
  | FetchDelegatedCtasSuccess
  | FetchDelegatedCtasFail;

export interface FetchDelegates extends Action {
  type: 'FETCH_DELEGATES';
}
export function fetchDelegates(): FetchDelegates {
  return {
    type: 'FETCH_DELEGATES',
  };
}

export interface FetchDelegatesSuccess extends Action {
  type: 'FETCH_DELEGATES_SUCCESS';
  delegates: Delegate[];
}
export function fetchDelegatesSuccess(
  delegates: Delegate[]
): FetchDelegatesSuccess {
  return {
    type: 'FETCH_DELEGATES_SUCCESS',
    delegates,
  };
}

export interface FetchDelegatesFail extends Action {
  type: 'FETCH_DELEGATES_FAIL';
  error: HttpErrorResponse;
}
export function fetchDelegatesFail(
  error: HttpErrorResponse
): FetchDelegatesFail {
  return {
    type: 'FETCH_DELEGATES_FAIL',
    error,
  };
}

export interface AddDelegate extends Action {
  type: 'ADD_DELEGATE';
  delegate: string;
}

export function addDelegate(delegate: string): AddDelegate {
  return {
    type: 'ADD_DELEGATE',
    delegate,
  };
}

export interface AddDelegateSuccess extends Action {
  type: 'ADD_DELEGATE_SUCCESS';
  delegate: Delegate;
}

export function addDelegateSuccess(delegate: Delegate): AddDelegateSuccess {
  return {
    type: 'ADD_DELEGATE_SUCCESS',
    delegate,
  };
}

export interface AddDelegateFail extends Action {
  type: 'ADD_DELEGATE_FAIL';
  error: HttpErrorResponse;
}

export function addDelegateFail(error: HttpErrorResponse): AddDelegateFail {
  return {
    type: 'ADD_DELEGATE_FAIL',
    error,
  };
}

export interface DeleteDelegate extends Action {
  type: 'DELETE_DELEGATE';
  uuid: string;
}

export function deleteDelegate(uuid: string): DeleteDelegate {
  return {
    type: 'DELETE_DELEGATE',
    uuid,
  };
}

export interface DeleteDelegateSuccess extends Action {
  type: 'DELETE_DELEGATE_SUCCESS';
}

export function deleteDelegateSuccess(): DeleteDelegateSuccess {
  return {
    type: 'DELETE_DELEGATE_SUCCESS',
  };
}

export interface DeleteDelegateFail extends Action {
  type: 'DELETE_DELEGATE_FAIL';
  error: HttpErrorResponse;
}

export function deleteDelegateFail(
  error: HttpErrorResponse
): DeleteDelegateFail {
  return {
    type: 'DELETE_DELEGATE_FAIL',
    error,
  };
}

export interface FetchPrincipals extends Action {
  type: 'FETCH_PRINCIPALS';
}
export function fetchPrincipals(): FetchPrincipals {
  return {
    type: 'FETCH_PRINCIPALS',
  };
}

export interface FetchPrincipalsSuccess extends Action {
  type: 'FETCH_PRINCIPALS_SUCCESS';
  principals: User[];
}
export function fetchPrincipalsSuccess(
  principals: User[]
): FetchPrincipalsSuccess {
  return {
    type: 'FETCH_PRINCIPALS_SUCCESS',
    principals,
  };
}

export interface FetchPrincipalsFail extends Action {
  type: 'FETCH_PRINCIPALS_FAIL';
  error: HttpErrorResponse;
}
export function fetchPrincipalsFail(
  error: HttpErrorResponse
): FetchPrincipalsFail {
  return {
    type: 'FETCH_PRINCIPALS_FAIL',
    error,
  };
}

export type FetchDelegatedCtas = ReturnType<typeof fetchDelegatedCtas>;
export function fetchDelegatedCtas(limit?: number) {
  return {
    type: 'FETCH_DELEGATED_CTAS' as const,
    limit,
  };
}

export type FetchDelegatedCtasSuccess = ReturnType<
  typeof fetchDelegatedCtasSuccess
>;
export function fetchDelegatedCtasSuccess(payload: ComplianceTravelApproval[]) {
  return {
    type: 'FETCH_DELEGATED_CTAS_SUCCESS' as const,
    payload,
  };
}

export type FetchDelegatedCtasFail = ReturnType<typeof fetchDelegatedCtasFail>;
export function fetchDelegatedCtasFail(error: HttpErrorResponse) {
  return {
    type: 'FETCH_DELEGATED_CTAS_FAIL' as const,
    error,
  };
}
