import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GuidelineHistory } from '@models/Guideline';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { ConfigureRouteRegex } from '@shared/utils/regex/routes.regex';
import { fetchCountryByCountryCode } from '@store/country/country.actions';
import { fetchGuidelineForConfiguration } from '@store/guideline/edit/guideline-edit.actions';
import { showWarningNotification } from '@store/header/header.actions';
import { AppState } from '@store/root/root.reducer';
import { getRouterStateUrl } from '@store/root/root.selectors';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  FetchBusinessUnitForGuideline,
  FetchHistory,
  fetchBusinessUnitForGuidelineFail,
  fetchBusinessUnitForGuidelineSuccess,
  fetchHistory,
  fetchHistoryFail,
  fetchHistorySuccess,
} from './guideline.actions';

@Injectable()
export class GuidelineEffects {
  // Route: /configure/${countryCode}/
  public navigateToConfigure$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RouterNavigationAction>(ROUTER_NAVIGATION),
      withLatestFrom(
        this.store$.select(getRouterStateUrl),
        (_action, router) => router
      ),
      filter((url) => ConfigureRouteRegex.test(url)),
      map<string, string>((url) => url.match(ConfigureRouteRegex)[1]),
      mergeMap((countryCode) => {
        return [
          fetchGuidelineForConfiguration(countryCode),
          fetchHistory(countryCode),
          fetchCountryByCountryCode(countryCode),
        ];
      })
    )
  );

  public fetchBusinessUnitForGuideline$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchBusinessUnitForGuideline>(
        'FETCH_BUSINESS_UNIT_FOR_GUIDELINE'
      ),
      switchMap(({ countryCode, businessUnit }) => {
        var url = `gateway/guidelines/${countryCode}${
          businessUnit ? `?businessUnit=${businessUnit}` : '/businessUnit'
        }`;
        return this.http.get(url).pipe(
          map((res: string[]) => fetchBusinessUnitForGuidelineSuccess(res)),
          catchError((err: HttpErrorResponse) => {
            return [
              showWarningNotification(err.error.message),
              fetchBusinessUnitForGuidelineFail(err),
            ];
          })
        );
      })
    )
  );

  public fetchHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchHistory>('FETCH_HISTORY'),
      switchMap(({ countryCode: countryCode }) =>
        this.http.get(`gateway/guidelines/${countryCode}/history`).pipe(
          map((res: GuidelineHistory) => fetchHistorySuccess(res)),
          catchError((err: HttpErrorResponse) => {
            return [
              showWarningNotification(err.error.message),
              fetchHistoryFail(err),
            ];
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store$: Store<AppState>
  ) {}
}
