import { CtaAnswer, CtaQuestion } from '@models/ComplianceTravelApproval';

export function getExpectedAnswerByOptionUUID(
  question: CtaQuestion,
  uuid: string
): string | undefined {
  const expectedAnswer =
    question && question.options
      ? question.options.find((option) => option.uuid === uuid)
      : undefined;
  return expectedAnswer ? expectedAnswer.text : undefined;
}

export function getIsAnswerMatched(
  actualAnswer: CtaAnswer,
  expectedAnswer: string
) {
  return actualAnswer && actualAnswer.answer && expectedAnswer
    ? actualAnswer.answer.split('\r\n').indexOf(expectedAnswer) !== -1
    : false;
}
