import { Component, Input } from '@angular/core';
import { ComplianceTravelApproval } from '@models/ComplianceTravelApproval';

@Component({
  selector: 'sgxb-travel-approval-standard-details',
  templateUrl: './travel-approval-standard-details.component.html',
  styleUrls: ['./travel-approval-standard-details.component.scss'],
})
export class TravelApprovalStandardDetailsComponent {
  @Input() public ta: ComplianceTravelApproval;
  constructor() {}

  get isSupervisorDetailHidden(): boolean {
    return !(
      this.ta &&
      /* this.ta.supervisor && */ this.ta.destination &&
      this.ta.destination.alpha2 === 'GB'
    );
  }
}
