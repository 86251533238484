import { Modal } from '@models/Modal';

export interface ModalState {
  modal: Modal;
}

export const initialModalState: ModalState = {
  modal: {
    content: null,
    failCallback: null,
    successCallback: null,
    title: null,
    type: 'alert',
  },
};
