import { Pipe, PipeTransform } from '@angular/core';
import { FormFieldValidator } from '@models/Validator';

@Pipe({ name: 'extractFieldValidator' })
export class ExtractFieldValidatorPipe implements PipeTransform {
  public transform(
    formFieldValidators: FormFieldValidator[],
    fieldName: string
  ): FormFieldValidator | undefined {
    return formFieldValidators
      ? formFieldValidators.find((o) => o.field === fieldName)
      : undefined;
  }
}
