import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MOCK_API_GENERIC_SUCCESS_RESPONSE } from 'app/mock-api/mocks/generic-response.mocks';
import {
  MOCK_API_CTAs,
  MOCK_API_DUPLICATED_CTA,
  MOCK_API_TAR_ALREADY_PROCESSED,
  MOCK_API_TAR_APPROVAL_ERROR_MESSAGE,
  MOCK_API_TAR_DASHBOARD_EXPORT,
  MOCK_API_TAR_FOR_APPROVAL,
  MOCK_API_TAR_LATE_DECLARATION_WARNING,
  MOCK_API_TAR_PENDING,
  MOCK_API_TAR_WORKING_DAYS_MESSAGE,
  MOCK_API_TAR_WORKING_DAYS_MESSAGE_DELEGATION,
  SAMPLE_DASHBOARD_DATA,
} from 'app/mock-api/mocks/travel-approval.mocks';
import { ok } from 'app/mock-api/utils/response.utils';
import { Observable, of } from 'rxjs';
import { delay, dematerialize, materialize, mergeMap } from 'rxjs/operators';

@Injectable()
export class TravelApprovalRequestInterceptor implements HttpInterceptor {
  constructor() {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return <Observable<HttpEvent<any>>>of(null).pipe(
      mergeMap(() => {
        if (
          request.url.match(/\/v2\/export\/travelApprovals$/) &&
          request.params.get('direction').match(/^to&|^from$/) &&
          (request.params.get('country').match(/^([A-Z]{2})$|^all$/) ||
            !request.params.get('country')) &&
          request.params.get('businessUnit').match(/^([A-Z]{2})$|^all$/) &&
          request.params.get('fromDate').match(/^[0-9\-]{10}$/) &&
          request.params.get('toDate').match(/^[0-9\-]{10}$/) &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_TAR_DASHBOARD_EXPORT);
        } else if (
          request.url.match(/\/export\/travelApprovals$/) &&
          request.params.get('destination').match(/^([A-Z]{2})$|^all$/) &&
          request.params.get('fromDate').match(/^[0-9\-]{10}$/) &&
          request.params.get('toDate').match(/^[0-9\-]{10}$/) &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_TAR_DASHBOARD_EXPORT);
        } else if (
          request.url.match(/\/travelApprovals\/([A-Z]{2})\/workingDays$/) &&
          request.params.get('startDate').match(/^[0-9\-]{10}$/) &&
          request.params.get('endDate').match(/^[0-9\-]{10}$/) &&
          request.params.get('traveler') &&
          request.params
            .get('traveler')
            .match(
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(socgen|sgcib).(com)(.[a-z]{2})?$/
            ) &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_TAR_WORKING_DAYS_MESSAGE_DELEGATION);
        } else if (
          request.url.match(/\/travelApprovals\/([A-Z]{2})\/workingDays$/) &&
          request.params.get('startDate').match(/^[0-9\-]{10}$/) &&
          request.params.get('endDate').match(/^[0-9\-]{10}$/) &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_TAR_WORKING_DAYS_MESSAGE);
        } else if (
          request.url.endsWith('/travelApprovals/lateDeclaration') &&
          request.params.get('startDate').match(/^[0-9\-]{10}$/) &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_TAR_LATE_DECLARATION_WARNING);
        } else if (
          request.url.endsWith('/travelApprovals/stats') &&
          request.params.get('countryCode').match(/^[A-Z]{2}$/) &&
          request.params.get('startDate').match(/^[0-9\-]{10}$/) &&
          request.params.get('endDate').match(/^[0-9\-]{10}$/) &&
          request.method === 'GET'
        ) {
          return ok(SAMPLE_DASHBOARD_DATA);
        } else if (
          request.url.endsWith('/travelApprovals/delegates') &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_CTAs);
        } else if (
          request.url.endsWith('/travelApprovals/last') &&
          request.method === 'GET' &&
          request.params.get('traveler') &&
          request.params
            .get('traveler')
            .match(
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(socgen|sgcib).(com)(.[a-z]{2})?$/
            )
        ) {
          return ok(undefined);
        } else if (
          request.url.match(/\/travelApprovals\/([0-9a-zA-Z\-]+)$/) &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_TAR_PENDING);
        } else if (
          request.url.match(
            /\/travelApprovals\/([0-9a-zA-Z\-]+)\/duplicate$/
          ) &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_DUPLICATED_CTA);
        } else if (
          request.url.endsWith('/travelApprovals/myself/latest') &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_CTAs);
        } else if (
          request.url.endsWith('/travelApprovals/myself/last') &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_TAR_PENDING);
        } else if (
          request.url.match(/\/travelApprovals\/myself\/latest$/) &&
          request.params.get('limit').match(/^[0-9]+$/) &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_CTAs);
          // } else if (
          //   request.url.match(/\/travelApprovals\/([A-Z]{2})\/stats$/) &&
          //   request.params.get('start').match(/^[0-9\-]{10}$/) &&
          //   request.params.get('end').match(/^[0-9\-]{10}$/) &&
          //   request.method === 'GET'
          // ) {
          //   return ok(MOCK_API_TAR_STATS);
        } else if (
          request.url.endsWith('/travelApprovals') &&
          request.method === 'POST'
        ) {
          return ok(MOCK_API_TAR_PENDING);
        } else if (
          request.url.match(/\/travelApprovals\/([0-9a-zA-Z\-]+)$/) &&
          request.method === 'PUT'
        ) {
          return ok(MOCK_API_TAR_PENDING);
        } else if (
          request.url.match(/\/travelApprovals\/([0-9a-zA-Z\-]+)\/cancel$/) &&
          request.method === 'PUT'
        ) {
          return ok(MOCK_API_GENERIC_SUCCESS_RESPONSE);
        } else if (
          request.url.endsWith('/travelApprovals/myself/forApproval') &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_TAR_FOR_APPROVAL);
        } else if (
          request.url.endsWith('/travelApprovals/myself/alreadyProcessed') &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_TAR_ALREADY_PROCESSED);
        } else if (
          request.url.match(/\/travelApprovals\/([0-9a-zA-Z\-]+)\/reject$/) &&
          request.method === 'POST'
        ) {
          return ok({
            message:
              'Your rejection has been submitted for Travel Approval Request',
          });
        } else if (
          request.url.match(/\/travelApprovals\/([0-9a-zA-Z\-]+)\/approve$/) &&
          request.method === 'POST'
        ) {
          // return ok({
          //   message: 'Your approval has been submitted for Travel Approval Request',
          // });
          return ok(MOCK_API_TAR_APPROVAL_ERROR_MESSAGE);
        }
        return next.handle(request);
      }),
      materialize(),
      delay(300),
      dematerialize()
    );
  }
}
