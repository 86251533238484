import {
  FetchCurrentUserAccessesSucceed,
  FetchCurrentUserSucceed,
  FetchUserSuccess,
  SetFakeSSOUser,
  UserActions,
} from './user.actions';
import { UserState, initialUserState } from './user.models';

export function userReducer(
  state: UserState = initialUserState,
  action: UserActions
): UserState {
  switch (action.type) {
    case 'FETCH_CURRENT_USER':
      return onFetchCurrentUser(state);
    case 'FETCH_CURRENT_USER_SUCCEED':
      return onFetchCurrentUserSucceed(state, action);
    case 'FETCH_CURRENT_USER_FAIL':
      return onFetchCurrentUserFail(state);
    case 'FETCH_CURRENT_USER_ACCESSES':
      return onFetchCurrentUserAccesses(state);
    case 'FETCH_CURRENT_USER_ACCESSES_SUCCEED':
      return onFetchCurrentUserAccessesSucceed(state, action);
    case 'FETCH_CURRENT_USER_ACCESSES_FAIL':
      return onFetchCurrentUserAccessesFail(state);
    case 'SET_FAKE_SSO_USER':
      return onSetFakeSSOUser(state, action);
    case 'FETCH_USER':
      return onFetchUser(state);
    case 'FETCH_USER_SUCCESS':
      return onFetchUserSuccess(state, action);
    case 'FETCH_USER_FAIL':
      return onFetchUserFail(state);
    default: {
      return state;
    }
  }
}

function onFetchCurrentUser(state: UserState): UserState {
  return {
    ...state,
    currentUser: null,
    fetchingCurrentUser: true,
  };
}
function onFetchCurrentUserSucceed(
  state: UserState,
  action: FetchCurrentUserSucceed
): UserState {
  return {
    ...state,
    currentUser: action.user,
    fetchingCurrentUser: false,
  };
}
function onFetchCurrentUserFail(state: UserState): UserState {
  return {
    ...state,
    currentUser: null,
    fetchingCurrentUser: false,
  };
}
function onFetchCurrentUserAccesses(state: UserState): UserState {
  return {
    ...state,
    accesses: null,
  };
}
function onFetchCurrentUserAccessesSucceed(
  state: UserState,
  action: FetchCurrentUserAccessesSucceed
): UserState {
  return {
    ...state,
    accesses: action.access,
  };
}
function onFetchCurrentUserAccessesFail(state: UserState): UserState {
  return {
    ...state,
    accesses: null,
  };
}
function onSetFakeSSOUser(state: UserState, action: SetFakeSSOUser): UserState {
  return {
    ...state,
    fakeSSOUser: action.fakeSSOUser,
  };
}
function onFetchUser(state: UserState): UserState {
  return {
    ...state,
    travelerUser: null,
  };
}
function onFetchUserSuccess(
  state: UserState,
  action: FetchUserSuccess
): UserState {
  return {
    ...state,
    travelerUser: action.user,
  };
}
function onFetchUserFail(state: UserState): UserState {
  return {
    ...state,
    travelerUser: null,
  };
}
