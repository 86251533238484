import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@store/root/root.reducer';
import { getRouterStateUrl } from '@store/root/root.selectors';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'sgxb-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public route$: Observable<string>;

  public containerClass = 'container';

  constructor(private store$: Store<AppState>) {
    this.route$ = this.store$.select(getRouterStateUrl);

    this.route$.pipe(filter((route) => !!route)).subscribe((route) => {
      this.containerClass =
        route.indexOf('/dashboard/gatekeeper') === 0
          ? 'container-fluid'
          : 'container';
    });
  }
}
