import { DelegationActions } from './delegation.actions';
import { DelegationState, initialDelegationState } from './delegation.models';

export function delegationReducer(
  state: DelegationState = initialDelegationState,
  action: DelegationActions
): DelegationState {
  switch (action.type) {
    case 'FETCH_DELEGATES':
      return {
        ...state,
        fetchDelegatesOnGoing: true,
        allDelegates: [],
      };
    case 'FETCH_DELEGATES_SUCCESS':
      return {
        ...state,
        fetchDelegatesOnGoing: false,
        allDelegates: action.delegates,
      };
    case 'FETCH_DELEGATES_FAIL':
      return {
        ...state,
        fetchDelegatesOnGoing: false,
      };
    case 'ADD_DELEGATE':
      return {
        ...state,
        addDelegateOnGoing: true,
      };
    case 'ADD_DELEGATE_FAIL':
      return {
        ...state,
        addDelegateOnGoing: false,
      };
    case 'ADD_DELEGATE_SUCCESS':
      return {
        ...state,
        addDelegateOnGoing: false,
        allDelegates: [...state.allDelegates].concat(action.delegate),
      };
    case 'DELETE_DELEGATE':
      return {
        ...state,
        deleteDelegateOnGoing: true,
      };
    case 'DELETE_DELEGATE_SUCCESS':
    case 'DELETE_DELEGATE_FAIL':
      return {
        ...state,
        deleteDelegateOnGoing: false,
      };
    case 'FETCH_PRINCIPALS':
      return {
        ...state,
        fetchPrincipalsOnGoing: true,
        allPrincipals: [],
      };
    case 'FETCH_PRINCIPALS_SUCCESS':
      return {
        ...state,
        fetchPrincipalsOnGoing: false,
        allPrincipals: action.principals,
      };
    case 'FETCH_PRINCIPALS_FAIL':
      return {
        ...state,
        fetchPrincipalsOnGoing: false,
      };
    case 'FETCH_DELEGATED_CTAS':
      return {
        ...state,
        fetchDelegatedCtasOnGoing: true,
        delegatedCtas: [],
      };
    case 'FETCH_DELEGATED_CTAS_SUCCESS':
      return {
        ...state,
        fetchDelegatedCtasOnGoing: false,
        delegatedCtas: action.payload,
      };
    case 'FETCH_DELEGATED_CTAS_FAIL':
      return {
        ...state,
        fetchDelegatedCtasOnGoing: false,
      };
    default: {
      return state;
    }
  }
}
