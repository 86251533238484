import { HttpErrorResponse } from '@angular/common/http';
import { Country } from '@models/Country';
import { Action } from '@ngrx/store';

export type CountryActions =
  | TogglePopin
  | ToggleRequestPopin
  | FetchCountryByCountryCode
  | FetchCountrySuccess
  | FetchCountryFail
  | ResetCurrent
  | UpdateCurrent
  | SaveGuidelineConfiguration
  | SaveGuidelineConfigurationSuccess
  | SaveGuidelineConfigurationFail
  | FetchCountries
  | FetchCountriesSuccess
  | FetchCountriesFail
  | FetchCountriesUnderOwnership
  | FetchCountriesUnderOwnershipSuccess
  | FetchCountriesUnderOwnershipFail;
export interface TogglePopin extends Action {
  type: 'TOGGLE_POPIN';
  payload: boolean;
}
export function togglePopin(payload: boolean): TogglePopin {
  return {
    type: 'TOGGLE_POPIN',
    payload,
  };
}

export interface ToggleRequestPopin extends Action {
  type: 'TOGGLE_REQUEST_POPIN';
  payload: boolean;
}
export function toggleRequestPopin(payload: boolean): ToggleRequestPopin {
  return {
    type: 'TOGGLE_REQUEST_POPIN',
    payload,
  };
}

export interface FetchCountryByCountryCode extends Action {
  type: 'FETCH_COUNTRY';
  countryCode: string;
}

export function fetchCountryByCountryCode(
  countryCode: string
): FetchCountryByCountryCode {
  return {
    type: 'FETCH_COUNTRY',
    countryCode,
  };
}

export interface FetchCountrySuccess extends Action {
  type: 'FETCH_COUNTRY_SUCCESS';
  payload: Country;
}

export function fetchCountrySuccess(payload: Country): FetchCountrySuccess {
  return {
    type: 'FETCH_COUNTRY_SUCCESS',
    payload,
  };
}

export interface FetchCountryFail extends Action {
  type: 'FETCH_COUNTRY_FAIL';
  payload: HttpErrorResponse;
}

export function fetchCountryFail(payload: HttpErrorResponse): FetchCountryFail {
  return {
    type: 'FETCH_COUNTRY_FAIL',
    payload,
  };
}

export interface ResetCurrent extends Action {
  type: 'RESET_CURRENT_COUNTRY';
}

export function resetCurrent(): ResetCurrent {
  return { type: 'RESET_CURRENT_COUNTRY' };
}

export interface UpdateCurrent extends Action {
  type: 'UPDATE_CURRENT_COUNTRY';
  payload: Country;
}
export function updateCurrent(payload: Country) {
  return {
    type: 'UPDATE_CURRENT_COUNTRY',
    payload,
  };
}

export interface SaveGuidelineConfiguration extends Action {
  type: 'SAVE_GUIDELINE_CONFIGURATION';
  payload: Country;
}
export function saveGuidelineConfiguration(
  payload: Country
): SaveGuidelineConfiguration {
  return { type: 'SAVE_GUIDELINE_CONFIGURATION', payload };
}

export interface SaveGuidelineConfigurationSuccess extends Action {
  type: 'SAVE_GUIDELINE_CONFIGURATION_SUCCESS';
  payload: Country;
}
export function saveGuidelineConfigurationSuccess(
  payload: Country
): SaveGuidelineConfigurationSuccess {
  return { type: 'SAVE_GUIDELINE_CONFIGURATION_SUCCESS', payload };
}

export interface SaveGuidelineConfigurationFail extends Action {
  type: 'SAVE_GUIDELINE_CONFIGURATION_FAIL';
  payload: HttpErrorResponse;
}
export function saveGuidelineConfigurationFail(
  payload: HttpErrorResponse
): SaveGuidelineConfigurationFail {
  return { type: 'SAVE_GUIDELINE_CONFIGURATION_FAIL', payload };
}

export interface FetchCountries extends Action {
  type: 'FETCH_COUNTRIES';
  underPerimeter: boolean;
  withGuidelines: boolean;
}
export function fetchCountries(
  underPerimeter: boolean = false,
  withGuidelines: boolean = false
): FetchCountries {
  return {
    type: 'FETCH_COUNTRIES',
    underPerimeter,
    withGuidelines,
  };
}

export interface FetchCountriesSuccess extends Action {
  type: 'FETCH_COUNTRIES_SUCCESS';
  countries: Country[];
  underPerimeter: boolean;
  withGuidelines: boolean;
}
export function fetchCountriesSuccess(
  countries: Country[],
  underPerimeter: boolean = false,
  withGuidelines: boolean = false
): FetchCountriesSuccess {
  return {
    type: 'FETCH_COUNTRIES_SUCCESS',
    countries,
    underPerimeter,
    withGuidelines,
  };
}

export interface FetchCountriesFail extends Action {
  type: 'FETCH_COUNTRIES_FAIL';
  errorResponse: HttpErrorResponse;
  underPerimeter: boolean;
  withGuidelines: boolean;
}
export function fetchCountriesFail(
  errorResponse: HttpErrorResponse,
  underPerimeter: boolean = false,
  withGuidelines: boolean = false
): FetchCountriesFail {
  return {
    type: 'FETCH_COUNTRIES_FAIL',
    errorResponse,
    underPerimeter,
    withGuidelines,
  };
}

export interface FetchCountriesUnderOwnership extends Action {
  type: 'FETCH_COUNTRIES_UNDER_OWNERSHIP';
}
export function fetchCountriesUnderOwnership(): FetchCountriesUnderOwnership {
  return { type: 'FETCH_COUNTRIES_UNDER_OWNERSHIP' };
}

export interface FetchCountriesUnderOwnershipSuccess extends Action {
  type: 'FETCH_COUNTRIES_UNDER_OWNERSHIPS_SUCCESS';
  payload: Country[];
}
export function fetchCountriesUnderOwnershipSuccess(
  payload: Country[]
): FetchCountriesUnderOwnershipSuccess {
  return { type: 'FETCH_COUNTRIES_UNDER_OWNERSHIPS_SUCCESS', payload };
}

export interface FetchCountriesUnderOwnershipFail extends Action {
  type: 'FETCH_COUNTRIES_UNDER_OWNERSHIP_FAIL';
  payload: HttpErrorResponse;
}
export function fetchCountriesUnderOwnershipFail(
  payload: HttpErrorResponse
): FetchCountriesUnderOwnershipFail {
  return { type: 'FETCH_COUNTRIES_UNDER_OWNERSHIP_FAIL', payload };
}
