import { Contact, ContactsDetailsState } from './contacts-details.models';

export const getAllContacts = (state: ContactsDetailsState): Contact[] =>
  state.contacts;
export const getCurrentPageNumber = (state: ContactsDetailsState): number =>
  state.page;
export const getTotalPageNumber = (state: ContactsDetailsState): number =>
  state.totalPages;
export const getFetchContactsOnGoing = (state: ContactsDetailsState): boolean =>
  state.fetchContactsOnGoing;
