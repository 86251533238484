import { Routes } from '@angular/router';
import { SignoutComponent } from '@core/components/signout/signout.component';
import { ConfigurationGuidelinesGuard } from '@shared/guards/configuration-guidelines/configuration-guidelines.guard';
import { ContactsDetailsGuard } from '@shared/guards/contacts-datails/contacts-details.guard';
import { DashboardGuard } from '@shared/guards/dashboard/dashboard.guard';
import { ExportGuard } from '@shared/guards/export/export.guard';
import { DelegationGuard } from '@shared/guards/delegation/delegation.guard';

export const ROUTES: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () =>
      import('./routes/home/home.module').then((m) => m.HomeModule),
  },
  { path: 'signout', component: SignoutComponent },
  {
    path: 'view',
    loadChildren: () =>
      import('./routes/view/view.module').then((m) => m.ViewModule),
  },
  {
    path: 'configure',
    loadChildren: () =>
      import('./routes/configure/configure.module').then(
        (m) => m.ConfigureModule
      ),
    canActivate: [ConfigurationGuidelinesGuard],
    canActivateChild: [ConfigurationGuidelinesGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./routes/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [DashboardGuard],
    canActivateChild: [DashboardGuard],
  },
  {
    path: 'export',
    loadChildren: () =>
      import('./routes/export/export.module').then((m) => m.ExportModule),
    canActivate: [ExportGuard],
    canActivateChild: [ExportGuard],
  },
  {
    path: 'approvals',
    loadChildren: () =>
      import('./routes/approval-management/approval-management.module').then(
        (m) => m.ApprovalManagementModule
      ),
  },
  {
    path: 'contacts-details',
    loadChildren: () =>
      import('./routes/contacts-details/contacts-details.module').then(
        (m) => m.ContactsDetailsModule
      ),
    canActivate: [ContactsDetailsGuard],
    canActivateChild: [ContactsDetailsGuard],
  },
  {
    path: 'delegation',
    loadChildren: () =>
      import('./routes/delegation/delegation.module').then(
        (m) => m.DelegationModule
      ),
    canActivate: [DelegationGuard],
    canActivateChild: [DelegationGuard],
  },
  {
    path: 'travel-approval',
    loadChildren: () =>
      import('./routes/travel-approval/travel-approval.module').then(
        (m) => m.TravelApprovalModule
      ),
  },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];
