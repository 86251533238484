import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Validator, ValidatorRule } from '@models/Validator';
import { warningMessagesGenerator } from '@store/validator/validator.helper';

@Component({
  selector: 'sgxb-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent {
  @Input() public rows = 3;
  @Input() public label = null;
  @Input() public validators: Validator[] = [];
  @Input() public maxlength = Infinity;
  @Input() public placeholder = '';
  @Output() public input = new EventEmitter();
  @Output() public focus = new EventEmitter<FocusEvent>();
  @Output() public blur = new EventEmitter<FocusEvent>();
  @Output() public validate = new EventEmitter<boolean>();

  private _value = '';
  private _disabled = false;
  public warnings: string[] = [];
  public touched = false;
  @Input() set value(value: string) {
    this._value = value ? value : '';
    if (value !== null && value !== '') {
      this.touched = true;
    }
  }
  get value(): string {
    return this._value;
  }
  @Input() set disabled(value: boolean) {
    this._disabled = value;
    this.touched = false;
    if (this._disabled) {
      this.validate.emit(true);
    }
  }
  get disabled(): boolean {
    return this._disabled;
  }

  get required(): boolean {
    return (
      !this.disabled &&
      this.validators &&
      this.validators
        .map((o: Validator) => o.rule)
        .indexOf(ValidatorRule.REQUIRED) !== -1
    );
  }

  get validity(): boolean {
    this.warnings = [];
    if (!this.disabled && this.validators) {
      const conditions = this.validators
        .map((validator) => {
          switch (validator.rule) {
            case ValidatorRule.REQUIRED: {
              return {
                rule: validator.rule,
                shouldRaiseWarning: this.value === '',
              };
            }
          }
        })
        .filter((o) => o !== null && o !== undefined);
      this.warnings = warningMessagesGenerator(
        this.warnings,
        this.validators,
        conditions
      );
    }
    const valid = this.warnings && this.warnings.length === 0;
    return valid;
  }

  constructor() {}

  public onFocus(event: FocusEvent) {
    this.focus.emit(event);
  }

  public onBlur(event: FocusEvent) {
    this.touched = true;
    this.blur.emit(event);
    this.validate.emit(this.validity);
  }

  public onInput(event) {
    this.touched = true;
    this.input.emit(event);
    this.validate.emit(this.validity);
  }
}
