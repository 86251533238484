<sgxb-checkbox-group
  displayWith="text"
  [label]="q.description"
  [options]="q.options"
  [value]="answer$ | async"
  [validators]="validators"
  (add)="onMultiSelectAdd($event)"
  (remove)="onMultiSelectRemove($event)"
  (validate)="onValidate($event)"
>
</sgxb-checkbox-group>
