import { Component } from '@angular/core';

@Component({
  selector: 'sgxb-mobile-nonsupported-screen',
  templateUrl: './mobile-nonsupported-screen.component.html',
  styleUrls: ['./mobile-nonsupported-screen.component.scss'],
})
export class MobileNonsupportedScreenComponent {
  constructor() {}
}
