import { Access } from '@models/Access';
import { User } from '@models/User';

export interface UserState {
  currentUser: User;
  fetchingCurrentUser: boolean;
  fakeSSOUser?: string;
  accesses: Access;
  travelerUser?: User;
}

export const initialUserState: UserState = {
  currentUser: null,
  fetchingCurrentUser: false,
  accesses: null,
};
