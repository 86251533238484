<div class="combo-box form-group" [class.has-error]="touched && !validity">
  <label class="control-label" *ngIf="label">
    <span [innerHTML]="label | nl2br | safeHtml"></span>
    <b *ngIf="required">*</b>
  </label>

  <div class="input-group">
    <div class="input-group suggested-input">
      <input
        [value]="suggestedText"
        disabled="true"
        class="form-control autocompletion"
        type="text"
        autocomplete="off"
        aria-autocomplete="none"
      />
      <input
        #input
        class="form-control"
        e2e="combo-box-input"
        type="text"
        autocomplete="off"
        aria-autocomplete="none"
        [disabled]="disabled"
        [placeholder]="suggestedText ? '' : !items ? 'Loading...' : placeholder"
        [(ngModel)]="inputValue"
        (focus)="onFocus($event)"
        (blur)="onBlur($event)"
        (keydown.enter)="onEnter($event)"
        (keydown.esc)="onEsc($event)"
        (keydown.backspace)="onBackspace($event)"
        (keydown.arrowup)="onArrowUp($event)"
        (keydown.arrowdown)="onArrowDown($event)"
        (keydown.tab)="onTab($event)"
        (keydown.shift.tab)="onShiftTab($event)"
      />

      <ng-container *ngIf="!disabled">
        <div class="input-group-btn" *ngIf="!items || (!!items && !!icon)">
          <button
            class="btn btn-default btn-icon"
            tabindex="-1"
            *ngIf="!items"
            (click)="$event.preventDefault()"
          >
            <i class="fa fa-spinner fa-pulse fa-fw"></i>
          </button>
          <button
            class="btn btn-{{ iconStyle ? iconStyle : 'default' }} btn-icon"
            tabindex="-1"
            *ngIf="!!items && !!icon"
            [disabled]="disabled"
            (click)="onButtonClick($event)"
          >
            <i class="fa fa-{{ icon ? icon : 'search' }}"></i>
          </button>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="warnings" *ngIf="touched && !active && !validity">
    <ng-container *ngFor="let warning of warnings">
      {{ warning }}<br />
    </ng-container>
  </div>

  <div
    class="combo-box-scroll"
    [class.no-icon]="!icon"
    *ngIf="active && suggestions && suggestions.length > 0"
  >
    <div #suggestionsBox class="list-group" e2e="combo-box-suggestions">
      <a
        *ngFor="let suggestion of suggestions; let i = index"
        class="list-group-item"
        [class.active]="i === suggestionIndex"
        (mouseover)="suggestionIndex = i"
        (mousedown)="onSuggestionClick($event)"
      >
        {{ getDisplayText(suggestion) }}
      </a>
    </div>
  </div>
</div>
