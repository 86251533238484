import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { LicenseManager } from 'ag-grid-enterprise';
import { sgwtConnect } from 'app/sgwt-connect';
import { AppModule } from './app/app.module';
import * as configuration from './app/configuration';

LicenseManager.setLicenseKey(configuration.agGrid.licenseKey);

if (window.configurations.production) {
  enableProdMode();
}

// const SGWTWidgetConfiguration = env => `window.SGWTWidgetConfiguration = { environment: "${env}" };`;
// const script = document.createElement('script');
// script.innerHTML =
//   window.configurations.name === 'production' ? SGWTWidgetConfiguration('production') : SGWTWidgetConfiguration('homologation');
// document.head.appendChild(script);

if (sgwtConnect.isAuthorized()) {
  // const healthCheck = new HealthCheck(window.configurations.backend.base);
  // healthCheck.initiate(window.configurations.backend.useMock).catch((errorMessage: object) => {
  //   healthCheck.showServiceUnavailablePage();

  //   const message = Object.assign(
  //     {
  //       user: sgwtConnect.getIdTokenClaims().sub,
  //     },
  //     errorMessage,
  //   );
  //   healthCheck.notifyAdmin(message);
  // });
  platformBrowserDynamic().bootstrapModule(AppModule);
} else if (sgwtConnect.getAuthorizationError()) {
  document.body.innerHTML = `
    <div class="alert alert-danger" role="alert">
      Authorization error: ${sgwtConnect.getAuthorizationError()}.
    </div>
  `;
} else {
  sgwtConnect.requestAuthorization();
}

// // Now, pass the sgwtConnect instance to the widget
// const widget: any = document.querySelector('sgwt-connect');
// if (widget) {
//   // When the code is executed, the widget may not have been initialized. So, we need to check that, otherwise calling
//   // `widget.setSgwtConnectInstance()` will throw an error.
//   if (typeof widget.setSgwtConnectInstance === 'undefined') {
//     // Widget is not initialized yet, so we will wait the event that indicates the widget is ready...
//     const handleSgwtConnectReady = () => {
//       widget.setSgwtConnectInstance(sgwtConnect);
//       // Remove the event listener
//       widget.removeEventListener('sgwt-connect--ready', handleSgwtConnectReady);
//     };

//     widget.addEventListener('sgwt-connect--ready', handleSgwtConnectReady);
//   } else {
//     // Widget is initialized...
//     widget.setSgwtConnectInstance(sgwtConnect);
//   }
// }
