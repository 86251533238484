import { HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';

export function ok<T>(body: T): Observable<HttpResponse<T>> {
  return of(
    new HttpResponse({
      status: 200,
      body,
    })
  );
}
