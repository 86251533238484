import { Acknowledgement } from '@models/Acknowledgement';
import {
  MOCK_API_COUNTRY_CHINA,
  MOCK_API_COUNTRY_HK,
  MOCK_API_COUNTRY_UK,
} from './country.mocks';
import { MOCK_API_USER, MOCK_API_USER_2 } from './user.mocks';

export const MOCK_API_ACKNOWLEDGEMENT: Acknowledgement = {
  country: MOCK_API_COUNTRY_HK,
  createdAt: new Date(),
  expired: true,
  updatedAt: new Date(),
  user: MOCK_API_USER,
};

export const MOCK_API_ACKNOWLEDGEMENTS: Acknowledgement[] = [
  {
    country: MOCK_API_COUNTRY_HK,
    createdAt: new Date(),
    expired: false,
    updatedAt: new Date(),
    user: MOCK_API_USER,
  },
  {
    country: MOCK_API_COUNTRY_CHINA,
    createdAt: new Date(),
    expired: true,
    updatedAt: new Date(),
    user: MOCK_API_USER,
  },
  {
    country: MOCK_API_COUNTRY_UK,
    createdAt: new Date(),
    expired: true,
    updatedAt: new Date(),
    user: MOCK_API_USER,
  },
];

export const MOCK_API_ACKNOWLEDGEMENTS_NOT_EXPIRED: Acknowledgement[] = [
  {
    country: MOCK_API_COUNTRY_HK,
    createdAt: new Date(),
    expired: false,
    updatedAt: new Date(),
    user: MOCK_API_USER,
  },
];

export const MOCK_API_ACKNOWLEDGEMENTS_NOT_EXPIRED_FOR_USER_2: Acknowledgement[] =
  [
    {
      country: MOCK_API_COUNTRY_HK,
      createdAt: new Date(),
      expired: false,
      updatedAt: new Date(),
      user: MOCK_API_USER_2,
    },
    {
      country: MOCK_API_COUNTRY_CHINA,
      createdAt: new Date(),
      expired: true,
      updatedAt: new Date(),
      user: MOCK_API_USER_2,
    },
  ];

export const MOCK_API_ACKNOWLEDGEMENTS_EXPORT: Blob = new Blob([
  `ACKNOWLEDGEMENTS,,,,,
User name,Email,Department,Country name,Last Acknowledgement,Expired
DOE John,john.doe@sgcib.com,GBSU/CLD/DIT,Hong Kong (HK),2019/05/23 11:07:18,Yes
DOE Jane,jane.doe@sgcib.com,GLFI/MGT/DIR,Hong Kong (HK),2018/02/20 16:30:47,Yes`,
]);
