import { BusinessUnitState } from './business-unit.models';

export const getAllBusinessUnits = (state: BusinessUnitState): string[] =>
  state.allBusinessUnits;
export const getAllBusinessUnitsWithAllValue = (
  state: BusinessUnitState
): string[] => ['ALL'].concat(state.allBusinessUnits);
export const getPerimeterBusinessUnits = (state: BusinessUnitState): string[] =>
  state.perimeterBusinessUnits;
export const getPerimeterBusinessUnitsWithAllValue = (
  state: BusinessUnitState
): string[] => ['ALL'].concat(state.perimeterBusinessUnits);
export const getFetchBusinessUnitsOnGoing = (
  state: BusinessUnitState
): boolean => state.fetchBusinessUnitsOnGoing;
