import { domStringToBase64 } from '@shared/utils/encode-decode/encode-decode.utils';
import { SubmitQuestionRequest } from '../guideline.models';

export function encodeSubmitQuestionRequest(
  request: SubmitQuestionRequest
): SubmitQuestionRequest {
  return {
    ...request,
    question: domStringToBase64(request.question),
  };
}
