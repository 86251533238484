import { User } from '@models/User';

export const MOCK_API_USER: User = {
  email: 'john.doe@sgcib.com',
  firstName: 'John',
  lastName: 'DOE',
  businessUnit: 'GBSU',
  department: 'GBSU/CLD/DIT',
  displayAs: 'John DOE, GBSU/CLD/DIT',
  igg: '9999999999',
};

export const MOCK_API_USER_2: User = {
  email: 'jane.doe@sgcib.com',
  firstName: 'Jane',
  lastName: 'DOE',
  businessUnit: 'GLFI',
  department: 'GLFI/MGT/DIR',
  displayAs: 'Jane DOE, GLFI/MGT/DIR',
  igg: '9999999999',
};

export const MOCK_API_USERS: User[] = [
  {
    email: 'john.doe@sgcib.com',
    firstName: 'John',
    lastName: 'DOE',
    businessUnit: 'GBSU',
    department: 'GBSU/CLD/DIT',
    displayAs: 'John DOE, GBSU/CLD/DIT',
    igg: '9999999999',
  },
  {
    email: 'jane.doe@sgcib.com',
    firstName: 'Jane',
    lastName: 'DOE',
    businessUnit: 'GLFI',
    department: 'GLFI/MGT/DIR',
    displayAs: 'Jane DOE, GLFI/MGT/DIR',
    igg: '9999999999',
  },
  {
    email: 'joe.bloggs@sgcib.com',
    firstName: 'Joe',
    lastName: 'BLOGGS',
    businessUnit: 'CPLE',
    department: 'CPLE/LDN/COO',
    displayAs: 'Joe BLOGGS, CPLE/LDN/COO',
    igg: '9999999999',
  },
];
