import { Store } from '@ngrx/store';
import { AppState } from '@store/root/root.reducer';
import { fetchCurrentUser, setFakeSSOUser } from '@store/user/user.actions';
import { parse } from 'qs';

export function appInitializer(store$: Store<AppState>) {
  return () => {
    const queryParams = parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    if (queryParams.fakeSSO && typeof queryParams.fakeSSO === 'string') {
      store$.dispatch(setFakeSSOUser(queryParams.fakeSSO));
    }
    store$.dispatch(fetchCurrentUser());
  };
}
