import { HttpRequest } from '@angular/common/http';
import { setupSGWTConnect } from '@sgwt/connect-core';

export const sgwtConnect = window.configurations
  ? setupSGWTConnect({
      authorization_endpoint: window.configurations.sgConnect.endpoint,
      client_id: window.configurations.sgConnect.clientId,
      redirect_uri: `${window.location.protocol}//${window.location.host}`,
      scope: window.configurations.sgConnect.scope,
    })
  : null;

// Export sgwtConnect factory & predicate (AOT requirement):
export function sgwtConnectFactory() {
  return sgwtConnect;
}

export function sgwtConnectPredicate(req: HttpRequest<any>): boolean {
  return (
    new RegExp(`^${window.configurations.backend.base}`).test(req.url) ||
    new RegExp(`^${window.configurations.backend.baseLAN}`).test(req.url)
  );
}
