import {
  ComplianceTravelApproval,
  CtaQuestion,
} from '@models/ComplianceTravelApproval';
import { Scope } from '@models/Guideline';
import {
  CtaSpecificState,
  UITravelApprovalQuestion,
} from './cta-specific.models';

export const getLoading = (state: CtaSpecificState): boolean => state.loading;
export const getQuestions = (
  state: CtaSpecificState
): UITravelApprovalQuestion[] => (state ? state.questions : null);
export const getSaveOnGoing = (state: CtaSpecificState): boolean =>
  state.saveOnGoing;
export const getTaSpecificPointOfView = (state: CtaSpecificState): string =>
  state.pointOfView;
export const getDefaultScopeForCreateQuestion = (
  state: CtaSpecificState
): Scope =>
  state && state.pointOfView && state.pointOfView !== 'ALL'
    ? [state.pointOfView]
    : [];
export const getQuestionsPreviewOnGoing = (state: CtaSpecificState): boolean =>
  state.fetchQuestionsPreviewOnGoing;
export const getTaPreview = (
  state: CtaSpecificState
): ComplianceTravelApproval => {
  return state ? state.taPreview : null;
};
export const getCtaSpecificConfigurationFormIsDirty = (
  state: CtaSpecificState
): boolean => state.isDirty;

export const getCtaIsNotEditableCheckboxVisibility = (
  state: CtaSpecificState,
  qIndex: number
): boolean | null => {
  const questions = getQuestions(state);
  return (
    questions &&
    qIndex >= 0 &&
    questions[qIndex] &&
    questions[qIndex].type === 'EMAIL' &&
    questions[qIndex].presetValueType === 'SUPERVISOR'
  );
};

export const getQuestionByUUID = (
  state: CtaSpecificState,
  uuid: string
): UITravelApprovalQuestion | undefined => {
  const questions = getQuestions(state);
  return questions ? questions.find((q) => q.uuid === uuid) : undefined;
};

export const getIsEmailFieldDisabled = (
  state: CtaSpecificState,
  uuid: string
): boolean => {
  const q = getQuestionByUUID(state, uuid);
  if (!q) {
    return false;
  }
  const hasPresetValue = !(
    q.presetValue === null ||
    q.presetValue === '' ||
    q.presetValue === undefined
  );
  return q.isNotEditable && hasPresetValue;
};

export const getIsEmailFieldCcCheckboxVisible = (
  state: CtaSpecificState,
  uuid: string
): boolean => {
  const q = getQuestionByUUID(state, uuid);
  return q
    ? !(
        q.isCCMandatory ||
        q.isCCMandatory === undefined ||
        q.isCCMandatory === null
      )
    : false;
};

export const getTaSpecificQuestionsToSaveOrPreview = (
  state: CtaSpecificState
): CtaQuestion[] => {
  const questions = getQuestions(state);
  return questions
    ? questions.map((q) => ({
        conditions: q.conditions,
        description: q.description,
        importantLevel: q.importantLevel,
        isCCMandatory: q.isCCMandatory,
        isNotEditable: q.isNotEditable,
        options: q.options,
        ordering: q.ordering,
        presetValueType: q.presetValueType,
        scope: q.scope,
        type: q.type,
        useOr: q.useOr,
        uuid: q.uuid,
        validationLevel: q.validationLevel,
      }))
    : [];
};
