import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  ViewChild,
} from '@angular/core';

import { Validator, ValidatorRule } from '@models/Validator';

import { warningMessagesGenerator } from '@store/validator/validator.helper';
import * as moment from 'moment';

@Component({
  selector: 'sgxb-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements AfterViewInit {
  @ViewChild('datepicker', { static: false }) public datepicker: ElementRef;
  @Input() public label = null;
  @Input() public placeholder = 'dd M yyyy';

  @Input() public container = null;
  @Input() public orientation = null;
  @Output() public focus = new EventEmitter<FocusEvent>();
  @Output() public blur = new EventEmitter<FocusEvent>();
  @Output() public update = new EventEmitter<Date>();
  @Output() public validate = new EventEmitter<boolean>();

  public warnings: string[] = [];
  public touched = false;
  private _min: Date | number = -Infinity;
  private _max: Date | number = Infinity;
  private _value: Date = null;
  private _disabled = false;
  private _validators: Validator[] = undefined;

  @Input()
  set value(date: Date) {
    if (this._value === date) {
      return;
    }
    const prevValue = this._value;
    this._value = date instanceof Date ? date : date ? new Date(date) : date;
    if (date) {
      this.touched = true;
    }
    // Condition below is used to pre-fill the input after duplication
    if (!prevValue) {
      this.render();
    }
  }
  get value() {
    return this._value;
  }
  @Input()
  set minDate(date: Date | number) {
    if (date) {
      this._min = date instanceof Date ? date : new Date(date);
      this.validateValue();
    }
  }
  get minDate(): Date | number {
    return this._min;
  }
  @Input()
  set maxDate(date: Date | number) {
    this._max = date instanceof Date ? date : new Date(date);
    this.validateValue();
  }
  get maxDate(): Date | number {
    return this._max;
  }
  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
    this.touched = false;
    if (this._disabled) {
      this.validate.emit(true);
    }
  }
  get disabled(): boolean {
    return this._disabled;
  }
  @Input()
  set validators(validators: Validator[]) {
    this._validators = validators || [];
  }
  get validators(): Validator[] {
    return this._validators;
  }

  constructor(private ngZone: NgZone) {}

  public ngAfterViewInit() {
    this.render();
    // Quick fix for datepicker shifting issue when first clicked
    this.ngZone.runOutsideAngular(() => {
      window
        .$(this.datepicker.nativeElement)
        .datepicker('show')
        .datepicker('hide');
    });
  }

  get required(): boolean {
    if (this.validators) {
      return (
        !this.disabled &&
        !!this.validators.find(
          (validator) => validator.rule === ValidatorRule.REQUIRED
        )
      );
    }
    return !this.disabled;
  }

  get validity(): boolean {
    this.warnings = [];

    if (!this.disabled && this.validators && this.validators.length > 0) {
      const conditions = this.validators
        .map((validator) => {
          switch (validator.rule) {
            case ValidatorRule.REQUIRED: {
              return {
                rule: validator.rule,
                shouldRaiseWarning: !this.value,
              };
            }
            default:
              return;
          }
        })
        .filter((o) => o !== null && o !== undefined);
      this.warnings = warningMessagesGenerator(
        this.warnings,
        this.validators,
        conditions
      );
    }
    /* Datepicker is valid when :
        - Validator rules are undefined (datepickers in dashboard)
        - List of validator rules are defined AND there is no warnings (CTA Form)
    */
    const valid =
      !this.validators ||
      (this.validators &&
        this.validators.length > 0 &&
        this.warnings &&
        this.warnings.length === 0);
    this.validate.emit(valid);
    return valid;
  }

  public onFocus(event: FocusEvent): void {
    this.focus.emit(event);
  }

  public onBlur(event: FocusEvent): void {
    this.touched = true;
    this.blur.emit(event);
  }

  public onTab(_event: KeyboardEvent): void {
    this.validateValue();
  }

  public show(event: MouseEvent): void {
    event.preventDefault();
    this.ngZone.runOutsideAngular(() => {
      window.$(this.datepicker.nativeElement).datepicker('show');
    });
  }

  public focusInput(): void {
    this.datepicker.nativeElement.focus();
  }

  private setValue(value: Date): void {
    this.ngZone.run(() => {
      this._value = value;
      this.update.emit(value);
    });
  }

  private clearValue(): void {
    this.setValue(null);
    this.render();
  }

  private validateValue() {
    if (this.value && this.withinRange()) {
      this.render();
    } else {
      this.clearValue();
    }
  }

  private withinRange(): boolean {
    if (!this.value) {
      return false;
    }
    return (
      (this.value instanceof Date ? this.value.getTime() : this.value) >=
        (this.minDate instanceof Date
          ? this.minDate.getTime()
          : this.minDate) &&
      (this.value instanceof Date ? this.value.getTime() : this.value) <=
        (this.maxDate instanceof Date ? this.maxDate.getTime() : this.maxDate)
    );
  }

  private render() {
    if (this.datepicker) {
      this.ngZone.runOutsideAngular(() => {
        window.$(this.datepicker.nativeElement).datepicker('destroy');
        window
          .$(this.datepicker.nativeElement)
          .datepicker({
            date: new Date(),
            format: 'dd M yyyy (D)',
            startDate:
              this.minDate instanceof Date
                ? moment(this.minDate).format('DD MMM YYYY')
                : this.minDate,
            endDate:
              this.maxDate instanceof Date
                ? moment(this.maxDate).format('DD MMM YYYY')
                : this.maxDate,
            container: this.container ? '#' + this.container : 'body',
            orientation: this.orientation ? this.orientation : 'auto',
          })
          .datepicker('setDate', this.value ? this.value : null)
          .on('changeDate', (_event: any) => {
            window.$(this.datepicker.nativeElement).datepicker('hide');
          })
          .on('hide', (event: any) => {
            this.setValue(event.date);
          });
      });
    }
    return this.validity;
  }
}
