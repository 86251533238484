import { Acknowledgement } from '@models/Acknowledgement';
import { Country } from '@models/Country';
import { AcknowledgementState } from './acknowledgement.models';

export const getAcknowledgements = (
  state: AcknowledgementState
): Acknowledgement[] => state.acknowledgements;
export const getAcknowledgedCountries = (
  state: AcknowledgementState
): Country[] => {
  return state.acknowledgements
    ? state.acknowledgements.map((o) => o.country)
    : null;
};
export const getAcknowledgedAndNotExpiredCountries = (
  state: AcknowledgementState
): Country[] => {
  return state.acknowledgementsNotExpired
    ? state.acknowledgementsNotExpired.map((o) => o.country)
    : null;
};
export const getExportAckOngoing = (state: AcknowledgementState): boolean => {
  return state.exportAckOnGoing;
};
