import { AfterViewInit, Directive, ElementRef, NgZone } from '@angular/core';

@Directive({
  selector: '[sgxbTooltip]',
})
export class TooltipDirective implements AfterViewInit {
  constructor(private el: ElementRef, private ngZone: NgZone) {}

  public ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() =>
      window.$(this.el.nativeElement).tooltip()
    );
  }
}
