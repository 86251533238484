import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { CtaAnswerResultType } from '@models/ComplianceTravelApproval';
import { Validator } from '@models/Validator';
import { Store } from '@ngrx/store';
import { getValidators } from '@routes/travel-approval/helpers/travel-approval.helper';
import { UITravelApprovalQuestion } from '@store/cta-specific/cta-specific.models';
import {
  updateCtaAnswer,
  updateCtaAnswerOnCcCheckbox,
  updateCtaAnswerValidity,
} from '@store/cta/cta.actions';
import { AppState } from '@store/root/root.reducer';
import {
  getAnswer,
  getIsEmailFieldCcCheckboxChecked,
  getIsEmailFieldCcCheckboxVisible,
  getIsEmailFieldDisabled,
} from '@store/root/root.selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'sgxb-travel-approval-email',
  templateUrl: './travel-approval-email.component.html',
  styleUrls: ['./travel-approval-email.component.scss'],
})
export class TravelApprovalEmailComponent implements OnInit {
  @ViewChildren('emailCcCheckbox')
  public emailCcCheckboxes: QueryList<ElementRef>;

  @Input('question') public q: UITravelApprovalQuestion;
  public answer$: Observable<CtaAnswerResultType>;
  public validators: Validator[];
  public isEmailFieldDisabled$: Observable<boolean>;
  public isCcCheckboxVisible$: Observable<boolean>;
  public isCcCheckboxChecked$: Observable<boolean>;

  constructor(private store$: Store<AppState>, private ngZone: NgZone) {}

  public ngOnInit() {
    this.answer$ = this.store$.select(getAnswer, { uuid: this.q.uuid });
    this.isEmailFieldDisabled$ = this.store$.select(getIsEmailFieldDisabled, {
      uuid: this.q.uuid,
    });
    this.isCcCheckboxVisible$ = this.store$.select(
      getIsEmailFieldCcCheckboxVisible,
      { uuid: this.q.uuid }
    );
    this.isCcCheckboxChecked$ = this.store$.select(
      getIsEmailFieldCcCheckboxChecked,
      { uuid: this.q.uuid }
    );
    this.validators = getValidators(this.q.type);

    // Tooltip on email cc checkboxes
    setTimeout(() => {
      if (this.emailCcCheckboxes) {
        this.emailCcCheckboxes.forEach((item: ElementRef) => {
          this.ngZone.runOutsideAngular(() => {
            window.$(item.nativeElement).tooltip('destroy');
            window.$(item.nativeElement).tooltip();
          });
        });
      }
    });
  }

  public onEmailFieldInput(event: any): void {
    if (event.target.type === 'text') {
      this.store$.dispatch(updateCtaAnswer(this.q.uuid, event.target.value));
    }
  }
  public onEmailCCCheckboxChange(event: any): void {
    this.store$.dispatch(
      updateCtaAnswerOnCcCheckbox(this.q.uuid, event.target.checked)
    );
  }

  public onValidate(isValidated: boolean): void {
    this.store$.dispatch(updateCtaAnswerValidity(this.q.uuid, isValidated));
  }
}
