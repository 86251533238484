<div class="modal fade" role="dialog" [attr.id]="id" data-backdrop="static">
  <div class="modal-dialog modal-{{ size }}">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          [disabled]="isDisabled()"
          (click)="close.emit()"
        >
          <span aria-hidden="true">
            <i class="fa fa-close"></i>
          </span>
        </button>
        <h4 class="modal-title">{{ title }}</h4>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
