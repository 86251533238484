import { isNonEmpty } from 'app/utils/predicates.utils';

const fontColorMap: { [key: string]: string } = {
  '529aff': 'text-blue',
  ff563c: 'text-red',
  '00d750': 'text-green',
  f98d00: 'text-orange',
  '000000': 'text-black',
  ffffff: 'text-white',
};

export const backgroundColorMap: { [key: string]: string } = {
  '529aff': 'bg-blue',
  ff563c: 'bg-red',
  '00d750': 'bg-green',
  f98d00: 'bg-orange',
  '000000': 'bg-black',
  ffffff: 'bg-white',
};

type CssProperty = 'color' | 'background-color';

export function translateStylesToCustomClasses(htmlString: string): string {
  return htmlString.replace(/style="([^"]*)"/g, (rawStyle) => {
    const styleValue = rawStyle.split('=')[1].replace(/"/g, '');

    const stylePropertiesCount = styleValue.match(/:/g).length;
    const styleProperty = styleValue.split(':')[0] as CssProperty;

    if (stylePropertiesCount !== 1) {
      return rawStyle;
    }

    switch (styleProperty) {
      case 'color':
        return convertFontStyleToFontClass(styleValue);
      case 'background-color':
        return convertBgStyleToBgClass(styleValue);
      default:
        return rawStyle;
    }
  });
}

function convertFontStyleToFontClass(fontStyle: string) {
  const fontColor = fontStyle.split(':')[1].replace('#', '');
  const fontClass = fontColorMap[fontColor];
  return isNonEmpty(fontClass) ? `class="${fontClass}"` : '';
}

function convertBgStyleToBgClass(backgroundStyle: string) {
  const backgroundColor = backgroundStyle.split(':')[1].replace('#', '');
  const bgClass = backgroundColorMap[backgroundColor];
  return isNonEmpty(bgClass) ? `class="${bgClass}"` : '';
}
