import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
} from '@angular/core';

@Component({
  selector: 'sgxb-popin',
  templateUrl: './popin.component.html',
  styleUrls: ['./popin.component.scss'],
})
export class PopinComponent implements OnDestroy {
  private readonly crypto = window.crypto || window.msCrypto;
  private arrayForCrypto = new Uint32Array(1);
  private isHandlerAttached = false;
  private _displayed = false;

  @Output() public requestClose = new EventEmitter();
  @Output() public close = new EventEmitter();

  @Input() public id = this.crypto
    .getRandomValues(this.arrayForCrypto)
    .toString()
    .substring(2);
  @Input() public title = '';
  @Input() public closeable = true;
  @Input() public size = 'md';
  @Input() set displayed(displayed: boolean) {
    this._displayed = displayed;
    this.ngZone.runOutsideAngular(() => {
      window.$(`#${this.id}`).modal(displayed ? 'show' : 'hide');
      if (!this.isHandlerAttached && window.$(`#${this.id}`).length) {
        window
          .$(`#${this.id}`)
          .on('hide.bs.modal', (e) => {
            if (this._displayed && !this.closeable) {
              e.preventDefault();
              this.ngZone.run(() => this.requestClose.emit());
            }
          })
          .on('hidden.bs.modal', (_e) => {
            this.ngZone.run(() => this.close.emit());
          });
        this.isHandlerAttached = true;
      }
      if (!displayed) {
        window.$(`#${this.id}`).trigger('hide.bs.modal');
      }
    });
  }

  public ngOnDestroy() {
    if (this.isHandlerAttached) {
      this.ngZone.runOutsideAngular(() => {
        if (window.$(`#${this.id}`).length) {
          window.$(`#${this.id}`).modal('hide').off('.bs.modal');
        }
        window.$('.modal-backdrop').remove();
        window.$(document.body).css('padding-right', '');
        window.$(document.body).toggleClass('modal-open', false);
      });
    }
  }

  public isDisabled() {
    return !this.closeable;
  }

  constructor(private ngZone: NgZone) {}
}
