<nav>
  <ul class="pagination">
    <li class="first" [class.disabled]="isPreviousPageBtnDisabled()">
      <a (click)="navigateToFirstPage()">
        <i class="fa fa-angle-double-left"></i>
        <span class="sr-only">First</span>
      </a>
    </li>
    <li
      class="disabled previous"
      [class.disabled]="isPreviousPageBtnDisabled()"
    >
      <a (click)="navigateToPreviousPage()">
        <i class="fa fa-angle-left"></i>
        <span class="sr-only">Previous</span>
      </a>
    </li>
    <li
      [class.active]="isCurrentPage(i + 1)"
      *ngFor="let page of [].constructor(totalPages); let i = index"
    >
      <a (click)="navigateToPage(i + 1)">
        {{ i + 1 }}
      </a>
    </li>
    <li class="next" [class.disabled]="isNextPageBtnDisabled()">
      <a (click)="navigateToNextPage()">
        <i class="fa fa-angle-right"></i>
        <span class="sr-only">Next</span>
      </a>
    </li>
    <li
      class="last"
      [class.disabled]="isNextPageBtnDisabled()"
      disabled="isNextPageBtnDisabled"
    >
      <a (click)="navigateToLastPage()">
        <i class="fa fa-angle-double-right"></i>
        <span class="sr-only">Last</span>
      </a>
    </li>
  </ul>
</nav>
