import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatApproverCategory', pure: true })
export class FormatApproverCategoryPipe implements PipeTransform {
  public transform(value: string): any {
    return this.formatApproverCategory(value);
  }
  public formatApproverCategory(originalName: string): string | undefined {
    switch (originalName) {
      case 'managers':
        return 'Managers';
      case 'owner':
        return 'Submitter';
      case 'others':
        return 'Other participants';
      case 'complianceOfficers':
        return 'Compliance Officers';
      case 'supervisor':
        return 'Supervisor';
      default:
        return undefined;
    }
  }
}
