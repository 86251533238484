import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  MOCK_API_ACKNOWLEDGEMENT,
  MOCK_API_ACKNOWLEDGEMENTS,
  MOCK_API_ACKNOWLEDGEMENTS_EXPORT,
  MOCK_API_ACKNOWLEDGEMENTS_NOT_EXPIRED,
} from 'app/mock-api/mocks/acknowledgement.mocks';
import { ok } from 'app/mock-api/utils/response.utils';
import { Observable, of } from 'rxjs';
import { delay, dematerialize, materialize, mergeMap } from 'rxjs/operators';

@Injectable()
export class AcknowledgementRequestInterceptor implements HttpInterceptor {
  constructor() {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return <Observable<HttpEvent<any>>>of(null).pipe(
      mergeMap(() => {
        if (
          request.url.endsWith('/acknowledgements') &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_ACKNOWLEDGEMENTS);
          // return ok([]);
        } else if (
          request.url.match(/\/acknowledgements$/) &&
          request.params.get('withExpired').match(/^true$/) &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_ACKNOWLEDGEMENTS);
        } else if (
          request.url.match(/\/acknowledgements$/) &&
          request.params.get('withExpired').match(/^false$/) &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_ACKNOWLEDGEMENTS_NOT_EXPIRED);
        } else if (
          request.url.match(/\/acknowledgements$/) &&
          request.params.get('withExpired').match(/^false$/) &&
          request.params
            .get('traveler')
            .match(
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(socgen|sgcib).(com)(.[a-z]{2})?$/
            ) &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_ACKNOWLEDGEMENTS);
        } else if (
          request.url.match(/\/acknowledgements\/([A-Z]{2})$/) &&
          request.method === 'POST'
        ) {
          return ok(MOCK_API_ACKNOWLEDGEMENT);
        } else if (
          request.url.match(
            /\/export\/acknowledgements\/(([A-Z]{2})|(all))$/
          ) &&
          request.method === 'GET'
        ) {
          return ok(MOCK_API_ACKNOWLEDGEMENTS_EXPORT);
        }

        return next.handle(request);
      }),
      materialize(),
      delay(300),
      dematerialize()
    );
  }
}
