import { BusinessUnitsResponse } from '@models/BusinessUnit';

export const MOCK_API_BUSINESS_UNITS: BusinessUnitsResponse = {
  businessUnits: ['MARK', 'GLBA', 'GTPS', 'CORI', 'GLFI'],
};
export const MOCK_API_BUSINESS_UNITS_UNDER_PERIMETER: BusinessUnitsResponse = {
  businessUnits: ['MARK', 'GBSU'],
};
export const MOCK_API_BUSINESS_UNITS_FILTER: BusinessUnitsResponse = {
  businessUnits: ['ALL', 'MARK', 'GLBA', 'GTPS'],
};
