export const ConfigureCountryRouteRegex = new RegExp(
  /^\/configure\/country\/([A-Z]{2})/
);
export const ConfigureCtaSpecificRouteRegex = new RegExp(
  /^\/configure\/cta-specific\/([A-Z]{2})/
);
export const ConfigureGuidelineRouteRegex = new RegExp(
  /^\/configure\/guideline\/([A-Z]{2})(\/((-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?))?/
);
export const ConfigureRouteRegex = new RegExp(/^\/configure\/([A-Z]{2})/);
export const CreateCTARouteRegex = new RegExp(/^\/travel-approval\/create/);
export const CreateCountryRouteRegex = new RegExp(
  /^\/configure\/country\/create/
);
export const ViewGuidelineRouteRegex = new RegExp(/^\/view\/([A-Z]{2})/);
export const ApprovalCtaRouteRegex = new RegExp(
  // /^\/approvals\/([0-9a-fA-F-]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12})\/(approve|reject)/,
  /^\/approvals/
);
export const ExportRouteRegex = new RegExp(/^\/export/);
export const ContactsDetailsRouteRegex = new RegExp(
  /^\/contacts-details\/([a-z,-]+)/
);
