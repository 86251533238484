import { Component, Input } from '@angular/core';
import { ComplianceTravelApproval } from '@models/ComplianceTravelApproval';
import { PiwikActions, PiwikCategories } from '@models/Piwik';
import { MatomoTracker } from '@ngx-matomo/tracker';

@Component({
  selector: 'sgxb-travel-approval-activity-ta',
  templateUrl: './travel-approval-activity-ta.component.html',
  styleUrls: ['./travel-approval-activity-ta.component.scss'],
})
export class TravelApprovalActivityTaComponent {
  @Input() public tas: ComplianceTravelApproval[];

  constructor(private matomoTracker: MatomoTracker) {}

  public trackEditCTA(cta: ComplianceTravelApproval): void {
    this.matomoTracker.trackEvent(
      PiwikCategories.ComplianceTravelApproval,
      PiwikActions.EditCtaRecord,
      `${cta.origin.name}-${cta.destination.name}`
    );
  }
}
