export function updateItemByIndexInObjectArray(
  initialArray,
  index: number,
  newProperties
) {
  const newArray = Object.assign([...initialArray], {
    [index]: Object.assign({}, initialArray[index], { ...newProperties }),
  });
  return newArray;
}
