import { ModalActions } from './modal.actions';
import { ModalState, initialModalState } from './modal.models';

export function modalReducer(
  state: ModalState = initialModalState,
  action: ModalActions
): ModalState {
  switch (action.type) {
    case 'CONFIRM_MODAL': {
      return {
        ...state,
        modal: {
          ...state.modal,
          type: action.type,
          title: action.title,
          content: action.content,
          successCallback: action.onSuccess,
          failCallback: action.onFail,
        },
      };
    }
    default:
      return state;
  }
}
